export const amenityData = [
  {
    mapID: "masterplan",
    x: 1784.17,
    y: 2688.17,
    activeState: "metro",
    level: null,
  },
  {
    mapID: "masterplan",
    x: 3284.17,
    y: 2012.17,
    activeState: "metro",
    level: null,
  },
  {
    mapID: "masterplan",
    x: 2433.17,
    y: 1934.17,
    activeState: "metro",
    level: null,
  },
  {
    mapID: "masterplan",
    x: 2336.17,
    y: 1272.17,
    activeState: "metro",
    level: null,
  },
  {
    mapID: "diriyah-square",
    x: 2085,
    y: 1556,
    activeState: "restrooms",
    level: "B3",
  },
  {
    mapID: "diriyah-square",
    x: 1941,
    y: 1987,
    activeState: "restrooms",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2106,
    y: 2519,
    activeState: "restrooms",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 1624,
    y: 1915,
    activeState: "restrooms",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 1941,
    y: 1987,
    activeState: "restrooms",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2218,
    y: 2027,
    activeState: "restrooms",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2106,
    y: 1733,
    activeState: "restrooms",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2085,
    y: 1556,
    activeState: "restrooms",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 1868,
    y: 1806,
    activeState: "restrooms",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2058,
    y: 1733,
    activeState: "restrooms",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2244,
    y: 1792,
    activeState: "restrooms",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 1837,
    y: 2108,
    activeState: "restrooms",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 1946,
    y: 2218,
    activeState: "restrooms",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2127,
    y: 2108,
    activeState: "restrooms",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2218,
    y: 2108,
    activeState: "restrooms",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2341,
    y: 2223,
    activeState: "restrooms",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2201,
    y: 2286,
    activeState: "restrooms",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2325,
    y: 2561,
    activeState: "restrooms",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2161,
    y: 2484,
    activeState: "restrooms",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2034,
    y: 2685,
    activeState: "restrooms",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2122,
    y: 1713,
    activeState: "driver-lounges",
    level: "B3",
  },
  {
    mapID: "diriyah-square",
    x: 2066,
    y: 1730,
    activeState: "welcome-halls",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 1941,
    y: 2043,
    activeState: "welcome-halls",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2422,
    y: 2577,
    activeState: "welcome-halls",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2466,
    y: 2258,
    activeState: "welcome-halls",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2222,
    y: 2070,
    activeState: "welcome-halls",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2445,
    y: 1825,
    activeState: "welcome-halls",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2803,
    y: 2048,
    activeState: "welcome-halls",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2099,
    y: 1600,
    activeState: "welcome-halls",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2094,
    y: 2508,
    activeState: "welcome-halls",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2803,
    y: 2048,
    activeState: "welcome-halls",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2445,
    y: 1825,
    activeState: "welcome-halls",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2099,
    y: 1600,
    activeState: "welcome-halls",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2066,
    y: 1730,
    activeState: "welcome-halls",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 1941,
    y: 2043,
    activeState: "welcome-halls",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2222,
    y: 2070,
    activeState: "welcome-halls",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2466,
    y: 2258,
    activeState: "welcome-halls",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2094,
    y: 2508,
    activeState: "welcome-halls",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2422,
    y: 2577,
    activeState: "welcome-halls",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2099,
    y: 1600,
    activeState: "welcome-halls",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 1941,
    y: 2043,
    activeState: "welcome-halls",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2222,
    y: 2070,
    activeState: "welcome-halls",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2803,
    y: 2048,
    activeState: "welcome-halls",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2466,
    y: 2258,
    activeState: "welcome-halls",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2445,
    y: 1825,
    activeState: "welcome-halls",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2087,
    y: 2527,
    activeState: "welcome-halls",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2422,
    y: 2577,
    activeState: "welcome-halls",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2066,
    y: 1730,
    activeState: "welcome-halls",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2066,
    y: 1730,
    activeState: "welcome-halls",
    level: "B3",
  },
  {
    mapID: "diriyah-square",
    x: 1941,
    y: 2043,
    activeState: "welcome-halls",
    level: "B3",
  },
  {
    mapID: "diriyah-square",
    x: 2087,
    y: 2527,
    activeState: "welcome-halls",
    level: "B3",
  },
  {
    mapID: "diriyah-square",
    x: 2099,
    y: 1600,
    activeState: "welcome-halls",
    level: "B3",
  },
  {
    mapID: "diriyah-square",
    x: 2729,
    y: 2039,
    activeState: "concierge",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2496,
    y: 2214,
    activeState: "concierge",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2536,
    y: 1820,
    activeState: "concierge",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2225,
    y: 1996,
    activeState: "concierge",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2289,
    y: 2584,
    activeState: "concierge",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2079,
    y: 1681,
    activeState: "concierge",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2108,
    y: 2516,
    activeState: "concierge",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2192,
    y: 2043,
    activeState: "valet-lounges",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2192,
    y: 2043,
    activeState: "valet-lounges",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2192,
    y: 2043,
    activeState: "valet-lounges",
    level: "1",
  },
  {
    mapID: "diriyah-square",
    x: 2192,
    y: 2043,
    activeState: "vip-lounges",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2192,
    y: 2043,
    activeState: "vip-lounges",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2192,
    y: 2043,
    activeState: "vip-lounges",
    level: "1",
  },
  {
    mapID: "diriyah-square",
    x: 2344,
    y: 1849,
    activeState: "vvip-lounges",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2344,
    y: 1849,
    activeState: "vvip-lounges",
    level: "1",
  },
  {
    mapID: "diriyah-square",
    x: 2344,
    y: 1849,
    activeState: "vvip-lounges",
    level: "0",
  },
  {
    mapID: "diriyah-square",
    x: 2107.83,
    y: 2422.88,
    activeState: "ev",
    level: "B3",
  },
  {
    mapID: "diriyah-square",
    x: 2156.58,
    y: 2401.21,
    activeState: "ev",
    level: "B3",
  },
  {
    mapID: "diriyah-square",
    x: 2140.33,
    y: 2367.62,
    activeState: "ev",
    level: "B3",
  },
  {
    mapID: "diriyah-square",
    x: 1923.67,
    y: 2027.46,
    activeState: "ev",
    level: "B3",
  },
  {
    mapID: "diriyah-square",
    x: 1930.17,
    y: 2064.29,
    activeState: "ev",
    level: "B3",
  },
  {
    mapID: "diriyah-square",
    x: 1889,
    y: 2031.79,
    activeState: "ev",
    level: "B3",
  },
  {
    mapID: "diriyah-square",
    x: 2119.75,
    y: 1807.54,
    activeState: "ev",
    level: "B3",
  },
  {
    mapID: "diriyah-square",
    x: 2154.42,
    y: 1783.71,
    activeState: "ev",
    level: "B3",
  },
  {
    mapID: "diriyah-square",
    x: 2112.17,
    y: 1745.79,
    activeState: "ev",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2475.08,
    y: 1732.79,
    activeState: "ev",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2494.58,
    y: 1837.88,
    activeState: "ev",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2238.92,
    y: 1954.88,
    activeState: "ev",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2183.67,
    y: 1939.71,
    activeState: "ev",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2188,
    y: 2102.21,
    activeState: "ev",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2329.92,
    y: 2102.21,
    activeState: "ev",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2516.25,
    y: 2225.71,
    activeState: "ev",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2509.75,
    y: 2298.29,
    activeState: "ev",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2472.92,
    y: 2300.46,
    activeState: "ev",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2149,
    y: 2464.04,
    activeState: "ev",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2401.42,
    y: 2540.96,
    activeState: "ev",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2469.67,
    y: 2266.88,
    activeState: "ev",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 1963.75,
    y: 1998.04,
    activeState: "ev",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2210.75,
    y: 1721.96,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2448,
    y: 1844.38,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2478.33,
    y: 1883.38,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2503.25,
    y: 1863.88,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2503.25,
    y: 1827.04,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2190.17,
    y: 1944.04,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2184.75,
    y: 1916.96,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2225.92,
    y: 2079.46,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2399.25,
    y: 2181.29,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2505.42,
    y: 2201.88,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2429.58,
    y: 2249.54,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2441.5,
    y: 2317.79,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2396,
    y: 2539.88,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2433.92,
    y: 2607.04,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2078.58,
    y: 2522.54,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2059.08,
    y: 2486.79,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2072.08,
    y: 2448.88,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2020.08,
    y: 2477.04,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2040.67,
    y: 2516.04,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2056.92,
    y: 2551.79,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2106.75,
    y: 2228.96,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2121.92,
    y: 2263.62,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 1739.5,
    y: 2282.04,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 1739.5,
    y: 2246.29,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 1744.92,
    y: 2302.62,
    activeState: "ev",
    level: "B1",
  },
  {
    mapID: "diriyah-square",
    x: 2138.17,
    y: 1536.71,
    activeState: "bus",
    level: "B3",
  },
  {
    mapID: "diriyah-square",
    x: 2852.08,
    y: 2027.46,
    activeState: "bus",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2169.58,
    y: 2027.46,
    activeState: "taxi",
    level: "B2",
  },
  {
    mapID: "diriyah-square",
    x: 2212.92,
    y: 1980.88,
    activeState: "valet",
    level: "B1",
  },
]
