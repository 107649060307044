import styled from "styled-components"
import { QRCodeSVG } from "qrcode.react"
import { AnimatePresence, motion } from "framer-motion"

type TShareQRProps = {
  url: string
}

const ShareQR = ({ url }: TShareQRProps) => {
  return (
    <AnimatePresence>
      <QRWrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 200 } }}
        transition={{ duration: 0.2 }}
      >
        <QR
          value={url}
          bgColor="transparent"
          fgColor="#004344"
          level="Q"
          includeMargin
          id="qr"
					size={250}
        />
      </QRWrapper>
    </AnimatePresence>
  )
}

export default ShareQR

const QRWrapper = styled(motion.div)`
  position: absolute;
  background-color: rgba(256, 256, 256, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 74.5%;
  transition: 0.3;
  border-radius: 5px;
  overflow: hidden;

  /* @media screen and (min-width: 1920px) {
    height: 23rem;
  } */
`

const QR = styled(QRCodeSVG)``
