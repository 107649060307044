const data = {
  metro: [
    {
      key: "line-1",
      label: "King Khaled Road",
      dashLength: 1072,
      initialDashOffset: 1072,
      path: "m1205.97,585.27l16.58,73.18c4.39,17.26,2.47,60.02-16.58,86.36-10.33,15.47-40.87,70.56-50.12,89.06-13.34,24.02-19.28,75.57-19.66,120.68,0,16.42-.53,100.64-.02,143.44-9.5,64.33-36.45,80.84-69.38,129.53-13.78,17.95-53.65,61.76-71.12,101.14-8.36,12.89-8.33,48.44-8.33,64.67.61,26.54-2.41,45.51-2.67,49.33-.98,14.8-17.09,38.55-41,67-10.74,12.41-26.45,35.54-43,55.67",
      color: "#926542",
    },
    {
      key: "line-2",
      label: "Northern Ring Road",
      dashLength: 1096,
      initialDashOffset: 1096,
      path: "m1074.21,1006.07l522.71-43.71c47.11-3.82,70.06,12.16,137.44,67.53l313.63,270.62",
      color: "#55697C",
    },
  ],
  car: [
    {
      key: "northern-ring-road",
      label: "Northern Ring Road",
      dashLength: 811,
      initialDashOffset: 811,
      path: "m2049,255.89l-427.89,217.13s-110.02,61.07-130.86,76.09c-26.17,16.48-80.45,48.47-95.96,55.25s-51.42,24.44-60.87,32.42",
      color: "#C2665d",
    },
    {
      key: "king-khaled-road",
      label: "King Khaled Road",
      dashLength: 1280,
      initialDashOffset: 1280,
      path: "m2048,1317.01s-218.58-174.18-274.5-219.71c-53.94-43.05-193.48-154.89-209.81-167.26-38.1-27.22-115.3-88.58-157.36-148.45-35.13-66.8-62.35-119.75-76.21-152.41-14.35-34.64,10.89-46.52,10.89-46.52,25.73-7.92,32.16,29.2,20.29,35.63s-67.3,44.54-82.14,66.31c-10.39,11.88-40.61,41.36-40.61,41.36",
      color: "#008080",
    },
    {
      key: "western-ring-road",
      label: "Western Ring Road",
      dashLength: 1140,
      initialDashOffset: 1140,
      path: "m1139.72,1088.74s-3.16,121.84-4.56,142.9c-2.81,23.17-19.66,121.48-36.87,153.44-9.13,21.07-45.64,111.65-100.42,184.68-26.68,39.68-32.3,46.35-70.22,78.3-20.36,20.72-173.8,169.94-196.27,195.57-24.93,30.55-81.81,100.46-135.53,204.37",
      color: "#9F8856",
    },
    {
      key: "western-ring-road-2",
      label: "Western Ring Road - 2",
      dashLength: 1055,
      initialDashOffset: 1055,
      path: "m1042.48,1s45.15,95.7,67.85,151.93c20.63,58.29,48.49,134.64,54.68,150.63,11.35,30.95,40.75,130.51,67.06,173.84,26.31,44.36,55.71,94.92,48.49,144.96-8.77,70.67-58.81,125.35-58.81,125.35,0,0-66.08,65.07-74.26,151.22-4.98,49.87-5.08,74.21-5.08,74.21",
      color: "#9F8856",
    },
  ],
  lengths: {
    car: {
      0: 811,
      1: 811,
      2: 811,
      3: 1280,
      4: 1280,
      5: 1280,
      6: 1140,
      7: 1140,
      8: 1140,
      9: 1055,
      10: 1055,
      11: 1055,
    },
    metro: {
      0: 1072,
      1: 1072,
      2: 1072,
      3: 1096,
      4: 1096,
      5: 1096,
    },
  },
}

export default data
