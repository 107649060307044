import { Route, Routes, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { routes } from "@data/routes"
import Navbar from "@components/navbar"
import Tour360 from "@components/Tour360"
import PopuGallery from "@components/PopuGallery"

import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { Login } from "./auth/components/Login"
import { useAuth } from "./auth/core/Auth"
import { useEffect } from "react"

const firebaseConfig = {
  apiKey: "AIzaSyCSnDquR7VIM9a96au3e1RWNCvGQAzRuBU",
  authDomain: "luna-auth-eed78.firebaseapp.com",
  projectId: "luna-auth-eed78",
  storageBucket: "luna-auth-eed78.appspot.com",
  messagingSenderId: "42982071646",
  appId: "1:42982071646:web:d2e88fc39761e858f783cf",
}

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)

function App() {
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!currentUser) {
      navigate("/login")
    }
  }, [currentUser])

  return (
    <LayoutWrapper>
      {currentUser ? (
        <>
          <Navbar />
          <Routes>
            {routes.map((route: any, idx: number) => {
              return (
                <Route
                  key={idx}
                  path={route.path}
                  element={<route.element />}
                />
              )
            })}
          </Routes>
          <Tour360 />
          <PopuGallery />
        </>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
        </Routes>
      )}
    </LayoutWrapper>
  )
}

const LayoutWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export default App
