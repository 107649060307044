import { useRef, useEffect, useLayoutEffect, forwardRef, Fragment } from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import gsap from "gsap"

import MapSVGIconGroup from "@components/MapSVGIconGroup"

import { useStore } from "@state/store"
import getMarkDown from "@utils/getMapMarkDown"
import dsqRetailOverlays from "@data/diriyah-square/dsqRetailOverlays"
import dsqRetailIcons from "@data/diriyah-square/dsqRetailIcons"

const DSQ2 = forwardRef(function DSQ(props: any, ref: any) {
  const { navDurationSec, mapCategory, setLocalState } = useStore((s) => ({
    navDurationSec: s.navDurationSec,
    mapCategory: s.mapCategory,
    setLocalState: s.setLocalState,
  }))
  const navigate = useNavigate()
  const timeline = gsap.timeline()
  const { videoPlayed, showUI, setShowUI, handleDataChange } = props

  function handleIconClick(obj) {
    if (obj.label === "OVERVIEW") {
      setLocalState({
        dsqOrbitDir: false,
        dsqOrbitPlaying: true,
      })

      setTimeout(() => {
        setLocalState({
          mapCategory: null,
        })
      }, 9000)
      return
    }
    setLocalState({
      navigating: true,
      navOpen: false,
      activeHotel: null,
      amenityCategory: null,
      mapCategory: obj.label === "FIND A UNIT" ? null : obj.activeState,
      unitLevel: null,
      selectedUnit: null,
    })
    setTimeout(() => {
      navigate("/unit-finder")
    }, navDurationSec * 1100)
  }
  return (
    <SVG
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid slice"
      viewBox="0 0 2048 2048"
      onDoubleClick={(e) => {
        e.stopPropagation()
        setShowUI(!showUI)
      }}
      className="centerABS"
      ref={ref}
    >
      <image
        x={0}
        y={0}
        width={2048}
        height={2048}
        href={"/assets/images/dsq/dsq2.webp"}
      />
      <g className={`mapUI ${showUI ? "show" : "hide"}`}>
        <g>
          {dsqRetailOverlays.map((obj, i) => {
            return getMarkDown(obj, i, null, null)
          })}
        </g>
        <g>
          {dsqRetailIcons.map((obj, i) => {
            return (
              <Fragment key={`icon-${i}`}>
                {!obj.label.includes("OVER") && (
                  <SVGPole data={obj} className=".secondary-icon" />
                )}
                <MapSVGIconGroup
                  label={obj.label}
                  x={obj.x}
                  y={obj.y - 100}
                  height={obj.height}
                  icon={obj.icon}
                  //@ts-ignore
                  iconPosition={
                    obj.label.includes("OVER") ? "right" : "center-top"
                  }
                  //@ts-ignore
                  iconShape={obj.iconShape}
                  bgColor={obj.bgColor}
                  textColor={obj.textColor}
                  strokeColor={obj.strokeColor}
                  strokeWidth={obj.strokeWidth}
                  hasIcon
                  className=".secondary-icon"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleIconClick(obj)
                  }}
                  style={{
                    cursor: "pointer"
                  }}
                />
              </Fragment>
            )
          })}
        </g>
      </g>
    </SVG>
  )
})

export default DSQ2

const SVG = styled.svg``

function SVGPole({ data, ...props }) {
  return (
    <g {...props}>
      <line
        x1={data.x}
        y1={data.y - 100}
        x2={data.x}
        y2={data.y}
        stroke={data.strokeColor}
        strokeWidth={data.strokeWidth + 1}
      />
      <circle cx={data.x} cy={data.y} r={5} fill={data.strokeColor} />
    </g>
  )
}
