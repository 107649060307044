import { Fragment, useEffect, useRef } from "react"
import styled from "styled-components"
import gsap from "gsap"

type TWrapperProps = {
  color?: string
  width?: string
  [x: string]: any
}

type TIconProps =
  | (TWrapperProps & {
      onClick?: () => void
      isAnimated?: undefined | false
    })
  | (TWrapperProps & {
      onClick?: () => void
      onClickDelay?: number
      isAnimated?: true
      cue: boolean
    })

function BurgerIcon({
	color = "white",
	width = "1vw",
	onClick,
	onClickDelay = 500,
	isAnimated = false,
	cue,
	...props
}: TIconProps) {
	const topRef = useRef(null)
	const middleRef = useRef(null)
	const bottomRef = useRef(null)
  const timeline = gsap.timeline()
	function handleClick() {
		if (onClick) {
			setTimeout(() => {
				onClick()
			}, onClickDelay)
		}
	}
	useEffect(() => {
		if(isAnimated){
      timeline.to(middleRef.current, {
        x: cue ? "150%" : "0%",
      })
      timeline.to(topRef.current, {
        y: cue ? "500%" : "25%",
      }, "<")
      timeline.to(bottomRef.current, {
        y: cue ? "-500%" : "25%",
      }, "<")
      timeline.to(topRef.current, {
        rotation: cue ? "45deg" : "0deg",
      }, cue ? ">" : 0)
      timeline.to(bottomRef.current, {
        rotation: cue ? "-45deg" : "0deg",
      }, cue ? "<" : 0)
    }
	}, [cue])

	return (
		<BurgerWrapper onClick={handleClick} color={color} width={width} {...props}>				
					<hr
						ref={topRef}
					/>
					<hr
						ref={middleRef}
					/>
					<hr
						ref={bottomRef}
					/>
		</BurgerWrapper>
	)
}

export default BurgerIcon

const BurgerWrapper =	styled.div <TWrapperProps>`
  position: relative;
  cursor: pointer;
  width: ${p => p.width};
  height: ${p => p.width};
  overflow: hidden;

  & hr {
    position: absolute;
    width: 50%;
    height: 5%;
    border: none;
    outline: none;
    border-radius: 50px;
    background-color: ${p => p.color};
    -webkit-transform-origin: center;
    transform-origin: center;

    :nth-of-type(1) { 
      top: 25%;
      left: 25%;
    }
    :nth-of-type(2) {
      top: 50%;
      left: 15%;
      width: 70%;
    }
    :nth-of-type(3) {
      left: 25%;
      top: 75%;
    }
  }
`
