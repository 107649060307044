import React from "react"
import { theme } from "../../theme/theme.js"
import styled from "styled-components"
import SVG from "react-inlinesvg"
import { MotionProps, motion } from "framer-motion"

type DiamondButtonProps = {
  icon?: string
  size?: "small" | "medium" | "large"
  color?: "green" | "brown" | "white" | "light-green" | "outline"
  style?: React.CSSProperties
  className?: string
  onClick?: () => void
} & MotionProps

const colorMap = {
  green: theme.darkGreen,
  brown: theme.lightBrown,
  white: theme.white,
  "light-green": theme.lightGreen,
  outline: "transparent",
}

const mapColor = (color: string) => colorMap[color] || theme.darkGreen

export const DiamondButton: React.FC<DiamondButtonProps> = ({
  size,
  icon,
  color,
  className,
  onClick,
  style,
  ...rest
}) => {
  const effectiveColor = mapColor(color)

  return (
    <Wrapper
      {...rest}
      className={className}
      color={effectiveColor}
      size={size}
      onClick={onClick}
      style={style}
    >
      <StyledSvg
        style={{ cursor: "pointer" }}
        src={icon}
        title="Close"
        // onClick={onClick}
      />
    </Wrapper>
  )
}

const Wrapper = styled(motion.div)<{ color: string; size: string }>`
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ color }) => color};
  transform: rotate(45deg);
  height: ${({ size }) => (size === "small" ? "1.5rem" : "3rem")};
  width: ${({ size }) => (size === "small" ? "1.5rem" : "3rem")};
  box-shadow: inset 0 0 0 1px
    ${({ theme, color }) =>
      color === "transparent"
        ? theme.darkGreen
        : color === theme.lightGreen
        ? theme.darkGreen
        : color};
  transition: all 0.3s ease-in-out;

  & path,
  rect {
    stroke: ${({ theme, color }) =>
      color === theme.lightGreen || color === theme.white
        ? theme.darkGreen
        : color === "transparent"
        ? theme.darkGreen
        : theme.white};
  }
`
//@ts-ignore
const StyledSvg = styled(SVG)`
  height: 1.5rem;
  width: 1.5rem;
  transform: rotate(-45deg);
`
