import styled from "styled-components"
import SVG from "react-inlinesvg"

interface SmallBoxProps {
  title: string
  data: any
  suffix?: string
  icon?: string
}

const SmallBox: React.FC<SmallBoxProps> = ({ title, data, suffix, icon }) => {
  return (
    <SmallBoxWrapper className="small-box">
      <div className="data">
        {data}
        <span>{suffix}</span>
      </div>
      <div className="title">{title}</div>
      {icon && (
        <div className="icon">
          {/* @ts-ignore */}
          <SVG src={icon} />
        </div>
      )}
    </SmallBoxWrapper>
  )
}

export default SmallBox

const SmallBoxWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 5rem;
  border-radius: 4px;
  padding: 10px 20px;
  background: ${({ theme }) => theme.lightGreen};
  color: ${({ theme }) => theme.darkGreen};

  &:nth-child(2n) {
    background: #f0f0ef;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 20px;
    opacity: 0.5;
    z-index: 1;

    svg {
      width: 1.8rem;
      height: 1.8rem;

      path {
        fill: ${({ theme }) => theme.darkGreen};
      }
    }
  }

  .title {
    font-size: 0.8rem;
    font-weight: 400;
    font-family: "BeausiteClassicTrial-Clear";
    width: 60%;
    padding: 0 1rem;
    text-transform: uppercase;
    z-index: 2;
  }

  .data {
    z-index: 2;
    font-size: 1.5rem;
    line-height: 0;
    font-weight: 400;
    color: ${({ theme }) => theme.darkGreen};
    font-family: "BeausiteGrandTrial-Light";

    & span {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }
`
