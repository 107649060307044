import { Fragment } from "react"
import styled from "styled-components"

import MapAmenityIcons from "@components/MapAmenityIcons"
import DropDown from "@components/DropDown"

import { useStore } from "@state/store"

const MapStatic = () => {
  const miniMap = useStore((s) => s.miniMap)
  const { unitLevel, setLocalState } = useStore((s) => ({
    unitLevel: s.unitLevel,
    setLocalState: s.setLocalState,
  }))
  const levels = ["1", "0", "B1", "B2", "B3"] as any

  return (
    <Wrapper>
      <StaticMapsSVGWrapper>
        <BackgroundImageElement />
        <g transform="scale(0.366) translate(-450 -160)">
          <MapAmenityIcons miniView={true} />
        </g>
      </StaticMapsSVGWrapper>
      <LevelFilter id="mini-map-filter">
        <DropDown
          button={
            <Button>
              <img src="/assets/icons/common/stairs.svg" />
            </Button>
          }
          dropContent={
            <Content>
              {levels.map((lvl, i) => (
                <Button
                  key={`lvl-btn${i}`}
                  style={{
                    opacity: unitLevel === lvl ? 1 : 0.5,
                  }}
                  onClick={() => {
                    setLocalState({ unitLevel: lvl })
                  }}
                >
                  {lvl === "0" ? "G" : lvl}
                </Button>
              ))}
            </Content>
          }
          autoClose={false}
          defaultState={true}
        />
      </LevelFilter>
    </Wrapper>
  )
}

function BackgroundImageElement() {
  const unitLevel = useStore((s) => s.unitLevel)
  const miniMapBgLvls = ["B3", "B2", "B1", "0", "1", null]
  return (
    <Fragment>
      {miniMapBgLvls.map((lvl, i) => {
        return (
          <image
            key={`bg-${i}`}
            x={0}
            y={0}
            href={`/assets/mini-map/1500/${lvl === null ? "roof" : lvl}.webp`}
            opacity={unitLevel === lvl ? 1 : 0}
          />
        )
      })}
    </Fragment>
  )
}

const StaticMapsSVGWrapper = ({ children }) => {
  return (
    <svg
      x="0"
      y="0"
      height="100%"
      width="100%"
      viewBox="0 0 1500 1500"
      preserveAspectRatio="xMidYMid slice"
    >
      {children}
    </svg>
  )
}

export default MapStatic

const Wrapper = styled.div`
  background-image: url("/assets/mini-map/1500/roof.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const LevelFilter = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: var(--margin);
  left: var(--margin);
  display: flex;
`

const Button = styled.div`
  background-color: rgba(var(--dark-green-rgb));
  width: 40px;
  height: 40px;
  left: var(--margin);
  border-radius: 4px;
  display: grid;
  place-content: center;
  pointer-events: all;
  cursor: pointer;
  margin-top: auto;
  color: var(--white);
  font-weight: 100;
  font-size: 0.7rem;
  font-family: "Arial";
  transition: opacity 0.4s ease-out;
`

const Content = styled.div`
  background-color: var(--dark-green);
`
