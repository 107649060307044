import { Fragment, useEffect } from "react"
import styled from "styled-components"
import { useMap } from "react-leaflet"
import gsap from "gsap"

import unitFinderZoneOverlayData from "@data/unit-finder/unitFinderZoneOverlays"
import getMarkDown from "@utils/getMapMarkDown"
import useRasterCoords from "@hooks/leaflet/useRasterCoords"
import { useStore } from "@state/store"

function ZonesLayer({
  img,
  active,
}: {
  img: [width: number, height: number]
  active: boolean
}) {
  const { unitLevel, mapCategory, navigating, unitID } = useStore((s) => ({
    unitLevel: s.unitLevel,
    mapCategory: s.mapCategory,
    navigating: s.navigating,
    unitID: s.unitID,
  }))
  const MAP = useMap()
  // const rasterCoords = useRasterCoords(img)

  useEffect(() => {
    if (!MAP) return
    if (mapCategory === null) {
      return
    } else {
      if (!unitID) {
        const center = centers[mapCategory]
        MAP.flyTo(center.coords, center.zoom, { duration: 0.4 })
      }
    }
  }, [mapCategory])

  return active ? (
    <Fragment>
      {unitFinderZoneOverlayData.map((obj, i) => {
        return obj.basement
          ? (mapCategory === obj.link || mapCategory === null) &&
              unitLevel === "B1" && (
                <Group key={i} transform={obj.transform}>
                  {getMarkDown(obj, i, null, null)}
                </Group>
              )
          : (mapCategory === null || mapCategory === obj.link) &&
              !unitLevel?.includes("B") && (
                <Group key={i}>{getMarkDown(obj, i, null, null)}</Group>
              )
      })}
    </Fragment>
  ) : null
}

export default ZonesLayer

const Group = styled.g`
  .blur-line {
    -webkit-filter: blur(5px);
    filter: blur(5px);
  }

  & path {
    stroke-linecap: round;
  }
`

const centers = {
  "luxury-s": {
    zoom: 5,
    coords: { lat: 48.777912755501845, lng: -54.65798019477333 },
  },
  culture: {
    zoom: 5,
    coords: { lat: 48.54323337741138, lng: -65.09239273307409 },
  },
  lifestyle: {
    zoom: 5,
    coords: { lat: 45.89937263125754, lng: -67.77022129568289 },
  },
  anchor: {
    zoom: 5,
    coords: {
      lat: 60.19615576604439,
      lng: -71.76388862478133,
    },
  },
  "premium-dining": {
    zoom: 5,
    coords: { lat: 63.84211402927347, lng: -89.50990904317484 },
  },
  "luxury-n": {
    zoom: 5,
    coords: {
      lat: 62.935234870604724,
      lng: -52.38579344087531,
    },
  },
  entertainment: {
    zoom: 6,
    coords: { lat: 66.37160759705864, lng: -68.95654477887842 },
  },
  department: {
    zoom: 5,
    coords: { lat: 69.96043926902489, lng: -61.51729047699764 },
  },
  "lifestyle-convenience-B1": {
    zoom: 5,
    coords: { lat: 62.63376960786813, lng: -76.52915493520595 },
  },
  "lifestyle-homewares-B1": {
    zoom: 5,
    coords: { lat: 69.72572191418737, lng: -61.18820495935535 },
  },
}
