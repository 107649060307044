import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import TabButton from "./components/TabButton"
import TabContent from "./components/TabContent"
import * as Slider from "@radix-ui/react-slider"
import { useStore } from "@state/store"
import { DiamondButton } from "@components/buttons"
import PageWrapper from "@components/PageWrapper"

export const ProgressBar = ({ handleSkipVideo, value, onChange }) => {
  const [isDragging, setIsDragging] = useState(false)

  return (
    <ProgressWrapper>
      <Slider.Root
        className="SliderRoot"
        defaultValue={[value]}
        {...(!isDragging && { value: [value] })}
        max={100}
        step={1}
        orientation="vertical"
        inverted={true}
        onValueChange={(value) => {
          setIsDragging(true)
          onChange(value[0])
        }}
        onPointerUp={() => setIsDragging(false)}
        onPointerDown={() => setIsDragging(true)}
        onValueCommit={() => setIsDragging(false)}
        onTouchStart={() => setIsDragging(true)}
        onTouchEnd={() => setIsDragging(false)}
      >
        <Slider.Track className="SliderTrack">
          <Slider.Range className="SliderRange" />
        </Slider.Track>
        <Slider.Thumb className="SliderThumb" />
      </Slider.Root>
      {handleSkipVideo && (
        <div className="skip-btn" onClick={handleSkipVideo}>
          Skip
        </div>
      )}
    </ProgressWrapper>
  )
}

const Features: React.FC<any> = () => {
  const showDetails = useStore((s) => s.showDetails)
  const navigating = useStore((s) => s.navigating)
  const [selectedTab, setSelectedTab] = useState(1)
  const videoRef = useRef(null)

  const [sliderValue, setSliderValue] = useState(0)

  useEffect(() => {
    const video = videoRef.current
    if (!video) return

    const updateSlider = () => {
      const value = (video.currentTime / video.duration) * 100
      setSliderValue(value)
    }

    video.addEventListener("timeupdate", updateSlider)

    return () => {
      video.removeEventListener("timeupdate", updateSlider)
    }
  }, [])

  const handleSliderChange = (value: number) => {
    const video = videoRef.current
    if (!video) return

    const currentTime = (value / 100) * video.duration
    video.currentTime = currentTime
  }

  const { setShowNavbar, fullscreenFeaturesVideo, setFullscreenFeaturesVideo } =
    useStore((state) => ({
      setShowNavbar: state.setShowNavbar,
      fullscreenFeaturesVideo: state.fullscreenFeaturesVideo,
      setFullscreenFeaturesVideo: state.setFullscreenFeaturesVideo,
    }))

  useEffect(() => {
    if (fullscreenFeaturesVideo) setShowNavbar(false)
    else setShowNavbar(true)
  }, [fullscreenFeaturesVideo])

  const handleTabClick = (tabNumber: number) => {
    setSelectedTab(tabNumber)
  }

  const tabs = [
    { id: 1, label: "KEY FEATURES" },
    { id: 2, label: "GUEST AMENITIES" },
    { id: 3, label: "TENANT FACILITIES" },
    { id: 4, label: "DESIGN PRINCIPLES" },
  ]

  const handleExpandVideo = () => {
    setFullscreenFeaturesVideo(true)
    videoRef.current.play()
  }

  const handleSkipVideo = () => {
    setFullscreenFeaturesVideo(false)
    videoRef.current.pause()
  }

  const handleVideoOnClick = () => {
    if (videoRef.current.paused) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
  }

  return (
    <PageWrapper>
      <Wrapper>
        <ButtonsWrapper
          className="btn-wrapper"
          style={{ opacity: showDetails ? 0 : 1 }}
        >
          {tabs.map((tab) => (
            <TabButton
              key={tab.id}
              isSelected={selectedTab === tab.id}
              onClick={() => handleTabClick(tab.id)}
            >
              {tab.label}
            </TabButton>
          ))}
        </ButtonsWrapper>

        {selectedTab === 1 && (
          <div
            className={`features-video ${
              fullscreenFeaturesVideo ? "" : "window"
            }`}
          >
            {!fullscreenFeaturesVideo ? (
              <DiamondButton
                icon="/assets/icons/common/expand-icon.svg"
                color="brown"
                size="large"
                className="expand-video-btn"
                onClick={() => handleExpandVideo()}
              />
            ) : (
              <>
                <ProgressBar
                  handleSkipVideo={handleSkipVideo}
                  value={sliderValue}
                  onChange={handleSliderChange}
                />
              </>
            )}
            <div
              className="video-wrapper"
              style={{ backgroundColor: "white" }}
              onClick={handleVideoOnClick}
            >
              <video
                ref={videoRef}
                autoPlay={!navigating}
                loop
                muted
                style={{ objectFit: "contain" }}
              >
                <source src={"/assets/videos/features.mp4"} type="video/mp4" />
              </video>
            </div>
          </div>
        )}
        <TabContent selectedTab={selectedTab} />
      </Wrapper>
    </PageWrapper>
  )
}

export default Features

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 2em;
  gap: 2em;

  .features-video {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
    transition: all 0.5s ease-in-out;

    .video-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: rgba(var(--white-rgb), 0);

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: rgba(var(--white-rgb), 0);
      }
    }

    .expand-video-btn {
      position: absolute;
      right: 50%;
      bottom: 50%;
      z-index: 5;
      transform: translate(50%, 50%) rotate(45deg);
      animation: fadeIn 1s ease-in-out;
    }

    &.window {
      bottom: clamp(60px, 3rem, 500px);
      right: 3rem;
      width: 30rem;
      height: 20rem;
    }
  }
`

const ButtonsWrapper = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  justify-content: center;
  display: flex;
  padding: 25px 20px;
  gap: 1em;
  border-radius: 5px;
  z-index: 100;
  transition: opacity 0.4s ease-in;
`

const ProgressWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  animation: f 400ms ease-in-out;

  @keyframes f {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  & .skip-btn {
    font-size: 0.7rem;
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.lightBrown};
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    cursor: pointer;
    border: 1px solid white;
    padding: 0.5rem 1rem;
  }

  .SliderRoot {
    position: relative;
    display: flex;
    align-items: center;
  }

  .SliderRoot[data-orientation="vertical"] {
    flex-direction: column;
    width: 20px;
    height: 30rem;
  }

  .SliderTrack {
    position: relative;
    flex-grow: 1;
    background-color: #f0f0ef;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 4px 0px;
    border-radius: 5px;
  }

  .SliderTrack[data-orientation="vertical"] {
    width: 3px;
  }

  .SliderRange {
    position: absolute;
    background-color: ${({ theme }) => theme.lightBrown};
    border-radius: 5px;
  }
  .SliderRange[data-orientation="vertical"] {
    width: 100%;
  }

  .SliderThumb {
    display: block;
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 50px;
    outline: none;
    border: 1px solid ${({ theme }) => theme.lightBrown};
    cursor: pointer;
  }

  .SliderThumb:focus {
    outline: none;
  }
`
