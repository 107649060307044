import React from "react"
import { motion } from "framer-motion"
import styled from "styled-components"
import FeatureText from "../FeatureText"
import { useStore } from "@state/store"
import StaggeredText from "@components/StaggeredText"

const KeyFeatures: React.FC = () => {
  const navigating = useStore((s) => s.navigating)
  const navDurationSec = useStore((s) => s.navDurationSec)

  const textAnimationDelay = (n) => navDurationSec * 0.75 + n * 0.25
  const textAnimationDuration = 0.5

  const mainText = [
    "Diriyah Square is an urban, mixed-use development inspired by the",
    "principles of both new urbanism and historical Najdi architecture",
    "typology.",
  ]

  const subText = [
    "A vibrant destination for residents of Riyadh and a must-visit",
    "for domestic and international tourists.",
  ]

  const navAnimStyle = {
    animationName: navigating ? "fadeOut" : "fadeIn",
    animationDelay: navigating ? `${navDurationSec}s` : "0.8s",
  }
  return (
    <TabWrapper>
      <img className="svgDesign" src={"/assets/images/features/svgDesign2.svg"} />
      <TopWrapper>
        <ImageContainer
          src={"/assets/images/features/cityOfEarth.jpg"}
          initial={{ opacity: 0, x: -1 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          exit={{ opacity: 0 }}
        />
        <RightText
          initial={{ opacity: 0, x: 5 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          exit={{ opacity: 0 }}
        >
          <div
            className="feature animateOnNav"
            style={{
              animationName: navAnimStyle.animationName,
              animationDelay: !navigating
                ? `${navDurationSec}s`
                : "0.8s",
            }}
          >
            <FeatureText text1="186K+" type="Total Retail" />
            <FeatureText text1="23M+" type="Expected visits by 2028" />
          </div>
          <div>
            {mainText.map((line, i) => {
              return (
                <StaggeredText
                  text={line}
                  cue={!navigating}
                  className="mainText"
                  delay={textAnimationDelay(i)}
                  duration={textAnimationDuration}
                  key={`mainTextLine${i}`}
                />
              )
            })}
          </div>
          <div className="subTextWrapper">
            {subText.map((line, i) => {
              return (
                <StaggeredText
                  text={line}
                  cue={!navigating}
                  className="subText"
                  delay={textAnimationDelay(i)}
                  duration={textAnimationDuration}
                  key={`subTextLine${i}`}
                />
              )
            })}
          </div>
        </RightText>
      </TopWrapper>
      <motion.div
        className="feature two"
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        exit={{ opacity: 0 }}
      >
        <FeatureText text1="10.5k" type="Paring spaces" />
        <FeatureText text1="10 Minute" type="Living Model" />
        <FeatureText text1="100%" type="Pedestrian Friendly" />
      </motion.div>
    </TabWrapper>
  )
}

export default KeyFeatures

const TabWrapper = styled(motion.div)`
  font-family: "BeausiteGrandTrial-Light";
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: var(--margin);
  height: 90vh;
  overflow-x: hidden;

  .mainText {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.darkGreen};
  }

  
  .subText {
    font-size: 1rem;
    font-family: "BeausiteClassicTrial-Clear";
  }

  .feature {
    display: flex;
    flex-direction: row;
    gap: 7rem;
  }

  .two {
    margin: auto 0;
    gap: 5rem;
  }

  #Icons {
    transform: rotate(-45deg);
    transform-origin: center;
  }

  .svgDesign {
    position: absolute;
    right: 0;
    opacity: 0.5;
  }
`

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`

const ImageContainer = styled(motion.img)`
  width: 40%;
  object-fit: cover;
`

const RightText = styled(motion.div)`
  position: relative;
  top: 5rem;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  gap: 3rem;
`
