import styled from "styled-components"
import { EmblaOptionsType } from "embla-carousel"
import FavsCarousel from "./components/FavsCarousel"
import { useEffect, useState } from "react"
import { Button } from "@components/buttons/Button"
import Dialog from "@components/dialog/Dialog"
import PageWrapper from "@components/PageWrapper"
import StaggeredText from "@components/StaggeredText"
import { useStore } from "@state/store"
import SharePopup from "./components/SharePopup"
import { useFavoritesStore } from "@state/favoritesStore"

const OPTIONS: EmblaOptionsType = {
  dragFree: true,
  align: "start",
}

export type Unit = {
  id: any
  buildingNo: any
  unitNo: any
  sqm: any
  roomName: any
  zone: any
}

const Favorites: React.FC<any> = () => {
  const [selected, setSelected] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const { loadFavorites, favorites, removeFromFavorites, clearFavorites } =
    useFavoritesStore((state) => ({
      loadFavorites: state.loadFavorites,
      favorites: state.favorites,
      removeFromFavorites: state.removeFromFavorites,
      clearFavorites: state.clearFavorites,
    }))
  const navigating = useStore((s) => s.navigating)
  const navDurationSec = useStore((s) => s.navDurationSec)

  useEffect(() => {
    loadFavorites()
  }, [loadFavorites])

  const noFavorites = ["No favorites", "added yet."]
  return (
    <PageWrapper>
      <Wrapper>
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "2rem",
            right: "3rem",
            gap: "1rem",
            zIndex: 10,
          }}
        >
          <Button
            className="clear-button"
            color="brown"
            title="Clear All"
            variant="rectangle"
            disabled={favorites.length === 0}
            icon="/assets/icons/common/trash.svg"
            onClick={() => clearFavorites()}
          />
          <Button
            className="share-button"
            color="brown"
            title="Share"
            variant="rectangle"
            disabled={favorites.length === 0}
            icon="/assets/icons/favorites/share.svg"
            onClick={() => setDialogOpen(true)}
          />
        </div>

        {favorites.length === 0 && (
          <div style={{ position: "absolute" }}>
            {noFavorites.map((line, i) => {
              return (
                <StaggeredText
                  key={`line${i}`}
                  text={line}
                  cue={!navigating}
                  delay={navDurationSec + i * 0.5}
                  className="favText"
                />
              )
            })}
          </div>
        )}
        <FavsCarousel
          units={favorites}
          options={OPTIONS}
          setUnit={(unit) => setSelected(unit)}
          removeFromFavorites={(unit) => {
            removeFromFavorites(unit)
          }}
        />
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          content={<SharePopup />}
          className="share-dialog"
          style={{}}
        />
      </Wrapper>
    </PageWrapper>
  )
}

export default Favorites

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 2em;
  gap: 2em;

  .share-button,
  .clear-button {
    z-index: 10;
  }

  .room {
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.darkGreen};
  }

  .medium-text {
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.darkGreen};
  }

  .favText {
    text-align: center;
    & span {
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 400;
      color: var(--dark-green);
    }
  }

  .unit-info-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    font-family: "BeausiteGrandTrial-Light";

    .unit-info {
      padding: 4rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.5rem;
      color: ${({ theme }) => theme.darkGreen};

      & .info {
        width: 100%;
        height: 100%;
        border: 1px solid #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0;
        background-color: ${({ theme }) => theme.lightGreen};
      }

      & .info:nth-child(1) {
        border-top-left-radius: 5px;
      }

      & .info:nth-child(2) {
        border-top-right-radius: 5px;
      }

      & .info:nth-last-child(2) {
        border-bottom-left-radius: 5px;
      }

      & .info:nth-last-child(1) {
        border-bottom-right-radius: 5px;
      }

      & .info-text {
        flex: 1;
      }

      & .info-icon {
        line-height: 0;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;

        & img {
          box-shadow: inset 0 0 0 1px ${({ theme }) => theme.white};
          border-radius: 4px;
          padding: 0.8rem;
        }
      }

      & .value {
        font-size: 1rem;
        font-family: "BeausiteClassicTrial-Clear";
      }

      & .text {
        font-size: 1.3rem;
        font-family: "BeausiteGrandTrial-Light";
      }
    }

    @media (max-height: 1080px) {
      & .unit-info {
        padding: 2rem 0 !important;
      }

      & .value {
        font-size: 0.7rem;
      }

      & .text {
        font-size: 1rem !important;
      }
    }
  }

  @media (max-height: 1080px) {
    .share-button {
      top: 1.5rem;
      right: 3rem;
    }
  }
`
