import React from "react"
import { theme } from "../../theme/theme.js"
import styled from "styled-components"
import SVG from "react-inlinesvg"

type ButtonProps = {
  variant?: "square" | "rectangle" | "rectangle-outline" | "square-outline"
  icon?: string | React.ReactNode
  color?: "green" | "brown" | "white" | "light-green"
  title?: string
  className?: string
  style?: React.CSSProperties
  disabled?: boolean
  onClick?: (e?: any) => void
}

const colorMap = {
  green: theme.darkGreen,
  brown: theme.lightBrown,
  white: theme.white,
  "light-green": theme.lightGreen,
}

const mapColor = (color: string) => colorMap[color] || theme.darkGreen

export const Button: React.FC<ButtonProps> = ({
  variant = "rectangle",
  icon,
  color,
  title,
  className,
  disabled,
  onClick,
  style,
}) => {
  const effectiveColor = mapColor(color)

  return (
    <Wrapper
      color={effectiveColor}
      onClick={onClick}
      style={style}
      className={className}
      variant={variant}
      disabled={disabled}
    >
      {title && <span>{title}</span>}
      {icon &&
        (typeof icon === "string" ? (
          <StyledSvg style={{ cursor: "pointer" }} src={icon} />
        ) : (
          icon
        ))}
    </Wrapper>
  )
}

const Wrapper = styled.div<{
  color: string
  variant: string
  disabled: boolean
}>`
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ variant, color }) =>
    variant === "rectangle-outline" || variant === "square-outline"
      ? "transparent"
      : color};
  height: 2.5rem;
  padding: ${({ variant }) =>
    variant === "square" ? "1.7rem 1.25rem" : "1.7rem 1.5rem"};
  gap: 0.5rem;
  border-radius: 4px;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  box-shadow: inset 0 0 0 1px
    ${({ theme, variant }) =>
      variant === "rectangle-outline" || variant === "square-outline"
        ? theme.darkGreen
        : "transparent"};
  transition: all 0.3s ease-in-out;

  span {
    font-size: 0.65rem;
    font-family: "BeausiteClassicTrial-Clear";
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: ${({ theme, color, variant }) =>
      variant === "rectangle-outline" || variant === "square-outline"
        ? color
        : theme.white};

    transition: all 0.3s ease-in-out;
  }

  & path,
  rect {
    stroke: ${({ theme, color, variant }) =>
      variant === "rectangle-outline" || variant === "square-outline"
        ? color
        : theme.white};
  }
`
//@ts-ignore
const StyledSvg = styled(SVG)`
  height: 1rem;
  width: 1rem;
  stroke-width: 0.1rem;
`
