import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { DiamondButton } from "@components/buttons"
import { motion, AnimatePresence } from "framer-motion"
import { useStore } from "@state/store"
import ListView from "../ListView"
import StaggeredText from "@components/StaggeredText"

interface DetailsProps {
  data?: {
    heading1?: string
    heading2?: string
    subText?: string
    subText2?: string
    feature1?: string
    type1?: string
    feature2?: string
    type2?: string
    feature3?: string
    type3?: string
    img?: string
    list1?: {
      [key: string]: string
    }
    list2?: {
      [key: string]: string
    }
  }
}

type ListItem1 = {
  text1?: string
  text2?: string
}

type ListItem2 = {
  text1?: string
  text2?: string
}

const Details2: React.FC<DetailsProps> = ({ data }: DetailsProps) => {
  const {
    heading1,
    heading2,
    subText,
    subText2,
    feature1,
    type1,
    feature2,
    type2,
    feature3,
    type3,
    img,
  } = data

  const listItems1: ListItem1[] = Object.entries(data.list1).map(
    ([key, value]) => ({
      text1: key,
      text2: value,
    })
  )

  const listItems2: ListItem2[] = Object.entries(data.list2).map(
    ([key, value]) => ({
      text1: key,
      text2: value,
    })
  )

  const features2 = [
    { text1: feature1, type: type1 },
    { text1: feature2, type: type2 },
    { text1: feature3, type: type3 },
  ]

  const delay = useStore((s) => s.featuresAnimationDelay)
  const showDetails = useStore((s) => s.showDetails)
  const setLocalState = useStore((s) => s.setLocalState)

  const unshowDetails = (): void => {
    setLocalState({ showDetails: false })
  }

  return (
    <AnimatePresence>
      {showDetails && (
        <TabWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          exit={{ opacity: 0, transition: { duration: 0.6, delay: 0.3 } }}
        >
          <img className="svgDesign" src={"/assets/images/features/svgDesign.svg"} onClick={unshowDetails} />
          <TopWrapper>
            <LeftText
              initial={{ x: -5, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1, ease: "easeInOut", delay}}
              exit={{ opacity: 0, transition: { duration: 0.3 } }}
              className="mainText2"
            >
              <div className="subText2"></div>
              <DiamondButton
                className="back"
                onClick={unshowDetails}
                icon="/assets/icons/common/close-icon.svg"
                size="medium"
                color="brown"
              />
              <div className="headers">
                <StaggeredText text={heading1 ?? ""} cue delay={delay} />
                <StaggeredText text={heading2 ?? ""} cue delay={delay} />
                <div
                  style={{
                    width: "100%",
                    lineHeight: "20px",
                    fontSize: "0.7rem",
                    fontFamily: "Arial",
                    textTransform: "uppercase",
                    textAlign: "right",
                  }}
                >
                  <StaggeredText
                    text={"SMART DIRIYAH DESIGN PRINCIPLES"}
                    cue
                    delay={delay}
                  />
                </div>
              </div>
              <BottomView
                initial={{ x: 15, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1, ease: "easeInOut", delay}}
                exit={{ opacity: 0, transition: { duration: 0.3 } }}
              >
                <div
                  style={{
                    width: "80%",
                    marginLeft: "20%",
                    textAlign: "right",
                  }}
                >
                  <ListView listItems={listItems1} />
                </div>
              </BottomView>
              <div className="subText2" />
            </LeftText>
            <div style={{ width: "50%" }}>
              <ImageContainer
                src={"/assets/images/gallery/lifestyle/1.webp"}
                initial={{ x: 5, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1, ease: "easeInOut", delay}}
                exit={{ opacity: 0, transition: { duration: 0.3 } }}
              />
              <motion.div
                className="subText2 variant"
                initial={{ y: 15, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 1, ease: "easeInOut", delay}}
                exit={{ opacity: 0, transition: { duration: 0.3 } }}
              >
                {subText2}
              </motion.div>
            </div>
          </TopWrapper>
          <BottomView
            initial={{ x: -15, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, ease: "easeInOut", delay}}
            exit={{ opacity: 0, transition: { duration: 0.3 } }}
          >
            <img className="image" src={"/assets/images/features/2.jpg"} alt="" />
            <div
              style={{
                width: "100%",
                fontSize: "0.7rem",
                fontFamily: "Arial",
                textTransform: "uppercase",
                backgroundColor: "white",
              }}
            >
              <StaggeredText
                text={"SMART DIRIYAH KEY OBJECTIVES:"}
                cue
                delay={delay}
                className="title-list"
              />

              <ListView listItems={listItems2} />
            </div>
          </BottomView>
        </TabWrapper>
      )}
    </AnimatePresence>
  )
}

export default Details2

const TabWrapper = styled(motion.div)`
  z-index: 200;
  background-color: white;
  font-family: "BeausiteGrandTrial-Light";
  width: 100%;
  height: 100%;
  font-weight: 200;
  position: absolute;
  color: ${({ theme }) => theme.darkGreen};
  display: flex;
  padding-right: 30px;
  flex-direction: column;
  gap: 2rem;
  opacity: 1;
  padding-bottom: 5rem;
  pointer-events: all;
  
  .title-list {
    font-family: "BeausiteGrandTrial-Light";
    font-size: 1.5rem;
    line-height: 1.5;
  }
  .bottomsubText2 {
    margin: auto auto auto auto;
    font-family: "BeausiteClassicTrial-Clear";
    font-size: 1rem;
  }

  .features2 {
    display: flex;
    margin: auto 0;
    flex-direction: row;
    width: 100%;
    gap: 4rem;
  }
  .mainText2 {
    line-height: 5rem;
    font-size: 5rem;
  }

  .subText2 {
    width: 70%;
    font-size: 1rem;
    font-family: "BeausiteClassicTrial-Clear";
    text-align: right;
    margin: 2rem 0 auto auto;
    line-height: 25px;
  }

  .variant {
    text-align: right;
    margin-left: auto;
  }

  #Icons {
    transform: rotate(-45deg);
    transform-origin: center;
  }

  .svgDesign {
    position: absolute;
    // left: 20px;
    opacity: 0.5;
  }

  .back {
    width: 2.5rem;
    height: 2.5rem;
    margin-top: 2rem;
    position: relative;
    right: -95%;
    top: -5%;
  }

  .headers {
    text-align: right;
  }

  overflow-y: auto;
`

const ImageContainer = styled(motion.img)`
  width: 100%;
  height: 70vh;
  object-fit: cover;
`

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0rem;
`

const LeftText = styled(motion.div)`
  width: 50vw;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-right: 2rem;
  // background-color:red;
`

const BottomView = styled(motion.div)`
  width: 100%;
  display: flex;
  gap: 5%;

  .image {
    width: 50%;
    height: 70%;
    object-fit: cover;
  }
`
