const baseAttributes = {
  fillOpacity: "0",
  strokeOpacity: "1",
  strokeWidth: 3,
  stroke: "white"
  // className: "dsq-overlay"
}

const data = [
  {
    type: "polygon",
    coords:
      "1656.2 780.72 1698.67 775.41 1701.88 772.2 1704.53 772.2 1709.28 773.73 1709.28 776.46 1741.97 800 1746.07 795.89 1752.87 797.76 1754.82 803.07 1803.16 837.43 1806.79 837.43 1810.56 841.21 1804.91 874.87 1820.91 871.47 1824.15 868.23 1829.77 866.36 1912.49 927.64 1908.74 944.32 1932.91 962.7 1927.47 986.19 1913.17 989.26 1895.81 977.68 1879.13 983.13 1871.47 975.47 1854.62 980.06 1839.3 969.17 1821.26 972.91 1821.26 977.68 1817.68 981.26 1814.62 978.19 1779.72 975.98 1730.36 973.26 1604.74 963.64 1599.43 964.68 1599.43 976.17 1564.61 984.28 1542.97 976.17 1453.39 1000.51 1389.5 1021.13 1384.26 1015.89 1379.02 1021.13 1374.28 1016.4 1361.44 1021.13 1255.29 1021.13 1230.61 1021.13 1214.04 1006.93 1200.18 1010.65 1200.18 1019.1 1150.83 1032.96 1150.83 1040.4 1118.37 1049.53 1104.18 1031.61 1084.23 1036.34 1084.23 1064.07 1054.82 1064.07 1031.49 1056.63 1031.49 1041.42 1012.22 1036.01 1012.22 1031.95 993.63 1024 985.6 1032.03 967.26 1026.2 967.26 1009.64 938.53 1000.85 938.53 989.35 924.61 985.98 931.21 966.96 931.21 931.72 937.34 914.87 967.47 914.87 967.47 921 972.57 921 987.89 897.02 993 897.02 993 881.03 1025.95 881.03 1028.37 864.12 1079.11 867.92 1076.69 887.94 1121.22 887.94 1134.33 889.66 1132.61 904.16 1224.42 908.65 1265.15 908.65 1265.15 897.02 1403.91 875.86 1403.91 887.25 1478.47 874.48 1521.27 860.67 1521.27 853.77 1534.38 845.83 1622.75 823.74 1622.75 814.76 1649.46 811.88 1653.18 803.76 1656.2 780.72",
    length: 2545.3662109375,
    activeState: "lifestyle",
    id: "lifestyle",
  },
  {
    type: "polygon",
    coords:
      "675.18 1270.03 664.29 1280.92 652.95 1295.15 652.95 1303.37 629.84 1334.27 629.84 1357.83 618.94 1382.72 515.04 1347.89 502.02 1347.89 476.23 1347.89 481.85 1335.64 451.98 1335.64 366.96 1267.21 326.62 1290.7 186.96 1297.34 179.58 1277.75 185.95 1146.21 166.35 1132.72 137.86 1131.2 132.01 1099.39 134.8 1094.56 140.66 1093.54 179.15 1049.1 232.2 1049.1 241.63 1037.26 235.93 1012.05 243.16 1004.16 240.53 991.01 255.22 976.32 270.78 971.5 272.97 976.1 291.9 973.66 296.23 969.33 297.86 959.63 317.27 955.98 317.27 950.02 346.48 943.87 350.71 959.82 374.15 954.63 374.53 963.66 377.22 973.46 386.64 973.46 386.64 965.78 382.6 948.87 382.6 940.8 397.78 930.61 410.47 930.61 410.47 940.03 429.11 937.72 441.98 937.72 441.98 932.92 466.58 931.77 467.15 937.53 473.88 937.53 476.38 927.73 505.59 926.96 505.59 931.77 537.87 930.23 542.67 923.12 565.35 923.12 568 944.27 578.69 936.21 588.63 937.52 597.82 928.33 626.33 930.77 647.53 935.83 651.37 931.99 663.84 934.71 670.5 948.47 673.39 991.36 693.17 993.36 693.17 981.14 709.4 964.92 726.96 969.81 757.63 978.92 758.96 1002.92 763.41 998.48 768.63 1003.7 782.52 1005.37 785.74 1002.14 807.41 1005.59 807.41 1012.48 817.42 1022.48 825.64 1010.92 857.42 1016.26 857.42 1036.26 850.53 1048.04 878.54 1053.6 849.42 1089.16 830.31 1082.71 811.42 1093.38 804.75 1107.39 804.75 1127.83 794.52 1157.39 780.08 1158.28 754.29 1183.62 741.4 1183.62 741.4 1197.18 735.62 1202.96 735.62 1226.52 727.62 1238.3 720.95 1236.96 689.84 1268.08 675.18 1270.03",
    length: 2342.43994140625,
    activeState: "premium-dining",
    id: "premium-dining",
  },
  {
    type: "polygon",
    coords:
      "280.66 956.37 283.41 974.75 291.9 973.66 296.23 969.33 297.86 959.63 317.27 955.98 317.27 950.02 346.48 943.87 350.71 959.82 374.15 954.63 374.53 963.66 377.22 973.46 386.64 973.46 386.64 965.78 382.6 948.87 382.6 940.8 397.78 930.61 410.47 930.61 410.47 940.03 429.11 937.72 441.98 937.72 441.98 932.92 466.58 931.77 467.15 937.53 473.88 937.53 476.38 927.73 505.59 926.96 505.59 931.77 537.87 930.23 542.67 923.12 565.35 923.12 568 944.27 578.69 936.21 588.63 937.52 590.07 936.08 586.11 899.04 596.16 899.04 615.45 881.69 635.56 884.77 646.58 875.85 643.08 851.94 676.8 832.27 702.66 837.12 714.37 828.02 713 796.48 730.67 784.25 752.47 784.25 752.47 773.17 760.69 762.81 758.9 734.22 741.75 731.36 741.75 723.14 677.07 710.99 669.63 709.61 559.21 693 542.87 690.96 537.38 698.68 508.52 723.7 472.14 717.87 459.92 716.59 459.92 726.19 452.96 730.83 428.46 726.41 407.49 741.42 399.11 739.21 376.59 754.67 361.58 765.26 356.51 777.18 348.01 785.68 349.22 800.14 345.47 803.89 345.91 819.12 323.18 817.13 293.82 837 285.65 849.36 296.25 915.13 290.51 915.13 292.05 929.7 287.86 929.7 280.66 956.37",
    length: 1508.17626953125,
    activeState: "entertainment",
    id: "entertainment",
  },
  {
    type: "polygon",
    coords:
      "345.47 803.89 336.45 803.89 324.07 801.47 318.54 802.68 295.89 801.47 292.46 801.47 276.13 808.05 260.59 801.47 246.63 794.88 246.63 789.88 230.03 782.24 188.73 759.78 186.99 728.54 214.02 718.28 212.49 707.55 255.13 691.98 258.01 693.63 263.03 691.21 273.46 693.07 297.86 682.27 297.86 676.86 332.51 662.9 346.48 649.48 390.06 653.21 402.54 645.2 411.67 646.69 411.67 648.74 413.72 646.69 440.35 652.28 440.35 657.68 464.57 661.96 465.68 676.49 477.42 678.91 477.42 684.31 484.87 684.31 484.87 689.03 475.48 686.68 467.75 691.98 467.75 697.72 461.79 701.47 461.79 714.71 459.92 716.59 459.92 726.19 452.96 730.83 428.46 726.41 407.49 741.42 399.11 739.21 361.58 765.26 356.51 777.18 348.01 785.68 349.22 800.14 345.47 803.89",
    length: 787.969482421875,
    activeState: "department",
    id: "department",
  },
  {
    type: "polygon",
    coords:
      "459.92 716.59 461.79 714.71 461.79 701.47 467.75 697.72 467.75 691.98 475.48 686.68 484.87 689.03 493.94 683.64 506.87 673.34 506.87 668.74 549.26 658.28 553.85 653.68 551.81 639.89 568.91 617.94 576.57 617.94 586.53 605.94 586.53 597 591.51 592.02 593.68 592.02 597.83 587.87 596.49 583.21 601.34 578.36 641.43 580.4 641.43 593.68 634.4 600.7 634.4 610.28 636.32 610.28 637.34 616.66 659.55 613.09 661.85 618.19 679.21 614.87 679.21 612.83 716.49 609 716.49 606.45 762.96 601.34 787.21 598.28 791.55 589.6 826.79 592.4 952.66 588.57 977.94 590.36 977.94 597.26 989.94 597.26 989.94 606.19 1005.26 608.49 1005.26 621.26 1001.17 625.34 1001.17 638.36 991.72 647.48 991.72 660.57 985.85 671.55 982.79 671.55 976.66 682.53 971.55 687.64 946.28 687.64 946.28 716.59 934.33 728.54 934.33 735.64 924.83 742.28 924.83 753.51 916.4 774.7 902.49 788.62 897.39 788.62 897.39 786.39 888.15 785.04 888.15 762.51 880.71 761.15 880.71 756.2 815.8 746.96 815.8 744.25 806.13 740.43 799.78 746.78 782.44 744.23 760.69 762.81 758.9 734.22 741.75 731.36 741.75 723.14 669.63 709.61 559.21 693 542.87 690.96 537.38 698.68 508.52 723.7 459.92 716.59",
    length: 1484.9737548828125,
    activeState: "luxury-n",
    id: "luxury-n",
  },
  {
    type: "polygon",
    coords:
      "887.24 1029.82 878.54 1053.6 850.53 1048.04 857.42 1036.26 857.42 1016.26 825.64 1010.92 817.42 1022.48 807.41 1012.48 807.41 1005.59 785.74 1002.14 782.52 1005.37 768.63 1003.7 763.41 998.48 758.96 1002.92 757.63 978.92 709.4 964.92 693.17 981.14 693.17 993.36 673.39 991.36 670.5 948.47 663.84 934.71 651.37 931.99 647.53 935.83 626.33 930.77 597.82 928.33 590.07 936.08 586.11 899.04 596.16 899.04 615.45 881.69 635.56 884.77 646.58 875.85 643.08 851.94 676.8 832.27 702.66 837.12 714.37 828.02 713 796.48 730.67 784.25 752.47 784.25 752.47 773.17 760.69 762.81 782.44 744.23 799.78 746.78 806.13 740.43 815.8 744.25 815.8 746.96 880.71 756.2 880.71 761.15 888.15 762.51 888.15 785.04 897.39 786.39 897.39 788.62 902.49 788.62 926.62 788.62 926.62 778.02 935.81 778.02 935.81 768.83 943.98 760.66 969 760.66 969 768.32 1024 770.36 1024 795.89 1035.89 795.89 1024 821.43 1016.49 839.81 999.64 839.81 993 877.09 993 897.02 987.89 897.02 972.57 921 967.47 921 967.47 914.87 937.34 914.87 931.21 931.72 931.21 966.96 924.61 985.98 916.04 983.93 902.33 994.21 902.33 1004.49 899.42 1007.41 899.42 1021.63 887.24 1029.82",
    length: 1503.002197265625,
    activeState: "anchor",
    id: "anchor",
  },
  {
    type: "polygon",
    coords:
      "1035.89 795.89 1024 795.89 1024 770.36 969 768.32 969 760.66 943.98 760.66 935.81 768.83 935.81 778.02 926.62 778.02 926.62 788.62 902.49 788.62 916.4 774.7 924.83 753.51 924.83 742.28 934.33 735.64 934.33 728.54 946.28 716.59 946.28 687.64 971.55 687.64 976.66 682.53 982.79 671.55 985.85 671.55 991.72 660.57 991.72 647.48 1001.17 638.36 1001.17 625.34 1005.26 621.26 1005.26 616.75 1029.19 618.57 1029.19 631.78 1047.86 631.78 1052.64 624.26 1071.77 625.4 1071.77 605.36 1077.24 599.9 1081.79 588.51 1096.82 588.51 1108.44 591.24 1108.44 594.89 1151.93 601.72 1151.93 598.3 1163.32 599.21 1163.32 597.62 1179.49 598.53 1179.49 614.47 1206.81 614.47 1206.81 618.57 1212.51 618.57 1216.15 614.93 1229.59 616.52 1229.59 629.96 1239.38 629.96 1239.38 621.08 1258.05 622.67 1258.05 639.29 1265.79 639.29 1295.4 649.54 1300.86 644.08 1351.88 660.02 1365.43 664.18 1371.49 658.12 1378.93 660.88 1384.38 655.43 1384.38 648.37 1391.54 641.21 1395.33 643.12 1403.39 635.06 1421.96 638.62 1421.96 643.12 1438.84 643.12 1438.84 646.87 1462.1 646.87 1471.1 644.62 1493.98 640.87 1521.74 637.12 1545.37 633.74 1585.13 659.25 1585.13 665.25 1600.89 675.38 1599.73 693.79 1612.95 707.01 1612.95 701.88 1637.13 700.46 1651.42 711.89 1651.42 721.66 1677.39 745.72 1677.39 749.29 1689.3 758.82 1688.11 773.83 1599.89 785.62 1562.42 785.62 1562.42 772.23 1539.23 749.04 1521.98 744.28 1521.98 739.82 1497.89 757.07 1481.24 752.31 1467.86 743.98 1444.37 742.2 1424.15 746.66 1424.15 750.23 1416.71 753.5 1369.77 763.73 1340.48 772.1 1253.77 780.85 1246.16 780.85 1139.29 788.62 1139.29 791.88 1131.3 791.88 1106.96 791.88 1101.25 794.16 1038.12 790.74 1035.89 795.89",
    length: 2052.236328125,
    activeState: "luxury-s",
    id: "luxury-s",
  },
  {
    type: "polygon",
    coords:
      "1599.89 785.62 1622.75 814.76 1622.75 823.74 1617.92 825.46 1589.27 833.06 1534.38 845.83 1521.27 853.77 1521.27 860.67 1478.47 874.48 1419.1 885.52 1403.91 887.25 1403.91 875.86 1364.22 882.07 1283.45 894.15 1265.15 897.02 1265.15 908.65 1224.42 908.65 1132.61 904.16 1134.33 889.66 1121.22 887.94 1076.69 887.94 1079.11 867.92 1028.37 864.12 1025.95 881.03 993 881.03 993 877.09 999.64 839.81 1016.49 839.81 1038.12 790.74 1101.25 794.16 1106.96 791.88 1139.29 791.88 1139.29 788.62 1246.16 780.85 1253.77 780.85 1340.48 772.1 1416.71 753.5 1424.15 750.23 1424.15 746.66 1444.37 742.2 1467.86 743.98 1481.24 752.31 1497.89 757.07 1521.98 739.82 1521.98 744.28 1539.23 749.04 1562.42 772.23 1562.42 785.62 1599.89 785.62",
    length: 1491.785400390625,
    activeState: "culture",
    id: "culture",
  },
]

const dsqRetailOverlays = data.map((obj) => {
  return {
    ...obj,
    attr: {
      ...baseAttributes,
      // strokeDasharray: obj.length,
      // strokeDashoffset: obj.length,
    },
  }
})

export default dsqRetailOverlays
