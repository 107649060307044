import React, { useState, useRef } from "react"
import styled from "styled-components"
import FeatureText from "../FeatureText"
import AmenitiesDetails2 from "../detailsVariants/Details6"
import AmenitiesDetails from "../detailsVariants/Details7"
import { DiamondButton } from "@components/buttons"
import StaggeredText from "@components/StaggeredText"
//@ts-ignore
import data from "../../data/tenantFacilities"
import { useStore } from "@state/store"

const TenantFacilities: React.FC = () => {
  const wrapperRef = useRef<HTMLDivElement>()
  const [activeAmenityIndex, setActiveAmenityIndex] = useState(null)
  const showDetails = useStore((s) => s.showDetails)
  const setLocalState = useStore((s) => s.setLocalState)
  const navigating = useStore((s) => s.navigating)
  const navDurationSec = useStore((s) => s.navDurationSec)

  const textAnimationDelay = (n) => navDurationSec * 0.75 + n * 0.25
  const textAnimationDuration = 0.5

  const mainText = ["Considered", "Tenant", "Facilities"]

  const subText = [
    "Every detail has been carefully curated",
    "to satisfy all requirements of any",
    "modern retail business.",
  ]

  const navAnimStyle = {
    animationName: navigating ? "fadeOut" : "fadeIn",
    animationDelay: navigating ? `${navDurationSec}s` : "0.8s",
  }

  const handleShowDetails = (index: number) => {
    setActiveAmenityIndex(index)
    setLocalState({ showDetails: true })
  }

  function updateIndex(dir) {
    const maxIndex = data.details.length - 1
    let nextIndex
    if (dir) {
      nextIndex = activeAmenityIndex + 1 > maxIndex ? 0 : activeAmenityIndex + 1
      setActiveAmenityIndex(nextIndex)
    } else {
      nextIndex = activeAmenityIndex - 1 < 0 ? maxIndex : activeAmenityIndex - 1
      setActiveAmenityIndex(nextIndex)
    }
    wrapperRef.current.style.opacity = "1"
  }

  function switchData(dir) {
    wrapperRef.current.style.opacity = "0"
    setTimeout(() => {
      updateIndex(dir)
    }, 400)
  }

  return (
    <TabWrapper>
      <img className="svgDesign" src={"/assets/images/features/svgDesign.svg"} />
      <TopWrapper>
        <RightText>
          <div>
            {subText.map((line, i) => {
              return (
                <StaggeredText
                  text={line}
                  cue={!navigating}
                  className="subText"
                  delay={textAnimationDelay(i)}
                  duration={textAnimationDuration}
                  key={`subTextLine${i}`}
                />
              )
            })}
          </div>
          <div>
            {mainText.map((line, i) => {
              return (
                <StaggeredText
                  text={line}
                  cue={!navigating}
                  className="mainText"
                  delay={textAnimationDelay(i)}
                  duration={textAnimationDuration}
                  key={`mainTextLine${i}`}
                />
              )
            })}
          </div>
        </RightText>
        <ImageContainer
          src={"/assets/images/features/tenantFacilities.jpg"}
          onClick={() => {
            setLocalState({
              swiperGalleryData: [
                { src: "/assets/images/features/tenantFacilities.jpg" },
              ],
              swiperGalleryOpen: true,
            })
          }}
          className="animateOnNav"
          style={navAnimStyle}
        />
      </TopWrapper>
      <div className="features">
        {data.features.map((feature, index) => (
          <FeatureWrapper
            onClick={() => handleShowDetails(index)}
            key={index}
            style={{
              animationName: navAnimStyle.animationName,
              animationDelay: !navigating
                ? `${navDurationSec + index * 0.1}s`
                : "0.8s",
            }}
            className="animateOnNav"
          >
            <FeatureText text1={feature.text1} text2={feature.text2} />
            <Icon
              width="40"
              height="40"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => handleShowDetails(index)}
            >
              <g id="Icons">
                <path
                  id="Vector"
                  d="M3 8.5H14"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_2"
                  d="M9.5 4L14 8.5L9.5 13"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </Icon>
          </FeatureWrapper>
        ))}
      </div>
      <Cover
        style={{ opacity: activeAmenityIndex === null || !showDetails ? 0 : 1 }}
      >
        <TransitionTarget ref={wrapperRef}>
          {activeAmenityIndex == 0 && (
            <AmenitiesDetails2 data={data.details[activeAmenityIndex]} />
          )}
          {activeAmenityIndex == 1 && (
            <AmenitiesDetails2 data={data.details[activeAmenityIndex]} />
          )}
          {activeAmenityIndex == 2 && (
            <AmenitiesDetails data={data.details[activeAmenityIndex]} />
          )}
          {activeAmenityIndex == 3 && (
            <AmenitiesDetails data={data.details[activeAmenityIndex]} />
          )}
          {activeAmenityIndex == 4 && (
            <AmenitiesDetails data={data.details[activeAmenityIndex]} />
          )}
          {activeAmenityIndex == 5 && (
            <AmenitiesDetails data={data.details[activeAmenityIndex]} />
          )}
          <IndexControl>
            <DiamondButton
              onClick={() => switchData(false)}
              icon="/assets/icons/common/arrow-left.svg"
              size="medium"
              color="brown"
            />
            <DiamondButton
              onClick={() => switchData(true)}
              icon="/assets/icons/common/arrow-right.svg"
              size="medium"
              color="brown"
            />
          </IndexControl>
        </TransitionTarget>
      </Cover>
    </TabWrapper>
  )
}

export default TenantFacilities

const TabWrapper = styled.div`
  font-family: "BeausiteGrandTrial-Light";
  font-weight: 200;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-left: var(--margin);
  width: 100%;
  height: 100%;

  .features {
    display: flex;
    flex-direction: row;
    gap: var(--margin);
    margin: auto 0;
    padding: 0 var(--margin);
  }
  .mainText {
    font-size: 6rem;
    margin: auto 0;
    color: ${({ theme }) => theme.lightBrown};
    line-height: 5rem;
  }

  .subText {
    font-size: 1rem;
    font-family: "BeausiteClassicTrial-Clear";
    color: ${({ theme }) => theme.darkGreen};
    text-align: right;

    :nth-of-type(1) {
      margin: 2rem 0 0rem 0;
    }
  }

  #Icons {
    transform: rotate(-45deg);
    transform-origin: center;
  }

  .svgDesign {
    position: absolute;
    opacity: 0.5;
    left: 0;
  }
`

const TopWrapper = styled.div`
  height: 60vh;
  display: flex;
  flex-direction: row;
  gap: 2rem;
`

const ImageContainer = styled.img`
  width: 50%;
  object-fit: cover;
  cursor: pointer;
`

const RightText = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`

const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.lightBrown}2A;
  padding: var(--margin);
  border-radius: 1px;
  gap: 1rem;
  width: 100%;
  cursor: pointer;
`

const Icon = styled.svg`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.lightBrown};
  margin-left: auto;
  margin-top: auto;
  transition: 0.3s;

  &:hover {
    opacity: 0.5;
    transition: 0.3s;
  }
`

const Cover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  pointer-events: none;
  transition: opacity 0.4s ease-in-out;
`

const IndexControl = styled.div`
  position: absolute;
  z-index: 210;
  width: 12rem;
  height: 5rem;
  bottom: 1rem;
  right: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  pointer-events: all;
`

const TransitionTarget = styled.div`
  transition: opacity 0.4s ease-in-out;
`