import * as React from "react"
import { motion } from "framer-motion"
import styled from "styled-components"
import retailTrends1 from "@assets/images/metrics/retail-trends-1.svg"
import retailTrends2 from "@assets/images/metrics/retail-trends-2.svg"

export default function RetailTrends() {
  return (
    <Wrapper>
      <div className="top">
        <div className="title">Riyadh: Retail consumer trends</div>
      </div>
      <div className="content">
        <div className="box1">
          <div className="description">
            Breakdown of annual household <br /> expenditure by major categories
          </div>
          <motion.img
            src={retailTrends2}
            initial={{ scale: 1.05 }}
            animate={{ scale: 1 }}
            transition={{ duration: 1 }}
            exit={{ opacity: 0 }}
            alt="Income Profile"
          />
        </div>
        <div className="box2">
          <motion.img
            src={retailTrends1}
            initial={{ scale: 1.05 }}
            animate={{ scale: 1 }}
            transition={{ duration: 1 }}
            exit={{ opacity: 0 }}
            alt="Income Profile"
          />
          <div className="bottom">
            *Essential expenditure includes spend on housing, utilities,
            transport, education, and savings & remittances **Other expenditure
            includes uncategorized expenses (e.g.: pets, hobbies, salons, etc.)
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled(motion.div)`
  position: relative;
  height: 100%;
  display: flex;
  background: #f0f0f0;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s ease-in forwards;

  .top {
    position: absolute;
    top: 0;
    left: 0;

    .title {
      padding: 1rem;
      font-size: 1.3rem;
      text-transform: uppercase;
      font-weight: 600;
      color: #004344;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .box1 {
      width: 35%;
      height: 100%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      overflow: hidden;
      margin-top: -3rem;

      .description {
        padding: 3rem 0;
        font-size: 1.2rem;
        color: #004344;
      }
    }

    .box2 {
      position: relative;
      width: 65%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .bottom {
        position: absolute;
        width: 50%;
        right: 0;
        padding: 1rem;
        font-size: clamp(10px, 1rem, 15px);
        color: #004344;
        text-align: right;
        bottom: 0;
      }
    }
  }

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 4px;
    filter: sepia(0) saturate(0.8) hue-rotate(-15deg) brightness(1) contrast(1);
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
