import React from "react"
import styled from "styled-components"

type LoadingScreenProps = {
  assetsLoaded: boolean
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ assetsLoaded }) => {
  return (
    <Wrapper visible={assetsLoaded}>
      <span className="loader"></span>
    </Wrapper>
  )
}

export default LoadingScreen

const Wrapper = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ visible }) => (visible ? -1 : 100)};
  opacity: ${({ visible }) => (visible ? 0 : 1)};
  transition: all 0.7s;

  .loader {
    position: relative;
    width: 70px;
    height: 70px;
  }

  .loader:before,
  .loader:after {
    content: "";
    border-radius: 50%;
    position: absolute;
    inset: 0;
    box-shadow: 0 0 10px 2px #b2c6c6 inset;
  }
  .loader:after {
    box-shadow: 0 2px 0 #004344 inset;
    animation: rotate 2s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
