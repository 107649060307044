import { useEffect } from "react"
import styled from "styled-components"
import { useStore } from "@state/store"
import GlobalMap from "./GlobalMap"
import SaudiMap from "./SaudiMap"
import { Panel } from "@components/panels"
import RiyadhMap from "./RiyahdMap"
import AirportPanel from "./panels/AirportPanel"
import KAFDPanel from "./panels/KAFDPanel"
import KingSalmanParkPanel from "./panels/KingSalmanParkPanel"
import KingdomTowerPanel from "./panels/KingdomTowerPanel"

const Maps = () => {
  const { setShowNavbar, activeMap, riyadhPanel } = useStore((state) => ({
    setShowNavbar: state.setShowNavbar,
    activeMap: state.activeMap,
    riyadhPanel: state.riyadhPanel,
  }))

  useEffect(() => {
    setShowNavbar(true)
  }, [])

  return (
    <Wrapper>
      <SaudiMap visible={activeMap === "saudi"} />
      <GlobalMap visible={activeMap === "global"} />
      <RiyadhMap activeMap={activeMap} visible={activeMap === "riyadh"} />
      <StyledPanel
        visible={activeMap === "saudi"}
        className="stats-panel"
        activemap={activeMap}
      >
        <div className="stats-box" style={{ transitionDelay: "0.1s" }}>
          <span className="top-text">A Thriving Economy</span>
          <div className="mid-text">1 trillion SAR</div>
          <div className="bottom-text">
            Invested in domestic economy by 2025
          </div>
        </div>
        <div className="stats-box" style={{ transitionDelay: "0.2s" }}>
          <span className="top-text">Economic Prosperity</span>
          <div className="mid-text">150 million</div>
          <div className="bottom-text">Expected visitors by 2030</div>
        </div>
        <div className="stats-box" style={{ transitionDelay: "0.3s" }}>
          <span className="top-text">A growing population</span>
          <div className="mid-text">32.2 million</div>
          <div className="bottom-text">Current Saudi population</div>
        </div>
        <div className="stats-box" style={{ transitionDelay: "0.4s" }}>
          <span className="top-text">A Vibrant Society</span>
          <div className="mid-text">60%</div>
          <div className="bottom-text">
            Saudi population under the age of 35
          </div>
        </div>
        <div className="stats-box" style={{ transitionDelay: "0.5s" }}>
          <span className="top-text">A gathering place</span>
          <div className="mid-text">58%</div>
          <div className="bottom-text">Saudi nationals vs 41% non saudis</div>
        </div>
      </StyledPanel>
      <AirportPanel
        visible={riyadhPanel === "airport" && activeMap === "riyadh"}
      />
      <KAFDPanel visible={riyadhPanel === "kafd" && activeMap === "riyadh"} />
      <KingSalmanParkPanel
        visible={riyadhPanel === "king-salman-park" && activeMap === "riyadh"}
      />
      <KingdomTowerPanel
        visible={riyadhPanel === "kingdom-tower" && activeMap === "riyadh"}
      />
    </Wrapper>
  )
}

export default Maps

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  margin: 0 auto;
  animation: fadeIn 1s ease forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const StyledPanel = styled(Panel)<{ activemap: string; visible: boolean }>`
  position: absolute;
  top: 0;
  right: ${({ activemap }) => (activemap === "saudi" ? "0" : "-23rem")};
  height: 100%;
  width: 23rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row dense;
  justify-items: center;
  z-index: 1;
  padding: 0;
  transition: right 0.6s;
  
  & .stats-box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    padding: 2rem;
    position: relative;
    right: ${({ activemap }) => (activemap === "saudi" ? "0%" : "-50%")};
    transition: right .6s ease-in-out;

    &:not(:last-child) {
      border-bottom: 1px solid #ffffff;
    }

    .top-text {
      flex: 1;
    }

    .top-text,
    .bottom-text {
      font-size: 0.7rem;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.darkGreen};
    }

    .bottom-text {
      text-transform: none;
    }

    .mid-text {
      font-size: 2.5rem;
      font-family: BeausiteGrandTrial-Light;
      padding: 0.5rem 0;
    }
  }
`
