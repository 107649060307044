import { paths } from "./sidePanelData.js"

const dsqCopyData = {
  default: {
    name: "",
    title: "Diriyah Square",
    desc: "Diriyah will be home to a unique collection of over 400 of the finest global brands and local souks offering world-class shopping.",
    feature1Text: "460k sqm",
    feature1: "Total land area",
    feature2Text: "186K sqm",
    feature2: "Total Retail",
    feature3: null,
    feature3Text: null,
    para: "A luxury-lifestyle destination where iconic brands will be complimented with traditional artisan souks and bazaars, all set within an authentic, walkable Najdi village atmosphere providing a new lifestyle experience unique to Diriyah.",
    swipeGalleryData: [{ src: paths.dsq.full, caption: "" }],
    img: paths.dsq.full,
    imgThumb: paths.dsq.thumb,
    link: "diriyah-square",
    mapID: "diriyah-square",
    subState: "primary",
  },
  community: {
    name: "CULTURE & COMMUNITY",
    title: "Culture and Community",
    desc: "Unveiling Arabian Peninsula's rich history through top-tier education and cultural institutions.",
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    para: "A diverse collection of attractions where visitors can engage with and enjoy a multitude of experiences: from celebrating the accomplishments of the Kingdom’s leaders to reliving the history of the region and connecting with modern artistic expressions.",
    swipeGalleryData: [{ src: paths.cultureCommunity.full, caption: "" }],
    img: paths.cultureCommunity.full,
    imgThumb: paths.cultureCommunity.thumb,
    link: "community",
    mapID: "diriyah-square",
    subState: "primary",
  },
  hospitality: {
    name: "HOTELS & RESORTS",
    title: "Hotels and Resorts",
    desc: "Diriyah Square will offer visitors a luxurious and world class service by the world's leading hospitality brands.",
    feature1: "Luxury Hotels & Resorts",
    feature1Text: "6",
    feature2: "Hotel Keys",
    feature2Text: "675",
    feature3: null,
    feature3Text: null,
    para: "For the experiential travelers, guests will be immersed in Najdi inspired architecture and design, triggering emotions of nostalgia, authenticity, wonder and discovery. They will experience an unparalleled level of service and luxury, an authentic cultural and heritage environment and generous hospitality by leading hospitality brands.",
    swipeGalleryData: [
      { src: paths.resortsHotels.full, caption: "" },
      { src: paths.baccaratHotel.full, caption: "" },
      { src: paths.rosewoodHotel.full, caption: "" },
      { src: paths.fauchonHotel.full, caption: "" },
      { src: paths.armaniHotel.full, caption: "" },
      { src: paths.orientHotel.full, caption: "" },
    ],
    img: paths.resortsHotels.full,
    imgThumb: paths.resortsHotels.thumb,
    link: "hospitality",
    mapID: "diriyah-square",
    subState: "primary",
  },
  "open-spaces": {
    name: "GREEN & OPEN SPACES",
    title: "Green and Open Spaces",
    desc: "Diriyah is a pioneer in sustainability and urban innovation, holding a Platinum LEED pre-certification from the US green building council.",
    feature1: "Escarpment Walk",
    feature1Text: "3km",
    feature2: "Plants, Trees and Shrubs",
    feature2Text: "6.5M+",
    feature3: null,
    feature3Text: null,
    para: "Diriyah Square is part of Diriyah which is a pedestrian oriented development that oversees the natural oasis of Wadi Hanifah encouraging walkability.",
    swipeGalleryData: [{ src: paths.greenSpaces.full, caption: "" }],
    img: paths.greenSpaces.full,
    imgThumb: paths.greenSpaces.thumb,
    link: "open-spaces",
    mapID: "diriyah-square",
    subState: "primary",
  },
  commercial: {
    name: "COMMERCIAL OFFICES",
    title: "Offices",
    desc: "An Exclusive and Sustainable  Business Address.",
    feature1: "Premium office buildings",
    feature1Text: "5",
    feature2: "Office",
    feature2Text: "45k sqm",
    feature3: "Top-tier Kindergarten",
    feature3Text: "1",
    para: "As Diriyah’s premier  commercial  hub, Diriyah Square will feature exclusive and sustainably  designed,  state-of-the-art office buildings. The prestigious  offices will be home to high-profile government  and institutions that align with Diriyah’s heritage,  cultural  and sustainability values, and are responsible  for driving the nation’s transformation. Employees will benefit from unparalleled  access  to luxurious amenities,  including dining, retail outlets, exclusive leisure  experiences and a top-tier  kindergarten  to enable a balanced work life.",
    swipeGalleryData: [{ src: paths.workspaceOffering.full, caption: "" }],
    img: paths.workspaceOffering.full,
    imgThumb: paths.workspaceOffering.thumb,
    link: "commercial",
    mapID: "diriyah-square",
    subState: "primary",
  },
  residential: {
    name: "BRANDED RESIDENCES",
    title: "Exclusive branded Residences",
    desc: "Diriyah Square offers a life of unprecedented luxury.",
    feature1: "Branded residences",
    feature1Text: "3",
    feature2: "Branded residential units",
    feature2Text: "34",
    feature3: null,
    feature3Text: null,
    para: "Diriyah Square comprises a limited collection of luxurious residences that cater to those looking for an elevated lifestyle in a one-of-a-kind location. The architecturally unique homes seamlessly blend modern comforts with exclusive leisure and lifestyle experiences.",
    swipeGalleryData: [
      { src: paths.uniqueResidences.full, caption: "" },
      { src: paths.baccaratResidences.full, caption: "" },
      { src: paths.corinthiaResidences.full, caption: "" },
    ],
    img: paths.uniqueResidences.full,
    imgThumb: paths.uniqueResidences.thumb,
    link: "residential",
    mapID: "diriyah-square",
    subState: "primary",
  },
  retail: {
    name: "RETAIL & DINING",
    title: "The Retail Experience",
    desc: "Pioneering Riyadh's future retail and leisure landscape.",
    feature1: "Retail Units",
    feature1Text: "400+",
    feature2: "Dining Units",
    feature2Text: "100+",
    feature3: null,
    feature3Text: null,
    para: "This unique project gathers together over 400 of the finest luxury and lifestyle brands from across the Kingdom and around the world in an exceptional, authentic and intimate environment of streets laneways and squares.",
    swipeGalleryData: [{ src: paths.luxuryRetail.full, caption: "" }],
    img: paths.luxuryRetail.full,
    imgThumb: paths.luxuryRetail.thumb,
    link: "retail",
    mapID: "diriyah-square",
    subState: "primary",
  },
  baccarat: {
    name: "baccarat",
    title: "Baccarat Hotel",
    desc: "Baccarat Diriyah will offer guests an artistic atmosphere catering to opulent social moments.",
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    swipeGalleryData: [
      {
        src: "/assets/images/residences-hotels/armani-hotel/aerial-2.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-hotel/aerial.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-hotel/court.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-hotel/drop-off.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-hotel/entrance-2.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-hotel/entrance.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-hotel/lux.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-hotel/pool.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-hotel/restaurant.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-hotel/spa.webp",
        caption: "",
      },
    ],
    img: paths.baccaratHotel.full,
    imgThumb: paths.baccaratHotel.thumb,
    link: "baccarat",
    mapID: null,
  },
  corinthia: {
    name: "baccarat",
    title: "Corinthia Hotel",
    desc: "The Corinthia Hotel will build upon its hospitality legacy by delighting the senses of Diriyah’s travelers with great service and timeless design motifs.    ",
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    swipeGalleryData: [
      {
        src: "/assets/images/residences-hotels/corinthia-hotel/aerial-1.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/corinthia-hotel/aerial-2.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/corinthia-hotel/aerial.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/corinthia-hotel/entrance.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/corinthia-hotel/external.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/corinthia-hotel/lobby.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/corinthia-hotel/lux.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/corinthia-hotel/plaza.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/corinthia-hotel/spa.webp",
        caption: "",
      },
    ],
    img: paths.corinthiaHotel.full,
    imgThumb: paths.corinthiaHotel.thumb,
    link: "corinthia",
    mapID: null,
  },
  rosewood: {
    name: "baccarat",
    title: "Rosewood Hotel & Resort",
    desc: "The Rosewood Hotel and Resort will add a grand and gracious new urban sanctuary to Diriyah and Wadi Hanifah. The hotel uniquely features 52 keys in the privacy and luscious Wadi Hanifah, and 210 keys in a prime location within the vibrant Diriyah Square in the midst of the retail hub.",
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    swipeGalleryData: [
      {
        src: "/assets/images/residences-hotels/rosewood-hotel/aerial-1.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-hotel/aerial-2.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-hotel/aerial-3.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-hotel/aerial-4.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-hotel/courtyard.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-hotel/drop-off-night.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-hotel/drop-off.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-hotel/guest-house.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-hotel/pavilion.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-hotel/pool-2.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-hotel/pool-3.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-hotel/pool.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-hotel/sikka.webp",
        caption: "",
      },
    ],
    img: paths.rosewoodHotel.full,
    imgThumb: paths.rosewoodHotel.thumb,
    link: "rosewood",
    mapID: null,
  },
  fauchon: {
    name: "baccarat",
    title: "Fauchon Hotel",
    desc: "The purveyor of French contemporary gastronomy since 1886, will launch its first Fauchon Hotel in the Middle East, showcasing the perfect Parisian “art de vivre” experience",
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    swipeGalleryData: [
      {
        src: "/assets/images/residences-hotels/fauchon-hotel/aerial-1.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/fauchon-hotel/aerial-2.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/fauchon-hotel/dining.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/fauchon-hotel/lobby.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/fauchon-hotel/pool.webp",
        caption: "",
      },
    ],
    img: paths.fauchonHotel.full,
    imgThumb: paths.fauchonHotel.thumb,
    link: "fauchon",
    mapID: null,
  },
  armani: {
    name: "baccarat",
    title: "Armani Hotel",
    desc: "Armani Diriyah will represent a new interpretation of Armani hospitality, by Mr. Giorgio Armani, conversing with the unique architecture and landscape of the destination.    ",
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    swipeGalleryData: [
      {
        src: "/assets/images/residences-hotels/armani-hotel/aerial-2.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-hotel/aerial.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-hotel/court.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-hotel/drop-off.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-hotel/entrance-2.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-hotel/entrance.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-hotel/lux.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-hotel/pool.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-hotel/restaurant.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-hotel/spa.webp",
        caption: "",
      },
    ],
    img: paths.armaniHotel.full,
    imgThumb: paths.armaniHotel.thumb,
    link: "armani",
    mapID: null,
  },
  orient: {
    name: "orient",
    title: "Orient Express",
    desc: "Will make its debut into the Middle East, bringing the refined nomadic spirit and state-of-art detailing that characterize the artisan of travel.",
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    swipeGalleryData: [{ src: paths.orientHotel.full, caption: "" }],
    img: paths.orientHotel.full,
    imgThumb: paths.orientHotel.thumb,
    link: "orient",
    mapID: null,
  },
  armaniresi: {
    name: "armani-resi",
    title: "Armani Residences Diriyah",
    desc: "Armani Residences Diriyah will represent a new interpretation of Armani hospitality, by Mr. Giorgio Armani, conversing with the unique architecture and landscape of the destination.",
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    para: "Key Count - 86",
    swipeGalleryData: [
      {
        src: "/assets/images/residences-hotels/armani-residence/aerial-view-2.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-residence/aerial-view.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-residence/courtyard.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-residence/entrance.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-residence/pool.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/armani-residence/sikka.webp",
        caption: "",
      },
    ],
    img: paths.armaniResidences.full,
    imgThumb: paths.armaniResidences.thumb,
    link: "armaniresi",
    mapID: null,
  },
  baccaratresi: {
    name: "armani-resi",
    title: "Baccarat Residences Diriyah",
    desc: "In the style of Baccarat’s uncompromising quality and impeccable hospitality, the residences are serviced by the five-star hotel group, which also features extensive amenities and events.",
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    swipeGalleryData: [{ src: paths.armaniResidences.full, caption: "" }],
    img: paths.armaniResidences.full,
    imgThumb: paths.armaniResidences.thumb,
    link: "baccaratresi",
    mapID: null,
  },
  corinthiaresi: {
    name: "armani-resi",
    title: "Corinthia Residences Diriyah",
    desc: "True to the style and motifs of Najdi design, the collection of residences is an interplay of height and depth, light and air. Elegant foyers open to soaring ceilings that branch out into intimate family spaces and sweeping areas for entertaining.",
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    swipeGalleryData: [
      {
        src: "/assets/images/residences-hotels/corinthia-residences/aerial-2.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/corinthia-residences/aerial-1.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/corinthia-residences/courtyard.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/corinthia-residences/entry.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/corinthia-residences/pool.webp",
        caption: "",
      },
    ],
    img: paths.armaniResidences.full,
    imgThumb: paths.armaniResidences.thumb,
    link: "corinthiaresi",
    mapID: null,
  },
  rosewoodresi: {
    name: "",
    title: "Rosewood Residences Diriyah",
    desc: "Will add a grand and gracious new urban sanctuary to Diriyah and Wadi Hanifah.",
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    swipeGalleryData: [
      {
        src: "/assets/images/residences-hotels/rosewood-residence/aerial-2.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-residence/aerial-1.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-residence/aerial-3.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-residence/entrance-2.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-residence/entrance-3.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-residence/entrance.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-residence/garden.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-residence/lobby.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-residence/pool.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-residence/pool-2.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-residence/pool-3.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-residence/restaurant-3.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-residence/restaurant-2.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-residence/restaurant.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-residence/spa.webp",
        caption: "",
      },
      {
        src: "/assets/images/residences-hotels/rosewood-residence/villa.webp",
        caption: "",
      },
    ],
    img: paths.armaniResidences.full,
    imgThumb: paths.armaniResidences.thumb,
    link: "rosewoodresi",
    mapID: null,
  },
}

export default dsqCopyData
