const items = [
  {
    path: "/vision",
    title: "Vision",
    icon: [
      {
        coords:
          "M20.25 4.5H3.75C3.33579 4.5 3 4.83579 3 5.25V16.5C3 16.9142 3.33579 17.25 3.75 17.25H20.25C20.6642 17.25 21 16.9142 21 16.5V5.25C21 4.83579 20.6642 4.5 20.25 4.5Z",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
      {
        coords: "M15 17.25L18 21",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
      {
        coords: "M9 17.25L6 21",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
      {
        coords: "M12 4.5V2.25",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
    ],
  },
  {
    path: "/masterplan",
    title: "Masterplan",
    icon: [
      {
        coords: "M9 17.25L3 18.75V5.25L9 3.75",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
      {
        coords: "M15 20.25L9 17.25V3.75L15 6.75V20.25Z",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
      {
        coords: "M15 6.75L21 5.25V18.75L15 20.25",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
    ],
  },
  {
    path: "/diriyah-square",
    title: "Diriyah Square",
    icon: [
      {
        coords:
          "M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
    ],
  },
  {
    path: "/unit-finder",
    title: "Retail Unit Finder",
    icon: [
      {
        coords:
          "M20.25 4.5H3.75C3.33579 4.5 3 4.83579 3 5.25V18.75C3 19.1642 3.33579 19.5 3.75 19.5H20.25C20.6642 19.5 21 19.1642 21 18.75V5.25C21 4.83579 20.6642 4.5 20.25 4.5Z",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
      {
        coords:
          "M15.75 8.25C15.75 9.24456 15.3549 10.1984 14.6517 10.9017C13.9484 11.6049 12.9946 12 12 12C11.0054 12 10.0516 11.6049 9.34835 10.9017C8.64509 10.1984 8.25 9.24456 8.25 8.25",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
    ],
  },
  {
    path: "/features",
    title: "Key Features",
    icon: [
      {
        coords:
          "M14.8801 15.3291L12.7046 21.3119C12.6519 21.4566 12.5561 21.5815 12.43 21.6699C12.3039 21.7582 12.1537 21.8056 11.9997 21.8056C11.8458 21.8056 11.6955 21.7582 11.5694 21.6699C11.4433 21.5815 11.3475 21.4566 11.2949 21.3119L9.11932 15.3291C9.08186 15.2261 9.0223 15.1326 8.9448 15.0551C8.86731 14.9776 8.77377 14.918 8.67078 14.8806L2.68803 12.705C2.54335 12.6524 2.41836 12.5566 2.33004 12.4305C2.24171 12.3044 2.19434 12.1541 2.19434 12.0002C2.19434 11.8462 2.24171 11.696 2.33004 11.5699C2.41836 11.4438 2.54335 11.348 2.68803 11.2954L8.67078 9.11981C8.77377 9.08235 8.86731 9.02278 8.9448 8.94529C9.0223 8.8678 9.08186 8.77426 9.11932 8.67127L11.2949 2.68852C11.3475 2.54383 11.4433 2.41885 11.5694 2.33052C11.6955 2.2422 11.8458 2.19482 11.9997 2.19482C12.1537 2.19482 12.3039 2.2422 12.43 2.33052C12.5561 2.41885 12.6519 2.54383 12.7046 2.68852L14.8801 8.67127C14.9176 8.77426 14.9771 8.8678 15.0546 8.94529C15.1321 9.02278 15.2256 9.08235 15.3286 9.11981L21.3114 11.2954C21.4561 11.348 21.5811 11.4438 21.6694 11.5699C21.7577 11.696 21.8051 11.8462 21.8051 12.0002C21.8051 12.1541 21.7577 12.3044 21.6694 12.4305C21.5811 12.5566 21.4561 12.6524 21.3114 12.705L15.3286 14.8806C15.2256 14.918 15.1321 14.9776 15.0546 15.0551C14.9771 15.1326 14.9176 15.2261 14.8801 15.3291Z",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
    ],
  },
  {
    path: "/gallery",
    title: "Gallery",
    icon: [
      {
        coords: "M10.5 4.5H4.5V10.5H10.5V4.5Z",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
      {
        coords: "M19.5 4.5H13.5V10.5H19.5V4.5Z",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
      {
        coords: "M10.5 13.5H4.5V19.5H10.5V13.5Z",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
      {
        coords: "M19.5 13.5H13.5V19.5H19.5V13.5Z",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
    ],
  },
  {
    path: "/favorites",
    title: "Favorites",
    icon: [
      {
        coords:
          "M17.999 21L11.9983 17.25L5.99902 21V4.5C5.99902 4.30109 6.07804 4.11032 6.21869 3.96967C6.35935 3.82902 6.55011 3.75 6.74902 3.75H17.249C17.4479 3.75 17.6387 3.82902 17.7794 3.96967C17.92 4.11032 17.999 4.30109 17.999 4.5V21Z",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
    ],
  },
  {
    path: "/metrics",
    title: "Metrics",
    icon: [
      {
        coords: "M21.375 19.4995H2.625",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
      {
        coords: "M9.375 19.4995V8.24951H14.625",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
      {
        coords: "M19.875 3.74951H14.625V19.4995H19.875V3.74951Z",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
      {
        coords: "M4.125 19.4995V12.7495H9.375",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
    ],
  },
  {
    path: "/team",
    title: "Team",
    icon: [
      {
        coords:
          "M12 16.8745C14.0711 16.8745 15.75 15.1956 15.75 13.1245C15.75 11.0534 14.0711 9.37451 12 9.37451C9.92893 9.37451 8.25 11.0534 8.25 13.1245C8.25 15.1956 9.92893 16.8745 12 16.8745Z",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
      {
        coords:
          "M18.375 10.8745C19.2484 10.8738 20.1098 11.0767 20.891 11.4673C21.6722 11.8578 22.3516 12.4251 22.8751 13.1241",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
      {
        coords:
          "M1.12473 13.1243C1.64826 12.4252 2.32763 11.8579 3.10884 11.4673C3.89006 11.0768 4.75159 10.8738 5.62499 10.8745",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
      {
        coords:
          "M6.60321 20.2494C7.09588 19.2375 7.86314 18.3845 8.8174 17.7877C9.77167 17.191 10.8745 16.8745 12 16.8745C13.1255 16.8745 14.2283 17.1909 15.1826 17.7877C16.1368 18.3844 16.9041 19.2375 17.3968 20.2494",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
      {
        coords:
          "M5.62499 10.8745C5.05578 10.8744 4.49834 10.7124 4.01777 10.4073C3.5372 10.1022 3.15334 9.66677 2.91101 9.15171C2.66867 8.63666 2.57787 8.0633 2.6492 7.49857C2.72053 6.93384 2.95105 6.40106 3.31384 5.96244C3.67664 5.52382 4.15673 5.19747 4.69806 5.02149C5.23938 4.84551 5.81961 4.82716 6.37097 4.96859C6.92233 5.11003 7.42208 5.4054 7.81186 5.82023C8.20163 6.23506 8.46535 6.75221 8.57221 7.3113",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
      {
        coords:
          "M15.4278 7.31136C15.5346 6.75218 15.7983 6.23492 16.1881 5.82001C16.5779 5.40509 17.0776 5.10964 17.6291 4.96815C18.1805 4.82666 18.7608 4.84498 19.3022 5.02096C19.8436 5.19695 20.3238 5.52334 20.6866 5.96201C21.0495 6.40068 21.28 6.93354 21.3513 7.49834C21.4227 8.06315 21.3318 8.63659 21.0895 9.1517C20.8471 9.66681 20.4631 10.1023 19.9825 10.4074C19.5018 10.7125 18.9443 10.8745 18.375 10.8745",
        color: "#004344",
        attr: { strokeLinecap: "round", strokeLinejoin: "round" },
      },
    ],
  },
]

export default items
