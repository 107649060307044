import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { DiamondButton } from "@components/buttons"
import { motion } from "framer-motion"

interface SidePanelProps {
  layoutId?: string
  className?: string
  children: React.ReactNode
  style?: React.CSSProperties
  onClose?: () => void
  [x: string] : unknown
}

const Panel: React.FC<SidePanelProps> = ({
  layoutId,
  style,
  children,
  onClose,
  className
}) => {
  const panelRef = useRef<HTMLDivElement>(null)

  return (
    <Wrapper
      ref={panelRef}
      style={style}
      className={className}
      layoutId={layoutId}
    >
      {children}
    </Wrapper>
  )
}

export { Panel }

const Wrapper = styled(motion.div)`
  position: relative;
  color: white;
  background-color: ${({ theme }) => theme.lightGreen};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
