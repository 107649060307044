import mapGlobalValues from "../mapGlobalValues"

const baseConfig = {
  icon: mapGlobalValues.iconPaths.green.eye,
  bgColor: mapGlobalValues.zoneIconBackgroundColor,
  textColor: mapGlobalValues.zoneIconTextColor,
  height: 35,
  strokeColor: "white",
  strokeWidth: 1
}

const data = [
  {
    label: "OPEN SPACES",
    activeState: "open-spaces",
    x: 412.24,
    y: 791.76,
  },
  {
    label: "OPEN SPACES",
    activeState: "open-spaces",
    x: 1620,
    y: 1011.92,
  },
  {
    label: "HOTELS",
    activeState: "hospitality",
    x: 600,
    y: 850,
  },
  {
    label: "HOTELS",
    activeState: "hospitality",
    x: 1200,
    y: 850,
  },
  {
    label: "BRANDED RESIDENCES",
    activeState: "residential",
    x: 1300,
    y: 950,
  },
  {
    label: "COMMUNITY",
    activeState: "community",
    x: 660.88,
    y: 1153.79,
  },
  {
    label: "COMMERCIAL",
    activeState: "commercial",
    x: 858.02,
    y: 1254.86,
  },
  {
    label: "RETAIL",
    activeState: "retail",
    x: 776.49,
    y: 910.75,
  },
]

const diriyahSquareZoneIconsData = data.map((item) => {
  return item.label === "FIND A UNIT" || item.label === "RETAIL"
    ? {
        ...item,
        bgColor: mapGlobalValues.mainIconBackgroundColor,
        textColor: mapGlobalValues.mainIconTextColor,
        icon: mapGlobalValues.iconPaths.white.arrow,
        iconShape: "diamond",
        height: 40,
        strokeColor: "white",
        strokeWidth: 1
      }
    : {
        ...item,
        ...baseConfig,
      }
})

export default diriyahSquareZoneIconsData
