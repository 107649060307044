import { useEffect } from "react"
import styled from "styled-components"
import { MapContainer, useMapEvents, useMap } from "react-leaflet"

import Filter from "@components/UnitFinderSideBar/components/Filter"
import RasterMapLayer from "./components/Layer"
import PageWrapper from "@components/PageWrapper"
import Tiles from "@components/MapTiles/RasterMap"

import { useStore } from "@state/store"
import ZonesLayer from "./components/ZonesLayer"
import UnitsLayer from "./components/UnitsLayer"
import UnitsLabelsLayer from "./components/UnitsLabelsLayer"
import AmenitiesLayer from "./components/AmenitiesLayer"
import UnitFinderSideBar from "@components/UnitFinderSideBar"
import useRasterCoords from "../../hooks/leaflet/useRasterCoords"

const IMG_RES: [width: number, height: number] = [20000, 20000]
const MIN_ZOOM = 4
const MAX_ZOOM = 7

function UnitFinderMap() {
  const {
    finderMapUI,
    finderOverlayLayer,
    finderPlotsLayer,
    finderLabelsLayer,
    amenityCategory,
    mapZooming,
  } = useStore((s) => ({
    finderMapUI: s.finderMapUI,
    finderOverlayLayer: s.finderOverlayLayer,
    finderPlotsLayer: s.finderPlotsLayer,
    finderLabelsLayer: s.finderLabelsLayer,
    amenityCategory: s.amenityCategory,
    mapCategory: s.mapCategory,
    unitLevel: s.unitLevel,
    mapZooming: s.mapZooming,
  }))
  const setLocalState = useStore((s) => s.setLocalState)

  function reset() {
    setTimeout(() => {
      setLocalState({
        unitLevel: null,
        activeHotel: null,
        amenityCategory: null,
        mapCategory: null,
        unitID: null,
        selectedUnit: null,
        finderMapUI: true,
        finderLabelsLayer: false,
        finderOverlayLayer: false,
        finderPlotsLayer: false,
      })
    }, 850)
  }

  const overlayActive = finderOverlayLayer && finderMapUI && !mapZooming
  const unitsActive = finderPlotsLayer && finderMapUI && !mapZooming
  const labelsActive = finderLabelsLayer && finderMapUI && !mapZooming
  const amenitiesActive = amenityCategory && finderMapUI && !mapZooming

  return (
    <PageWrapper>
      <UnitFinderMapWrapper>
        <TiledMap
          id={"unitFinderMap"}
          minZoom={MIN_ZOOM}
          maxZoom={MAX_ZOOM}
          scrollWheelZoom={true}
          zoomControl={false}
          attributionControl={false}
          zoomSnap={0.01}
          zoomDelta={0.01}
        >
          <MapConfig img={IMG_RES} onDoubleClick={reset} />
          <Tiles img={IMG_RES} />
          <RasterMapLayer img={IMG_RES} active={overlayActive}>
            <ZonesLayer img={IMG_RES} active={overlayActive} />
          </RasterMapLayer>
          <RasterMapLayer img={IMG_RES} active={unitsActive}>
            <UnitsLayer active={unitsActive} />
          </RasterMapLayer>
          <RasterMapLayer img={IMG_RES} active={labelsActive}>
            <UnitsLabelsLayer active={labelsActive} />
          </RasterMapLayer>
          <RasterMapLayer img={IMG_RES} active={amenitiesActive}>
            <AmenitiesLayer active={amenitiesActive} />
          </RasterMapLayer>
          <EventsHandler />
        </TiledMap>
        <Filter />
        <UnitFinderSideBar />
      </UnitFinderMapWrapper>
    </PageWrapper>
  )
}

function EventsHandler() {
  const setLocalState = useStore((s) => s.setLocalState)
  const { unitLevel, mapCategory, finderMapUI, amenityCategory } = useStore(
    (s) => ({
      unitLevel: s.unitLevel,
      mapCategory: s.mapCategory,
      finderMapUI: s.finderMapUI,
      amenityCategory: s.amenityCategory,
    })
  )

  useEffect(() => {
    if (!finderMapUI) {
      setLocalState({
        finderMapUI: true,
      })
    }
  }, [mapCategory, amenityCategory, unitLevel])

  const map = useMapEvents({
    zoomstart: (e) => {
      setLocalState({
        mapZooming: true,
      })
    },
    zoomend: () => {
      const zoomLevel = map.getZoom()
      setLocalState({
        mapZooming: false,
      })
      setLocalState({
        finderOverlayLayer: zoomLevel === 4,
        finderPlotsLayer: zoomLevel > 4,
        finderLabelsLayer: zoomLevel > 4,
      })

      if (zoomLevel > 4 && unitLevel === null) {
        setLocalState({
          unitLevel: "0",
        })
      }
    },
  })

  return null
}

export default UnitFinderMap

const UnitFinderMapWrapper = styled.div`
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: clip;
  background-color: var(--light-grey);

  .cloudsWrapper {
    z-index: 10;
    transition: all 0.6s ease-in-out;
  }

  .animateLayer {
    opacity: 0;
    animation-duration: 0.4s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }

  .animateIn {
    animation-name: fadeIn;
    pointer-events: all;
  }

  .animateOut {
    animation-name: fadeOut;
    pointer-events: none;
  }
`

const TiledMap = styled(MapContainer)`
  position: absolute;
  z-index: 0;
  width: calc(100% - 26vw);
  height: 100%;
  background-color: #eac68d;
`

function MapConfig({
  img,
  onDoubleClick = undefined,
  resetViewonDblClick = true,
}: {
  img: [width: number, height: number]
  resetViewonDblClick?: boolean
  onDoubleClick?: () => void
}) {
  const map = useMap()
  const rc = useRasterCoords(img)
  useEffect(() => {
    map.setView(rc.unproject([img[0] / 2, img[1] / 2]), map.options.minZoom)
    map.on("drag", function (e) {
      map.panInsideBounds(rc.getMaxBounds(), { animate: false })
    })
    map.on("dblclick", function (e) {
      onDoubleClick && onDoubleClick()
      resetViewonDblClick &&
        map.flyTo(rc.unproject([img[0] / 2, img[1] / 2]), map.options.minZoom, {
          duration: 0.8,
        })
    })
  }, [])
  return null
}
