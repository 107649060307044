import { useEffect, useLayoutEffect, useRef } from "react"
import gsap from "gsap"

type TAnimatedIconProps = {
  color?: string
  animated: boolean
  cue: boolean
  data: { color: string; coords: string; attr: object }[]
  duration?: number
  delay?: number
  gsapID?: string
  [x: string]: unknown
}

function AnimatedIcon({
  color,
  data,
  animated,
  cue,
  duration = 1,
  delay = 0,
  gsapID,
  ...props
}: TAnimatedIconProps) {
  const svgRef = useRef<SVGSVGElement>(null)
  const lengths = useRef<number[]>([])

  useLayoutEffect(() => {
    if (!svgRef.current) return
    const paths = Array.from(svgRef.current.children)
    paths.forEach((path, i) => {
      if (path.tagName !== "path" || !path) return
      //@ts-ignore
      const length = path.getTotalLength()
      lengths.current[i] = length
      if (animated) {
        gsap.set(path, {
          strokeDasharray: length,
          strokeDashoffset: length
        })
      }
    })
  }, [])

  useEffect(() => {
    if (!animated) return
    gsap.to(`.${gsapID}`, {
      strokeDashoffset: (i) => (cue ? 0 : lengths.current[i]),
      duration,
      delay,
    })
  }, [cue])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      ref={svgRef}
      {...props}
    >
      {data.map((obj, i) => {
        return (
          <path
            key={`path${i}`}
            className={gsapID}
            d={obj.coords}
            stroke={color ? color : obj.color}
            strokeWidth={1}
            fill="none"
            {...obj.attr}
          />
        )
      })}
    </svg>
  )
}

export default AnimatedIcon
