import React from "react"
import styled from "styled-components"

type FeatureTextProps = {
  text1?: string
  text2?: string
  type?: string
  className?: string
}

const FeatureText: React.FC<FeatureTextProps> = (props) => {
  return (
    <Feature>
      <Text>{props.text1}</Text>
      <Text>{props.text2}</Text>
      <FeatureType>{props.type}</FeatureType>
    </Feature>
  )
}

export default FeatureText

const Feature = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  color: ${({ theme }) => theme.darkGreen};
`

const Text = styled.div`
  font-size: 1.75rem;
  text-align: right;
  font-family: 'BeausiteGrandTrial-Light';

`

const FeatureType = styled.div`
  // padding: 0.5rem 0;
  font-weight: 100;
  font-size: 1rem;
  text-align: right;
  color: ${({ theme }) => theme.lightBrown};
`
