import CameraControls from './components/CameraControls';
import Sphere from './components/Sphere';

type TProps ={
  textures: string[];
  camera?: unknown;
  initialPosition?: [number, number, number];
  controls?: boolean;
  controlsProps?: {[x:string]: unknown}
  activeIndex?: number
  show?: boolean
  onLoaded?: () => void
  [x:string]: unknown
}

function TransitionTour(props: TProps) {
  const { show = true, controls, textures, initialPosition, controlsProps = {}, activeIndex = 0, onLoaded } = props
  return (
    <group {...props}>
        {controls && (
          <CameraControls {...controlsProps}/>
        )}
        <Sphere
          textures={textures}
          initialPosition={initialPosition ?? [0, 0, 0]}
          activeIndex={activeIndex}
          display={show}
          //@ts-ignore
          onLoaded={onLoaded}
        />
    </group>
  );
}

export default TransitionTour;
