import React, { useState } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import TeamCarousel from "../../TeamCarousel"
//@ts-ignore
import teamData from "./team.ts"

const MainTeam: React.FC = () => {
  const [activeCategory, setActiveCategory] = useState<string>("board")
  const handleCategoryClick = (team: string) => {
    setActiveCategory(team)
  }

  return (
    <TabWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <img className="svgDesign" src={"/assets/images/features/svgDesign2.svg"} />
      <TopWrapper>
        <LeftText
          initial={{ opacity: 0, x: -5 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}
        >
          <div className="subText">Diriyah</div>
          <div className="mainText">
            Experience the Kingdom's <br /> rich culture and heritage
          </div>
        </LeftText>
        <RightText
          initial={{ opacity: 0, x: 5 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}
        >
          Diriyah Company’s mission is to re-establish Diriyah’s position as a
          globally significant destination and one of the world’s greatest
          gathering places.
        </RightText>
      </TopWrapper>
      <CategoryWrapper
        initial={{ opacity: 0, x: -5 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6 }}
      >
        {Object.keys(teamData).map((team, index) => (
          <Category
            key={index}
            active={activeCategory === team}
            onClick={() => handleCategoryClick(team)}
          >
            {team.toUpperCase()}
          </Category>
        ))}
      </CategoryWrapper>
      <TeamCarousel
        items={teamData[activeCategory]}
        activeCategory={activeCategory}
      />
    </TabWrapper>
  )
}

export default MainTeam

const TabWrapper = styled(motion.div)`
  font-family: "BeausiteGrandTrial-Light";
  width: 100%;
  height: calc(100% - 2.5rem);
  font-weight: 200;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  transition: opacity 1s ease;
  opacity: 0;
  gap: 2rem;
  animation: fadeIn 1s ease forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .mainText {
    font-size: 3rem;
    color: ${({ theme }) => theme.darkGreen};
  }

  .subText {
    font-size: 2rem;
    margin: 1rem 0;
    line-height: 25px;
  }

  .features {
    border-radius: 10px;
  }

  // @media only screen and (min-width: 1921px) {
  //   .mainText {
  //     font-size: 5rem;
  //   }

  //   .subText {
  //     font-size: 1.5rem;
  //   }
  // }

  .svgDesign {
    position: absolute;
    right: 0;
    opacity: 0.5;
  }
`

const TopWrapper = styled.div`
  padding: 4rem 2rem;
  width: 100%;
  max-height: 45%;
  min-height: 45%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  flex: 1;

  background-color: ${({ theme }) => theme.lightGreen};

  // @media only screen and (max-height: 800px) {
  //   padding: 2rem 2rem;
  // }
`

const LeftText = styled(motion.div)`
  width: 60%;
  display: flex;
  color: ${({ theme }) => theme.darkGreen};
  flex-direction: column;
`

const RightText = styled(motion.div)`
  margin-left: auto;
  width: 30%;
  color: ${({ theme }) => theme.darkGreen};
  font-family: "Arial";
  font-size: 1rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  padding-right: 2rem;
  line-height: 25px !important;
`

const CategoryWrapper = styled(motion.div)`
  display: flex;
  padding-left: var(--margin);
  z-index: 1;
`

const Category = styled.div<{ active: boolean }>`
  font-size: 0.75rem;
  font-family: "Arial";
  border: 0.5px solid ${({ theme }) => theme.lightBrown}8A;
  border-left: none;
  padding: 0.75rem 2.5rem;
  background-color: ${({ active, theme }) =>
    active ? theme.lightBrown : "transparent"};
  color: ${({ active, theme }) => (active ? "white" : theme.lightBrown)};
  cursor: pointer;
  transition: 0.3s;

  &:first-child {
    border-left: 0.5px solid ${({ theme }) => theme.lightBrown}8A;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`
