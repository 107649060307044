import { useEffect, useState, useRef, ReactNode } from "react"
import styled from "styled-components"

function Popup({
  openState,
  setOpenState,
  children,
  durationMS,
  closeButton,
  closeColor,
  onClose,
}: {
  openState: boolean
  setOpenState: (v: boolean) => void
  children: ReactNode
  durationMS: number
  closeButton?: ReactNode
  closeColor?: string
  onClose?: () => void
}) {
  const [visible, setVisible] = useState(false)
  const popupRef = useRef<HTMLDivElement>(null)

  function handleRef(node) {
    if (popupRef.current === null) {
      node.style.transitionDuration = `${durationMS}ms`
      setTimeout(() => {
        node.style.opacity = "1"
      }, 100)
      popupRef.current = node
    } else {
      return
    }
  }

  function handleClose() {
    popupRef.current.style.opacity = "0"
    setTimeout(() => {
      setVisible(false)
      setTimeout(() => {
        setOpenState(false)
        onClose && onClose()
        popupRef.current = null
      }, durationMS)
    }, durationMS)
  }

  useEffect(() => {
    if (visible) {
      popupRef.current.style.opacity = "0"
      setTimeout(() => {
        setVisible(openState)
        popupRef.current = null
      }, durationMS + 10)
    } else {
      setVisible(openState)
    }
  }, [openState])

  return (
    visible && (
      <PopupWrapper ref={(node) => node && handleRef(node)}>
        {children}
        {closeButton ? (
          <Close onClick={handleClose}>{closeButton}</Close>
        ) : (
          <Close onClick={handleClose}>
            <CloseIcon strokeWidth={7} strokeColor={closeColor ?? "#FFFFFF"} />
          </Close>
        )}
      </PopupWrapper>
    )
  )
}

export default Popup

const PopupWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 10000000000000;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
`

const Close = styled.div`
  position: absolute;
  z-index: 100;
  z-index: 1;
  width: 1rem;
  aspect-ratio: 1/1;
  cursor: pointer;
  pointer-events: auto;
  top: 2rem;
  right: 4rem;
  display: grid;
  place-content: center;
  border-radius: 100%;
`

type TSVGPropTypes = {
  strokeWidth: number
  strokeColor: string
  bgColor?: string
  [x: string]: any
}

function CloseIcon({
  strokeWidth,
  strokeColor,
  bgColor,
  ...props
}: TSVGPropTypes) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      width="100%"
      height="100%"
      {...props}
    >
      <circle cx="50" cy="50" r="50" fill={bgColor ?? "none"} />
      <line
        x1="5"
        y1="5"
        x2="95"
        y2="95"
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <line
        x1="95"
        y1="5"
        x2="5"
        y2="95"
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
