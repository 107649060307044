import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { useLocation, useNavigate } from "react-router-dom"
import gsap from "gsap"
import StaggeredText from "@components/StaggeredText"
import AnimatedIcon from "@components/SVGIcons/AnimatedIcon"
import BurgerIcon from "@components/BurgerIcon"
import { useStore } from "@state/store"
import menu from "./items"
import { useFavoritesStore } from "@state/favoritesStore"

type NavbarProps = {}

//animation total duration all animation depend on this value
const TOTALDURATION = 1

const Navbar: React.FC<NavbarProps> = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const navOpen = useStore((s) => s.navOpen)
  const navigating = useStore((s) => s.navigating)
  const navDurationSec = useStore((s) => s.navDurationSec)
  const setLocalState = useStore((s) => s.setLocalState)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const buttonsRef = useRef<HTMLDivElement[]>([])
  const navVisible = pathname !== "/"
  const iconDuration = navOpen ? TOTALDURATION * 0.75 : 0.2
  const iconDelay = navOpen ? TOTALDURATION * 0.75 : 0
  const isSelected = (path) => path === pathname
  const logoDown = useRef(0)
  const { clearFavorites } = useFavoritesStore((state) => ({
    clearFavorites: state.clearFavorites,
  }))

  function animateButtons() {
    buttonsRef.current.forEach((btn, i) => {
      gsap.to(btn, {
        autoAlpha: navOpen ? 1 : 0,
        duration: iconDuration,
        delay: TOTALDURATION * 0.075 * i,
      })
    })
  }

  function handleClickOutside(e) {
    const x = e.clientX
    const percent = (x * 100) / innerWidth
    if (percent >= 12) {
      setLocalState({ navOpen: false })
      document.removeEventListener("click", handleClickOutside)
    }
  }

  function handleNav(path) {
    document.removeEventListener("click", handleClickOutside)
    setLocalState({
      navigating: true,
      navOpen: false,
      activeHotel: null,
      amenityCategory: null,
      mapCategory: null,
      unitLevel: null,
      selectedUnit: null,
      unitID: null,
    })
    setTimeout(() => {
      navigate(path)
    }, navDurationSec * 1100)
  }
  // set navigating for page transition effects
  useEffect(() => {
    if (!navigating) return
    setTimeout(() => {
      setLocalState({ navigating: false })
    }, navDurationSec * 1200)
  }, [navigating])

  useEffect(() => {
    gsap.to(".nav-menu", {
      autoAlpha: navVisible ? 1 : 0,
      duration: iconDuration,
    })
  }, [pathname])

  useEffect(() => {
    if (!wrapperRef.current || !buttonsRef.current.length) return
    gsap.to(".nav-main", {
      x: navOpen ? "0%" : "-100%",
      duration: TOTALDURATION * 0.5,
    })
    gsap.to(wrapperRef.current, {
      autoAlpha: navOpen ? 1 : 0,
      duration: TOTALDURATION * 0.5,
      onComplete: () => navOpen && animateButtons(),
      onStart: () => !navOpen && animateButtons(),
    })
  }, [navOpen])

  return (
    <NavWrapper className="nav-main">
      <OpenButton
        className="nav-menu"
        onClick={() => {
          if (navOpen) {
            setLocalState({ navOpen: false })
            document.removeEventListener("click", handleClickOutside)
            return
          } else {
            setLocalState({ navOpen: true })
            document.addEventListener("click", handleClickOutside)
            return
          }
        }}
      >
        <BurgerIcon
          isAnimated
          cue={navOpen}
          color={"var(--white)"}
          width="55%"
          className="burger"
        />
      </OpenButton>
      <ButtonsWrapper ref={wrapperRef}>
        <Logo
          className="nav-menu"
          onClick={() => {
            handleNav("/")
          }}
          onPointerDown={(e) => {
            logoDown.current = e.timeStamp
          }}
          onPointerUp={(e) => {
            const delta = Math.abs((e.timeStamp - logoDown.current) / 1000)
            if (delta > 3) {
              setTimeout(() => {
                window.location.reload()
                clearFavorites()
              }, navDurationSec * 1300)
            }
          }}
        />
        {menu.map((obj, i) => {
          return (
            <Button
              key={`navBtn${i}`}
              className="nav-btn"
              ref={(node) => {
                if (node) {
                  buttonsRef.current[i] = node
                }
              }}
              onClick={() => {
                handleNav(obj.path)
              }}
              style={{
                backgroundColor: isSelected(obj.path)
                  ? "var(--dark-green)"
                  : "transparent",
                color: isSelected(obj.path)
                  ? "var(--white)"
                  : "var(--dark-green)",
              }}
            >
              <AnimatedIcon
                animated={true}
                cue={navOpen}
                data={obj.icon}
                color={
                  isSelected(obj.path) ? "var(--white)" : "var(--dark-green)"
                }
                gsapID={`icon${obj.title.replaceAll(" ", "")}`}
                duration={iconDuration}
                delay={iconDelay}
              />
              <StaggeredText
                text={obj.title}
                cue={navOpen}
                duration={iconDuration}
                delay={iconDelay}
              />
            </Button>
          )
        })}
      </ButtonsWrapper>
    </NavWrapper>
  )
}

export default Navbar

const NavWrapper = styled.div`
  position: fixed;
  z-index: 99999;
  display: flex;
  width: 12rem;
  height: 100%;
  transform: translateX(-100%);
  background-color: rgba(var(--white-rgb), 0.8);
  box-shadow: 8px 8px 15px rgba(var(--black-rgb), 0.2);

  .selection-transition {
    transition-property: color, background-color, fill;
    transition-duration: 0.8s;
    transition-timing-function: ease-in-out;
  }
`

const ButtonsWrapper = styled.div`
  position: absolute;
  z-index: 99999;
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 12rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  opacity: 0;
`

const Button = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--dark-green);
  font-size: clamp(10px, 0.6rem, 15px);
  letter-spacing: 0.1rem;
  font-weight: 400;
  cursor: pointer;
  opacity: 0;
`

export const Logo = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 100000;
  width: 10rem;
  height: 10rem;
  background-image: url("/logo-eng.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-color: var(--white);
  box-shadow: 0px 8px 15px rgba(var(--black-rgb), 0.2);
  cursor: pointer;
`

const OpenButton = styled.div`
  position: absolute;
  z-index: 100000;
  top: 2.5rem;
  right: -25%;
  width: calc(10rem * 0.25);
  height: calc(10rem * 0.25);
  transform: translate(50%, -50%) rotate(45deg);
  background-color: var(--light-brown);
  box-shadow: 8px 8px 15px rgba(var(--black-rgb), 0.2);
  opacity: 0;
  cursor: pointer;

  .burger {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`
