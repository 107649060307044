import { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import ReactDOMServer from "react-dom/server"
import EmailTemplate from "./EmailTemplate"
import LoadingIcon from "@components/LoadingIcon"
import { validation } from "@utils/validation"
import { Button } from "@components/buttons/Button"
import { Logo } from "@components/navbar/Navbar"

type TForm = {
  name: string
  to: string
}

const initialForm = {
  name: null,
  to: null,
}

function SharePopup() {
  const [favorites, setFavorites] = useState<any[]>(
    JSON.parse(localStorage.getItem("favorites")) || []
  )
  const promptRef = useRef<HTMLDivElement>()
  const [formData, setFormData] = useState<TForm>(initialForm)
  const [formMessage, setFormMessage] = useState("")
  const [readyToSend, setReadyToSend] = useState(false)
  const [sending, setSending] = useState(false)
  const favIDS = favorites?.map((fav: any) => fav.unitID)

  function resetPrompt() {
    if (!formMessage) return
    promptRef.current.style.opacity = "0"
    setTimeout(() => {
      setFormMessage("")
    }, 800)
  }

  function handleSubmit(e) {
    e.preventDefault()
    const form = document.getElementById("form") as HTMLFormElement
    const errors = validation(formData)
    if (errors !== undefined) {
      setFormMessage(errors)
      promptRef.current.style.opacity = "1"
    } else {
      setFormData({
        ...formData,
      })
      setSending(true)
      setReadyToSend(true)
      form.reset()
    }
  }

  useEffect(() => {
    if (formData && readyToSend) {
      const htmlTemplate = ReactDOMServer.renderToStaticMarkup(
        <EmailTemplate name={formData?.name} />
      )
      fetch(
        `https://jysb9a7a4j.execute-api.me-south-1.amazonaws.com/sendEmail`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            ...formData,
            from: "dsqretail@diriyah.sa",
            message: htmlTemplate,
            subject: "Your Units at Diriyah Square",
            filename: "Your Units at Diriyah Square",
            units: favorites,
          }),
        }
      )
        .then((res) => {
          if (!res.ok) {
            throw new Error("Could not send email.")
          }
          return res.json()
        })
        .then((data) => {
          setReadyToSend(false)
          setFormData(initialForm)
          setSending(false)
          setFormMessage("Email sent")
          setTimeout(() => {
            setFormMessage("")
          }, 2000)
          promptRef.current.style.opacity = "1"
        })
        .catch((err) => {
          setReadyToSend(false)
          setFormData(initialForm)
          setSending(false)
          setFormMessage("Email failed to send. Please try again later.")
          promptRef.current.style.opacity = "1"
          console.error(
            "There has been a problem with your fetch operation:",
            err
          )
        })
    }
    return () => {
      setReadyToSend(false)
      setFormMessage("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToSend])

  return (
    <FormWrapper>
      <div className="left">
        <Logo
          className="nav-menu"
          style={{
            boxShadow: "none",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
        <h6
          style={{
            opacity: 1,
            fontSize: "1rem",
            position: "absolute",
            top: "25%",
            color: "#004344",
          }}
        >
          Share favorite units
        </h6>
        <div className="container">
          <h6 ref={promptRef}>{formMessage}</h6>
          <form id="form">
            <input
              placeholder="Name"
              name="fName"
              type="text"
              max="20"
              autoComplete="off"
              onPointerDown={(e) => {
                e.currentTarget.focus()
              }}
              onInput={(e) => {
                setFormData({
                  ...formData,
                  name: (e.target as HTMLInputElement).value,
                })
                resetPrompt()
              }}
            />

            <input
              placeholder="Email"
              name="Email"
              type="Email"
              max="30"
              autoComplete="off"
              onPointerDown={(e) => {
                e.currentTarget.focus()
              }}
              onInput={(e) => {
                setFormData({
                  ...formData,
                  to: (e.target as HTMLInputElement).value,
                })
                resetPrompt()
              }}
            />
            <Button
              color="brown"
              title="SEND"
              style={{ width: "80%", marginTop: "1em" }}
              icon={sending && <LoadingIcon color="white" />}
              onClick={(e: any) => handleSubmit(e)}
            />
          </form>
        </div>
      </div>
      <div className="right">
        <img src="/assets/images/renders/full/view4.webp" alt="share" />
      </div>
    </FormWrapper>
  )
}

export default SharePopup

const FormWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.darkGreen};
  padding: 0 5em;
  user-select: none !important;

  .left {
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container {
    position: relative;
    width: 100%;
    margin-top: 2em;
  }

  * {
    touch-action: auto !important;
    pointer-events: all !important;
  }

  & h6 {
    width: 100%;
    height: 1em;
    text-align: center;
    font-size: 0.7rem;
    transition: opacity 0.8s ease-in-out;
    opacity: 0;
    position: absolute;
    bottom: -25px;
    color: ${({ theme }) => theme.lightBrown};
    font-weight: 100;
    text-transform: uppercase;
  }

  & form {
    color: ${({ theme }) => theme.darkGreen};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 0.5em;
    padding: 0 3em;

    & input {
      width: 80%;
      height: 3em;
      border: none;
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.lightBrown};
      border-radius: 4px;
      color: ${({ theme }) => theme.lightBrown};
      font-size: 1rem;
      font-weight: 400;
      padding: 0 1.5em;
      font-family: BeausiteClassicTrial-Clear;
      pointer-events: all;

      ::placeholder {
        font-family: BeausiteClassicTrial-Clear;
        color: ${({ theme }) => theme.lightBrown};
        font-weight: 400;
        font-size: 0.8rem;
        opacity: 0.8;
      }
      :focus-visible {
        outline: none;
        background-color: transparent;
      }
      :-webkit-autofill,
      :-webkit-autofill:hover,
      :-webkit-autofill:focus,
      :-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        color: ${({ theme }) => theme.lightBrown} !important;
      }
    }
  }

  .right {
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`
