import { useCallback, useEffect, useState, useRef } from "react"
import styled from "styled-components"
import useEmblaCarousel from "embla-carousel-react"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

import { DiamondButton } from "@components/buttons"

function SwipeGallery(props) {
  const { initialIndex, data, ...rest } = props
  const [activeIndex, setActiveIndex] = useState(0)
  const isTouch = navigator.maxTouchPoints > 2
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    watchDrag: isTouch,
    dragThreshold: 125,
  })

  const onSelect = useCallback((emblaApi) => {
    const currentId = emblaApi.selectedScrollSnap()
    setActiveIndex(currentId)
  }, [])

  function handleClick(dir) {
    const max = data.length - 1
    if (dir) {
      setActiveIndex(activeIndex === max ? 0 : activeIndex + 1)
    } else {
      setActiveIndex(activeIndex === 0 ? max : activeIndex - 1)
    }
  }

  useEffect(() => {
    setActiveIndex(initialIndex)
  }, [initialIndex])

  useEffect(() => {
    if (emblaApi) {
      if (activeIndex !== emblaApi.selectedScrollSnap()) {
        emblaApi.scrollTo(activeIndex)
      }
    }
  }, [activeIndex])

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", onSelect)
    }
  }, [emblaApi])

  return (
    <SwipeGalleryWrapper {...rest}>
      {/* {
        <Caption>
          {data?.map((obj, i) => {
            return (
              <span
                key={`caption${i}`}
                style={{
                  opacity: i === activeIndex ? 1 : 0,
                }}
              >
                {obj?.caption}
              </span>
            )
          })}
        </Caption>
      } */}
      {isTouch ? (
        <SwiperWrapper ref={emblaRef}>
          <Swiper>
            {data?.map((obj, i) => {
              return (
                <SwiperSlide key={`img${i}`}>
                  <img
                    src={obj?.src}
                    alt=""
                    style={{
                      width: "auto",
                      height: "100%",
                      maxWidth: "95%",
                      maxHeight: "95%",
                    }}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </SwiperWrapper>
      ) : (
        <SwiperWrapper ref={emblaRef}>
          <Swiper>
            {data?.map((obj, i) => {
              return (
                <SwiperSlide key={`img${i}`}>
                  <TransformWrapper
                    initialScale={1}
                    minScale={1}
                    maxScale={3}
                    limitToBounds={true}
                    smooth={true}
                    centerZoomedOut={true}
                    wheel={{
                      step: 0.025,
                    }}
                    doubleClick={{
                      mode: "reset",
                    }}
                  >
                    <TransformComponent
                      wrapperClass="pinch-zoom"
                      contentClass="pinch-zoom"
                    >
                      <img
                        src={obj?.src}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </TransformComponent>
                  </TransformWrapper>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </SwiperWrapper>
      )}
      {data?.length > 1 && (
        <Buttons>
          <DiamondButton
            onClick={() => {
              handleClick(false)
            }}
            icon="/assets/icons/common/arrow-left.svg"
            size="medium"
            color="brown"
            className="arrow-btn"
          />
          <DiamondButton
            onClick={() => {
              handleClick(true)
            }}
            icon="/assets/icons/common/arrow-right.svg"
            size="medium"
            color="brown"
            className="arrow-btn"
          />
        </Buttons>
      )}
    </SwipeGalleryWrapper>
  )
}

export default SwipeGallery

const SwipeGalleryWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: white;
  pointer-events: all;

  .arrow-btn {
    width: 50px;
    height: 50px;
  }

  .pinch-zoom {
    width: 100%;
    height: 100%;
  }
`
const SwiperWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Swiper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 500px;
`

const SwiperSlide = styled.div`
  position: relative;
  flex: 0 0 100%;
  min-width: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;

  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    object-fit: contain;
    cursor: grab;
  }
`

const Buttons = styled.div`
  position: absolute;
  z-index: 5;
  bottom: 2.5rem;
  right: 4rem;
  width: 12rem;
  min-width: 180px;
  height: 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 3rem;
`

const Caption = styled.div`
  position: relative;
  z-index: 5;

  & span {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.5s ease;
    text-transform: uppercase;
    color: #d7c0b0;
    white-space: nowrap;
    letter-spacing: 0.1rem;
    font-weight: 600;
    font-size: clamp(10px, 1rem, 15px);
    background-color: #393d3d;
    border-radius: 0 0 25px 25px;
    box-shadow: 2px 2px 15px #00000076;
    padding: 1em 1em;
  }
`
