
import { amenityData } from "@data/map/amenity-data"
import MapSVGIconGroup from "@components/MapSVGIconGroup"
import { useStore } from "@state/store"
import { useLocation } from "react-router-dom"
import { iconIndex } from "@data/mapGlobalValues"
import { paths } from "@pages/features/data/guestAmenities"
const combinedPageIndex = {
  masterplan: "masterplan",
  "diriyah-square": "diriyah-square",
  "unit-finder": "diriyah-square",
}

const MapAmenityIcons = ({ miniView }) => {
  const amenityCategory = useStore((s) => s.amenityCategory)
  const miniMap = useStore((s) => s.miniMap)
  const unitLevel = useStore((s) => s.unitLevel)
  const setLocalState = useStore((s) => s.setLocalState)
  const { pathname } = useLocation()
  const mapPage = combinedPageIndex[pathname.split("/")[1]]



  function handleAmenityIconClick(state) {
    switch (state) {
      case "vip-lounges":
        setLocalState({
          swiperGalleryAmenity: state,
          swiperGalleryData: paths["vip-lounges"],
          swiperGalleryOpen: true,
        })
        break
      case "vvip-lounges":
        setLocalState({
          swiperGalleryAmenity: state,
          swiperGalleryData: paths["vvip-lounges"],
          swiperGalleryOpen: true,
        })
        break
      case "restrooms":
        setLocalState({
          swiperGalleryAmenity: state,
          swiperGalleryData: paths.restrooms,
          swiperGalleryOpen: true,
        })
        break
      case "welcome-halls":
        setLocalState({
          swiperGalleryAmenity: state,
          swiperGalleryData: paths["welcome-halls"],
          swiperGalleryOpen: true,
        })
        break
      case "driver-lounges":
        setLocalState({
          swiperGalleryAmenity: state,
          swiperGalleryData: paths["valet-lounges"],
          swiperGalleryOpen: true,
        })
        break
      case "valet-lounges":
        setLocalState({
          swiperGalleryAmenity: state,
          swiperGalleryData: paths["valet-lounges"],
          swiperGalleryOpen: true,
        })
        break
      default:
        console.log(state)
        break
    }
  }

  return (
    <>
      {amenityData.map((obj, i) => (
        <MapAmenityIconElement
          miniView={miniMap && miniView}
          key={`amenityIcon-${i}`}
          obj={obj}
          mapPage={mapPage}
          amenityCategory={amenityCategory}
          unitLevel={unitLevel}
          onClick={handleAmenityIconClick}
        />
      ))}
    </>
  )
}

const MapAmenityIconElement = ({
  obj,
  amenityCategory,
  unitLevel,
  mapPage,
  miniView,
  onClick,
}) => {
  let show = true
  if (miniView) {
    if (obj.activeState === amenityCategory && obj.level === unitLevel) {
      show = true
    } else {
      show = false
    }
  } else {
    if (
      obj.activeState === amenityCategory &&
      obj.level === unitLevel &&
      obj.mapID === mapPage
    ) {
      show = true
    } else {
      show = false
    }
  }

  if (show) {
    return (
      <MapSVGIconGroup
        x={obj.x}
        y={obj.y}
        label={null}
        icon={iconIndex[obj.activeState]}
        bgColor="#104344"
        hasIcon={true}
        onClick={() => {
          onClick(obj.activeState)
        }}
        style={{
          cursor: "pointer"
        }}
        height={80}
      />
    )
  } else {
    return null
  }
}

export default MapAmenityIcons
