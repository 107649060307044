import React from "react"
import * as Tabs from "@radix-ui/react-tabs"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import { gsap } from "gsap"
import MapStatic from "@components/MapStatic"
import { useStore } from "@state/store"

//TODO re impliment split view map splitviewmap
type SplitView = {
  img?: string
  popupArray?: {src: string}[]
}

const SplitView: React.FC<SplitView> = (props) => {
  const setLocalState = useStore((s) => s.setLocalState)
  return (
    <Wrapper
      initial={{ x: 10, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.6, ease: [0.42, 0, 0.58, 1], delay: 0.6 }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <Tabs.Root className="TabsRoot" defaultValue="tab1">
        <Tabs.List className="TabsList" aria-label="Manage your account">
          <Tabs.Trigger
            className="TabsTrigger"
            value="tab1"
            onClick={() => {
              gsap.to("#mapContainer", {
                opacity: 0.5,
                filter: "blur(10px)",
                duration: 0.3,
              })
            }}
          >
            IMAGE
          </Tabs.Trigger>
          <Tabs.Trigger
            className="TabsTrigger"
            value="tab2"
            onClick={() => {
              setLocalState({ miniMap: true })
              gsap.to("#mapContainer", {
                delay: 0.5,
                opacity: 1,
                filter: "blur(0px)",
                duration: 0.4,
              })
            }}
          >
            MAP VIEW
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content className="TabsContent" value="tab1">
          <ImageContainer 
            src={props.img} 
            onClick={() => {
              setLocalState({
                swiperGalleryData: props.popupArray,
                swiperGalleryOpen: true,
              })
            }}
          />
        </Tabs.Content>
        <Tabs.Content
          id="mapContainer"
          // style={{ filter: "blur(10px)", opacity: 0.5 }}
          className="TabsContent"
          value="tab2"
        >
          <MapStatic />
        </Tabs.Content>
      </Tabs.Root>
    </Wrapper>
  )
}

export default SplitView

const Wrapper = styled(motion.div)`
  position: relative;
  width: 60%;
  height: 80vh;
  button,
  fieldset,
  input {
    all: unset;
  }

  .TabsRoot {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .TabsList {
    z-index: 100;
    flex-shrink: 0;

    margin: 1rem auto;
    display: flex;
  }

  .TabsTrigger {
    font-family: "BeausiteClassicTrial-Clear";
    background-color: rgba(0, 0, 0, 0.7);
    padding: 12px 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    letter-spacing: 1px;
    color: white;
    user-select: none;
    border: ${({ theme }) => theme.lightBrown} solid 1px;
  }
  .TabsTrigger:first-child {
    padding: 0 90px;
    border-radius: 2px 0 0 2px;
  }
  .TabsTrigger:last-child {
    border-radius: 0 2px 2px 0;
  }
  .TabsTrigger:hover {
    opacity: 0.9;
    transition: 0.3s;
  }
  .TabsTrigger[data-state="active"] {
    background-color: ${({ theme }) => theme.lightBrown};
  }
  .TabsTrigger:focus {
    background-color: ${({ theme }) => theme.lightBrown};
  }

  .TabsContent {
    flex-grow: 1;
    position: absolute;
    width: 100%;
    height: 100%;
  }
`

const ImageContainer = styled(motion.img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
  cursor: pointer;
`

const MapContainer = styled(motion.img)`
  width: 100%;
  height: 85vh;
  object-fit: cover;
`

const TabContent = styled(motion.div)``
