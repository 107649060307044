import { useRef, useLayoutEffect } from "react"
import styled from "styled-components"

type TSVGIconGroupProps = {
  label: string | null
  icon?: string | null
  iconPosition?: "left" | "right" | "center-top" | "center-bottom"
  iconShape?: "circle" | "diamond" | "square"
  textColor?: string
  bgColor?: string
  strokeColor?: string
  strokeWidth?: number
  x?: number
  y?: number
  height?: number
  [x: string]: unknown
}

function SVGIconGroup(props: TSVGIconGroupProps) {
  const rectRef = useRef<SVGRectElement>(null)
  const textRef = useRef<SVGTextElement>(null)
  const iconGroupRef = useRef<SVGGElement>(null)

  const {
    label,
    icon = "/assets/images/map/icons/eyeIconGreen.svg",
    iconPosition = "left",
    iconShape = "circle",
    x = 0,
    y = 0,
    textColor = "black",
    bgColor = "white",
    height = 50,
    strokeColor = "none",
    strokeWidth = 0,
    ...rest
  } = props

  function getRectX() {
    if (!icon) {
      return 0
    }
    // @ts-ignore
    const width = rectRef.current?.attributes.width.value
    if (!width) return

    switch (iconPosition) {
      case "right":
        return 0
      case "left":
        return height
      case "center-bottom":
        return -Number(width) * 0.5
      case "center-top":
        return -Number(width) * 0.5
      default:
        return 0
    }
  }

  function getRectY() {
    switch (iconPosition) {
      case "right":
        return 0
      case "left":
        return 0
      case "center-bottom":
        return height + 10
      case "center-top":
        return -height - 10
      default:
        return 0
    }
  }

  function getTextX() {
    if (!icon) {
      return height
    }
    // @ts-ignore
    const width = textRef.current?.getBBox().width
    if (!width) return

    switch (iconPosition) {
      case "right":
        return height
      case "left":
        return height * 2
      case "center-bottom":
        return -Number(width) * 0.5
      case "center-top":
        return -Number(width) * 0.5
      default:
        return height
    }
  }

  function getTextY() {
    // @ts-ignore
    const textHeight = textRef.current?.getBBox().height
    if (!textHeight) return
    switch (iconPosition) {
      case "right":
        return height / 2
      case "left":
        return height / 2
      case "center-bottom":
        return height * 2
      case "center-top":
        return -height + textHeight * .5
      default:
        return height / 2
    }
  }
  useLayoutEffect(() => {
    if (
      !rectRef.current ||
      !textRef.current ||
      !label ||
      !textRef.current.getBBox
    )
      return
    const textBox = textRef.current.getBBox()
    rectRef.current.setAttribute("width", `${textBox.width + height * 2}`)
    if (iconPosition === "right") {
      if (!iconGroupRef.current) return
      iconGroupRef.current.setAttribute(
        "transform",
        `translate(${textBox.width + height * 3})`
      )
    }
  }, [label, rectRef.current, height])
  return (
    <IconGroup transform={`translate(${x} ${y})`} {...rest}>
      {icon && (
        <g ref={iconGroupRef}>
          <rect
            width={height}
            height={height}
            x={-height / 2}
            y={0}
            rx={iconShape === "circle" ? height : null}
            fill={bgColor}
            transform={
              iconShape === "diamond" ? `rotate(45, 0, ${height / 2})` : null
            }
            stroke={strokeColor}
            strokeWidth={strokeWidth}
          />
          <image
            href={icon}
            width={height / 2}
            height={height / 2}
            x={0 - height / 4}
            y={height / 4}
            preserveAspectRatio="xMidYMid meet"
          />
        </g>
      )}
      {label && (
        <>
          <rect
            x={getRectX()}
            y={getRectY()}
            height={height}
            fill={bgColor}
            rx={height / 2}
            ref={rectRef}
            stroke={strokeColor}
            strokeWidth={strokeWidth}
          />
          <IconText
            x={getTextX()}
            y={getTextY()}
            fontSize={height * 0.4}
            fill={textColor}
            dominantBaseline="middle"
            ref={textRef}
          >
            {label}
          </IconText>
        </>
      )}
    </IconGroup>
  )
}

export default SVGIconGroup

const IconGroup = styled.g``

const IconText = styled.text`
  font-family: "Arial";
  letter-spacing: 2px;
  font-weight: 600;
`
