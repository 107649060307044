import { createRoot } from "react-dom/client"
import { ThemeProvider } from "styled-components"
import "./style.css"
import { theme } from "./theme/theme"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { AuthProvider } from "./auth/core/Auth"

const container: HTMLElement = document.getElementById("root")
const root = createRoot(container!)

root.render(
  <AuthProvider>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </AuthProvider>,
)
