import React, { useRef } from "react"
import styled from "styled-components"

interface BreadcrumbItem {
  key: string
  label: string
}

interface BreadcrumbProps {
  items: BreadcrumbItem[]
  style?: React.CSSProperties
  onClick?: (location: string) => void
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items, style, onClick }) => {
  const breadcrumbRef = useRef<HTMLUListElement>(null) // Ref for the breadcrumb list

  return (
    <Nav aria-label="breadcrumb" style={style}>
      <BreadcrumbList ref={breadcrumbRef}>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <BreadcrumbItem onClick={() => onClick && onClick(item.key)}>
              <span>{item.label}</span>
            </BreadcrumbItem>
            {index < items.length - 1 && (
              <Icon>
                <img src="/assets/icons/common/chevron-right.svg" alt=">" />
              </Icon>
            )}
          </React.Fragment>
        ))}
      </BreadcrumbList>
    </Nav>
  )
}

const Nav = styled.nav``

const BreadcrumbList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;

  @media screen and (min-width: 1920px) {
    gap: 0.5rem;
  }
`

const BreadcrumbItem = styled.li`
  cursor: pointer;
  font-size: 1rem;
  border-radius: 4px;
  background: #f0f0ef;
  padding: 0.4rem 0.9rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.darkGreen};
  opacity: 1;
  animation: fadeIn 0.5s ease forwards;

  @media screen and (min-width: 1920px) {
    font-size: 0.7rem;
    padding: 0.2rem 0.5rem;
  }

  &:last-child {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.lightGreen};
    box-sizing: border-box;
    transition: box-shadow 0.5s ease;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0ef;
  border-radius: 4px;
  padding: 0.5rem 0.5rem;
  line-height: 1rem;
  opacity: 1;
  animation: fadeIn 0.5s ease forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

export default Breadcrumb
