const baseAttributes = {
  strokeOpacity: "1",
  strokeWidth: 3,
  className: "unit-finder-overlay",
}

const data = [
  {
    link: "department",
    coords:
      "M2074.5 1458.5L2156.5 1341.5L2283 1421L2332.5 1417L2353.5 1521.5L2310.5 1587.5L2092 1535L2074.5 1458.5Z",
    fill: "#004344",
    basement: false,
    type: "path",
    length: 830.4984741210938,
  },
  {
    link: "entertainment",
    coords:
      "M2233.5 1648.5L2251 1573L2092 1535L1951 1526.5L1820 1573L1814.5 1606L1856.5 1683.5L1875 1691.5L1885.5 1781.5L1973 1775.5L2083.5 1832L2140.5 1848.5L2227 1857L2271.5 1659L2233.5 1648.5Z",
    length: 1354.0457763671875,
    fill: "#b2c6c6",
    basement: false,
    type: "path",
  },
  {
    link: "premium-dining",
    coords:
      "M1814.5 1606.5L1739.5 1578L1657 1586.5L1450 1691.5L1433.5 1929.5L1785.5 2016L1868 1877.5L1885.5 1781.5L1875 1691.5L1856.5 1683.5L1814.5 1606.5Z",
    length: 1453.5880126953125,
    fill: "#002321",
    basement: false,
    type: "path",
  },
  {
    link: "anchor",
    coords:
      "M1868 1878L1885.5 1782L1973 1775.5L2083.5 1832L2140.5 1848.5L2226.5 1857L2218.5 1878L2226.5 1904.5L2209 1974L2248.5 1991L2232 2083.5L1987 2067L1903 2043H1843.5L1786 2015.5L1868 1878Z",
    length: 1330.2235107421875,
    fill: "#e2e1e0",
    basement: false,
    type: "path",
  },
  {
    link: "luxury-n",
    coords:
      "M2233.5 1648.5L2271.5 1659.5L2226.5 1857L2218.5 1878.5L2226.5 1904.5L2209 1974L2248.5 1991L2334 1994L2426.5 2005H2444H2530V1955H2518L2508 1917L2481.5 1844.5L2439.5 1742.5L2384.5 1652H2491V1614.5L2344 1612L2248 1586L2233.5 1648.5Z",
    length: 1538.47705078125,
    fill: "#cf956c",
    basement: false,
    type: "path",
  },
  {
    link: "luxury-s",
    coords:
      "M2447 2015.5L2530 2020.5L2536 2088.5L2511 2163L2479 2221.5L2413 2347.5L2393 2423.5L2449 2456L2482.5 2507.5L2356.5 2575L2268 2622.5L2238 2566L2247.5 2541.5L2293 2462.5L2298.5 2423.5L2256 2419.5L2223 2328.5L2190.5 2253L2169 2182L2160 2123.5L2163 2079L2232 2083.5L2247 1999.5L2334.5 2001.5L2402.5 2010L2447 2015.5Z",
    length: 1862.895263671875,
    fill: "#d9c6ad",
    basement: false,
    type: "path",
  },
  {
    link: "culture",
    coords:
      "M2029 2069.5L2163.5 2079L2160 2123.5L2169 2182L2190.5 2253L2223.5 2329.5L2256 2419.5L2298.5 2423L2293 2463L2248 2541L2201 2555L2150 2560L2115.5 2498L2091 2510L2073 2474.5L2047.5 2426L2042.5 2420.5L2021 2391L2032 2381.5L2027 2370L2023.5 2363L2040 2354.5L2036 2347L2042.5 2343L2034 2327.5L2028 2331L2023.5 2322.5L2012 2328.5L2007.5 2320.5L2003 2322.5L2000 2316L2032 2298.5L2013 2264.5L1989 2277L1977 2179L2022 2176L2021 2120H2029V2069.5Z",
    length: 1500.8604736328125,
    fill: "#af9781",
    basement: false,
    type: "path",
  },
  {
    link: "lifestyle",
    coords:
      "M1822.5 2117L1835 2039L1843.5 2043H1903L1987 2067L2029 2070V2120H2021L2022 2176L1977 2179L1989 2277L2013 2264.5L2032 2298.5L2000 2316L2003 2322.5L2007.5 2320.5L2012 2328.5L2023.5 2322.5L2028 2331L2034 2327.5L2042.5 2343L2036 2347L2040 2354.5L2023.5 2363L2032 2381.5L2021 2391L2047.5 2426L2091 2510L2115.5 2498L2150.5 2560L2201 2555L2244.5 2629L1998.5 2760.5L1968 2694.5L1976 2614L2007.5 2586L1983 2538L1937 2526.5L1920 2496L1925 2472L1883 2397.5L1889.5 2381.5L1863.5 2300L1847.5 2209.5H1835L1825.5 2183L1807 2184.5L1796.5 2152V2124.5L1822.5 2117Z",
    length: 2212.89990234375,
    fill: "#d7cbc0",
    basement: false,
    type: "path",
  },
  {
    link: "premium-dining",
    coords:
    "1755.7,1844.9 1730.4,1911.6 1751.3,1939.4 1747.7,1962.4 1820.2,1980 1855,1839.9 1794.8,1823.2 1779.3,1843.5",
    fill: "#8CA19B",
    basement: true,
    type: "polygon",
    transform: "translate(-520 -590) scale(1.3)"
  },
  {
    link: "premium-dining",
    coords:
    "1897.4,1583.2 1830.4,1597.4 1813.3,1608.5 1753.7,1599.3 1731.7,1618.1 1743.3,1681.3 1815.5,1701.5 1821.3,1679 1835.7,1665.6 1879.8,1657",
    fill: "#8CA19B",
    basement: true,
    type: "polygon",
    transform: "translate(-80 -100) scale(1.05)"
  },
  {
    link: "lifestyle-homewares-B1",
    coords:
    "2091.8,1478.2 2174.6,1360.5 2305.7,1443.9 2338,1446 2325.9,1494.8 2327.1,1514.1 2313,1570.6 2331.7,1575.4 2302.6,1686.8 2221.3,1666.6 2191.6,1623.6 2200.3,1591.3 2185.4,1546.3 2172.5,1553.1 2129.9,1542 2089,1482.1",
    fill: "#A4AEB7",
    basement: true,
    type: "polygon",
    transform: "translate(-125 -85) scale(1.05)"
  },
  {
    link: "lifestyle-convenience-B1",
    coords:
    "2089,1482.1 2129.9,1542 2172.5,1553.1 2185.4,1546.3 2200.3,1591.3 2191.6,1623.6 2221.3,1666.6 2191.6,1684.3 2136.3,1918.6 2313.6,1967.2 2294.5,2028.2 2136.3,1990.8 2112,2014.5 2109.6,2190.6 2039.4,2203.5 2039.4,2283 1883.4,2283 1803.8,2226 1803.8,2045.3 1832.1,1932.7 1899,1662 1879.8,1657 1897.4,1583.2 1918.5,1570.9 1949.2,1564.8 1955.2,1544.8 1979.7,1543.2 1999.9,1530.2 2013,1477.1 2032.9,1460 2064.9,1463.6",
    fill: "#C3AC9A",
    basement: true,
    type: "polygon",
    transform: "translate(-55 -55) scale(1.02)"
  },
  {
    link: "lifestyle-convenience-B1",
    coords: "1503.8,1675.9 1524.2,1730.6 1686.8,1660 1663.1,1606.1",
    fill: "#C3AC9A",
    basement: true,
    type: "polygon",
    transform: "translate(-65 -93) scale(1.05)"
  },
  {
    link: "lifestyle-convenience-B1",
    coords: "1606,1915.7 1589.6,1922 1567.9,1976.3 1572.5,1986 1588.4,1992.2 1617.1,1989.3 1633.6,1977.5 1643.6,1939.3 1625.6,1923.1",
    fill: "#C3AC9A",
    basement: true,
    type: "polygon",
    transform: "translate(-73 -125) scale(1.05)"
  },
]

const unitFinderZoneOverlayData = data.map((obj) => {
  return {
    //@ts-ignore
    type: obj.type,
    attr: {
      ...baseAttributes,
      stroke: "white",
      fill: "none",
      // strokeDasharray: obj.length,
      // strokeDashoffset: obj.length,
    },
    ...obj,
  }
})

export default unitFinderZoneOverlayData

// useEffect(() => {
//   if (navigating) return
//   gsap.set(".unit-finder-overlay", { opacity: 0 })
//   timeline.set(".unit-finder-overlay", { opacity: 1 }, 0)
//   timeline.to(
//     ".unit-finder-overlay",
//     {
//       strokeDashoffset: 0,
//       duration: 4,
//       ease: "power2.out",
//     },
//     "<"
//   )
// }, [navigating, active])
