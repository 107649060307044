import styled from "styled-components"
import data from "@data/masterplan/masterplanTransportLines"
import { useLocation } from "react-router-dom"
import { useStore } from "@state/store"
import { Fragment, useEffect } from "react"
import gsap from "gsap"

const MapTransportLines = () => {
  const amenityCategory = useStore((s) => s.amenityCategory)
  const { pathname } = useLocation()
  const duration = 1

  function drawLine(cond, target, data){
    //get lengths
    // const paths = Array.from(document.getElementsByClassName("draw-car-line"))
    // const paths = Array.from(document.getElementsByClassName("draw-metro-line"))
    // paths.forEach(path => {
    //   console.log(path.getTotalLength())
    // })
    gsap.to(target, {
      strokeDashoffset: (i) => {
        //@ts-ignore
        const length = data[i]
        return cond ? 0 : length
      },
      duration: pathname === "/masterplan" ? duration : 0.1,
      delay: 0.1,
    })
  }

  useEffect(() => {
    const carActive = pathname === "/masterplan" && amenityCategory === "car"
    const metroActive =
      pathname === "/masterplan" && amenityCategory === "metro"
    drawLine(carActive, ".draw-car-line", data.lengths.car)
    drawLine(metroActive, ".draw-metro-line", data.lengths.metro)
  }, [amenityCategory, pathname])

  return (
    <TransportLinesWrapper>
      <g id="car-transport-lines">
        <g>
          {data.car.map((line, i) => {
            return (
              <Fragment key={`line-group-${i}`}>
                <path
                  strokeDasharray={line.dashLength}
                  strokeDashoffset={line.initialDashOffset}
                  strokeWidth={20}
                  stroke={line.color}
                  fill="none"
                  d={line.path}
                  className="blur-line draw-car-line"
                />
                <path
                  strokeDasharray={line.dashLength}
                  strokeDashoffset={line.initialDashOffset}
                  strokeWidth={4}
                  stroke={line.color}
                  fill="none"
                  d={line.path}
                  className="draw-car-line"
                />
                <path
                  strokeDasharray={line.dashLength}
                  strokeDashoffset={line.initialDashOffset}
                  strokeWidth={2}
                  stroke={"#FFFFFF"}
                  opacity={0.8}
                  fill="none"
                  d={line.path}
                  className="glow-line draw-car-line"
                />
              </Fragment>
            )
          })}
        </g>
        <g>
          {data.metro.map((line, i) => {
            return (
              <Fragment key={`line-group-${i}`}>
                <path
                  strokeDasharray={line.dashLength}
                  strokeDashoffset={line.initialDashOffset}
                  strokeWidth={20}
                  stroke={line.color}
                  fill="none"
                  d={line.path}
                  className="blur-line draw-metro-line"
                />
                <path
                  strokeDasharray={line.dashLength}
                  strokeDashoffset={line.initialDashOffset}
                  strokeWidth={4}
                  stroke={line.color}
                  fill="none"
                  d={line.path}
                  className="draw-metro-line"
                />
                <path
                  strokeDasharray={line.dashLength}
                  strokeDashoffset={line.initialDashOffset}
                  strokeWidth={2}
                  stroke={"#FFFFFF"}
                  opacity={0.8}
                  fill="none"
                  d={line.path}
                  className="glow-line draw-metro-line"
                />
              </Fragment>
            )
          })}
        </g>
      </g>
    </TransportLinesWrapper>
  )
}

export default MapTransportLines

const TransportLinesWrapper = styled.g`
  .blur-line {
    -webkit-filter: blur(15px);
    filter: blur(15px);
  }

  .glow-line {
    transform: translate(1px, -1px);
    transform-origin: center;
    transform-box: fill-box;
  }
`

