import { useEffect, useState } from "react"
import styled from "styled-components"
import gsap from "gsap"

import UnitCard from "./components/UnitCard"
import { DiamondButton } from "@components/buttons"

import { useStore } from "@state/store"
import { sidePanelData } from "@data/side-panel/sidePanelData"

const data = sidePanelData.filter((obj) =>
  obj.mapID ? obj.mapID?.includes("unit-finder") : false
)

const miniMapBgLvls = ["B3", "B2", "B1", "0", "1", null]

function UnitFinderSideBar() {
  const { setLocalState, mapCategory, unitLevel } = useStore((s) => ({
    setLocalState: s.setLocalState,
    mapCategory: s.mapCategory,
    unitLevel: s.unitLevel,
  }))
  
  const [zonesVisible, setZonesVisible] = useState(true)
  const [copyData, setCopyData] = useState(data[0])
  const [keysData, setKeysData] = useState([])
  const LABEL_SIZE = 15

  useEffect(() => {
    setZonesVisible(mapCategory === null)
    if (!zonesVisible && mapCategory !== null) {
      gsap.to("#units-keys", {
        autoAlpha: 0,
        onComplete: () => {
          setCopyData(data.find((obj) => obj.link === mapCategory))
          setKeysData(
            keyColors.filter((obj) => obj.categories.includes(mapCategory))
          )
          gsap.to("#units-keys", {
            autoAlpha: 1,
          })
        },
      })
    }
  }, [mapCategory])

  useEffect(() => {
    gsap.to("#diriyah-keys", {
      autoAlpha: zonesVisible ? 1 : 0,
    })
    gsap.to("#units-keys", {
      autoAlpha: zonesVisible ? 0 : 1,
      onStart: () => {
        if (!zonesVisible) {
          setCopyData(data.find((obj) => obj.link === mapCategory))
          setKeysData(
            keyColors.filter((obj) => obj.categories.includes(mapCategory))
          )
        }
      },
      onComplete: () => {
        if (zonesVisible && mapCategory === null) {
          setCopyData(data[0])
          setKeysData([])
        }
      },
    })
  }, [zonesVisible])

  return (
    <BarWrapper>
      <MapKeys>
        <MiniMap viewBox="5 35 410 410" preserveAspectRatio="xMidYMid slice">
          {miniMapBgLvls.map((lvl, i) => {
            return (
              <image
                key={`bg-${i}`}
                x={0}
                y={0}
                href={`/assets/mini-map/${lvl === null ? "roof" : lvl}.webp`}
                opacity={unitLevel === lvl ? 1 : 0}
              />
            )
          })}
          <path
            d="m0,0v500h500V0H0Zm347.2,325.61l-2.4,2.2.4,24.33-2.3,1.7h-19.63l-1.1,1.7-.3,32.74-4.71,1-8.11,3.71-10.31,5.31-11.82,6.11-8.51,4.61-9.01,5.41-2.4,1.4,2.5,3.1,1.5,3.1-1.4,1.4,1.6,4.01-3.1,3-5.51-3.81-2.7-.8-3.2-3-1.9,1.6-2.7-1.6-3.5.7-2.9-4.21-9.21-14.02h-3.91l-2.5-4.21,1.3-2.7-3.81-6.41-.1-2.3,14.52-7.21v-3l11.92-6.61-9.61-16.92-27.74,15.12-6.11-9.01-5.21,2.7-12.12-20.33.5-6.81-2.2-4.31-4.31-.7-10.41-8.01-18.22-18.02-18.83-25.53-9.41-3-7.61-11.92-6.81-4.81-4.41-2.8-1.3-6.31-1.2-6.71-2.9-4.81-2.11-7.41-1.4-7.51-1.8-4.61s3-4.61,3.1-4.91,2.21-4.2,2.21-4.2v-7.14l2.86-4.05-2.86-8.1,10.48-15.24-3.53-2.52-3.54-12.11,4.42-2.12,29.08-11.58.88-2.3-3.18-5.66,3.18-.53,6.89.53,8.66,1.15,6.28.44,4.42.53,4.6-2.65,2.3-2.74,5.39-4.68,4.77-1.77,5.83-1.86s9.28-3.01,9.55-3.09c.27-.09,4.33-1.94,4.33-1.94l2.83-.97,4.68,3.01,4.15-5.3,3.45-6.28,1.86-4.42,4.24-5.92,6.89,4.95,5.21,3.71,5.66,3.62,5.3,3.45,4.51.88,1.94.09v10.25l.35,5.75-.62,3.54-1.86,3.01,2.47,2.65,18.21,4.42,57.19.27.97-4.15,7.78.09,5.66,4.07-.49,174.28Z"
            fill="black"
            opacity={unitLevel?.includes("B") ? 0.8 : 0}
            className="blur"
          />
          <path
            d="m0,0v500h500V0H0Zm203.12,408.57l-6.33-2.07-9.94-3.39-5.79-3.82-.66-4.37.98-6.33,2.29-6.33,2.95-6.66,3.82-3.82,3.28-3.71.33-9.39.55-11.9.33-7.53.87-6.22-8.41-12.56-8.52-15.4-6.99-11.58s-2.95-8.3-3.06-8.74c-.11-.44-2.84-8.19-2.84-8.19l-1.31-4.91-2.95-7.64-1.42-8.19-.66-7.64.33-5.68,1.97-6.66,1.39-3.09-21.98-8.1-11.57-2.31-12.15-6.94-8.1-4.34-3.47-9.25v-8.1l4.34-7.52,3.76-6.07,2.31-3.76-4.63-8.1-2.89-5.49,2.2-2.61,2.07-3.17,5.35-2.95h4.59s4.37-1.2,4.7-1.31c.33-.11,5.13-2.4,5.68-2.73.55-.33,3.71-2.84,3.71-2.84,0,0,1.86-2.84,2.18-3.28.33-.44,1.86-2.95,1.86-2.95h3.71l4.59,1.31,6.77.66,5.35-.11,4.15-.87,5.57-2.62s2.95-2.29,3.28-2.62c.33-.33,4.91-3.06,4.91-3.06,0,0,3.49-1.42,3.93-1.53.44-.11,6.01-2.07,6.01-2.07,0,0,5.02-.76,5.57-1.09.55-.33,5.02-2.29,5.02-2.29l7.21-1.09,5.57-.55,2.51-.87,4.91-9.72,3.28-4.26,3.17-4.26,7.1,4.15,5.9,3.93,7.1,4.04,3.09,1.42,8.81.55v13.21l-.76,5.79s-.55,4.26-.66,4.59c-.11.33-2.95,4.59-2.95,4.59l-1.75,2.73,11.25,12.78,4.91,7.97,5.24,11.47,2.73,7.43,3.17,10.05,2.51,10.26,2.18,10.16v13.87l-.98,11.03-2.07,9.83-2.62,9.17-4.48,9.06-4.26,9.06-4.15,6.66-3.49,4.8-2.4,6.66s1.2,7.97,1.31,8.3,1.09,6.88,1.09,6.88l4.37,8.85,2.73,2.84,3.17,1.31,10.7-5.9,15.51,28.06-95.99,57.11Z"
            fill="black"
            opacity={!unitLevel?.includes("B") ? 0.8 : 0}
            className="blur"
          />
          {zones.map((obj, i) => {
            return (
              obj.levels.includes(unitLevel) && (
                <g
                  key={`map-key${i}`}
                  onClick={() => {
                    setLocalState({
                      unitID: null,
                      mapCategory: mapCategory === obj.set ? null : obj.set,
                      unitLevel:
                        unitLevel === null
                          ? obj.set.includes("B1")
                            ? "B1"
                            : "0"
                          : unitLevel,
                    })
                  }}
                >
                  <rect
                    fill={
                      mapCategory === obj.set
                        ? "var(--dark-green)"
                        : "var(--light-brown)"
                    }
                    stroke={"var(--white)"}
                    transform="rotate(-45)"
                    x={obj.position[0]}
                    y={obj.position[1]}
                    width={LABEL_SIZE}
                    height={LABEL_SIZE}
                  />
                  <text
                    x={obj.position[0] + LABEL_SIZE * 0.5}
                    y={obj.position[1] + LABEL_SIZE * 0.5}
                    fill={"var(--white)"}
                    fontSize={LABEL_SIZE * 0.5}
                    textAnchor="middle"
                    dominantBaseline="central"
                  >
                    {obj.key}
                  </text>
                </g>
              )
            )
          })}
        </MiniMap>
        <div style={{ position: "relative" }}>
          <Keys id="diriyah-keys">
            <Title>Diriyah Zones</Title>
            {zones.map((obj, i) => {
              return (
                obj.levels.includes(unitLevel) && (
                  <Key
                    $color={"var(--dark-green)"}
                    $border={"var(--white)"}
                    style={{
                      cursor: "pointer",
              
                    }}
                    onClick={() => {
                      setLocalState({
                        unitID: null,
                        mapCategory: mapCategory === obj.set ? null : obj.set,
                        unitLevel:
                          unitLevel === null
                            ? obj.set.includes("B1")
                              ? "B1"
                              : "0"
                            : unitLevel,
                      })
                    }}
                    key={`mapKey-${i}`}
                  >
                    {obj.name}
                    <span>
                      <p>{obj.key}</p>
                    </span>
                  </Key>
                )
              )
            })}
          </Keys>
          <Keys id="units-keys">
            <div className="buttons">
              <DiamondButton
                size="medium"
                color="light-green"
                className="button"
                onClick={() => {
                  if (!copyData?.swipeGalleryData) return
                  setLocalState({
                    swiperGalleryData: copyData?.swipeGalleryData,
                    swiperGalleryOpen: true,
                  })
                }}
                icon="/assets/icons/navbar/gallery.svg"
              />
              <DiamondButton
                size="medium"
                color="light-green"
                className="button"
                onClick={() => {
                  setLocalState({
                    mapCategory: null,
                  })
                }}
                icon="/assets/icons/common/close.svg"
              />
            </div>
            <Title>{copyData?.title}</Title>
            <p className="copy">{copyData?.para}</p>
    
            {keysData.map((obj, i) => {
              return (
                <Key
                  $color={obj.color}
                  key={`mapKey-${i}`}
                  className="unit-key"
                >
                  {obj.name} <span />
                </Key>
              )
            })}
          </Keys>
        </div>
      </MapKeys>
      <UnitCard />
    </BarWrapper>
  )
}

export default UnitFinderSideBar

const BarWrapper = styled.div`
  position: absolute;
  right: 0;
  width: 26vw;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  box-shadow: -8px 8px 15px rgba(var(--black-rgb), 0.5);

  .buttons {
    z-index: 2;
    position: relative;
    margin-left: auto;
    margin-top: var(--margin);
    display: flex;
    justify-content: flex-end;
    gap: var(--margin);
  }

  .button {
    z-index: 2;
    position: relative;
    transform: scale(0.7) rotate(45deg);
  }
`

const Title = styled.h2`
  font-family: "BeausiteGrandTrial-Light";
  color: var(--white);
  font-size: clamp(25px, 2.5rem, 40px);
  font-weight: 100;
  margin: 1rem 0;
  text-align: right;
`

const MapKeys = styled.div`
  overflow: hidden scroll;
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  padding: var(--margin);
`

const Keys = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  .copy {
    text-align: right;
    font-size: clamp(10px, 1rem, 15px);
    margin-bottom: var(--margin);
    padding-bottom: var(--margin);
    border-bottom: 1px solid var(--dark-green);
  }

  .unit-key {
    font-size: clamp(8px, 1rem, 12px);
    > span {
      width: clamp(6px, 0.8rem, 10px);
      height: clamp(6px, 0.8rem, 10px);
    }
  }
`

const Key = styled.h3<{ $color?: string; $border?: string }>`
  margin: clamp(10px, 1.8rem, 15px) 0;
  font-size: clamp(10px, 2.5rem, 15px);
  text-align: right;
  transition: opacity 0.4s ease-out;
  font-family: "BeausiteGrandTrial-Light";
  font-weight: 100;

  & span {
    transform: rotate(-45deg) translateY(-50%);
    width: clamp(10px, 1rem, 15px);
    height: clamp(10px, 1rem, 15px);
    background-color: ${(p) => p.$color};
    border: 1px solid ${(p) => p.$border ?? "rgba(var(--black-rgb), 0.3)"};
    margin-left: 1rem;
    display: inline-grid;
    place-content: center;

    & p {
      font-weight: 600;
      font-family: Arial, Helvetica, sans-serif;
      font-size: clamp(4px, 0.8rem, 6px);
      transform: rotate(45deg);
      color: var(--white);
    }
  }
`
const MiniMap = styled.svg`
  width: 100%;
  height: 50%;
  .blur {
    -webkit-filter: blur(15px);
    filter: blur(15px);
  }

  & rect {
    transition: fill 0.4s ease-out;
    transform-origin: center;
    transform-box: fill-box;
    cursor: pointer;
  }

  & image {
    transition: opacity 0.4s ease-out;
  }
`

const keyColors = [
  {
    name: "Food and Beverage",
    color: "rgb(140,163,120)",
    categories: [
      "department",
      "entertainment",
      "premium-dining",
      "anchor",
      "luxury-n",
      "lifestyle-convenience-B1",
      "luxury-s",
      "lifestyle",
      "culture",
    ],
  },
  {
    name: "High Street Fashion and Lifestyle",
    color: "rgb(227,115,115)",
    categories: [
      "department",
      "entertainment",
      "premium-dining",
      "anchor",
      "lifestyle-convenience-B1",
      "lifestyle",
      "culture",
    ],
  },
  {
    name: "Leisure, Entertainment and Co-Working",
    color: "rgb(207,167,210)",
    categories: ["entertainment", "luxury-s", "lifestyle", "culture"],
  },
  {
    name: "Souq",
    color: "rgb(74,120,133)",
    categories: ["entertainment", "anchor", "lifestyle", "culture"],
  },
  {
    name: "Sports and Activewear",
    color: "rgb(242,148,115)",
    categories: ["lifestyle", "culture"],
  },
  {
    name: "Luxury and Bridge",
    color: "rgb(232,201,105)",
    categories: [
      "department",
      "entertainment",
      "anchor",
      "luxury-n",
      "luxury-s",
      "culture",
    ],
  },
  {
    name: "Services",
    color: "rgb(150,125,135)",
    categories: ["lifestyle-convenience-B1"],
  },
  {
    name: "Home and Electronics",
    color: "rgb(186,217,224)",
    categories: [
      "department",
      "lifestyle-homewares-B1",
      "lifestyle-convenience-B1",
    ],
  },
  { name: "Supermarket", color: "rgb(255,66,117)", categories: [] },
]

const zones = [
  {
    name: "Department Store",
    set: "department",
    key: "A",
    position: [230, 120],
    levels: [null, "0"],
  },
  {
    name: "Premium Dining",
    set: "premium-dining",
    key: "B",
    position: [130, 180],
    levels: [null, "B1", "0", "1"],
  },
  {
    name: "Entertainment and Dining",
    set: "entertainment",
    key: "C",
    position: [210, 160],
    levels: [null, "B1", "0", "1"],
  },
  {
    name: "Anchor Lifestyle Retail",
    set: "anchor",
    key: "D",
    position: [195, 200],
    levels: [null, "B1", "0", "1"],
  },
  {
    name: "Luxury Retail North",
    set: "luxury-n",
    key: "E",
    position: [255, 190],
    levels: [null, "0", "1"],
  },
  {
    name: "Luxury Retail South",
    set: "luxury-s",
    key: "F",
    position: [255, 240],
    levels: [null, "0", "1"],
  },
  {
    name: "Culture and Dining",
    set: "culture",
    key: "G",
    position: [220, 260],
    levels: [null, "0", "1"],
  },
  {
    name: "Lifestyle Retail",
    set: "lifestyle",
    key: "H",
    position: [185, 270],
    levels: [null, "B1", "0", "1"],
  },
  {
    name: "Lifestyle Convenience",
    set: "lifestyle-convenience-B1",
    key: "I",
    position: [209, 214],
    levels: [null, "B1"],
  },
  {
    name: "Lifestyle Homewares",
    set: "lifestyle-homewares-B1",
    key: "J",
    position: [244, 134],
    levels: [null, "B1", "0", "1"],
  },
]
