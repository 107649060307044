import { useStore } from "@state/store"
import React, { useEffect, useRef, useState } from "react"
import SVG from "react-inlinesvg"
import mapSaudi from "@assets/images/vision/map-saudi.svg"
import styled from "styled-components"

type SaudiMapProps = {
  visible: boolean
}

const SaudiMap: React.FC<SaudiMapProps> = ({ visible }) => {
  const saudiMapRef = useRef(null)
  const [svgLoaded, setSvgLoaded] = useState(false)
  const { setActiveMap } = useStore((state) => ({
    setActiveMap: state.setActiveMap,
  }))

  useEffect(() => {
    const saudiMap = saudiMapRef.current

    if (!saudiMap) return

    const saudiMapWrapper = saudiMap.querySelector("#SaudiMapWrapper")
    const riyadhLabel = saudiMap.querySelector("#Label-Riyadh")
    const northernBorders = saudiMap.querySelector("#Label-NorthernBorders")

    if (northernBorders) {
      northernBorders.style.padding = "1rem"
    }

    const toggleVisibility = (isVisible: boolean) => {
      if (saudiMapWrapper) {
        saudiMapWrapper.setAttribute(
          "class",
          isVisible ? "saudi-visible" : "saudi-hidden",
        )
      }
    }

    toggleVisibility(visible)

    if (riyadhLabel) {
      riyadhLabel.style.pointerEvents = "all"
      riyadhLabel.style.cursor = "pointer"
      const onClickHandler = () => setActiveMap("riyadh")

      riyadhLabel.addEventListener("click", onClickHandler)

      return () => riyadhLabel.removeEventListener("click", onClickHandler)
    }
  }, [visible, svgLoaded])

  return (
    <StyledSvg
      visible={visible ? "true" : "false"}
      fill="none"
      innerRef={saudiMapRef}
      src={mapSaudi}
      preserveAspectRatio="xMidYMid slice"
      onLoad={() => {
        setSvgLoaded(true)
      }}
    />
  )
}

export default SaudiMap

//@ts-ignore
const StyledSvg = styled(SVG)<{ visible: string }>`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${({ visible }) => (visible === "true" ? 1 : -1)};
  opacity: ${({ visible }) => (visible === "true" ? 1 : 0)};

  @media screen and (min-width: 1920px) {
    padding: 5rem;
  }

  & .saudi-visible {
    opacity: 1;
    transform: scale(0.8) translate(-300px, 0) !important;
    transition: all 1s;
  }

  & .saudi-hidden {
    opacity: 0;
    transform: scale(0.8) translate(-300px, 0) !important;
    transition: all 1s;
  }
`
