import React, { useEffect, useState } from "react"
import { EmblaOptionsType } from "embla-carousel"
import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from "./GalleryCarouselArrowButtons"
import useEmblaCarousel from "embla-carousel-react"
import styled from "styled-components"
import gsap from "gsap"

type PropType = {
  mode: "fullscreen" | "grid"
  setMode: (mode: "fullscreen" | "grid") => void
  category: string
  imagePaths: string[]
  options?: EmblaOptionsType
}

const EmblaCarousel: React.FC<PropType> = (props) => {
  const { imagePaths, options } = props

  const [emblaRef, emblaApi] = useEmblaCarousel(options)

  useEffect(() => {
    props.setMode(props.mode)
    emblaApi?.reInit({
      ...options,
      duration: 0,
    })
    animateSlideSize()
  }, [props.mode, props.category])

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi)

  const handleExpandClick = (mode?: any) => {
    if (mode === "fullscreen") {
      props.setMode("grid")
    } else {
      props.setMode("fullscreen")
    }
    animateSlideSize()

    const speed = mode === "fullscreen" ? 0 : options?.duration

    emblaApi?.reInit({
      ...options,
      duration: speed,
    })
  }

  const animateSlideSize = () => {
    gsap.fromTo(
      ".embla",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.6,
      },
    )
  }

  return (
    <Section className="embla" mode={props.mode}>
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {imagePaths.map((path, index) => {
            return (
              <div className="embla__slide" key={index}>
                <img
                  className="image"
                  src={path}
                  alt={`Slide ${index + 1}`}
                  onClick={() => handleExpandClick(props.mode)}
                />
                {false && (
                  <DetailsWrapper>
                    <Caption>Caption Placeholder</Caption>
                  </DetailsWrapper>
                )}
              </div>
            )
          })}
        </div>
      </div>
      {props.mode === "grid" && (
        <div className="embla__controls">
          <div className="embla__button">
            <PrevButton
              onClick={onPrevButtonClick}
              disabled={prevBtnDisabled}
            />
          </div>
          <div className="embla__button">
            <NextButton
              onClick={onNextButtonClick}
              disabled={nextBtnDisabled}
            />
          </div>
        </div>
      )}
    </Section>
  )
}

export default EmblaCarousel

const Section = styled.section<{ mode: "fullscreen" | "grid" }>`
  font-family: "BeausiteGrandTrial-Light";
  font-size: 2rem;

  &.embla {
    position: relative;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0 var(--margin);
    flex-direction: column;
    justify-content: center;
    --slide-size: ${({ mode }) =>
      mode === "fullscreen" ? "calc(100% / 1)" : "calc(100% / 1.3)"};
  }

  .embla__viewport {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${({ mode }) => (mode === "fullscreen" ? "100%" : "75%")};
  }

  .embla__container {
    backface-visibility: hidden;
    display: flex;
    gap: 5rem;
    touch-action: pan-y;
    align-items: center;
    height: ${({ mode }) => (mode === "fullscreen" ? "100%" : "90%")};
  }

  .embla__slide {
    flex: 0 0 var(--slide-size);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 100%;
  }

  .image {
    width: 100%;
    height: ${({ mode }) => (mode === "fullscreen" ? "90%" : "auto")};
    border-radius: 4px;
    object-fit: ${({ mode }) => (mode === "fullscreen" ? "cover" : "contain")};
  }

  .embla__controls {
    display: flex;
    position: fixed;
    bottom: 5rem;
    right: 3rem;
    gap: 1rem;
    z-index: 40;

    @media screen and (min-width: 1920px) {
      bottom: 2.5rem;
    }
  }

  .embla__buttons {
    background-color: blanchedalmond;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    align-items: center;
  }

  .embla__button {
    cursor: pointer;
    border: none;
    padding: 10px;
    z-index: 10;
    border-radius: 0.25rem;
    color: var(--text-body);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .embla__button__svg {
    width: 15px;

    stroke: #ffffff;
    stroke-width: 2px;
  }

  .image {
    z-index: 11;
    border-radius: 4px;
  }

  &.expanded {
    background-color: rgba(256, 256, 256, 1);
    z-index: 20;
  }

  &.expanded .image {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    border-radius: 4px;
  }

  .embla__button {
    line-height: 0px;
    background-color: ${({ theme }) => theme.lightBrown};
    color: white;
    height: 3.5rem;
    width: 3.5rem;

    @media screen and (min-width: 1920px) {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
`

const DetailsWrapper = styled.div`
  z-index: 100;
  background: ${({ theme }) => theme.darkGreen};
  padding: 1rem;
  border-top-right-radius: 4px;
`

const Caption = styled.div`
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.white};
`
