import { useState } from "react"
import styled from "styled-components"
import { Canvas } from "@react-three/fiber"

import Popup from "@components/Popup"
import TransitionTour from "@components/TransitionTour"
import { DiamondButton } from "@components/buttons"
import SVGIconGroup from "@components/MapSVGIconGroup/MapSVGIconGroup"
import { Loader } from "@components/PageWrapper/PageWrapper"

import { useStore } from "@state/store"

const icons360Data = [
  {
    //24
    x: 140,
    y: 170,
    label: null,
    icon: "/assets/images/map/icons/orbitIconWhite.svg",
    show: "diriyah-square",
    set: 0,
  },
  {
    //8
    x: 350,
    y: 230,
    label: null,
    icon: "/assets/images/map/icons/orbitIconWhite.svg",
    show: "diriyah-square",
    set: 2,
  },
  {
    //10B
    x: 300,
    y: 230,
    label: null,
    icon: "/assets/images/map/icons/orbitIconWhite.svg",
    show: "diriyah-square",
    set: 1,
  },
  {
    //4
    x: 215,
    y: 210,
    label: null,
    icon: "/assets/images/map/icons/orbitIconWhite.svg",
    show: "diriyah-square",
    set: 3,
  },
  {
    //16
    x: 260,
    y: 170,
    label: null,
    icon: "/assets/images/map/icons/orbitIconWhite.svg",
    show: "diriyah-square",
    set: 4,
  },
]

function Tour360() {
  const [showUI, setShowUI] = useState(true)
  const [tourLoaded, setTourLoaded] = useState(false)
  const tourOpen = useStore((s) => s.tourOpen)
  const tourIndex = useStore((s) => s.tourIndex)
  const setLocalState = useStore((s) => s.setLocalState)
  const tourLabels = [
    "Amphitheater",
    "Lux Square",
    "East Courtyard",
    "Sunken Garden",
    "Theater Square",
  ]
  return (
    <Popup
      openState={tourOpen}
      setOpenState={(v) => setLocalState({ tourOpen: v })}
      onClose={() => {
        setTourLoaded(false)
        setShowUI(true)
      }}
      closeButton={
        <DiamondButton
          size="medium"
          color="brown"
          icon="/assets/icons/common/close.svg"
          style={{
            opacity: tourLoaded && showUI ? 1 : 0,
          }}
        />
      }
      durationMS={400}
    >
      <TourWrapper $active={showUI && tourLoaded}>
        <Loader className="loader" />
        <Canvas
          onDoubleClick={() => {
            setShowUI(!showUI)
          }}
        >
          <TransitionTour
            controls={true}
            textures={[
              "/assets/images/tour/amphitheater.webp",
              "/assets/images/tour/east-courtyard.webp",
              "/assets/images/tour/lux-square.webp",
              "/assets/images/tour/sunken-garden.webp",
              "/assets/images/tour/theater-square.webp",
            ]}
            show={true}
            activeIndex={tourIndex}
            onLoaded={() => {
              setTourLoaded(true)
            }}
          />
        </Canvas>
        <List className="ui-element">
          <h1>Locations</h1>
          {tourLabels.map((name, i) => {
            return (
              <ListItem key={`btn${i}`}>
                <button
                  onClick={() => {
                    setLocalState({ tourIndex: i })
                  }}
                  style={{
                    transform: `translateX(${tourIndex === i ? "20%" : "0%"})`,
                    opacity: tourIndex === i ? 1 : 0.5,
                  }}
                >
                  {name}
                </button>
              </ListItem>
            )
          })}
        </List>
        <MiniMap viewBox="0 0 500 500" className="ui-element">
          <image
            x={0}
            y={0}
            href="/assets/images/map/background/thumb/mini-top-view.webp"
          />
          <path
            d="m0,0v500h500V0H0Zm222.76,480.57l-25.97,8.58-13.48-7.11,12.5-48.51,8.33-7.11v-54.15l-9.8-5.64s-21.55-46.79-26.14-68.24c-7.66-7.66-10.22-22.79-10.22-22.79l9.85-21.78v-8.06l-41.18-7.76-25.36-13.73h-13.43v-23.28l14.02-23.87-10.15-20.59,43.57-20.29-3.28-8.95h21.49s20.29,6.56,41.18-5.37c9.4-9.4,15.99-17.9,23.96-17.9,7.67-2.39,23.12-13.13,31.36-26.26,0,0,18.57,17.4,30.58,21.56h11.52c4.41,0,1.96,16.66,1.96,16.66l-3.92,21.56c-1.23,3.43,2.94,18.87,2.94,18.87,10.78,16.66,29.16,42.63,31.36,86,0,43.37-21.81,83.55-32.83,98.25-4.41,7.84,12.01,38.96,12.01,38.96l22.3-11.52,23.52,40.92-126.67,71.54Z"
            fill="black"
            opacity={0.8}
            className="blur"
          />
          {icons360Data.map((obj, i) => {
            return (
              <SVGIconGroup
                key={i}
                label={null}
                x={obj.x}
                y={obj.y}
                icon={obj.icon}
                bgColor={
                  obj.set === tourIndex
                    ? "var(--dark-green)"
                    : "var(--light-brown)"
                }
                height={35}
                className="mini-map-icon"
                onClick={() => {
                  setLocalState({ tourIndex: obj.set })
                }}
              />
            )
          })}
        </MiniMap>
        <Prompt>Double click/tap to {showUI ? "hide" : "show"} controls</Prompt>
      </TourWrapper>
    </Popup>
  )
}

export default Tour360

const TourWrapper = styled.div<{ $active: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;

  .ui-element {
    opacity: ${(p) => (p.$active ? 1 : 0)};
    pointer-events: ${(p) => (p.$active ? "all" : "none")};
    transition: opacity 0.4s ease-in;
  }
`

const List = styled.div`
  position: absolute;
  width: fit-content;
  height: fit-content;
  left: 3vh;
  bottom: 3vh;
  padding: 1.5vh;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: #255044;
  transition: opacity 0.5s ease-in-out;
  box-shadow: 5px 5px 10px 2px #0000004e;
  white-space: nowrap;

  & h1 {
    transform: translateX(0.5rem);
    color: white;
    margin-right: 2rem;
  }
`

const ListItem = styled.div`
  position: relative;
  width: 100%;
  height: auto;

  & button {
    font-family: "BeausiteGrandTrial-Light";
    min-width: 8rem;
    width: fit-content;
    text-transform: capitalize;
    font-size: 1.2rem;
    padding: 0.3rem;
    border-radius: 100px;
    border: none;
    transition: all 0.5s ease-in-out;
    text-align: left;
    background-color: transparent;
    color: var(--white);
    cursor: pointer;
  }
`

const MiniMap = styled.svg`
  position: absolute;
  bottom: 3vh;
  right: 3vh;
  width: clamp(260px, 550px, 15vw);
  height: clamp(260px, 550px, 15vw);
  opacity: 0;
  transition: opacity 0.4s ease-in;
  box-shadow: 5px 5px 10px 2px #0000004e;

  .blur {
    -webkit-filter: blur(15px);
    filter: blur(15px);
  }

  .mini-map-icon {
    cursor: pointer;
    > g rect {
      transition: fill 1.5s ease-in;
    }
  }
`

const Prompt = styled.div`
  position: absolute;
  left: 50%;
  color: var(--white);
  bottom: 0.5%;
  left: 50%;
  transform: translateX(-50%);
  font-family: "BeausiteGrandTrial-Light";
  font-size: 0.8rem;
`
