import { useEffect, useRef, memo, Fragment } from "react"
import styled from "styled-components"
import gsap from "gsap"

import unitsSVGData from "@data/unit-finder/unitFinderZoneUnits"
import { useStore } from "@state/store"

function LabelGroup({ data }: { data: any }) {
  const LABEL_WIDTH = 10
  return (
    <Fragment>
      <Background
        x={data.center[0] - LABEL_WIDTH * 0.5}
        y={data.center[1] - LABEL_WIDTH * 0.166}
        rx={LABEL_WIDTH * 0.2}
        fill="black"
        fillOpacity={0.6}
        width={LABEL_WIDTH}
        height={LABEL_WIDTH * 0.333}
      />
      <Label
        x={data.center[0] }
        y={data.center[1]}
        fontSize={LABEL_WIDTH * 0.18}
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {data.unitID.substring(0, 3)}-{data.unitID.substring(3)}
      </Label>
    </Fragment>
  )
}

function UnitsLabelsLayer({
  active,
}: {
  active: boolean
}) {
  const { unitLevel, mapCategory } = useStore((s) => ({
    unitLevel: s.unitLevel,
    mapCategory: s.mapCategory,
  }))
  const overlayRef = useRef<SVGGElement>(null)
  const layerTranslate = [8, -8]

  function getClassName(cond) {
    return `animateLayer ${cond ? "animateIn" : "animateOut"}`
  }

  useEffect(() => {
    // console.log("render")
  }, [])

  return active ? (
    <Group
      transform={`translate(${layerTranslate[0]} ${layerTranslate[1]})`}
      ref={overlayRef}
    >
      {unitLevel === "B1" && (
        <Group className={getClassName(unitLevel === "B1")}>
          {unitsSVGData["B1"].map((obj, i) => {
            return (
              <Group key={`unit-${i}`}>
                <LabelGroup data={obj} />
              </Group>
            )
          })}
        </Group>
      )}
      {(unitLevel === "0") && (
        <Group className={getClassName(unitLevel === "0")}>
          {unitsSVGData["0"].map((obj, i) => {
            // console.log("render polygon")
            return  (
              <Group key={`unit-${i}`}>
                <LabelGroup data={obj} />
              </Group>
            )
          })}
        </Group>
      )}
      {unitLevel === "1" && (
        <Group className={getClassName(unitLevel === "1")}>
          {unitsSVGData["1"].map((obj, i) => {
            return  (
              <Group key={`unit-${i}`}>
                <LabelGroup data={obj} />
              </Group>
            )
          })}
        </Group>
      )}
    </Group>
  ) : null
}

export default UnitsLabelsLayer

const Group = styled.g`
  pointer-events: none;
`

const Label = styled.text`
  fill: var(--white);
  font-family: "Arial";
  pointer-events: none;
  letter-spacing: 0.1px;
`

const Background = styled.rect`
  pointer-events: none;
`
