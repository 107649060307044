const availableSLDS = [
  "C01001-1",
  "C01002-1",
  "C01003-1",
  "C01004-1",
  "C01004-2",
  "C02001-1",
  "C02001-2",
  "C02002-1",
  "C02003-1",
  "C02004-1",
  "C02005-1",
  "C02006-1",
  "C02007-1",
  "C03001-1",
  "C03002-1",
  "C03003-1",
  "C03004-1",
  "C03005-1",
  "C03005-2",
  "C03005-3",
  "C04009-1",
  "C04010-1",
  "C05001-1",
  "C05002-1",
  "C05003-1",
  "C05004-1",
  "C05004-2",
  "C05004-3",
  "C05004-4",
  "C06001-1",
  "C06001-2",
  "C06001-3",
  "C06001-4",
  "C06002-1",
  "C06002-2",
  "C06002-3",
  "C06002-4",
  "C07001-1",
  "C07001-2",
  "C07002-1",
  "C07003-1",
  "C07004-1",
  "C07005-1",
  "C08001-1",
  "C08004-1",
  "C08004-2",
  "C09001-1",
  "E01001-1",
  "E01001-2",
  "E01002-1",
  "E01003-1",
  "E01004-1",
  "E01005-1",
  "E02001-1",
  "E02002-1",
  "E02003-1",
  "E02004-1",
  "E03001-1",
  "E03001-2",
  "E03002-1",
  "E04001-1",
  "E04001-2",
  "E04002-1",
  "E04002-2",
  "E04004-1",
  "E05002-1",
  "E05002-2",
  "E05003-1",
  "E05003-2",
  "E06002-1",
  "E06002-2",
  "E06003-1",
  "E07001-1",
  "E07003-1",
  "E08001-1",
  "E08002-1",
  "E08003-1",
  "E10001-1",
  "E10002-1",
  "E10003-1",
  "E10004-1",
  "E10005-1",
  "E10006-1",
  "E10007-1",
  "E10008-1",
  "E10009-1",
  "E11001-1",
  "H01001-1",
  "H01002-1",
  "H01003-1",
  "H01003-2",
  "H01004-1",
  "H01006-1",
  "H01007-1",
  "H02002-1",
  "H02003-1",
  "H02004-1",
  "H02005-1",
  "H03003-1",
  "H03004-1",
  "H03005-1",
  "H03006-1",
  "H03007-1",
  "H03008-1",
  "H04002-1",
  "H04003-1",
  "H05001-1",
  "H05002-1",
  "H05003-1",
  "H05005-1",
  "H05006-1",
  "L02001-1",
  "L02002-1",
  "L02003-1",
  "L02004-1",
  "L02005-1",
  "L02005-2",
  "L02006-1",
  "L02008-1",
  "L02009-1",
  "L02012-1",
  "L02013-1",
  "L02014-1",
  "L02015-1",
  "L02016-1",
  "L02017-1",
  "L02018-1",
  "L02019-1",
  "L02020-1",
  "L02021-1",
  "L02022-1",
  "L03002-1",
  "L03003-1",
  "L03008-1",
  "L03009-1",
  "L04001-1",
  "L04002-1",
  "L04003-1",
  "L04004-1",
  "L04005-1",
  "L04005-2",
  "L04006-1",
  "L04007-1",
  "L04008-1",
  "L04009-1",
  "L04010-1",
  "L04011-1",
  "L04012-1",
  "L04012-2",
  "L04013-1",
  "L04014-1",
  "L04015-1",
  "L04016-1",
  "L04018-1",
  "L04018-2",
  "L04019-1",
  "L05001-1",
  "L05002-1",
  "L05003-1",
  "L05004-1",
  "L05005-1",
  "L05006-1",
  "L05007-1",
  "L05008-1",
  "L05009-1",
  "L06001-1",
  "L06002-1",
  "L06003-1",
  "L06004-1",
  "L06005-1",
  "L06006-1",
  "L06007-1",
  "L06017-1",
  "L06018-1",
  "L06019-1",
  "L06020-1",
  "L06021-1",
  "L07001-1",
  "L07002-1",
  "L07002-2",
  "L08001-1",
  "L08002-1",
  "L10004-1",
  "L10004-2",
  "L10005-1",
  "L10005-2",
  "L10006-1",
  "L10006-2",
  "L11001-1",
  "L11002-1",
  "L11003-1",
  "N01001-1",
  "N01001-2",
  "N01001-3",
  "N01002-1",
  "N01003-1",
  "N01004-1",
  "N01005-1",
  "N01006-1",
  "N01007-1",
  "N02001-1",
  "N02001-2",
  "N02001-3",
  "N02002-1",
  "N02003-1",
  "N02004-1",
  "N02005-1",
  "N03001-1",
  "N03002-1",
  "N03003-1",
  "N03004-1",
  "N03005-1",
  "N03006-1",
  "N03007-1",
  "N03008-1",
  "N03009-1",
  "N04001-1",
  "N04002-1",
  "N04003-1",
  "N04004-1",
  "N04005-1",
  "N04006-1",
  "N05001-1",
  "N06001-1",
  "N06002-1",
  "N06003-1",
  "N06004-1",
  "N07001-1",
  "N07001-2",
  "N07001-3",
  "N07002-1",
  "N07003-1",
  "N07004-1",
  "N07005-1",
  "N08001-1",
  "N08002-1",
  "N08003-1",
  "N08004-1",
  "N08005-1",
  "N08006-1",
  "N08007-1",
  "N08008-1",
  "N09001-1",
  "N09002-1",
  "N09003-1",
  "N09004-1",
  "N09005-1",
  "N09006-1",
  "N09007-1",
  "N10001-1",
  "N10002-1",
  "N10003-1",
  "N10003-2",
  "N10004-1",
  "N10005-1",
  "N10006-1",
  "N10007-1",
  "N11001-1",
  "N11001-2",
  "N11001-3",
  "N11001-4",
  "N11002-1",
  "N11003-1",
  "N11004-1",
  "N11005-1",
  "N11006-1",
  "N11007-1",
  "N12001-1",
  "N12002-1",
  "N12003-1",
  "N12004-1",
  "N12005-1",
  "N12006-1",
  "N12007-1",
  "N12008-1",
  "N12009-1",
  "N12010-1",
  "N12011-1",
  "N12012-1",
  "N12013-1",
  "N12013-2",
  "S01001-1",
  "S01002-1",
  "S01003-1",
  "S01004-1",
  "S01004-2",
  "S02001-1",
  "S02001-2",
  "S02002-1",
  "S02002-2",
  "S02002-3",
  "S02003-1",
  "S02004-1",
  "S02005-1",
  "S02006-1",
  "S02007-1",
  "S02008-1",
  "S02009-1",
  "S02010-1",
  "S02011-1",
  "S03001-1",
  "S03001-2",
  "S03002-1",
  "S03003-1",
  "S03004-1",
  "S03005-1",
  "S04001-1",
  "S04002-1",
  "S04002-2",
  "S04003-1",
  "S05001-1",
  "S05002-1",
  "S05003-1",
  "S05004-1",
  "S05005-1",
  "S05006-1",
  "S05007-1",
  "S05008-1",
  "S05009-1",
  "S06001-1",
  "S06001-2",
  "S06002-1",
  "S06003-1",
  "S06004-1",
  "S06005-1",
  "S06006-1",
  "S06007-1",
  "S06008-1",
  "S06009-1",
  "S06009-2",
  "S07001-1",
  "S07002-1",
  "S08001-1",
  "S08001-2",
  "S08002-1",
  "S08003-1",
  "S08004-1",
  "S08004-2",
  "S08005-1",
  "S08005-2",
  "S09001-1",
  "S09001-2",
  "S09002-1",
  "S10001-1",
  "S10002-1",
  "S10003-1",
  "S10004-1",
  "S10005-1",
  "S10006-1",
  "S10006-2",
  "S10007-1",
  "S11001-1",
  "S11002-1",
  "S11003-1",
  "S11004-1",
  "S11005-1",
  "S11005-2",
  "S12001-1",
  "S12001-2",
  "S12003-1",
  "S12003-2",
  "S12003-3",
  "S12003-4",
  "S13001-1",
  "S14001-1",
  "S15002-1",
  "S15003-1",
  "S15004-1",
  "S15005-1",
  "S15006-1",
  "S15007-1",
  "S15007-2",
  "S15007-3",
  "S16001-1",
  "S16002-1",
  "S16003-1",
  "S16004-1",
  "S16008-1",
  "S17001-1",
  "S17001-2",
  "S17002-1",
  "S17003-1",
  "S17004-1",
  "S17005-1",
  "S17006-1",
  "S17007-1",
  "S17007-2",
  "S17008-1",
  "S18001-1",
  "S18001-2",
  "S18001-3",
  "S18002-1",
  "S18003-1",
  "S18004-1",
  "S18005-1",
  "S18006-1",
  "S18007-1",
  "S18008-1",
  "S18009-1",
  "S18010-1",
  "S18011-1",
  "S18012-1",
  "S18013-1",
  "S18014-1",
  "S19001-1",
  "S19001-2",
  "S19001-3",
  "S19002-1",
  "S19003-1",
  "S19004-1",
  "S19005-1",
  "S19006-1",
  "S19006-2",
  "S19007-1",
  "S20001-1",
  "S20002-1",
  "S21001-1",
  "S22001-1",
  "W01001-1",
  "W01001-2",
  "W01002-1",
  "W01003-1",
  "W01003-2",
  "W02001-1",
  "W02001-2",
  "W03001-1",
  "W03001-2",
  "W04001-1",
  "W04001-2",
  "W04002-1",
  "W04002-2",
  "W05001-1",
  "W05001-2",
  "W05002-1",
  "W06001-1",
  "W06001-2",
  "W06002-1",
  "W07001-1",
  "W07001-2",
  "W07002-1",
  "W07003-1",
  "W07004-1",
  "W07005-1",
  "W07006-1",
  "W07007-1",
  "W07008-1",
  "W07009-1",
  "W08001-1",
  "W08002-1",
  "W09001-1",
  "W09001-2",
  "W09001-3",
  "W09002-1",
  "W09003-1",
  "W09004-1",
  "W10001-1",
  "W10001-2",
  "W10001-3",
  "W10002-1",
  "W10003-1",
  "W10004-1",
  "W11001-1",
  "W12001-1",
  "W13001-1",
  "W13001-2",
  "W14001-1",
  "W14001-2",
  "W14002-1",
  "W14003-1",
  "W14004-1",
  "W14005-1",
  "W14006-1",
  "W14007-1",
  "W14008-1",
  "W14008-2",
  "W14009-1",
  "W14010-1",
  "W14011-1",
  "W14012-1",
  "W14013-1",
  "W15003-1",
  "W15004-1",
  "W15005-1",
  "W15005-2",
  "W16001-1",
  "W16001-2",
  "W17001-1",
]

export default availableSLDS
