import { forwardRef } from "react"
import { useRef, useState, useEffect, Fragment, useLayoutEffect } from "react"
import styled from "styled-components"
import gsap from "gsap"
import { useNavigate } from "react-router-dom"

import PageWrapper from "@components/PageWrapper"
import Clouds from "@components/Clouds"
import MapSVGIconGroup from "@components/MapSVGIconGroup"
import { DiamondButton } from "@components/buttons"
import {
  Title,
  Image,
  Figure,
  Figures,
  Paragraph,
  Desc,
} from "@components/panels"

import { useStore } from "@state/store"
import diriyahSquareSecondaryIconsData from "@data/diriyah-square/diriyahSquareSecondaryIcons"
import diriyahSquareZoneMaskData from "@data/diriyah-square/diriyahSquareZoneMask"
import dsqCopyData from "@data/side-panel/dsq"

const SideBar = forwardRef(function SideBar(props: any, ref: any) {
  const { copy } = props
  const {
    navigating,
    navDurationSec,
    setLocalState,
    mapCategory,
    amenityCategory,
  } = useStore((s) => ({
    navigating: s.navigating,
    navDurationSec: s.navDurationSec,
    setLocalState: s.setLocalState,
    mapCategory: s.mapCategory,
    amenityCategory: s.amenityCategory,
  }))
  return (
    <Fragment>
      {mapCategory && (
        <DiamondButton
          size="medium"
          color="light-green"
          className="close-btn-dsq"
          onClick={() => {
            setLocalState({
              mapCategory: null,
            })
          }}
          icon="/assets/icons/common/close.svg"
        />
      )}
      <CopyWrapper ref={ref}>
        <Title>{copy?.title}</Title>
        <Image
          src={copy?.imgThumb}
          onClick={() => {
            if (!copy?.swipeGalleryData) return
            setLocalState({
              swiperGalleryData: copy?.swipeGalleryData,
              swiperGalleryOpen: true,
            })
          }}
        />
        <Desc>{copy?.desc}</Desc>
        {copy?.feature1 && (
          <Figure>
            <div className="subHeading">KEY FIGURES</div>
            <div className="rightText">
              <Figures>
                <div className="figureMetric">{copy?.feature1Text}</div>
                <div className="figureType">{copy?.feature1}</div>
              </Figures>
              <Figures>
                <div className="figureMetric">{copy?.feature2Text}</div>
                <div className="figureType">{copy?.feature2}</div>
              </Figures>
              <Figures>
                <div className="figureMetric">{copy?.feature3Text}</div>
                <div className="figureType">{copy?.feature3}</div>
              </Figures>
            </div>
          </Figure>
        )}
        <Paragraph>{copy?.para}</Paragraph>
      </CopyWrapper>
    </Fragment>
  )
})

export default SideBar

const CopyWrapper = styled.div`
  position: relative;
  width: 100%;
`
