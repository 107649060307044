import styled from "styled-components"
import { motion } from "framer-motion"
import Categories from "./components/Categories"
import PrimaryTradeArea from "./components/pages/PrimaryTradeArea"
import MetricsOverview from "./components/pages/MetricsOverview"
import { useState } from "react"
import SecondaryTradeArea from "./components/pages/SecondaryTradeArea"
import RetailSupply from "./components/pages/RetailSupply"
import IncomeProfile from "./components/pages/IncomeProfile"
import RetailTrends from "./components/pages/RetailTrends"
import MarketShare from "./components/pages/MarketShare"
import PageWrapper from "@components/PageWrapper"

const Metrics: React.FC<any> = () => {
  const [activeMetric, setActiveMetric] = useState(0)
  return (
    <PageWrapper>
      <Wrapper>
        <div className="menu">
          <Categories onClick={setActiveMetric} />
        </div>
        <motion.div
          style={{ width: "100%", height: "100%" }}
          initial={{ opacity: 0, x: 0 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          {activeMetric === 0 && <MetricsOverview />}
          {activeMetric === 1 && <PrimaryTradeArea />}
          {activeMetric === 2 && <SecondaryTradeArea />}
          {activeMetric === 3 && <RetailSupply />}
          {activeMetric === 4 && <IncomeProfile />}
          {activeMetric === 5 && <RetailTrends />}
          {activeMetric === 6 && <MarketShare />}
        </motion.div>
      </Wrapper>
    </PageWrapper>
  )
}

export default Metrics

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  margin-left: auto;
  padding: 2.5rem;
  gap: 1.5rem;

  .menu {
    width: 100%;
  }
`
