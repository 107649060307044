import { Button } from "@components/buttons/Button"
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import { useEffect, useState } from "react"
import styled from "styled-components"

function Download() {
  const [downloadUrl, setDownloadUrl] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState("")

  useEffect(() => {
    const storage = getStorage()
    const starsRef = ref(
      storage,
      "gs://luna-auth-eed78.appspot.com/Diriyah_0.1.0_x64_en-US.msi",
    )

    getDownloadURL(starsRef)
      .then((url) => {
        setDownloadUrl(url)
        setIsLoading(false)
      })
      .catch((error) => {
        console.error("Error fetching file download URL:", error)
        setError("Failed to fetch download link")
        setIsLoading(false)
      })
  }, [])

  return (
    <Wrapper>
      <h1>Download Your File</h1>
      <p>Click the button below to download Diriyah installer.</p>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <a
        href={downloadUrl}
        download="Diriyah_0.1.0_x64_en-US.msi"
        style={{ marginTop: "1rem", all: "unset" }}
      >
        <Button
          color="brown"
          title={isLoading ? "Loading..." : "Download"}
          variant="rectangle"
          disabled={!downloadUrl || isLoading}
          icon="/assets/icons/common/download.svg"
          onClick={() => {}}
        />
      </a>
    </Wrapper>
  )
}

export default Download

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 11rem;
  width: calc(100% - 11rem);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  margin-left: auto;
  padding: 2.5rem;
  gap: 1.5rem;
`
