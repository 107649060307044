import { Panel } from "@components/panels"
import * as React from "react"
import styled from "styled-components"
import { DiamondButton } from "@components/buttons"
import { useStore } from "@state/store"
import kkiaImg from "@assets/images/vision/panels/kkia.png"

export interface IAirportPanelProps {
  visible: boolean
}

export default function AirportPanel({ visible }: IAirportPanelProps) {
  const { setRiyadhPanel, riyadhPanel } = useStore((state) => ({
    riyadhPanel: state.riyadhPanel,
    setRiyadhPanel: state.setRiyadhPanel,
  }))

  return (
    <StyledPanel className="stats-panel" visible={visible}>
      <div className="stats-box">
        <div className="top">
          <CloseWrapper>
            <DiamondButton
              style={{ height: "2rem", width: "2rem" }}
              icon="/assets/icons/common/close.svg"
              size="medium"
              color="light-green"
              onClick={() => setRiyadhPanel(null)}
            />
          </CloseWrapper>
          <img src={kkiaImg} alt="Airport" />
        </div>
        <div className="bottom-text">
          <div className="mid-text">Riyadh International Airport</div>
          <p>
            King Khalid International Airport was opened in 1983 A.D in Riyadh
            city. It is located in an area of 225 km2 about 35 km from the city
            center, containing five passengers' terminals, 40 Passenger boarding
            bridges, and more car parking designed for 11.600 vehicles. The
            airport consists of Royal Terminal, the Central Control Tower, two
            Parallel Runways, which are each 4,260 meters (13,980 ft) long.
          </p>
          <br />
          <p>Facts about KKIA:</p>
          <ul>
            <li>
              <p>Established in 1983.</p>
            </li>
            <li>
              Collaborates with over 51 international and domestic airlines with
              over 105 destinations in total and over 217,000 flights a year.
            </li>
            <li>More than 28.5 million passengers a year are served.</li>
          </ul>
        </div>
      </div>
    </StyledPanel>
  )
}

const CloseWrapper = styled.div`
  position: absolute;
  top: -1rem;
  right: -1rem;
  z-index: 15;
`

const StyledPanel = styled(Panel)<{ visible: boolean }>`
  position: absolute;
  top: 0;
  right: ${({ visible }) => (visible ? "0" : "-30rem")};
  height: 100%;
  width: 25rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row dense;
  justify-items: center;
  z-index: 1;
  padding: 0;
  font-size: 0.8rem;
  transition: right 0.6s;

  @media screen and (max-width: 1366px) {
    font-size: 1rem;
  }

  & .stats-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 2rem;

    &:not(:last-child) {
      border-bottom: 1px solid #ffffff;
    }

    .top {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 3rem;
      height: 15rem;
      border-radius: 4px;
      box-shadow: 0px 0px 3px rgba(255, 255, 255, 0.7);
      background: #ffffff;

      img {
        width: 100%;
        height: auto;
        opacity: 1;
      }
    }

    .bottom-text {
      text-transform: uppercase;
      letter-spacing: 0.03rem;
      font-weight: 400;
      line-height: 1.5;
      color: ${({ theme }) => theme.darkGreen};
      text-align: left;

      ul {
        padding-left: 2rem;
      }
    }

    .bottom-text {
      text-transform: none;
    }

    .mid-text {
      font-size: 2rem;
      font-family: BeausiteGrandTrial-Light;
      padding: 0.5rem 0;
      color: #ffffff;

      @media screen and (max-width: 1366px) {
        font-size: 2.5rem;
      }
    }
  }
`
