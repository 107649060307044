import { useEffect, useRef, FC } from "react"
import ApexCharts, { ApexOptions } from "apexcharts"

type ColumnStackedChartProps = {
  title: string
  data: any[]
  yaxismax?: number
  categories?: string[]
  yaxisTitle?: string
}

const ColumnStackedChart: FC<ColumnStackedChartProps> = ({
  title,
  data,
  yaxismax,
  categories,
  yaxisTitle,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const barChart = new ApexCharts(
      chartRef.current,
      columnStackedChartOptions(title, data, yaxismax, categories, yaxisTitle),
    )
    if (barChart) {
      barChart.render()
    }

    return barChart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef])

  return <div style={{ width: "100%", height: "100%" }} ref={chartRef}></div>
}

const columnStackedChartOptions = (
  title: string,
  data: any[],
  yaxismax?: number,
  categories?: string[],
  yaxisTitle?: string,
): ApexOptions => {
  const labelColor = "#004344"

  return {
    series: data,
    title: {
      text: title,
      align: "left",
      style: {
        fontSize: "18px",
        fontWeight: 400,
        color: labelColor,
      },
    },
    chart: {
      type: "bar",
      height: "100%",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "13px",
        fontWeight: 400,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          total: {
            // enabled: true,
            style: {
              fontSize: "13px",
              fontWeight: 400,
            },
          },
        },
      },
    },
    colors: ["#002e2f", "#335859", "#668282", "#99abac", "#ccd5d5"],
    yaxis: {
      max: yaxismax,
      labels: {
        show: false,
      },
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    legend: {
      position: "bottom",
      offsetY: 0,
    },
    fill: {
      opacity: 1,
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
  }
}

export { ColumnStackedChart }
