import { useStore } from "@state/store"
import React, { useEffect, useRef, useState } from "react"
import SVG from "react-inlinesvg"
import styled from "styled-components"
import globalMap from "@assets/images/vision/map-global.svg"
import gsap from "gsap"
import { transform } from "framer-motion"

type GlobalMapProps = {
  visible: boolean
}

const GlobalMap: React.FC<GlobalMapProps> = ({ visible }) => {
  const globalMapRef = useRef(null)
  const [svgLoaded, setSvgLoaded] = useState(false)
  const { setActiveMap } = useStore((state) => ({
    setActiveMap: state.setActiveMap,
  }))
  const timeline = gsap.timeline()

  useEffect(() => {
    const globalMap = globalMapRef.current

    if (!globalMap) return

    const worldMapWrapper = globalMap.querySelector("#WorldMapWrapper")
    const saudiArabiaLabel = globalMap.querySelector("#Label-SaudiArabia")
    const icons = globalMap.querySelectorAll(`[data-gsap="icon-world"]`)
    const shapes = globalMap.querySelectorAll(`[data-gsap="shape-world"]`)
    const info = globalMap.querySelectorAll(`[data-gsap="info-world"]`)
    const infoYAttr = ["220 100", "250 300"]
    timeline.to(shapes, {
      attr: {
        "stroke-dashoffset": "0"
      },
      duration: 1,
      stagger: 0.5,
      ease: "power1.in",
      delay: 0.6
    }, 0)
    timeline.to(shapes, {
      attr: {
        "fill": "#0043441A",
        "stroke": "#00434400"
      },
      duration: .8,
      ease: "power4.in"
    }, ">")
    timeline.to(icons, {
      attr: {
        opacity: 1
      },
      duration: 2,
      ease: "power1.in"
    },"< +=0.5")
    timeline.to(info, {
      attr: {
        opacity: "1",
        transform: (i) => `translate(${infoYAttr[i]})`
      },
      duration: 2,
      ease: "power2.in"
    },"<")
    const toggleVisibility = (isVisible: boolean) => {
      if (worldMapWrapper) {
        worldMapWrapper.setAttribute("class", isVisible ? "visible" : "hidden")
      }
    }

    toggleVisibility(visible)

    if (saudiArabiaLabel) {
      saudiArabiaLabel.style.cursor = "pointer"
      saudiArabiaLabel.setAttribute("class", visible ? "show" : "hide")
      const onClickHandler = () => setActiveMap("saudi")
      saudiArabiaLabel.addEventListener("click", onClickHandler)

      return () => saudiArabiaLabel.removeEventListener("click", onClickHandler)
    }
  }, [visible, svgLoaded])

  return (
    <StyledSvg
      visible={visible ? "true" : "false"}
      fill="none"
      innerRef={globalMapRef}
      src={globalMap}
      preserveAspectRatio="xMidYMid slice"
      onLoad={() => {
        setSvgLoaded(true)
      }}
    />
  )
}

export default GlobalMap

//@ts-ignore
const StyledSvg = styled(SVG)<{ visible: string }>`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${({ visible }) => (visible === "true" ? 1 : -1)};

  @media screen and (min-width: 1920px) {
    height: 95%;
    width: 85%;
  }

  & .visible {
    opacity: 1;
    transform: scale(1) translate(0, 0) !important;
    transform-origin: center;
    transition: all 1s;
  }

  & .hidden {
    opacity: 0;
    transform: scale(2.4) translate(0, 0) !important;
    transform-origin: center;
    transition: all 1s;
  }

  & .show {
    opacity: 1;
  }

  & .hide {
    opacity: 0;
  }
`
