import React from "react";
import styled from "styled-components";

type ListItem = {
  text1?: string;
  text2?: string;
};

type ListViewProps = {
  listItems: ListItem[];
};



const ListView: React.FC<ListViewProps> = ({ listItems }) => {
  return (
    <ListContainer>
      {listItems.map((item, index) => (
        <ListItemWrapper key={index}>
          <div className="heading">{item.text1}</div>
          <div className="body">{item.text2}</div>
        </ListItemWrapper>
      ))}
    </ListContainer>
  );
};

export default ListView;

const ListContainer = styled.div `
color: ${({theme}) => theme.darkGreen};
width: 100%;
display: flex;
flex-direction: column;
gap: 1rem;

  .heading{
    font-size: 1.5rem;
    line-height: 1.5;
  }

  .body{
    font-size: 0.8rem;
    font-family: "Arial";
    line-height: 1.25rem;
  }
`

const ListItemWrapper = styled.div `
display: flex;
flex-direction: column;
padding-bottom: 1rem;
border-bottom: 1px solid rgba(0,0,0,0.1);

`
