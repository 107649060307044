import React, { useState, useCallback, useEffect } from "react"
import EmblaCarousel from "./components/carousel/GalleryCarousel"
import { EmblaOptionsType } from "embla-carousel"
import styled from "styled-components"
import { motion } from "framer-motion"
import SVG from "react-inlinesvg"
import LoadingScreen from "@components/loading-screen/LoadingScreen"
import PageWrapper from "@components/PageWrapper"

const fetchImages = {
  lifestyle: [
    "/assets/images/gallery/lifestyle/1.webp",
    "/assets/images/gallery/lifestyle/2.webp",
    "/assets/images/gallery/lifestyle/3.webp",
    "/assets/images/gallery/lifestyle/4.webp",
    "/assets/images/gallery/lifestyle/5.webp",
    "/assets/images/gallery/lifestyle/6.webp",
  ],
  retail: [
    "/assets/images/renders/full/view8.webp",
    "/assets/images/renders/full/view4.webp",
    "/assets/images/renders/full/view24.webp",
    "/assets/images/renders/full/view18.webp",
    "/assets/images/gallery/retail/5.webp",
    "/assets/images/gallery/retail/6.webp",
    "/assets/images/gallery/retail/7.webp",
    "/assets/images/renders/full/view22.webp",
    "/assets/images/renders/full/view10A.webp",
  ],
  construction: [
    "/assets/images/gallery/construction/1.webp",
    "/assets/images/gallery/construction/2.webp",
    "/assets/images/gallery/construction/3.webp",
    "/assets/images/gallery/construction/4.webp",
    "/assets/images/gallery/construction/5.webp",
    "/assets/images/gallery/construction/6.webp",
    "/assets/images/gallery/construction/7.webp",
    "/assets/images/gallery/construction/8.webp",
    "/assets/images/gallery/construction/9.webp",
    "/assets/images/gallery/construction/10.webp",
    "/assets/images/gallery/construction/11.webp",
    "/assets/images/gallery/construction/12.webp",
    "/assets/images/gallery/construction/13.webp",
  ]
}

const getImagesForCategory = async (category: string) => {
  const images = fetchImages[category]()

  // Adding a delay to give UI a chance to update
  await new Promise((resolve) => setTimeout(resolve, 400))

  const imagePaths: string[] = await Promise.all(
    Object.entries(images).map(async ([, importer]) => {
      //@ts-ignore
      const module: any = await importer()
      return module.default
    })
  )

  return imagePaths
}

const OPTIONS: EmblaOptionsType = {
  loop: false,
  duration: 30,
  align: "center",
  dragFree: false,
}

const Gallery = () => {
  const [mode, setMode] = useState<"grid" | "fullscreen">("grid")
  const [images, setImages] = useState<string[]>([])
  const [category, setCategory] = useState<string>("lifestyle")

  const handleCategoryChange = useCallback((newCategory: string) => {
    setCategory(newCategory)
  }, [])

  useEffect(() => {
    const loadImages = async () => {
      // Simulate an asynchronous image loading operation
      const imagePaths = fetchImages[category]

      return imagePaths
    }

    loadImages().then((res) => {
      setImages(res)
    })

    return () => {
      setImages([])
    }
  }, [category])

  return (
    <PageWrapper>
      <Wrapper>
        <div className="top-content">
          <CategoryWrapper visible={mode === "grid" ? "true" : "false"}>
            <Category
              active={category === "lifestyle"}
              onClick={() => handleCategoryChange("lifestyle")}
            >
              Lifestyle
            </Category>
            <Category
              active={category === "retail"}
              onClick={() => handleCategoryChange("retail")}
            >
              Retail
            </Category>
            <Category
              active={category === "construction"}
              onClick={() => handleCategoryChange("construction")}
            >
              Site Progress
            </Category>
          </CategoryWrapper>
          <ModeWrapper>
            <Mode
              id="grid"
              mode={mode}
              active={mode === "grid"}
              onClick={() => setMode("grid")}
            >
              {/* @ts-ignore */}
              <SVG src="/assets/icons/common/pause.svg" />
            </Mode>
            <Mode
              id="fullscreen"
              mode={mode}
              active={mode === "fullscreen"}
              onClick={() => setMode("fullscreen")}
            >
              {/* @ts-ignore */}
              <SVG src="/assets/icons/common/square.svg" />
            </Mode>
          </ModeWrapper>
        </div>
        {images.length === 0 ? (
          <LoadingScreen assetsLoaded={images.length > 0} />
        ) : (
          <EmblaCarousel
            imagePaths={images}
            options={OPTIONS}
            mode={mode}
            setMode={setMode}
            category={category}
          />
        )}
      </Wrapper>
    </PageWrapper>
  )
}

export default Gallery

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 1;

  .top-content {
    position: absolute;
    top: var(--margin);
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: var(--margin);
    z-index: 1;
    padding: 0 var(--margin) 0 0;
  }
`

const CategoryWrapper = styled(motion.div)<{ visible: string }>`
  display: flex;
  align-items: center;
  border-radius: 5px;
  text-transform: uppercase;
  z-index: 1;
  opacity: ${({ visible }) => (visible === "true" ? "1" : "0")};
  transition: 0.3s;
`

const Category = styled.div<{ active: boolean }>`
  font-size: 0.8rem;
  border: 0.5px solid ${({ theme }) => theme.lightBrown}8A;
  border-left: none;
  padding: 1rem 2rem;
  background-color: ${({ active, theme }) =>
    active ? theme.lightBrown : "transparent"};
  color: ${({ active, theme }) => (active ? "white" : theme.lightBrown)};
  cursor: pointer;
  transition: 0.3s;

  @media screen and (min-width: 1920px) {
    font-size: 0.5rem;
    padding: 0.6rem 1.6rem;
  }

  &:first-child {
    border-left: 0.5px solid ${({ theme }) => theme.lightBrown}8A;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`

const ModeWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  border-radius: 5px;
`

const Mode = styled.div<{ active: boolean; mode: string }>`
  height: 3.5rem;
  width: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.lightBrown}8A;
  border-left: none;
  background-color: ${({ mode, active, theme }) =>
    active
      ? theme.lightBrown
      : mode === "fullscreen"
      ? "rgba(0, 0, 0, 0.63)"
      : "transparent"};
  color: ${({ active, theme }) => (active ? "white" : theme.lightBrown)};
  cursor: pointer;
  transition: 0.3s;

  @media screen and (min-width: 1920px) {
    height: 1.9rem;
    width: 1.9rem;
  }

  svg {
    width: 20px;
  }

  svg path {
    stroke: ${({ active, theme }) => (active ? "white" : theme.lightBrown)};
    stroke-width: 1px;
  }

  &:first-child {
    border-left: 1px solid ${({ theme }) => theme.lightBrown}8A;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`
