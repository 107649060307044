import styled from "styled-components"
import { Fragment, useState, useRef } from "react"

import Popup from "@components/Popup"
import SwipeGallery from "@components/SwipeGallery"
import { DiamondButton } from "@components/buttons"
import { ProgressBar } from "@pages/features/Features"

import { useStore } from "@state/store"
import guestAmenitiesData from "@pages/features/data/guestAmenities"

function PopupGallery() {
  const [hideDetails, setHideDetails] = useState(false)
  const [sliderValue, setSliderValue] = useState(0)
  const videoOpen = useStore((s) => s.videoOpen)
  const swiperGalleryData = useStore((s) => s.swiperGalleryData)
  const swiperGalleryOpen = useStore((s) => s.swiperGalleryOpen)
  const swiperGalleryAmenity = useStore((s) => s.swiperGalleryAmenity)
  const initialSwiperGalleryIndex = useStore((s) => s.initialSwiperGalleryIndex)
  const setLocalState = useStore((s) => s.setLocalState)
  const videoRef = useRef<HTMLVideoElement>(null)

  const handleSliderChange = (value: number) => {
    const video = videoRef.current
    if (!video) return

    const currentTime = (value / 100) * video.duration
    video.currentTime = currentTime
    const sliderValue = (video.currentTime / video.duration) * 100
    setSliderValue(sliderValue)
  }

  const handleVideoOnClick = () => {
    if (videoRef.current.paused) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
  }

  return (
    <>
      <Popup
        openState={swiperGalleryOpen}
        setOpenState={(x) => {
          setLocalState({ swiperGalleryOpen: x })
        }}
        durationMS={500}
        onClose={() => {
          setHideDetails(false)
          setLocalState({
            swiperGalleryData: [],
            swiperGalleryAmenity: null,
          })
        }}
        closeColor={"#000000"}
        closeButton={
          <DiamondButton
            icon="/assets/icons/common/close-icon.svg"
            size="medium"
            color="brown"
            style={{
              width: "50px",
              height: "50px",
            }}
          />
        }
      >
        <SwipeGallery
          className="centerABS"
          data={swiperGalleryData}
          initialIndex={initialSwiperGalleryIndex}
          onDoubleClick={() => setHideDetails(!hideDetails)}
        />
        {swiperGalleryAmenity && (
          <Fragment>
            <AmenityContent
              style={{
                opacity: hideDetails ? 0 : 1,
              }}
            >
              <h1>{amenityDetails[swiperGalleryAmenity].heading1}</h1>
              <h1>{amenityDetails[swiperGalleryAmenity].heading2}</h1>
              <p>{amenityDetails[swiperGalleryAmenity].subText}</p>
            </AmenityContent>
            <Prompt>Double click to hide/show details</Prompt>
          </Fragment>
        )}
      </Popup>
      <Popup
        durationMS={500}
        closeColor={"#000000"}
        openState={videoOpen}
        setOpenState={(x) => {
          setLocalState({ videoOpen: x })
        }}
        closeButton={
          <DiamondButton
            icon="/assets/icons/common/close-icon.svg"
            size="medium"
            color="brown"
            style={{
              width: "50px",
              height: "50px",
            }}
          />
        }
      >
        <video
          src="/assets/videos/transport.mp4"
          muted
          autoPlay
          playsInline
          controls={false}
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "var(--white)",
            objectFit: "contain",
          }}
          ref={videoRef}
          onClick={handleVideoOnClick}
          onTimeUpdate={(e) => {
            const video = e.target
            //@ts-ignore
            const sliderValue = (video.currentTime / video.duration) * 100
            setSliderValue(sliderValue)
          }}
        />
        <ProgressBar
          handleSkipVideo={null}
          value={sliderValue}
          onChange={handleSliderChange}
        />
      </Popup>
    </>
  )
}

export default PopupGallery

const AmenityContent = styled.div`
  position: absolute;
  bottom: 1vh;
  left: 2vh;
  width: 25%;
  height: auto;
  background-color: #ffffffcc;
  border: 1px solid #af9781;
  padding: 2rem;
  transition: opacity 400ms ease-in-out;

  & p {
    font-size: 1rem;
    font-family: "BeausiteClassicTrial-Clear";
    margin: 0.5rem 0 auto auto;
    line-height: 25px;
    color: #014142;
  }

  & h1 {
    color: #bda896;
    font-size: 3rem;
    font-family: "BeausiteGrandTrial-Light";
  }
`

const Prompt = styled.p`
  color: black;
  position: absolute;
  bottom: 0.5%;
  left: 50%;
  transform: translateX(-50%);
  font-family: "BeausiteGrandTrial-Light";
  font-size: 0.8rem;
`

const amenityDetails = {
  "welcome-halls": {
    heading1: guestAmenitiesData.details[0].heading1,
    heading2: guestAmenitiesData.details[0].heading2,
    subText: guestAmenitiesData.details[0].subText,
  },
  "vip-lounges": {
    heading1: guestAmenitiesData.details[1].heading1,
    heading2: guestAmenitiesData.details[1].heading2,
    subText: guestAmenitiesData.details[1].subText,
  },
  "vvip-lounges": {
    heading1: guestAmenitiesData.details[2].heading1,
    heading2: guestAmenitiesData.details[2].heading2,
    subText: guestAmenitiesData.details[2].subText,
  },
  restrooms: {
    heading1: guestAmenitiesData.details[3].heading1,
    heading2: guestAmenitiesData.details[3].heading2,
    subText: guestAmenitiesData.details[3].subText,
  },
  "driver-lounges": {
    heading1: guestAmenitiesData.details[5].heading1,
    heading2: guestAmenitiesData.details[5].heading2,
    subText: guestAmenitiesData.details[5].subText,
  },
  "valet-lounges": {
    heading1: guestAmenitiesData.details[5].heading1,
    heading2: guestAmenitiesData.details[5].heading2,
    subText: guestAmenitiesData.details[5].subText,
  },
}
