import { useRef, useState, useEffect, Fragment, useLayoutEffect } from "react"
import styled from "styled-components"
import gsap from "gsap"

import PageWrapper from "@components/PageWrapper"
import Clouds from "@components/Clouds"
import DSQ from "./components/DSQ"
import SideBar from "./components/SideBar"

import { useStore } from "@state/store"
import diriyahSquareSecondaryIconsData from "@data/diriyah-square/diriyahSquareSecondaryIcons"
import diriyahSquareZoneMaskData from "@data/diriyah-square/diriyahSquareZoneMask"
import dsqCopyData from "@data/side-panel/dsq"
import VideoOrbit from "@components/VideoOrbit"
import DSQ2 from "./components/DSQ2"

function DSQMap() {
  const {
    navigating,
    navDurationSec,
    setLocalState,
    mapCategory,
    amenityCategory,
    dsqOrbitDir,
    dsqOrbitPlaying,
    dsqRetailView,
  } = useStore((s) => ({
    navigating: s.navigating,
    navDurationSec: s.navDurationSec,
    setLocalState: s.setLocalState,
    mapCategory: s.mapCategory,
    amenityCategory: s.amenityCategory,
    dsqOrbitDir: s.dsqOrbitDir,
    dsqOrbitPlaying: s.dsqOrbitPlaying,
    dsqRetailView: s.dsqRetailView,
  }))
  const [showUI, setShowUI] = useState(true)
  const [copy, setCopy] = useState(dsqCopyData.default)
  const [videoPlayed, setVideoPlayed] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const mapWrapperRef = useRef<HTMLDivElement>(null)
  const mapRef = useRef<SVGSVGElement>(null)
  const mapRetailRef = useRef<SVGSVGElement>(null)
  const sidePanelRef = useRef<HTMLDivElement>(null)
  const copyRef = useRef<HTMLDivElement>(null)
  const timeline = gsap.timeline()

  function handleDataChange(category) {
    timeline.to(
      copyRef.current,
      {
        opacity: 0,
        x: "100%",
        duration: 0.6,
        delay: 0.2,
      },
      0
    )
    timeline.set(
      copyRef.current,
      {
        x: "-100%",
        onComplete: () =>
          setCopy(dsqCopyData[category === null ? "default" : category]),
      },
      ">"
    )
    timeline.to(copyRef.current, { opacity: 1, x: "0%" }, ">0.4")
  }

  useLayoutEffect(() => {
    gsap.set(mapRetailRef.current, { autoAlpha: 0 })
    gsap.set(videoRef.current, { autoAlpha: 0 })
    gsap.set(".icon-square, .secondary-icon, .clouds", { autoAlpha: 0 })
    gsap.set(".dsq-mask, .dsq-overlay", { opacity: 0 })
  }, [])
  
  useEffect(() => {
    if (navigating || !videoRef.current) return
    gsap.to(videoRef.current, { autoAlpha: 1 })
    videoRef.current.play()
  }, [navigating])

  //INTRO ANIMATION
  useEffect(() => {
    if (!videoPlayed) return
    timeline.to(
      videoRef.current,
      {
        autoAlpha: 0,
      },
      "0.5"
    )
    timeline.to(
      mapWrapperRef.current,
      {
        width: "calc(100% - 26vw)",
        ease: "power1.out",
        duration: 0.8,
      },
      ">"
    )
    timeline.to(
      sidePanelRef.current,
      {
        x: "0%",
        ease: "power1.out",
        duration: 0.8,
      },
      "<"
    )
    timeline.set(".dsq-overlay", { autoAlpha: 1 })
    timeline.to(
      ".dsq-overlay",
      {
        strokeDashoffset: 0,
        duration: 4,
        ease: "power2.out",
      },
      "<"
    )
    // timeline.to(
    //   ".dsq-overlay",
    //   {
    //     fillOpacity: 0.6,
    //     stroke: (i) => diriyahSquareZoneOverlayData[i].fill,
    //     strokeOpacity: 0.6,
    //     filter: "blur(10px)",
    //     duration: 2,
    //     ease: "power1.out",
    //   },
    //   "<30%"
    // )
    timeline.to(
      ".icon-square, .clouds",
      {
        autoAlpha: 1,
        stagger: 0.025,
      },
      "<50%"
    )
  }, [videoPlayed])

  useEffect(() => {
    if (!videoPlayed) return
    timeline.to(
      ".dsq-mask",
      {
        opacity: (i) =>
          mapCategory === diriyahSquareZoneMaskData[i].activeState ? 0.6 : 0,
        duration: 0.8,
      },
      0
    )
    timeline.to(
      ".dsq-overlay",
      {
        opacity: mapCategory === null ? 0.6 : 0,
        duration: 0.8,
      },
      0
    )
    timeline.to(
      ".icon-square",
      {
        autoAlpha: mapCategory === null ? 1 : 0,
        duration: 0.8,
      },
      0
    )
    timeline.to(
      ".secondary-icon",
      {
        autoAlpha: (i) =>
          mapCategory === diriyahSquareSecondaryIconsData[i].activeState
            ? 1
            : 0,
        duration: 0.8,
      },
      0
    )
    handleDataChange(mapCategory)
  }, [mapCategory])

  useEffect(() => {
    gsap.to(".orbit", {
      autoAlpha: dsqOrbitPlaying ? 1 : 0,
      duration: 1,
    })
  }, [dsqOrbitPlaying])

  useEffect(() => {
    if (!mapRef.current || !mapRetailRef.current) return
    gsap.set(mapRef.current, { autoAlpha: dsqRetailView || !videoPlayed ? 0 : 1 })
    gsap.set(mapRetailRef.current, { autoAlpha: dsqRetailView && videoPlayed ? 1 : 0 })
  }, [dsqRetailView, videoPlayed])

  useEffect(() => {
    return () => {
      setLocalState({
        dsqOrbitDir: true,
        dsqRetailView: false,
      })
    }
  }, [])
  return (
    <PageWrapper>
      <DSQWrapper>
        <MapsWrapper ref={mapWrapperRef}>
          <DSQ
            videoPlayed={videoPlayed}
            setShowUI={setShowUI}
            showUI={showUI}
            handleDataChange={handleDataChange}
            ref={mapRef}
          />
          <DSQ2
            videoPlayed={videoPlayed}
            setShowUI={setShowUI}
            showUI={showUI}
            handleDataChange={handleDataChange}
            ref={mapRetailRef}
          />
          <VideoOrbit
            direction={dsqOrbitDir}
            directionSet={(val) => {
              setLocalState({
                dsqOrbitDir: val,
              })
            }}
            fwdVideos={["/assets/videos/aerial2.mp4"]}
            bwdVideos={["/assets/videos/aerial2-rv.mp4"]}
            indexState={0}
            playState={dsqOrbitPlaying}
            delayMS={1000}
            playSet={(val) => {
              setLocalState({
                dsqOrbitPlaying: val,
              })
            }}
            wrapperProps={{
              className: "orbit",
            }}
            onComplete={() => {
              setLocalState({
                dsqRetailView: !dsqRetailView,
                mapCategory: dsqOrbitDir ? "retail" : null,
              })
              if (dsqOrbitDir) {
                setLocalState({
                  mapCategory: "retail",
                })
              }
            }}
            lockWhilePLaying
          />
        </MapsWrapper>
        <SidePanelWrapper ref={sidePanelRef}>
          <SideBar copy={copy} ref={copyRef} />
        </SidePanelWrapper>
      </DSQWrapper>
      <Prompt>Double click/tap to {showUI ? "hide" : "show"} overlay</Prompt>
      <VideoElement
        src="/assets/videos/aerial.mp4"
        onEnded={() => {
          setVideoPlayed(true)
        }}
        muted
        playsInline
        loop={false}
        ref={videoRef}
      />
      {/* {videoPlayed && (
        <Fragment>
          <Clouds
            position="top"
            amount={15}
            className="mapUI clouds"
            style={{ opacity: showUI ? 1 : 0 }}
          />
          <Clouds
            position="bottom"
            amount={15}
            className="mapUI clouds"
            style={{ opacity: showUI ? 1 : 0 }}
          />
        </Fragment>
      )} */}
    </PageWrapper>
  )
}

export default DSQMap

const DSQWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--white);

  .mapWrapper {
    width: 100%;
    height: 100%;
  }

  .dsq-blur {
    transition: opacity 0.4s ease-in-out;
    -webkit-filter: blur(5px);
    filter: blur(5px);
  }

  .icon-square,
  .secondary-icon {
    cursor: pointer;
  }

  .show {
    opacity: 1;
  }

  .hide {
    opacity: 0;
  }

  .dsq-mask {
    transition: opacity 0.4s ease-in-out;
    opacity: 0;
    -webkit-filter: blur(20px);
    filter: blur(20px);
  }

  .close-btn-dsq {
    position: absolute;
    z-index: 10;
    right: var(--margin);
    top: var(--margin);
  }

  .mapUI {
    transition: opacity 0.4s ease-in-out;
  }
`

const MapsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .orbit {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;

    & video {
      object-fit: cover;
    }
  }
`

const SidePanelWrapper = styled.div`
  position: absolute;
  z-index: 10;
  width: 26vw;
  height: 100%;
  top: 0;
  right: 0;
  overflow: hidden scroll;
  background-color: var(--light-grey);
  padding-top: 4vw;
  transform: translateX(100%);
`

const VideoElement = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  pointer-events: none;
`

const Prompt = styled.div`
  position: absolute;
  left: calc(50% - 13vw);
  color: var(--white);
  bottom: 0.5%;
  transform: translateX(-50%);
  font-family: "BeausiteGrandTrial-Light";
  font-size: 0.8rem;
  letter-spacing: 1px;
  mix-blend-mode: exclusion;
`
