import { signInWithEmailAndPassword } from "firebase/auth"
import styled from "styled-components"
import * as Form from "@radix-ui/react-form"
import { auth } from "../../App"
import { Button } from "@components/buttons/Button"
import { useStore } from "@state/store"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import splashBg from "@assets/images/splash.svg"

const initialValues = {
  email: "diriyah@vmistudio.com",
  password: "",
}

export function Login() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const { setCurrentUser } = useStore((state) => ({
    setCurrentUser: state.setCurrentUser,
  }))
  const [formValues, setFormValues] = useState(initialValues)

  async function firebaseLogin(email: string, password: string) {
    setLoading(true)
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      )

      // return userCredential?.user
      setCurrentUser(userCredential.user)
      setLoading(false)
      navigate("/")
    } catch (error) {
      setLoading(false)
      if (error.code === "auth/invalid-credential") {
        setErrorMessage("Invalid credentials.")
      }
      console.error("Login error:", error)
    }
  }

  return (
    <LoginWrapper style={{ backgroundImage: `url(${splashBg})` }}>
      {loading ? (
        <div
          style={{
            color: "#ffffff",
            fontSize: "1.5em",
            fontWeight: 700,
          }}
        >
          Logging you in...
        </div>
      ) : (
        <Form.Root
          className="FormRoot"
          onSubmit={async () =>
              firebaseLogin(formValues.email, formValues.password)
          }
        >
          <Form.Field className="FormField" name="password">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Form.Label className="FormLabel">PIN</Form.Label>
              <Form.Message className="FormMessage" match="valueMissing">
                Please enter your pin
              </Form.Message>
              <div className="FormMessage">{errorMessage}</div>
            </div>
            <Form.Control asChild>
              <input
                value={formValues.password}
                onChange={(event) => {
                  setErrorMessage("")
                  setFormValues({ ...formValues, password: event.target.value })
                }}
                className="password-input"
                type="password"
                required
              />
            </Form.Control>
          </Form.Field>
          <Form.Submit asChild type="submit">
            <Button
              title="Enter"
              color="brown"
              variant="rectangle"
              style={{ marginTop: "1rem", width: "100%" }}
              onClick={async () =>
                firebaseLogin(formValues.email, formValues.password)
              }
            ></Button>
          </Form.Submit>
        </Form.Root>
      )}
    </LoginWrapper>
  )
}

const LoginWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    input {
      background: rgba(223, 223, 223, 1);
      color: #000000;
      width: 15em;
      height: 4em;
      border: none;
      padding: 10px;
      border-radius: 4px;
    }

    .FormLabel {
      font-size: 1.5em;
      margin-bottom: 0.5em;
      color: #ffffff;
      font-weight: 700;
    }

    .FormMessage {
      font-size: 1em;
      color: #ff7e7e;
      font-weight: 400;
      margin: 0.5em 0;
    }
  }
`
