import SplashPage from "@pages/splash"
import TeamPage from "@pages/team"
import MetricsPage from "@pages/metrics"
import FavoritesPage from "@pages/favorites"
import GalleryPage from "@pages/gallery"
import VisionPage from "@pages/vision"
import UnitFinderMap from "@components/UnitFinderMap"
import DSQMap from "@pages/dsq/DSQMap"
import Features from "@pages/features/Features"
import Masterplan from "@pages/masterplan"
import DownloadPage from "@pages/donwload"

export const routes = [
  {
    path: "/",
    element: SplashPage,
  },
  {
    path: "/vision",
    element: VisionPage,
  },
  {
    path: "/masterplan",
    element: Masterplan,
  },
  {
    path: "/diriyah-square",
    element: DSQMap,
  },
  {
    path: "/unit-finder",
    element: UnitFinderMap,
  },
  {
    path: "/features",
    element: Features,
  },
  {
    path: "/gallery",
    element: GalleryPage,
  },
  {
    path: "/favorites",
    element: FavoritesPage,
  },
  {
    path: "/metrics",
    element: MetricsPage,
  },
  {
    path: "/team",
    element: TeamPage,
  },
  {
    path: "/download",
    element: DownloadPage,
  }
]
