const baseAttributes = {
  fillOpacity: "0.8",
  strokeWidth: "2",
  stroke: "none",
  className: "masterplan-blur"
}

const data = [
  {
    type: "polygon",
    fill: "#145959",
    coords:
      "1123.21 1164.08 1124.55 1179.74 1124.66 1192.05 1123.72 1208.86 1122.33 1221.44 1120.19 1232.56 1119.02 1244.25 1117.77 1254.9 1116.31 1267.42 1115.47 1279.89 1113.01 1294.46 1109.39 1305.65 1105.57 1317.51 1103.5 1325.61 1100.35 1336.6 1097.53 1344.3 1093.72 1353.28 1090.03 1362.86 1085.27 1373.71 1080.72 1383.76 1076.03 1394.08 1073.49 1398.83 1065.04 1392.2 1057.81 1386.24 1046.29 1375.18 1000.5 1329.17 997.42 1329.17 995.03 1329.73 992.67 1329.83 988.42 1325 990.92 1320.42 994.08 1315.75 995.75 1311.67 988.42 1311.42 984.83 1314 981 1314.08 976.25 1314 971.67 1313.92 970.75 1314.75 965 1314.83 959.25 1313.08 953.5 1312.42 951.67 1314.25 943.92 1314.33 942.92 1312.75 940.33 1312.58 937.25 1311.25 934.67 1311.33 931 1317.67 914.83 1316.83 914 1322.92 911 1326.92 908.42 1329.75 896.08 1326.17 892.17 1315.17 889.25 1315.75 880.83 1305.75 880.83 1303 883.67 1300 865.5 1280.5 860.83 1284.75 852.78 1279.89 862 1272.99 866.82 1269.49 877.74 1258.6 887.34 1249.42 893.14 1241.51 897.82 1235.48 906.59 1224.41 915.22 1209.71 920.84 1201.6 926.49 1188.35 932.42 1176.04 933.32 1169.59 933.53 1162 933.81 1159.47 939.74 1148.47 941.54 1142.68 942.79 1138.14 944.98 1133.98 946.05 1126.63 948.79 1121.84 954.17 1116.47 968.9 1115.01 976.91 1113.73 981.28 1114.66 982.95 1115.18 984.92 1118.65 988.01 1119.8 988.84 1121.84 989.08 1124.55 988.53 1125.66 989.22 1127.01 991.23 1129.44 999.07 1129.19 1002.26 1124.93 1007.13 1117.69 1023.03 1120.32 1010.17 1169.51 1010.17 1175.87 1014.68 1181.04 1017.59 1181.57 1019.18 1180.38 1044.11 1166.86 1048.88 1167.92 1054.98 1176.4 1055.38 1180.78 1060.42 1180.38 1067.31 1191.78 1069.97 1191.52 1075.8 1196.69 1075.8 1199.61 1079.78 1202.39 1082.83 1194.17 1086.54 1191.92 1096.48 1168.58 1106.16 1162.88 1123.21 1164.08",
    id: "diriyah-south",
  },
  {
    type: "polygon",
    fill: "#9f8856",
    coords:
      "992.67 1329.83 989.87 1336.16 987.33 1341.46 986.67 1343.37 988.9 1345.73 994.33 1346.07 997.18 1347.92 996.39 1360.28 999.61 1361.5 1001.78 1372.22 1002.28 1376.22 1005.56 1381.72 1006.61 1387.33 1011.56 1396.11 1015.17 1402.28 1020.89 1411 1025.33 1413.94 1029.39 1418 1027.78 1421.89 1024.44 1421.89 1019.94 1420.94 1017.17 1422.89 1016.83 1428.11 1014.5 1428.72 1004.89 1433.94 1016.56 1445.78 1016.28 1448.44 1015.89 1450.44 1019.06 1456.83 1019.78 1460.61 1019.72 1463 1014.44 1467.33 987.38 1466.67 960.68 1460.51 951.84 1458.37 945.88 1457.84 936.22 1454.12 928.9 1453.93 919.78 1451.91 914.06 1448.78 904.44 1445.21 898.52 1443.44 888.12 1440.77 883.15 1439.47 873.72 1434.29 865.34 1429.29 854.94 1423.08 851.12 1420.91 845.85 1416.41 841.29 1412.13 836.94 1411.78 833.96 1407.59 833.62 1401.82 826.89 1395.77 822.82 1393.85 817.42 1390.21 806.68 1389.66 783.65 1382.98 774.99 1376.59 769.31 1369.76 764.16 1366.81 760.84 1365.85 758.67 1363.65 758.67 1361.57 761 1358.96 764.47 1354.62 764.81 1349.84 769.13 1336.77 778.44 1329.01 785.27 1323.27 794.89 1319.52 797.49 1318.89 809.04 1310.14 817.95 1303.5 822.32 1301.14 835.76 1291.65 838.31 1290.68 845.23 1285.97 847.56 1283.36 852.78 1279.89 860.83 1284.75 865.5 1280.5 883.67 1300 880.83 1303 880.83 1305.75 889.25 1315.75 892.17 1315.17 896.08 1326.17 908.42 1329.75 911 1326.92 914 1322.92 914.83 1316.83 931 1317.67 934.67 1311.33 937.25 1311.25 940.33 1312.58 942.92 1312.75 943.92 1314.33 951.67 1314.25 953.5 1312.42 959.25 1313.08 965 1314.83 970.75 1314.75 971.67 1313.92 984.83 1314 988.42 1311.42 995.75 1311.67 994.08 1315.75 990.92 1320.42 988.42 1325 992.67 1329.83",
    id: "diriyah-gardens",
  },
  {
    type: "path",
    fill: "#9f8856",
    coords:
      "m1291.93,780.5l-16.63-10.58-18.31-18.42-10.69-12.17,16.62-15.03,9.21-5.72,8.36-3.39,11.44-3.28h26.24s10.69,4.34,11.01,4.45,15.24,7.94,15.24,7.94l9.53,7.73,16.83,13.34,7.83,8.47,8.04,11.01,9.42,13.34,11.11,15.14,7.73,11.85,11.01,12.81,8.15,9.63,9.1,10.16s8.04,11.22,8.57,11.75,12.07,10.69,12.07,10.69l13.76,11.11,12.49,9.95,10.58,9,15.24,11.43,12.49,9.1s9.74,8.79,10.37,9,10.06,9.1,10.06,9.1l2.75,3.7-.85,4.76-3.49,4.23-14.08,7.68-23.6,8.62-36.09,2.86-43.29,2.75h-50.7l-54.62,1.16-34.29,2.54-27.41,2.33-23.6.21-15.56-.42-12.49.42s-3.07-4.45-3.07-4.87v-29.95l3.81-22.02,6.35-20,8.79-17.25,11.19-20.22,10.62-13.76,11.54-14.29,12.28-14.4,8.15-12.28,6.46-9.31,4.35-4.13v-6.78Z",
    id: "creative-district",
  },
  {
    type: "polygon",
    fill: "#b2c6c6",
    coords:
      "1026.34 958.82 1020.69 956.92 1017.59 957.24 1013.13 955.09 1007.39 953.45 1001.81 952.58 993.67 950.92 987.33 947.83 978.67 945.75 971.17 942.08 966.58 939.42 966.58 936.33 973.83 931.92 983.25 921.08 996.46 909.33 1007.92 899.33 1017 891.75 1021.25 884.75 1021.33 878.42 1030.42 869.17 1039.83 860.75 1043.67 856.08 1049.92 848.33 1054.83 843.58 1060.25 833.75 1066.83 822 1069.08 814.33 1070.75 808.67 1074.83 803.5 1076.67 801 1100.67 811.5 1105.67 811.25 1120 816.42 1123.08 819.92 1134.75 824.83 1137.92 824.33 1151.83 829.58 1154.25 833.42 1155.83 837.42 1157.5 840.75 1150.67 858.17 1146.5 871.58 1142.92 880.92 1139.92 892.5 1137.33 903.63 1135.08 914 1132.83 923.42 1130 931.67 1130.5 940.08 1129.58 950.42 1128.5 960.25 1127.92 967.83 1128.42 972.75 1125 975.5 1122.08 978.33 1121 979.72 1118.87 979.68 1118.87 981.85 1105.28 981.97 1105.28 980.14 1098.88 980.25 1098.88 982.88 1086.89 982.88 1086.89 981.28 1076.72 981.28 1071.46 977.97 1073.63 974.54 1073.63 960.26 1067.12 960.38 1050.33 949.3 1040.85 963.01 1030.85 955.38 1026.34 958.82",
    id: "qurain-cultural-district",
  },
  {
    type: "path",
    fill: "#55697c",
    coords:
      "m1157.5,840.75l-3.25-7.33-2.42-3.83-13.92-5.25-3.17.5-11.67-4.92-3.08-3.5-14.33-5.17-5,.25-24-10.5v-9.56l2.25-10,2.83-7.67,1.42-14.42,1.83-16.33-.67-10.86,2-6.5-4.67-16.33-.5-15.17-.33-8.17,7.33-10.83,4.83-10.17,10-2.83,7-1.5,10.67-8.17,5.17-6.17,12.67-2.17,13.5,2,6.67,1,2.83,4.33,5.33,3.17,6.33-1,4.5.5,5.17,4.17,4.17,16.5,7.67,15.67,5.67,8.67s4.17,9.5,4.67,9.83,9,13.83,9,13.83l2.33,8v6.17l-1,8.33-4.33,7-7.83,8.33-9,11.5-4.5,6-6.17,9.83-4.17,6.33-4.17,8.33-3.67,7.17-3.83,9.17-5.17,9.33-3,7.67-2,4.75Z",
    id: "king-salman-knowledge-district",
  },
  {
    type: "path",
    fill: "#926542",
    coords:
      "m994.69,584.79l11.28-3.7,29.98-8.93,23.74-6.4,19.2-3.54,21.56-7.41,32.67-12.63,5.05,2.19,4.55,4.21,6.06,21.36,3.2,7.44-.84,9.6-5.39,10.1-14.92,11.45-13.88,5.64h-13.14l-9.09,3.62-12.63,7.75-10.78,3.7-15.83,12.13-3.87-4.38-7.07-8.42-8.25-7.07-11.29-13.64-6.06-7.75-10.27-6.9-10.1-1.52-5.39-4.72,1.52-2.19Zm155.61-39.07l1.68,7.07,6.74,19.7,5.22,21.22,5.39,13.81,5.56,2.19,6.4,1.52,5.39,5.47,1.68,6.32,2.36,9.09-.67,9.26-6.23,9.94,2.86,5.56,12.29,20.71,7.58,15.83,11.62,16.67,12.46,12.46,3.2-3.54,7.41-11.96,8.25-13.14,8.42-16.5,6.9-14.15,3.87-13.14,1.18-12.46,1.85-15.83-1.85-19.53-2.86-20.71-3.03-23.58-16-36.04-10.78-11.11-17.51,6.06-24.25,7.07-23.91,8.76-19.53,6.9-1.68,6.06Z",
    id: "events-district",
  },
  {
    type: "polygon",
    fill: "#9f8856",
    coords:
      "1050.32 568.29 1042.77 555.51 1030.23 550.05 1020.77 542.18 1011.32 540.97 998.24 546.24 996.06 550.96 988.6 551.51 983.7 550.42 977.34 546.24 968.79 542.18 956.98 542.18 951.53 536.79 945.89 516.98 942.8 506.8 932.99 499.35 924.63 493.35 914.63 485.53 908.09 483.9 898.27 483.9 896.64 488.8 891.55 493.89 888.1 499.89 882.28 501.16 868.83 498.98 858.65 494.26 852.84 491.53 850.65 487.17 848.66 481.53 844.11 480.81 836.84 486.99 832.48 495.53 827.21 505.16 820.48 507.89 813.76 519.16 832.66 539.69 839.75 549.51 837.93 558.6 829.21 566.59 820.85 568.29 809.76 562.05 802.85 550.05 801.95 543.88 797.22 544.97 788.31 549.51 775.41 557.32 771.05 561.69 746.33 572.67 749 591.78 739.33 610.67 725.67 618.33 722.67 635.33 754.67 644.17 776.33 652.33 791.33 658.33 819.33 657.33 846.33 658.33 861.67 658.33 882.67 664.33 911.67 672 935.67 685.33 954 699.67 987.33 728.33 1001.33 746.55 1024 770 1037 795 1040.67 813.33 1051.67 821.33 1065.33 812.33 1070.75 808.67 1076.67 801 1076.67 791.44 1081.75 773.77 1085 743.02 1084.33 732.17 1086.33 725.67 1081.67 709.33 1080.83 686 1088.17 675.17 1093 665 1092.51 660.01 1088.56 657.67 1083.22 647.22 1067.89 648.33 1054.78 647.67 1055.49 641.37 1044.54 628.57 1036.29 621.5 1018.94 600.11 1008.67 593.21 998.57 591.69 993.18 586.98 994.69 584.79 1035.95 572.16 1050.32 568.29",
    id: "bujairi",
  },
  {
    type: "path",
    fill: "#255044",
    coords:
      "m588.48,468.23c.79,0,9.97,6.17,9.97,6.17l9.33,3.64,12.5,7.59,4.9,4.27,10.92,7.12,4.43,5.54,8.39.95,8.07.95,5.54,6.17,7.75,3.01,12.71,4.75,12.76,5.7,7.12,5.54,15.66,5.06,9.02,4.27,12.5,7.12,2.85,6.96v9.02l3.16,7.59.26,3.02,24.71-10.98,4.36-4.36,12.9-7.82,8.91-4.54,4.73-1.09.91,6.18,6.91,12,11.09,6.24,8.36-1.69,8.72-8,1.82-9.09-7.09-9.81-18.9-20.54,2.39-4-8.15-5.16-11-8.5-14.83-10.5s-16.83-7-17.33-7-11.83-1.33-12.33-1.5-21.17.17-21.17.17l-16-1.17-24.5-.69-22.33-4.14-12.67-8.44-12.83-9.06-7.33-3.17-10.33-.83h-17.5l-11.5,4.73-5.67,3.61-2.18,4.73Z",
    id: "samhan",
  },
  {
    type: "polygon",
    fill: "#926542",
    coords:
      "1208.41 978.43 1253.37 978.43 1291.76 977.05 1324.2 977.05 1360.3 974.08 1397.31 973.17 1428.15 969.76 1463.1 968.83 1505.14 963.12 1525.25 959.46 1545.58 951.47 1559.06 944.38 1565.68 942.33 1572.77 944.61 1583.73 954.89 1603.15 973.4 1618 986.19 1628.51 994.19 1644.5 1003.78 1664.38 1021.15 1685.63 1036.23 1703.68 1050.62 1703.68 1067.07 1698.19 1094.03 1697.51 1113.68 1696.59 1118.93 1664.61 1123.04 1627.83 1123.04 1605.67 1123.04 1600.64 1118.47 1605.67 1101.8 1614.58 1088.77 1620.29 1076.89 1615.49 1066.38 1610.69 1063.41 1604.75 1073.69 1591.96 1086.49 1583.5 1100.2 1573.22 1108.19 1569.57 1123.96 1556.32 1128.07 1529.82 1129.44 1505.83 1133.1 1425.86 1137.89 1292.67 1144.75 1273.02 1144.75 1256.12 1140.63 1239.67 1133.78 1224.82 1121.44 1208.41 1110.93 1194.2 1096.31 1188.49 1090.83 1183.92 1085.8 1188.26 1075.84 1193.52 1069.13 1202.43 1057.02 1207.23 1043.54 1206.31 1029.14 1205.4 1024 1205.17 1013.38 1202.2 1006.53 1201.06 997.16 1204.48 981.85 1208.41 978.43",
    id: "boulevard-district",
  },
  {
    type: "path",
    fill: "#b2c6c6",
    coords:
      "m1260.96,1148.5v6.25l-4.52,7.11-8.4,2.58-10.77-4.95-12.49-1.51-1.94,9.26,3.23,5.6-4.95,9.48-6.25,11.42v13.35h-7.11l-5.82,5.82,4.31,9.48,2.8,6.46,2.8,5.17,6.25,2.8h9.05s4.74-3.23,4.31-2.58,1.29,7.11,1.29,7.11l4.52,4.95,8.18,2.58,3.66,2.15,9.69.22s3.88,2.37,4.52,2.8,4.52,3.66,4.52,3.66c0,0,2.58,3.88,2.58,4.52v8.18h-9.91s-10.34.65-11.2,0-3.02-1.29-6.03-1.29-11.2-1.72-11.85-1.94-4.31-.86-6.03-.86-5.82.22-5.82.22l2.58,3.02,10.12,4.52,9.48,3.66,7.11,5.82,1.94,3.23-.86,8.4-11.85-1.08,11.2,6.03,14,9.05,7.11,6.46,2.15,5.17,1.29,6.03-4.09,1.94-10.55-4.74-8.18-4.31-10.34-2.8-27.35,2.8-18.52,5.82-10.91,4.09-2.23,5.82v7.32l33.17-1.08,7.54,1.08,5.38,5.38,14,5.82,6.03,7.97,13.14,6.25,7.32,11.2-1.72,7.11-3.88,3.45-4.74,5.6,1.08,7.11-2.37,6.03-2.76,4.3-5.78,9.78-11.11,13.33-47.11,12-16,3.56-23.11,8.44h-20.89l-17.78,2.22-5.33-17.78,3.11-15.56v-12.44l-3.11-10.67,6.22-17.78,4.89-19.56,13.78-37.33,8-25.8,4.89-31.97,2.22-23.11.44-21.33,2.67-20.44-1.33-19.56,3.11-21.33,7.11-30.67,7.56-29.78,7.56-13.33,10.13-8.14,24.07,25.04,16.99,10.89,14.56,12.14,16.44,6.94,5.13,1.18-.25,6.58Z",
    id: "media-district",
  },
  {
    type: "polygon",
    fill: "#255044",
    coords:
      "1240.2 754.8 1246.41 754.8 1268.8 772.34 1279.12 783.4 1282.56 788.8 1268.8 807.41 1253.69 824.95 1243.44 840.84 1232.11 858.14 1222.4 872.71 1218.62 882.15 1212.41 893.63 1208.41 903.63 1207.02 918.04 1204.05 935.58 1202.43 957.7 1202.43 967.14 1201.08 972 1189.75 974.97 1170.59 973.89 1163.85 971.46 1160.34 951.49 1160.34 930.99 1163.31 911.02 1166.54 891.94 1172.21 869.2 1179.5 845.19 1186.78 829 1194.07 814.97 1199.19 806.06 1211.01 790.95 1218.89 779.35 1226.98 768.83 1234.27 760.74 1240.2 754.8",
    id: "civic-quarter",
  },
  {
    type: "polygon",
    fill: "#b2c6c6",
    coords:
      "1487.74 567.46 1514.12 620.92 1503.5 629.49 1541.54 707.28 1474.37 747.38 1505.21 802.21 1521.32 796.38 1586.44 857.39 1605.63 837.85 1648.46 872.12 1636.13 891.66 1681.36 933.12 1701.93 950.94 1713.58 950.94 1722.49 950.94 1732.08 949.23 1738.25 942.33 1788.29 885.49 1835.58 924.56 1845.18 930.72 1859.23 942.33 1865.74 946.15 1874.31 947.17 1915.09 945.12 1922.63 955.4 1924 1002.01 1922.29 1028.05 1919.2 1048.96 1916.8 1083.57 1916.8 1109.62 1919.89 1131.55 1926.74 1157.94 1934.62 1171.3 1944.9 1186.04 1968.21 1204.55 1981.23 1216.2 1987.4 1224.42 1982.6 1234.02 1962.38 1230.59 1914.06 1191.18 1581.64 920.44 1443.8 798.44 1403.77 744.64 1394.52 716.19 1392.81 689.12 1396.23 666.5 1404.8 641.37 1418.17 620.23 1433.93 604.13 1452.44 588.36 1473.69 574.65 1487.74 567.46",
    id: "KSU-district",
  },
  {
    type: "path",
    fill: "#55697c",
    coords:
      "m1528.97,1138.92h37.84l-1.58,11.35-3.15,5.99,2.52,8.51v8.51l3.47,5.99v8.83l-2.21,8.51-2.21,3.15-5.68,1.58v5.99l2.21,6.31-2.84,5.99-2.84,7.25-.95,16.71.95,5.68-1.89,6.94-4.1,4.41-5.99,5.68,9.78,11.35,2.52,5.05.95,7.57-7.25,2.84-9.78,1.58-5.36,5.05,9.46,6.94,5.68,4.41,9.78,9.46.95,9.15-1.58,10.09-9.15,6.31-30.27,1.58-13.88-10.41-19.24-20.18,12.93-13.88,13.88-12.93,14.66-14.19,6.78-10.72,2.52-10.09,1.58-11.98-.95-11.98-.63-8.51-1.58-9.15-6.62-68.91,1.26-5.83Zm91.03,148.08l-1.67-9.67,4.67-10.67,7.33-11,10.67-16,9.67-12.33,8.33,3,3.33,7-2.33,9.33-10.33,16.33-2.33,16,.67,20.67,5.67,6.67,9.33,12.33,8.67,9v5l-2.67,9.33-2,13,6.67,15,12.33,18.67,13.33,10.67,25.33,15,11.33,9,8.67,3.33,5.67,10,4.33,10.67,14.33,26,11,20.67,5,13.33,13.67,26.33,35.67-45.67-16.67-15.67,68-88.67,14.67,9.33,24.67-32-7.33-7.33,42.33-50.33-20.67-16.67,10.33-9.67,11.67-17.33-38-33.33-42.33-29.67-82.09-70.99-86.09-67.17v12.93l-4.41,10.41-4.73,12.3v22.71l-2.53,10.09-23.34,1.26-15.45,2.21-11.67,3.15h-44.15l-11.98,3.15-2.84,5.05v10.09l5.05,5.68,2.84,5.68,1.26,7.25,5.36,4.1,1.89,4.73.63,8.2-1.26,9.78-1.89,5.36-.32,5.68-3.15,5.99-5.36,11.35,2.52,8.38,2.52,11.17,4.1,8.2-1.58,10.41v14.19l6.31,13.24,9.36-4.23Z",
    id: "khuzama-east",
  },
  {
    type: "polygon",
    fill: "#145959",
    coords:
      "1520.85 1138.92 1526.6 1186.08 1530.49 1204.94 1530.49 1219.19 1530.49 1234.02 1526.6 1260.67 1520.85 1269.05 1515.82 1279.89 1509.54 1287.06 1499.9 1297.96 1491.52 1305.92 1485.13 1313.88 1480.21 1317.23 1490.26 1331.48 1505.35 1344.05 1490.26 1347.68 1460.93 1347.68 1427.83 1352.43 1411.07 1352.43 1388.44 1362.49 1372.1 1371.28 1356.6 1360.39 1345.29 1347.68 1348.64 1332.32 1351.57 1322.26 1357.86 1313.88 1354.92 1303.41 1349.9 1299.63 1339.42 1302.99 1332.72 1306.76 1325.17 1300.05 1319.31 1293.77 1316.79 1287.48 1320.57 1277.85 1320.15 1269.47 1316.79 1261.09 1319.31 1252.29 1332.3 1239.3 1342.35 1223.38 1348.22 1212.39 1345.71 1197.82 1341.93 1189.44 1333.14 1188.18 1324.34 1193.21 1312.6 1205.36 1305.48 1212.39 1297.52 1223.38 1290.82 1227.57 1282.44 1234.02 1282.44 1241.39 1264.42 1246.84 1252.27 1240.55 1240.95 1234.02 1238.86 1228.4 1235.93 1216.25 1235.93 1202.39 1235.93 1191.53 1242.21 1180.22 1250.59 1173.1 1257.71 1166.39 1262.32 1162.62 1264 1155.5 1267.35 1150.47 1339.84 1148.79 1413.41 1146.28 1482.72 1142.51 1520.85 1138.92",
    id: "khuzama-west",
  },
  {
    type: "polygon",
    fill: "#145959",
    coords:
      "1264.57 493.19 1369.98 445.57 1418.33 546.99 1406.7 549.17 1406.7 567.46 1400.15 571.7 1371.07 564.07 1338.72 555.71 1324.18 552.44 1310.01 544.8 1295.83 537.53 1286.02 530.99 1279.12 523.36 1270.02 512.82 1264.57 500.82 1264.57 493.19",
    id: "gateway",
  },
  {
    type: "polygon",
    fill: "#55697c",
    coords:
      "1040.85 963.01 1050.33 949.3 1067.12 960.38 1073.63 960.26 1073.63 974.54 1071.46 977.97 1076.72 981.28 1086.89 981.28 1086.89 982.88 1098.88 982.88 1098.88 980.25 1105.28 980.14 1105.28 981.97 1118.87 981.85 1118.87 979.68 1124.92 979.8 1129.42 982.88 1177.4 980.66 1184.89 980.66 1184.89 987.56 1182.27 991.56 1181.81 1075.86 1171.99 1088.2 1161.49 1083.44 1125.87 1085.08 1123.21 1164.08 1113.63 1163.34 1106.16 1162.88 1096.48 1168.58 1086.54 1191.92 1082.83 1194.17 1079.78 1202.39 1075.8 1199.61 1075.8 1196.69 1069.97 1191.52 1067.31 1191.78 1060.42 1180.38 1055.38 1180.78 1054.98 1176.4 1048.88 1167.92 1044.11 1166.86 1019.18 1180.38 1017.59 1181.57 1014.68 1181.04 1010.17 1175.87 1010.17 1169.51 1023.03 1120.32 1006.99 1117.67 986.17 1085.84 976.22 1081.47 968.67 1069.01 964.29 1065.96 956.07 1069.27 955.41 1064.1 956.87 1050.18 951.7 1034.13 954.08 995.82 956.87 990.25 957.53 982.69 960.31 977.78 980.87 968.64 1003.01 970.76 1011.76 970.89 1018.65 965.85 1024 965.32 1027.27 961.83 1026.34 958.82 1030.85 955.38 1040.85 963.01",
    id: "diriyah-square",
  }
]

const masterplanZoneOverlayData = data.map((obj) => {
  return obj.id === "diriyah-square"
    ? {
        ...obj,
        attr: {
          fillOpacity: "0.8",
          strokeWidth: "2",
          stroke: "white",
        },
      }
    : {
        ...obj,
        attr: {
          ...baseAttributes,
        },
      }
})

export default masterplanZoneOverlayData

const maskData = [
  {
    type: "path",
    stroke: null,
    fill: null,
    coords:
      "m0,0v2048h2048V0H0Zm1568.07,1188.11l-2.21,8.51-2.21,3.15-5.68,1.58v5.99l2.21,6.31-2.84,5.99-2.84,7.25-.95,16.71.95,5.68-1.89,6.94-4.1,4.41-5.99,5.68,9.78,11.35,2.52,5.05.95,7.57-7.25,2.84-9.78,1.58-5.36,5.05,9.46,6.94,5.68,4.42,9.78,9.46.95,9.15-1.58,10.09-9.15,6.31-30.27,1.58-13.88-10.41-19.24-20.18,12.93-13.88,13.88-12.93,14.66-14.19,6.78-10.72,2.52-10.09,1.58-11.98-.95-11.98-.63-8.51-1.58-9.15-6.62-68.91,1.26-5.83h37.84l-1.58,11.35-3.15,5.99,2.52,8.51v8.51l3.47,5.99v8.83Zm391.93,115.22l-42.33,50.33,7.33,7.33-24.67,32-14.67-9.33-68,88.67,16.67,15.67-35.67,45.67-13.67-26.33-5-13.33-11-20.67-14.33-26-4.33-10.67-5.67-10-8.67-3.33-11.33-9-25.33-15-13.33-10.67-12.33-18.67-6.67-15,2-13,2.67-9.33v-5l-8.67-9-9.33-12.33-5.67-6.67-.67-20.67,2.33-16,10.33-16.33,2.33-9.33-3.33-7-8.33-3-9.67,12.33-10.67,16-7.33,11-4.67,10.67,1.67,9.67-9.36,4.23-6.31-13.24v-14.19l1.58-10.41-4.1-8.2-2.52-11.17-2.52-8.38,5.36-11.35,3.15-5.99.32-5.68,1.89-5.36,1.26-9.78-.63-8.2-1.89-4.73-5.36-4.1-1.26-7.25-2.84-5.68-5.05-5.68v-10.09l2.84-5.05,11.98-3.15h44.15l11.67-3.15,15.45-2.21,23.34-1.26,2.53-10.09v-22.71l4.73-12.3,4.42-10.41v-12.93l86.09,67.17,82.09,70.99,42.33,29.67,38,33.33-11.67,17.33-10.33,9.67,20.67,16.67Z",
    id: "khuzama-east",
  },
  {
    type: "path",
    stroke: null,
    fill: null,
    coords:
      "m0,0v2048h2048V0H0Zm1530.49,1219.19v14.83l-3.88,26.65-5.75,8.38-5.03,10.84-6.29,7.18-9.64,10.89-8.38,7.96-6.39,7.96-4.93,3.35,10.06,14.25,15.08,12.57-15.08,3.63h-29.33l-33.1,4.75h-16.76l-22.63,10.06-16.34,8.8-15.5-10.89-11.31-12.71,3.35-15.36,2.93-10.06,6.29-8.38-2.93-10.48-5.03-3.77-10.48,3.35-6.7,3.77-7.54-6.7-5.87-6.29-2.51-6.29,3.77-9.64-.42-8.38-3.35-8.38,2.51-8.8,12.99-12.99,10.06-15.92,5.87-10.98-2.51-14.57-3.77-8.38-8.8-1.26-8.8,5.03-11.73,12.15-7.12,7.03-7.96,10.98-6.7,4.19-8.38,6.45v7.37l-18.02,5.45-12.15-6.29-11.31-6.54-2.1-5.61-2.93-12.15v-24.72l6.29-11.31,8.38-7.12,7.12-6.7,4.61-3.77,1.68-7.12,3.35-5.03,72.49-1.68,73.57-2.51,69.31-3.77,38.13-3.59,5.75,47.17,3.88,18.86v14.25Z",
    id: "khuzama-west",
  },
  {
    type: "path",
    stroke: null,
    fill: null,
    coords:
      "m0,0v2048h2048V0H0Zm1406.7,549.17v18.29l-6.55,4.25-29.08-7.63-32.35-8.36-14.54-3.27-14.18-7.63-14.18-7.27-9.81-6.54-6.9-7.63-9.09-10.54-5.45-12v-7.63l105.41-47.62,48.34,101.41-11.63,2.18Z",
    id: "gateway",
  },
  {
    type: "path",
    stroke: null,
    fill: null,
    coords:
      "m0,0v2048h2048V0H0Zm1982.6,1234.02l-20.22-3.43-48.32-39.41-332.42-270.74-137.84-122-40.03-53.8-9.25-28.44-1.71-27.07,3.43-22.62,8.57-25.13,13.37-21.14,15.76-16.11,18.51-15.76,21.25-13.71,14.05-7.2,26.39,53.46-10.62,8.57,38.04,77.79-67.17,40.1,30.84,54.83,16.11-5.83,65.11,61,19.19-19.53,42.84,34.27-12.34,19.53,45.24,41.47,20.56,17.82h20.56l9.6-1.71,6.17-6.9,50.03-56.84,47.29,39.07,9.6,6.17,14.05,11.6,6.51,3.82,8.57,1.03,40.78-2.06,7.54,10.28,1.37,46.61-1.71,26.05-3.08,20.91-2.4,34.61v26.05l3.08,21.93,6.85,26.39,7.88,13.37,10.28,14.74,23.3,18.51,13.02,11.65,6.17,8.22-4.8,9.6Z",
    id: "ksu-district",
  },
  {
    type: "path",
    stroke: null,
    fill: null,
    coords:
      "m0,0v2048h2048V0H0Zm1268.8,807.41l-15.11,17.54-10.25,15.89-11.33,17.3-9.71,14.57-3.78,9.44-6.21,11.48-4.01,10-1.39,14.4-2.97,17.54-1.62,22.12v9.44l-1.35,4.86-11.33,2.97-19.16-1.08-6.75-2.43-3.51-19.97v-20.51l2.97-19.97,3.24-19.08,5.67-22.74,7.28-24.01,7.29-16.19,7.29-14.03,5.13-8.9,11.82-15.11,7.88-11.6,8.09-10.52,7.29-8.09,5.94-5.94h6.21l22.39,17.54,10.31,11.06,3.45,5.4-13.76,18.62Z",
    id: "civic-quarter",
  },
  {
    type: "path",
    stroke: null,
    fill: null,
    coords:
      "m0,0v2048h2048V0H0Zm1269.79,1328.79l-10.55-4.74-8.19-4.31-10.34-2.8-27.35,2.8-18.52,5.82-10.91,4.09-2.23,5.82v7.32l33.17-1.08,7.54,1.08,5.38,5.38,14,5.82,6.03,7.97,13.14,6.25,7.32,11.2-1.72,7.11-3.88,3.45-4.74,5.6,1.08,7.11-2.37,6.03-2.76,4.3-5.78,9.78-11.11,13.33-47.11,12-16,3.56-23.11,8.44h-20.89l-17.78,2.22-5.33-17.78,3.11-15.56v-12.44l-3.11-10.67,6.22-17.78,4.89-19.56,13.78-37.33,8-25.8,4.89-31.97,2.22-23.11.44-21.33,2.67-20.44-1.33-19.56,3.11-21.33,7.11-30.67,7.56-29.78,7.56-13.33,10.13-8.14,24.07,25.04,16.99,10.89,14.56,12.14,16.44,6.94,5.13,1.18-.25,6.58v6.25l-4.52,7.11-8.4,2.58-10.77-4.95-12.49-1.51-1.94,9.26,3.23,5.6-4.95,9.48-6.25,11.42v13.35h-7.11l-5.82,5.82,4.31,9.48,2.8,6.46,2.8,5.17,6.25,2.8h9.05s4.74-3.23,4.31-2.58c-.43.65,1.29,7.11,1.29,7.11l4.52,4.95,8.18,2.58,3.66,2.15,9.69.22s3.88,2.37,4.52,2.8c.65.43,4.52,3.66,4.52,3.66,0,0,2.58,3.88,2.58,4.52v8.18h-9.91s-10.34.65-11.2,0c-.86-.65-3.02-1.29-6.03-1.29s-11.2-1.72-11.85-1.94-4.31-.86-6.03-.86-5.82.22-5.82.22l2.58,3.02,10.12,4.52,9.48,3.66,7.11,5.82,1.94,3.23-.86,8.4-11.85-1.08,11.2,6.03,14,9.05,7.11,6.46,2.15,5.17,1.29,6.03-4.09,1.94Z",
    id: "media-district",
  },
  {
    type: "path",
    stroke: null,
    fill: null,
    coords:
      "m0,0v2048h2048V0H0Zm1703.68,1067.07l-5.48,26.96-.69,19.65-.91,5.25-31.98,4.11h-58.94l-5.03-4.57,5.03-16.68,8.91-13.02,5.71-11.88-4.8-10.51-4.8-2.97-5.94,10.28-12.79,12.79-8.45,13.71-10.28,8-3.66,15.76-13.25,4.11-26.5,1.37-23.99,3.66-79.96,4.8-133.19,6.85h-19.65l-16.91-4.11-16.45-6.85-14.85-12.34-16.41-10.51-14.2-14.62-5.71-5.48-4.57-5.03,4.34-9.96,5.25-6.72,8.91-12.11,4.8-13.48-.91-14.39-.91-5.14-.23-10.62-2.97-6.85-1.14-9.37,3.43-15.31,3.92-3.43h44.97l38.38-1.37h32.44l36.1-2.97,37.01-.91,30.84-3.41,34.95-.93,42.04-5.71,20.1-3.66,20.33-8,13.48-7.08,6.63-2.06,7.08,2.28,10.97,10.28,19.42,18.51,14.85,12.79,10.51,8,15.99,9.6,19.88,17.36,21.25,15.08,18.05,14.39v16.45Z",
    id: "boulevard-district",
  },
  {
    type: "path",
    stroke: null,
    fill: null,
    coords:
      "m0,0v2048h2048V0H0Zm837.93,558.6l-8.72,8-8.36,1.69-11.09-6.24-6.91-12-.91-6.18-4.73,1.09-8.91,4.54-12.9,7.82-4.36,4.36-24.71,10.98-.26-3.02-3.16-7.59v-9.02l-2.85-6.96-12.5-7.12-9.02-4.27-15.66-5.06-7.12-5.54-12.76-5.7-12.71-4.75-7.75-3.01-5.54-6.17-8.07-.95-8.39-.95-4.43-5.54-10.92-7.12-4.9-4.27-12.5-7.59-9.33-3.64s-9.18-6.17-9.97-6.17l2.18-4.73,5.67-3.61,11.5-4.73h17.5l10.33.83,7.33,3.17,12.83,9.06,12.67,8.44,22.33,4.14,24.5.69,16,1.17s20.67-.33,21.17-.17c.5.17,11.83,1.5,12.33,1.5s17.33,7,17.33,7l14.83,10.5,11,8.5,8.15,5.16-2.39,4,18.9,20.54,7.09,9.81-1.82,9.09Z",
    id: "samhan",
  },
  {
    type: "path",
    stroke: null,
    fill: null,
    coords:
      "m0,0v2048h2048V0H0Zm1088.17,675.17l-7.33,10.83.83,23.33,4.67,16.33-2,6.5.67,10.86-3.25,30.75-5.08,17.67v9.56l-5.92,7.67-5.42,3.67-13.67,9-11-8-3.67-18.33-13-25-22.67-23.45-14-18.22-33.33-28.67-18.33-14.33-24-13.33-29-7.67-21-6h-15.33l-27-1-28,1-15-6-21.67-8.17-32-8.83,3-17,13.67-7.67,9.67-18.89-2.67-19.11,24.71-10.98,4.36-4.36,12.9-7.82,8.91-4.54,4.73-1.09.91,6.18,6.91,12,11.09,6.24,8.36-1.69,8.72-8,1.82-9.09-7.09-9.81-18.9-20.54,6.72-11.27,6.72-2.73,5.27-9.63,4.36-8.54,7.27-6.18,4.54.73,2,5.63,2.18,4.36,5.82,2.73,10.18,4.73,13.45,2.18,5.82-1.27,3.45-6,5.09-5.09,1.64-4.91h9.81l6.54,1.64,10,7.82,8.36,6,9.81,7.45,3.09,10.18,5.63,19.81,5.45,5.4h11.81l8.54,4.06,6.36,4.18,4.91,1.09,7.45-.55,2.18-4.73,13.09-5.27,9.45,1.21,9.45,7.87,12.54,5.45,7.55,12.78-14.36,3.87-41.26,12.63-1.52,2.19,5.39,4.72,10.1,1.52,10.27,6.9,17.35,21.39,8.25,7.07,10.95,12.8-.71,6.29,13.11.67,15.33-1.11,5.33,10.44,3.95,2.35.49,4.99-4.83,10.17Z",
    id: "bujairi",
  },
  {
    type: "path",
    stroke: null,
    fill: null,
    coords:
      "m0,0v2048h2048V0H0Zm1151.14,586.98l-5.39,10.1-14.92,11.45-13.88,5.64h-13.14l-9.09,3.62-12.63,7.75-10.78,3.7-15.83,12.13-3.87-4.38-7.07-8.42-8.25-7.07-11.29-13.64-6.06-7.75-10.27-6.9-10.1-1.52-5.39-4.72,1.52-2.19,11.28-3.7,29.98-8.93,23.74-6.4,19.2-3.54,21.56-7.41,32.67-12.63,5.05,2.19,4.55,4.21,6.06,21.36,3.2,7.44-.84,9.6Zm118.72,50.69l-1.18,12.46-3.87,13.14-6.9,14.15-8.42,16.5-8.25,13.14-7.41,11.96-3.2,3.54-12.46-12.46-11.62-16.67-7.58-15.83-12.29-20.71-2.86-5.56,6.23-9.94.67-9.26-2.36-9.09-1.68-6.32-5.39-5.47-6.4-1.52-5.56-2.19-5.39-13.81-5.22-21.22-6.74-19.7-1.68-7.07,1.68-6.06,19.53-6.9,23.91-8.76,24.25-7.07,17.51-6.06,10.78,11.11,16,36.04,3.03,23.58,2.86,20.71,1.85,19.53-1.85,15.83Z",
    id: "events-district",
  },
  {
    type: "path",
    stroke: null,
    fill: null,
    coords:
      "m0,0v2048h2048V0H0Zm1216.33,737l-1,8.33-4.33,7-7.83,8.33-9,11.5-4.5,6-6.17,9.83-4.17,6.33-4.17,8.33-3.67,7.17-3.83,9.17-5.17,9.33-3,7.67-2,4.75-3.25-7.33-2.42-3.83-13.92-5.25-3.17.5-11.67-4.92-3.08-3.5-14.33-5.17-5,.25-24-10.5v-9.56l2.25-10,2.83-7.67,1.42-14.42,1.83-16.33-.67-10.86,2-6.5-4.67-16.33-.5-15.17-.33-8.17,7.33-10.83,4.83-10.17,10-2.83,7-1.5,10.67-8.17,5.17-6.17,12.67-2.17,13.5,2,6.67,1,2.83,4.33,5.33,3.17,6.33-1,4.5.5,5.17,4.17,4.17,16.5,7.67,15.67,5.67,8.67s4.17,9.5,4.67,9.83c.5.33,9,13.83,9,13.83l2.33,8v6.17Z",
    id: "king",
  },
  {
    type: "path",
    stroke: null,
    fill: null,
    coords:
      "m0,0v2048h2048V0H0Zm1150.67,858.17l-4.17,13.42-3.58,9.33-3,11.58-2.58,11.13-2.25,10.37-2.25,9.42-2.83,8.25.5,8.42-.92,10.33-1.08,9.83-.58,7.58.5,4.92-3.42,2.75-2.92,2.83-1.08,1.39-2.13-.04v2.17l-13.59.11v-1.83l-6.4.11v2.63h-11.99v-1.6h-10.17l-5.25-3.31,2.17-3.43v-14.28l-6.51.11-16.79-11.08-9.48,13.71-10-7.63-4.51,3.45-5.65-1.91-3.11.32-4.46-2.15-5.74-1.63-5.58-.88-8.15-1.66-6.33-3.08-8.67-2.08-7.5-3.67-4.58-2.67v-3.08l7.25-4.42,9.42-10.83,13.21-11.75,11.45-10,9.08-7.58,4.25-7,.08-6.33,9.08-9.25,9.42-8.42,3.83-4.67,6.25-7.75,4.92-4.75,5.42-9.83,6.58-11.75,2.25-7.67,1.67-5.67,4.08-5.17,1.83-2.5,24,10.5,5-.25,14.33,5.17,3.08,3.5,11.67,4.92,3.17-.5,13.92,5.25,2.42,3.83,1.58,4,1.67,3.33-6.83,17.42Z",
    id: "qurain",
  },
  {
    type: "path",
    stroke: null,
    fill: null,
    coords:
      "m0,0v2048h2048V0H0Zm1550.71,937.37l-3.49,4.23-14.08,7.68-23.6,8.62-36.09,2.86-43.29,2.75h-50.7l-54.62,1.16-34.29,2.54-27.41,2.33-23.6.21-15.56-.42-12.49.42s-3.07-4.45-3.07-4.87v-29.95l3.81-22.02,6.35-20,8.79-17.25,11.19-20.22,10.62-13.76,11.54-14.29,12.28-14.4,8.15-12.28,6.46-9.31,4.35-4.13v-6.78l-16.63-10.58-18.31-18.42-10.69-12.17,16.62-15.03,9.21-5.72,8.36-3.39,11.44-3.28h26.24s10.69,4.34,11.01,4.45,15.24,7.94,15.24,7.94l9.53,7.73,16.83,13.34,7.83,8.47,8.04,11.01,9.42,13.34,11.11,15.14,7.73,11.85,11.01,12.81,8.15,9.63,9.1,10.16s8.04,11.22,8.57,11.75,12.07,10.69,12.07,10.69l13.76,11.11,12.49,9.95,10.58,9,15.24,11.43,12.49,9.1s9.74,8.79,10.37,9,10.06,9.1,10.06,9.1l2.75,3.7-.85,4.76Z",
    id: "creative-district",
  },
  {
    type: "path",
    stroke: null,
    fill: null,
    coords:
      "m1,1v2048h2048V1H1Zm1026.78,1420.89h-3.33l-4.5-.94-2.78,1.94-.33,5.22-2.33.61-9.61,5.22,11.67,11.83-.28,2.67-.39,2,3.17,6.39.72,3.78-.06,2.39-5.28,4.33-27.07-.66-26.69-6.16-8.85-2.14-5.96-.53-9.65-3.72-7.32-.19-9.12-2.02-5.71-3.13-9.62-3.57-5.93-1.77-10.4-2.67-4.97-1.3-9.43-5.18-8.38-5-10.4-6.21-3.82-2.17-5.28-4.5-4.56-4.28-4.35-.34-2.98-4.19-.34-5.77-6.73-6.05-4.07-1.92-5.4-3.63-10.74-.56-23.03-6.67-8.66-6.39-5.68-6.83-5.15-2.95-3.32-.96-2.17-2.2v-2.08l2.33-2.61,3.48-4.34.34-4.78,4.31-13.07,9.31-7.76,6.83-5.74,9.62-3.76,2.61-.62,11.55-8.75,8.91-6.64,4.38-2.36,13.44-9.5,2.54-.96,6.92-4.72,2.33-2.61,5.22-3.47,8.06,4.86,4.67-4.25,18.17,19.5-2.83,3v2.75l8.42,10,2.92-.58,3.92,11,12.33,3.58,2.58-2.83,3-4,.83-6.08,16.17.83,3.67-6.33,2.58-.08,3.08,1.33,2.58.17,1,1.58,7.75-.08,1.83-1.83,5.75.67,5.75,1.75,5.75-.08.92-.83,13.17.08,3.58-2.58,7.33.25-1.67,4.08-3.17,4.67-2.5,4.58,4.25,4.83-2.79,6.33-2.54,5.3-.66,1.91,2.23,2.35,5.43.34,2.85,1.85-.79,12.36,3.22,1.22,2.17,10.72.5,4,3.28,5.5,1.06,5.61,4.94,8.78,3.61,6.17,5.72,8.72,4.44,2.94,4.06,4.06-1.61,3.89Z",
    id: "diriyah-gardens",
  },
  {
    type: "path",
    stroke: null,
    fill: null,
    coords:
      "m1,1v2048h2048V1H1Zm1122.72,1207.86l-1.38,12.58-2.14,11.12-1.17,11.69-1.25,10.65-1.46,12.53-.83,12.46-2.46,14.58-3.62,11.19-3.82,11.86-2.08,8.11-3.15,10.99-2.81,7.7-3.82,8.98-3.68,9.58-4.76,10.85-4.56,10.05-4.69,10.32-2.55,4.76-8.44-6.63-7.23-5.96-11.52-11.05-45.79-46.02h-3.08l-2.39.56-2.36.1-4.25-4.83,2.5-4.58,3.17-4.67,1.67-4.08-7.33-.25-3.58,2.58-3.83.08-4.75-.08-4.58-.08-.92.83-5.75.08-5.75-1.75-5.75-.67-1.83,1.83-7.75.08-1-1.58-2.58-.17-3.08-1.33-2.58.08-3.67,6.33-16.17-.83-.83,6.08-3,4-2.58,2.83-12.33-3.58-3.92-11-2.92.58-8.42-10v-2.75l2.83-3-18.17-19.5-4.67,4.25-8.06-4.86,9.22-6.89,4.82-3.5,10.92-10.89,9.61-9.19,5.79-7.91,4.68-6.03,8.77-11.06,8.63-14.7,5.62-8.11,5.65-13.25,5.93-12.31.9-6.45.21-7.59.28-2.53,5.93-10.99,1.8-5.79,1.25-4.54,2.18-4.16,1.07-7.35,2.74-4.79,5.37-5.37,14.74-1.46,8.01-1.28,4.37.94,1.66.52,1.98,3.47,3.09,1.14.83,2.05.24,2.7-.55,1.11.69,1.35,2.01,2.43,7.84-.24,3.19-4.27,4.87-7.24,15.9,2.63-12.86,49.19v6.36l4.51,5.17,2.92.53,1.59-1.19,24.93-13.52,4.77,1.06,6.1,8.49.4,4.38,5.04-.4,6.89,11.4,2.65-.27,5.83,5.17v2.92l3.98,2.78,3.05-8.22,3.71-2.25,9.94-23.34,9.68-5.7,17.05,1.2,1.34,15.65.1,12.32-.94,16.81Z",
    id: "diriyah-south",
  },
  {
    type: "path",
    stroke: null,
    fill: null,
    coords:
      "m1,1v2048h2048V1H1Zm1183.89,986.56l-2.63,4-.46,84.3-9.82,12.34-10.5-4.76-35.62,1.65-2.66,79-9.58-.75-7.47-.46-9.68,5.7-9.94,23.34-3.71,2.25-3.05,8.22-3.98-2.78v-2.92l-5.83-5.17-2.65.27-6.89-11.4-5.04.4-.4-4.38-6.1-8.49-4.77-1.06-24.93,13.52-1.59,1.19-2.92-.53-4.51-5.17v-6.36l12.86-49.19-16.04-2.65-20.82-31.82-9.94-4.38-7.56-12.46-4.38-3.05-8.22,3.31-.66-5.17,1.46-13.92-5.17-16.04,2.39-38.32,2.78-5.57.66-7.56,2.78-4.91,20.55-9.15,22.14,2.12,8.75.13,6.89-5.04,5.35-.53,3.27-3.49-.93-3,4.51-3.45,10,7.63,9.48-13.71,16.79,11.08,6.51-.11v14.28l-2.17,3.43,5.25,3.31h10.17v1.6h11.99v-2.63l6.4-.11v1.83l13.59-.11v-2.17l6.05.11,4.49,3.08,47.99-2.22h7.49v6.91Z",
    id: "dsq",
  },
]

const baseMaskAttributes = {
  fillOpacity: "0.6",
  stroke: "none",
  className: "masterplan-mask",
}

export const masterplanMaskData = maskData.map((obj) => {
  return {
    ...obj,
    attr: {
      ...baseMaskAttributes,
    },
  }
})
