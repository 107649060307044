import { DiamondButton } from "@components/buttons"
import { Panel } from "@components/panels"
import { useStore } from "@state/store"
import styled from "styled-components"
import kafdImg from "@assets/images/vision/panels/kafd.png"

export interface IKAFDPanelProps {
  visible: boolean
}

export default function KAFDPanel({ visible }: IKAFDPanelProps) {
  const { setRiyadhPanel, riyadhPanel } = useStore((state) => ({
    riyadhPanel: state.riyadhPanel,
    setRiyadhPanel: state.setRiyadhPanel,
  }))

  return (
    <StyledPanel className="stats-panel" visible={visible}>
      <div className="stats-box">
        <div className="top">
          <CloseWrapper>
            <DiamondButton
              style={{ height: "2rem", width: "2rem" }}
              icon="/assets/icons/common/close.svg"
              size="medium"
              color="light-green"
              onClick={() => setRiyadhPanel(null)}
            />
          </CloseWrapper>
          <img src={kafdImg} alt="Airport" />
        </div>
        <div className="bottom-text">
          <div className="mid-text">King Abdullah's Financial District</div>
          <p>
            KAFD was inspired by King Abdullah’s vision to create a new
            financial district that will take the economy of Riyadh to new
            heights. Following its acquisition by the Public Investment Fund
            (PIF) of Saudi Arabia, KAFD has evolved to become a prime business
            and lifestyle destination capturing the core values of Vision 2030.
          </p>
          <br />
          <p>
            While contributing to the growth and diversification of the economy,
            provide our community with a vibrant experience in the heart of
            Riyadh. This city within a city, is redefining Riyadh’s skyline with
            outstanding buildings inspired by the native landscape.
          </p>
        </div>
      </div>
    </StyledPanel>
  )
}

const CloseWrapper = styled.div`
  position: absolute;
  top: -1rem;
  right: -1rem;
  z-index: 15;
`

const StyledPanel = styled(Panel)<{ visible: boolean }>`
  position: absolute;
  top: 0;
  right: ${({ visible }) => (visible ? "0" : "-30rem")};
  height: 100%;
  width: 25rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row dense;
  justify-items: center;
  z-index: 1;
  padding: 0;
  font-size: 0.8rem;
  transition: right 0.6s;

  @media screen and (max-width: 1366px) {
    font-size: 1rem;
  }

  & .stats-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 2rem;

    &:not(:last-child) {
      border-bottom: 1px solid #ffffff;
    }

    .top {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 3rem;
      height: 15rem;
      border-radius: 4px;
      box-shadow: 0px 0px 3px rgba(255, 255, 255, 0.7);
      background: #ffffff;

      img {
        width: auto;
        height: 13rem;
        opacity: 1;
      }
    }

    .bottom-text {
      text-transform: uppercase;
      letter-spacing: 0.03rem;
      font-weight: 400;
      line-height: 1.5;
      color: ${({ theme }) => theme.darkGreen};
      text-align: left;

      ul {
        padding-left: 2rem;
      }
    }

    .bottom-text {
      text-transform: none;
    }

    .mid-text {
      font-size: 2rem;
      font-family: BeausiteGrandTrial-Light;
      padding: 0.5rem 0;
      line-height: 1.2;
      color: #ffffff;

      @media screen and (max-width: 1366px) {
        font-size: 2.5rem;
      }
    }
  }
`
