const boardChairman = "/assets/images/team/board/chairman.png"
const boardMember1 = "/assets/images/team/board/board-member1.jpg"
const boardMember2 = "/assets/images/team/board/board-member2.jpg"
const boardMember3 = "/assets/images/team/board/board-member3.jpg"
const boardMember4 = "/assets/images/team/board/board-member4.jpg"
const boardMember5 = "/assets/images/team/board/board-member5.png"
const boardMember6 = "/assets/images/team/board/board-member6.jpg"
const boardMember7 = "/assets/images/team/board/board-member7.jpg"
const boardMemberSecretary = "/assets/images/team/board/board-member-secretary.png"
const chiefExecutiveOfficer = "/assets/images/team/executives/chief-executive-officer.jpg"
const chiefFinancialOfficer = "/assets/images/team/executives/chief-financial-officer.jpg"
const chiefOfStaff = "/assets/images/team/executives/chief-of-staff.jpg"
const chiefOperatingOfficer = "/assets/images/team/executives/chief-operating-officer.jpg"
const chiefStrategic = "/assets/images/team/executives/chief-strategic.jpg"
const presidentDevco = "/assets/images/team/executives/president-devco.jpg"
const wadih = "/assets/images/team/sales/wadih.png"
const nabil = "/assets/images/team/sales/nabil.png"
const nermeen = "/assets/images/team/sales/nermeen.jpg"

const teamData = {
  board: [
    {
      imagePath: boardChairman,
      job: "Chairman of the Board",
      name: "HRH PRINCE MOHAMMED BIN SALMAN BIN ABDULAZIZ AL SAUD",
      email: "",
    },
    {
      imagePath: boardMember1,
      job: "Board Member",
      name: "H.H. PRINCE BADER BIN ABDULLAH BIN FARHAN AL SAUD",
      email: "",
    },
    {
      imagePath: boardMember2,
      job: "Board Member",
      name: "H.H. PRINCE DR. ABDULAZIZ BIN MOHAMMED BIN AYYAF",
      email: "",
    },
    {
      imagePath: boardMemberSecretary,
      job: "Board Member Secretary",
      name: "H.E. MR. AHMED BIN AQEEL ALKHATEEB",
      email: "",
    },
    {
      imagePath: boardMember3,
      job: "Board Member",
      name: "H.E. DR. FAHAD BIN ABDULLAH AL-SEMMARI",
      email: "",
    },
    {
      imagePath: boardMember4,
      job: "Board Member",
      name: "H.E. ENG. IBRAHIM BIN MOHAMMED ALSULTAN",
      email: "",
    },
    {
      imagePath: boardMember5,
      job: "Board Member",
      name: "H.E. MR. FAHD BIN ABDULMOHSEN ALRASHEED",
      email: "",
    },
    {
      imagePath: boardMember6,
      job: "Board Member",
      name: "MR. AIMEN BIN MOHAMMED ALMUDAIFER",
      email: "",
    },
    {
      imagePath: boardMember7,
      job: "Board Member",
      name: "MR. SAAD BIN ABDULAZIZ ALKRUOD",
      email: "",
    },
  ],
  leadership: [
    {
      imagePath: chiefExecutiveOfficer,
      job: "GROUP CHIEF EXECUTIVE OFFICER",
      name: "GERARD J. INZERILLO",
      email: "",
    },
    {
      imagePath: chiefOperatingOfficer,
      job: "GROUP CHIEF OPERATING OFFICER",
      name: "FAISAL FARUQI",
      email: "",
    },
    {
      imagePath: chiefOfStaff,
      job: "CHIEF OF STAFF",
      name: "BADER BINMAFERIJ",
      email: "",
    },
    {
      imagePath: presidentDevco,
      job: "PRESIDENT OF DEVCO",
      name: "MOHAMED SAAD",
      email: "",
    },
    {
      imagePath: chiefStrategic,
      job: "CHIEF STRATEGIC MANAGEMENT OFFICER",
      name: "TALAL KENSARA",
      email: "",
    },
    {
      imagePath: chiefFinancialOfficer,
      job: "CHIEF FINANCIAL OFFICER",
      name: "AHMED ALAMR",
      email: "",
    },
  ],
  leasing: [
    {
      imagePath: nermeen,
      job: "Chief Retail Leasing Officer",
      name: "NERMEEN NOSSEIR",
      qr: "https://qrco.de/be3neK",
    },
    {
      imagePath: nabil,
      job: "Leasing Director DG1",
      name: "NABIL NASSAR",
      qr: "https://qrco.de/beWnTG",
    },
    {
      imagePath: wadih,
      job: "Retail Leasing Senior Director",
      name: "WADIH EL KHOURY",
      qr: "https://qrco.de/ben2MN",
    },
  ],
}

export default teamData
