import { Fragment, memo, useEffect, useState } from "react"
import { TileLayer, useMap } from "react-leaflet"

import { useStore } from "@state/store"
import useRasterCoords from "../../hooks/leaflet/useRasterCoords"

const Tiles = memo(function ({
  img,
}: {
  img: [width: number, height: number]
}) {
  const [prevLevel, setPrevLevel] = useState(null)
  const { unitLevel, finderMapUI } = useStore((s) => ({
    unitLevel: s.unitLevel,
    finderMapUI: s.finderMapUI,
  }))
  // const MAP = useMap()
  const rc = useRasterCoords(img)
  const DURATION = 400

  const paths = [
    { src: `/assets/roof/{z}/{x}/{y}.webp`, activeLevel: [null] },
    { src: `/assets/ff/{z}/{x}/{y}.webp`, activeLevel: ["1"] },
    { src: `/assets/gf/{z}/{x}/{y}.webp`, activeLevel: ["0"] },
    { src: `/assets/b1/{z}/{x}/{y}.webp`, activeLevel: ["B1"] },
    { src: `/assets/b2/{z}/{x}/{y}.webp`, activeLevel: ["B2"] },
    { src: `/assets/b3/{z}/{x}/{y}.webp`, activeLevel: ["B3"] },
  ]

  useEffect(() => {
    setTimeout(() => {
      setPrevLevel(unitLevel)
    }, DURATION)
  }, [unitLevel])

  return (
    <Fragment>
      {paths.map((obj, i) => {
        return finderMapUI ? (
          (obj.activeLevel.includes(unitLevel) ||
            obj.activeLevel.includes(prevLevel)) && (
            <TileLayer
              url={obj.src}
              noWrap={true}
              bounds={rc.getMaxBounds()}
              maxNativeZoom={rc.zoomLevel()}
              key={i}
            />
          )
        ) : (
          <TileLayer
            url={`/assets/roof/{z}/{x}/{y}.webp`}
            noWrap={true}
            bounds={rc.getMaxBounds()}
            maxNativeZoom={rc.zoomLevel()}
          />
        )
      })}
    </Fragment>
  )
})
export default Tiles
