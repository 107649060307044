import { DiamondButton } from "@components/buttons"
import * as DialogPrimitives from "@radix-ui/react-dialog"
import { ReactNode } from "react"
import styled from "styled-components"

interface DialogProps {
  title?: string
  description?: string
  content: ReactNode
  open: boolean
  className?: string
  style: React.CSSProperties
  onClose: () => void
}

const Dialog = ({
  title,
  description,
  content,
  open,
  onClose,
}: DialogProps) => {
  return (
    <Wrapper>
      <DialogPrimitives.Dialog open={open}>
        <div className={`overlay ${open ? "show" : ""}`}></div>

        <DialogPrimitives.DialogContent className="content">
          <DialogPrimitives.DialogTitle>{title}</DialogPrimitives.DialogTitle>
          <DialogPrimitives.DialogDescription>
            {description}
          </DialogPrimitives.DialogDescription>
          {content}
          <DiamondButton
            icon="/assets/icons/common/close.svg"
            color="brown"
            size="large"
            style={{
              position: "absolute",
              top: "-1.5rem",
              right: "-1.5rem",
              zIndex: 10,
            }}
            onClick={onClose}
          />
        </DialogPrimitives.DialogContent>
      </DialogPrimitives.Dialog>
    </Wrapper>
  )
}

export default Dialog

const Wrapper = styled.div`
  height: 100%;
  width: 100%;

  *:focus {
    outline: none;
  }

  .content {
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 60%;
    width: 70%;
    max-width: 90vw;
    max-height: 85vh;
    z-index: 1000;
  }

  .overlay {
    opacity: 0;
  }

  .overlay.show {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    opacity: 1;
    transition: opacity 0.3s;
  }
`
