import React, { useEffect, useState } from "react"
import styled from "styled-components"
import FeatureText from "../FeatureText"
import AmenitiesDetails from "../detailsVariants/Details"
import { motion, AnimatePresence } from "framer-motion"
import StaggeredText from "@components/StaggeredText"
//@ts-ignore
import guestAmenitiesData from "../../data/guestAmenities.ts"
import { useStore } from "@state/store"
import gsap from "gsap"

type TAmenityCategory =
  | "restrooms"
  | "driver-lounges"
  | "valet-lounges"
  | "welcome-halls"
  | "vip-lounges"
  | "vvip-lounges"
  | "concierge"
  | null

const transportState: (string | string[])[] = [
  "welcome-halls",
  "vip-lounges",
  "restrooms",
  "valet-lounges",
]

const GuestAmenities: React.FC = () => {
  const [activeAmenityIndex, setActiveAmenityIndex] = useState(null)
  const setLocalState = useStore((s) => s.setLocalState)
  const showDetails = useStore((s) => s.showDetails)
  const navigating = useStore((s) => s.navigating)
  const navDurationSec = useStore((s) => s.navDurationSec)

  const textAnimationDelay = (n) => navDurationSec * 0.75 + n * 0.25
  const textAnimationDuration = 0.5
  const navAnimStyle = {
    animationName: navigating ? "fadeOut" : "fadeIn",
    animationDelay: navigating ? `${navDurationSec}s` : "0.8s",
  }

  const mainText = ["Best in", "Class Guest", "Amenities"]

  const handleShowDetails = (index: number) => {
    const category = guestAmenitiesData.features[index].link
    setActiveAmenityIndex(index)
    setLocalState({ showDetails: true })
    const value = transportState[index]

    setLocalState({
      amenityCategory: category,
      unitLevel:  "0"
    })
  }
  const indexes = [0, 1, 2, 3, 4, 5]

  useEffect(() => {
    gsap.to("#details-wrapper", {
      autoAlpha: showDetails ? 1 : 0,
      duration: 0.4,
    })
  }, [showDetails])

  return (
    <AnimatePresence>
      <>
        <TabWrapper
          initial="hidden"
          animate="visible"
          exit={{ opacity: 0, transition: { duration: 0.4 } }}
        >
          <img className="svgDesign" src={"/assets/images/features/svgDesign.svg"} />
          <TopWrapper>
            <RightText className="mainText">
              <div>
                {mainText.map((line, i) => {
                  return (
                    <StaggeredText
                      text={line}
                      cue={!navigating}
                      delay={textAnimationDelay(i)}
                      duration={textAnimationDuration}
                      key={`mainTextLine${i}`}
                    />
                  )
                })}
              </div>
            </RightText>
            <ImageContainer
              src={"/assets/images/amenities/welcome-halls/full/atrium-2.webp"}
              className="animateOnNav"
              style={navAnimStyle}
              onClick={() => {
                setLocalState({
                  swiperGalleryData: [
                    { src: "/assets/images/amenities/welcome-halls/full/atrium-2.webp" },
                  ],
                  swiperGalleryOpen: true,
                })
              }}
            />
          </TopWrapper>
          <div className="features">
            {guestAmenitiesData.features.map((feature, index) => (
              <FeatureWrapper
                onClick={() => handleShowDetails(index)}
                key={index}
                style={{
                  animationName: navAnimStyle.animationName,
                  animationDelay: !navigating
                    ? `${navDurationSec + index * 0.1}s`
                    : "0.8s",
                }}
                className="animateOnNav"
              >
                <FeatureText
                  text1={feature.text1}
                  text2={feature.text2}
                  // type={feature.type}
                />
                <Icon
                  width="40"
                  height="40"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleShowDetails(index)}
                >
                  <g id="Icons">
                    <path
                      id="Vector"
                      d="M3 8.5H14"
                      stroke="black"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      id="Vector_2"
                      d="M9.5 4L14 8.5L9.5 13"
                      stroke="black"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </Icon>
              </FeatureWrapper>
            ))}
          </div>
        </TabWrapper>
        <DetailsWrapper id="details-wrapper">
          {activeAmenityIndex !== null &&
            indexes.map((i) => {
              return (
                activeAmenityIndex === i && (
                  <AmenitiesDetails
                    data={guestAmenitiesData.details}
                    activeIndex={i}
                    setActiveIndex={setActiveAmenityIndex}
                    updateMap
                    key={i}
                  />
                )
              )
            })}
        </DetailsWrapper>
      </>
    </AnimatePresence>
  )
}

export default GuestAmenities

const TabWrapper = styled(motion.div)`
  font-family: "BeausiteGrandTrial-Light";
  width: 100%;
  font-weight: 200;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  gap: 2rem;
  padding: 0 var(--margin);
  transition: opacity 1s ease;
  opacity: 0;
  animation: fadeIn 1s ease forwards;
  height: 100%;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .features {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    z-index: 100;
    margin: auto 0;
  }
  .mainText {
    font-size: 6rem;
    margin: auto 0;
    color: ${({ theme }) => theme.lightBrown};
    line-height: 5rem;
  }

  .subText {
    font-size: 1rem;
    color: ${({ theme }) => theme.darkGreen};
    font-family: "BeausiteClassicTrial-Clear";
    text-align: right;
    margin: 2rem 0;
    margin-left: auto;
    line-height: 20px;
  }

  #Icons {
    transform: rotate(-45deg);
    transform-origin: center;
  }

  .svgDesign {
    position: absolute;
    left: 0;
    opacity: 0.5;
  }
`

const TopWrapper = styled.div`
  height: 60vh;
  display: flex;
  flex-direction: row;
  gap: 2rem;
`

const ImageContainer = styled.img`
  width: 50%;
  object-fit: cover;
  cursor: pointer;
`

const RightText = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`

const FeatureWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.lightBrown}2A;
  padding: 15px;
  border-radius: 1px;
  gap: 1rem;
  cursor: pointer;
`

const Icon = styled.svg`
  margin-left: auto;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.lightBrown};
  transition: 0.3s;
  margin-top: auto;

  &:hover {
    opacity: 0.5;
    transition: 0.3s;
  }
`

const DetailsWrapper = styled.div`
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: var(--white);
  opacity: 0;
`
