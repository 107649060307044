import React, { useRef } from "react"
import styled from "styled-components"
import { DiamondButton } from "@components/buttons"
import SplitView from "../SplitView"
import { motion, AnimatePresence } from "framer-motion"
import FeatureText from "../FeatureText"
import svgDesign from "@assets/images/features/svgDesign.svg"
import { useStore } from "@state/store"
import StaggeredText from "@components/StaggeredText"
//@ts-ignore
import guestAmenitiesData from "../../data/guestAmenities.ts"

interface DetailsProps {
  data: {
    heading1?: string
    heading2?: string
    subText?: string
    feature1?: string
    type1?: string
    feature2?: string
    type2?: string
    feature3?: string
    type3?: string
    img?: string
    popupArray?: {src: string}[]
  }[]
  activeIndex: number
  setActiveIndex: (v: number) => void
  updateMap?: boolean
}

const Details: React.FC<DetailsProps> = ({
  data,
  activeIndex,
  setActiveIndex,
  updateMap = false,
}) => {
  const {
    heading1,
    heading2,
    subText,
    feature1,
    type1,
    feature2,
    type2,
    feature3,
    type3,
    img,
    popupArray
  } = data[activeIndex]

  const wrapperRef = useRef<HTMLDivElement>()

  const features2 = [
    { text1: feature1, type: type1 },
    { text1: feature2, type: type2 },
    { text1: feature3, type: type3 },
  ]

  const delay = useStore((s) => s.featuresAnimationDelay)
  const showDetails = useStore((s) => s.showDetails)
  const setLocalState = useStore((s) => s.setLocalState)

  const unshowDetails = (): void => {
    setLocalState({ showDetails: false, miniMap: false, amenityCategory: null })
  }

  function updateState(index) {
    const category = guestAmenitiesData.features[index].link
    setActiveIndex(index)
    updateMap && setLocalState({ amenityCategory: category })
  }

  function updateIndex(dir) {
    const maxIndex = data.length - 1
    let nextIndex
    if (dir) {
      nextIndex = activeIndex + 1 > maxIndex ? 0 : activeIndex + 1
      updateState(nextIndex)
    } else {
      nextIndex = activeIndex - 1 < 0 ? maxIndex : activeIndex - 1
      updateState(nextIndex)
    }
    wrapperRef.current.style.opacity = "1"
  }

  function switchData(dir) {
    wrapperRef.current.style.opacity = "0"
    setTimeout(() => {
      updateIndex(dir)
    }, 400)
  }
  return (
    <AnimatePresence>
      {showDetails && (
        <TabWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          exit={{ opacity: 0, transition: { duration: 0.6, delay: 0.3 } }}
        >
          <TransitionTarget ref={wrapperRef}>
            <img
              className="svgDesign"
              src={svgDesign}
              onClick={unshowDetails}
            />
            <TopWrapper>
              <LeftText
                initial={{ x: -5, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1, ease: "easeInOut", delay }}
                exit={{ opacity: 0, transition: { duration: 0.3 } }}
                className="mainText2"
              >
                <div className="subText2"></div>
                <DiamondButton
                  className="back"
                  onClick={unshowDetails}
                  icon="/assets/icons/common/close-icon.svg"
                  size="medium"
                  color="brown"
                />

                <div className="headers">
                  <StaggeredText text={heading1 ?? ""} cue delay={1} />
                  <StaggeredText text={heading2 ?? ""} cue delay={1} />
                </div>
                <motion.div
                  className="subText2"
                  initial={{ x: -15, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 1, ease: "easeInOut", delay }}
                  exit={{ opacity: 0, transition: { duration: 0.3 } }}
                >
                  {subText}
                </motion.div>
                <motion.div
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1, ease: "easeInOut", delay }}
                  exit={{ opacity: 0, transition: { duration: 0.3 } }}
                >
                  {features2.map((feature, index) => (
                    <FeatureWrapper key={index}>
                      <FeatureText text1={feature.text1} type={feature.type} />
                    </FeatureWrapper>
                  ))}
                </motion.div>
              </LeftText>
              <SplitView img={img} popupArray={popupArray}/>
            </TopWrapper>
            <IndexControl className="animateOnNav">
              <DiamondButton
                onClick={() => switchData(false)}
                icon="/assets/icons/common/arrow-left.svg"
                size="medium"
                color="brown"
              />
              <DiamondButton
                onClick={() => switchData(true)}
                icon="/assets/icons/common/arrow-right.svg"
                size="medium"
                color="brown"
              />
            </IndexControl>
          </TransitionTarget>
        </TabWrapper>
      )}
    </AnimatePresence>
  )
}

export default Details

const TabWrapper = styled(motion.div)`
  z-index: 200;
  background-color: white;
  color: ${({ theme }) => theme.darkGreen};
  font-family: "BeausiteGrandTrial-Light";
  width: 100%;
  height: 100%;
  font-weight: 200;
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  padding-left: var(--margin);
  gap: 2rem;

  .bottomsubText2 {
    margin: auto auto auto auto;
    font-family: "BeausiteClassicTrial-Clear";
    font-size: 1rem;
  }

  .features2 {
    display: flex;
    margin: auto 0;
    flex-direction: row;
    width: 100%;
    gap: 4rem;
  }
  .mainText2 {
    font-size: 5rem;
  }

  .subText2 {
    width: 70%;
    font-size: 1rem;
    font-family: "BeausiteClassicTrial-Clear";
    text-align: right;
    margin: 2rem 0 auto auto;
    line-height: 25px;
  }

  #Icons {
    transform: rotate(-45deg);
    transform-origin: center;
  }

  .svgDesign {
    position: absolute;
    opacity: 0.5;
    left: 0;
  }

  .back {
    width: 2.5rem;
    height: 2.5rem;
    margin-top: 2rem;
    position: relative;
    right: -95%;
    top: -5%;
  }

  .headers {
    text-align: right;
  }
`

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`

const LeftText = styled(motion.div)`
  width: 50vw;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const FeatureWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  cursor: pointer;
`

const TransitionTarget = styled.div`
  transition: opacity 0.4s ease-in-out;
`

const IndexControl = styled.div`
  position: absolute;
  width: 12rem;
  height: 5rem;
  bottom: 5rem;
  right: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  animation-name: fadeIn;
  animation-delay: 1s;
`
