import { useEffect, useRef, FC } from "react"
import ApexCharts, { ApexOptions } from "apexcharts"

type ColumnChartProps = {
  title: string
  data: number[]
  yaxismax?: number
  categories?: string[]
  yaxisTitle?: string
}

const ColumnChart: FC<ColumnChartProps> = ({
  title,
  data,
  yaxismax,
  categories,
  yaxisTitle,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const barChart = new ApexCharts(
      chartRef.current,
      barChartOptions(title, data, yaxismax, categories, yaxisTitle),
    )
    if (barChart) {
      barChart.render()
    }

    return barChart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef])

  return <div style={{ width: "100%", height: "100%" }} ref={chartRef}></div>
}

// Simple linear interpolation between two values
const lerp = (a, b, t) => a + (b - a) * t

// Interpolates two colors
const lerpColor = (color1, color2, t) => {
  let r = Math.round(lerp(color1.r, color2.r, t))
  let g = Math.round(lerp(color1.g, color2.g, t))
  let b = Math.round(lerp(color1.b, color2.b, t))
  return `rgb(${r},${g},${b})`
}

// Converts a hex color to RGB
const hexToRgb = (hex) => {
  let r = 0,
    g = 0,
    b = 0
  if (hex.length == 4) {
    r = parseInt(hex[1] + hex[1], 16)
    g = parseInt(hex[2] + hex[2], 16)
    b = parseInt(hex[3] + hex[3], 16)
  } else if (hex.length == 7) {
    r = parseInt(hex[1] + hex[2], 16)
    g = parseInt(hex[3] + hex[4], 16)
    b = parseInt(hex[5] + hex[6], 16)
  }
  return { r, g, b }
}

// Generate shades between two colors
const generateShades = (color1, color2, steps) => {
  let shades = []
  for (let i = 0; i < steps; i++) {
    let t = i / (steps - 1)
    shades.push(lerpColor(hexToRgb(color1), hexToRgb(color2), t))
  }
  return shades
}

const baseColor1 = "#b2c6c6" // Light shade
const baseColor2 = "#004344" // Dark shade

const barChartOptions = (
  title: string,
  data: number[],
  yaxismax?: number,
  categories?: string[],
  yaxisTitle?: string,
): ApexOptions => {
  const labelColor = "#004344"
  // Generate color shades based on data length
  const colorShades = generateShades(baseColor1, baseColor2, data.length)

  return {
    responsive: [
      {
        breakpoint: 1367,
        options: {
          chart: {
            height: "100%",
          },
          plotOptions: {
            bar: {},
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    series: [
      {
        name: title,
        data: data,
      },
    ],
    title: {
      text: title,
      align: "left",
      style: {
        fontSize: "16px",
        color: "#004344",
        fontWeight: "400",
        fontFamily: "BeausiteClassicTrial-Clear",
      },
    },
    chart: {
      fontFamily: "inherit",
      type: "bar",
      height: "100%",
      width: "100%",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    colors: ["#b2c6c6"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: 40,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      offsetY: 0,
      formatter(val, opts) {
        const formattedVal = Number(val).toLocaleString()
        return formattedVal
      },
      style: {
        fontSize: "13px",
        fontWeight: "400",
        colors: [labelColor],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: categories,
      position: "bottom",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        offsetY: 0,
        style: {
          colors: "#004344",
          fontSize: "14px",
        },
      },
    },
    yaxis: {
      max: yaxismax,
      title: {
        text: yaxisTitle,
        offsetX: 5,
        style: {
          color: "#004344",
          fontSize: "13px",
          fontWeight: 400,
        },
      },
      labels: {
        show: false,
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
  }
}

export { ColumnChart }
