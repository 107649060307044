import styled from "styled-components"
import { motion } from "framer-motion"
import { ColumnChart } from "../charts/ColumnChart"
import SmallBox from "../charts/SmallBox"
import map1Img from "@assets/images/metrics/map1.jpg"

const PrimaryTradeArea: React.FC<any> = () => {
  const formatNumber = (val: number) => {
    const formattedVal = Number(val).toLocaleString()
    return formattedVal
  }

  return (
    <Wrapper>
      <div className="content">
        <div className="top">
          <div className="left">
            <div className="image-caption">Primary Catchment Area</div>
            <motion.img
              initial={{ scale: 1.05 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1 }}
              exit={{ opacity: 0 }}
              src={map1Img}
              alt="map"
            />
          </div>
          <div className="right">
            <div className="half">
              <ColumnChart
                title="Population (2024 - 2031)"
                data={[1394, 1459, 1528, 1601, 1680, 1767, 1865, 1976]}
                yaxismax={1980}
                yaxisTitle="Population (Thousand)"
                categories={[
                  "2024",
                  "2025",
                  "2026",
                  "2027",
                  "2028",
                  "2029",
                  "2030",
                  "2031",
                ]}
              />
            </div>
            <div className="half">
              <ColumnChart
                title="Retail Potential (2024 - 2031)"
                data={[43.15, 47.01, 51.29, 56.02, 61.35, 67.43, 74.47, 82.79]}
                yaxismax={90}
                yaxisTitle="Retail Potential (SAR Billions)"
                categories={[
                  "2024",
                  "2025",
                  "2026",
                  "2027",
                  "2028",
                  "2029",
                  "2030",
                  "2031",
                ]}
              />
            </div>
          </div>
        </div>

        <div className="bottom">
          <SmallBox
            icon="/assets/icons/metrics/population.svg"
            title="Total Population (2024)"
            data={formatNumber(1394400)}
          />
          <SmallBox
            icon="/assets/icons/metrics/growth.svg"
            title="Population growth % CAGR (Yrs. 2024 - 2031)"
            data={5.1}
            suffix="%"
          />
          <SmallBox
            icon="/assets/icons/metrics/houses.svg"
            title="Number of households"
            data={formatNumber(219780)}
          />
          <SmallBox
            icon="/assets/icons/metrics/income.svg"
            title="Average household income (SAR)"
            data={formatNumber(42700)}
          />
        </div>
      </div>
    </Wrapper>
  )
}

export default PrimaryTradeArea

const Wrapper = styled(motion.div)`
  height: 100%;
  animation: fadeIn 1s ease-in forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      gap: 1rem;
    }
  }

  .menu {
    width: 100%;
  }

  .left {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border-radius: 4px;
    overflow: hidden;
    background: rgb(205 205 205);
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
      opacity: 0.7;
      filter: sepia(0) saturate(0.5) hue-rotate(-15deg) brightness(1)
        contrast(1);
    }

    .image-caption {
      position: absolute;
      left: 0;
      top: 0;
      font-size: 1rem;
      font-weight: 700;
      color: ${({ theme }) => theme.darkGreen};
      padding: 1rem;
      z-index: 1;
      text-transform: uppercase;
    }

    .medium-box {
      width: 100%;
      height: 18rem;
      border-radius: 4px;
      background-color: #f0f0f0;
      padding: 1rem;
      flex: 1;
    }
  }

  .right {
    width: 40%;
    height: 100%;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;

    .half {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50%;
      width: 100%;
      padding: 1rem;
      background: #f0f0ef;
      border-radius: 4px;
    }

    .top-boxes {
      width: 100%;
      border-radius: 4px;
      flex: 1;
      background: #f0f0f0;
      padding: 1rem;
      border-radius: 4px;
    }

    .middle-boxes {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      .box {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;
        border-radius: 4px;
        padding: 1rem 2rem;
        background: ${({ theme }) => theme.lightGreen};
        color: ${({ theme }) => theme.darkGreen};

        &:nth-child(2) {
          background: #f0f0ef;
        }

        .title {
          font-size: 0.8rem;
          font-weight: 400;
          font-family: "BeausiteClassicTrial-Clear";
          text-transform: uppercase;
        }

        .data {
          font-size: 1;
          font-weight: 400;
          color: ${({ theme }) => theme.darkGreen};
          font-family: "BeausiteGrandTrial-Light";
        }
      }
    }

    .bottom-boxes {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 30%;
      gap: 1rem;

      .left-box,
      .right-box {
        width: 50%;
        height: 100%;
        border-radius: 4px;
        padding: 1rem;
        background: #f0f0ef;
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 100%;
  }
`
