
const img1 = "/assets/images/features/t1.webp";
const img2 = "/assets/images/features/t2.webp";
const img3 = "/assets/images/features/t3.webp";
const img4 = "/assets/images/features/t4.webp";
const img5 = "/assets/images/features/t5.webp";


const data = {
  "features": [
    { "text1": "Brand", "text2": "Expression", "type": "10+ locations" },
    { "text1": "Operational and", "text2": "Staff Amenity", "type": "100+ locations" },
    { "text1": "Technologically", "text2": "Enabled", "type": "20+ locations" },
    { "text1": "Concierge &", "text2": "Support Services", "type": "20+ locations" },
    { "text1": "Marketing", "text2": "Support", "type": "12k+ locations" },
    { "text1": "Common Area", "text2": "Facilities", "type": "12k+ locations" }
  ],

  "details": [
    {
      "heading1": "Brand",
      "heading2": "Expression",
      "subText": "The historic town of Diriyah roots its main anchors in its heritage and the historical Najdi architectural typology. Diriyah Squares objective is to maintain the Najdi Architecture character through out the project cohesively, while ensuring the tenant's brand and design is maintained.​",
      "subText2": "Each retail space can serve as platforms for storytelling, allowing retailers to communicate their brand values, heritage, and unique selling propositions through design elements such as signage, artwork, and interior decor. By weaving narratives that resonate with the site's history and context, retailers can create deeper connections with customers, while maintain the connection and respectful integration with the sites historical and cultural significance.",
      "subText3": "All branded signage shall reflect a consistent look throughout Diriyah with respect to brand placement, brand dimensions, colors, typography, contrast and illumination.​",
      "img": img1
    },
    {
      "heading1": "Operational and",
      "heading2": "Staff Amenity",
      "subText": "Diriyah Square offers exceptional high-level operational and staff amenities tailored to our tenants needs. The assets management can cultivate a supportive and collaborative environment that fosters the success and longevity of tenant businesses, and ultimately contributing to the overall vibrancy and prosperity of Diriyah Square.​",
      "subText2": "Equipping Diriyah Square with accessible loading docks ensures that tenant businesses can receive and dispatch goods efficiently. These loading docks are strategically located for easy access by delivery vehicles and equipped with features such as adjustable ramps, ample space for maneuvering, and designated loading zones to minimize congestion.",
      "subText3": "Offering on-site storage facilities allows tenants to efficiently manage their inventory and supplies. These storage areas are conveniently located within Diriyah Squares basement and is easily accessible in several locations across the site.​",
      "img": img2
    },
    {
      "heading1": "Technologically",
      "heading2": "Enabled",
      "subText": "Diriyah Square will provide access to data analytics and insights on customer demographics, foot traffic patterns, and sales trends within the shopping center to help tenants make informed business decisions and optimse their operations.​",
      "subText2": "By leveraging best-in-class live data analytics, Diriyah Square can gain a competitive edge by making data-driven decisions, optimsing operations, and delivering personalized experiences that resonate with customers.",
      "subText3": "Reliable internet connectivity and advanced technology infrastructure, such as Wi-Fi hotspots  to support operations of retail businesses and improve customer experience.​",
      "img": img3
    },
    {
      "heading1": "Tenant Concierge &",
      "heading2": "Support Services",
      "subText": "We aim to enhance the overall experience of our tenants by offering concierge services for tasks such as package delivery, booking reservations, or providing local information to provide convenience.​",
      "img": img2
    },
    {
      "heading1": "Partnerships",
      "heading2": "and Collaboration",
      "subText": "Diriyah Square engaging in marketing initiatives via events, advertising campaigns, and  collaborations with other retailers to drive traffic to the precinct and create a thriving retail environment that benefits both tenants and customers.​",
      "img": img4
    },
    {
      "heading1": "Common Area",
      "heading2": "Facilities",
      "subText": "Shared facilities such as restrooms, lounges, or meeting rooms can provide additional convenience for retail tenants and their staff.​",
      "img": img5
    }
  ]
};

export default data;
