import { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import gsap from "gsap"
import { Button } from "@components/buttons/Button"
import { DiamondButton } from "@components/buttons"
import { useStore } from "@state/store"
import availableSLDS from "@data/availableSLDS"
import plotData from "@data/plotData.json"
import { useFavoritesStore } from "@state/favoritesStore"

function UnitCard() {
  const { addToFavorites, isFavorite } = useFavoritesStore((state) => ({
    loadFavorites: state.loadFavorites,
    favorites: state.favorites,
    removeFromFavorites: state.removeFromFavorites,
    clearFavorites: state.clearFavorites,
    addToFavorites: state.addToFavorites,
    isFavorite: state.isFavorite,
  }))
  const [metrics, setMetrics] = useState(null)
  const { unitID, mapCategory, setLocalState } = useStore((s) => ({
    unitID: s.unitID,
    mapCategory: s.mapCategory,
    setLocalState: s.setLocalState,
  }))
  const currentPage = location.pathname
  const cardRef = useRef<HTMLDivElement>(null)

  // console.log(unitID)

  // N07003
  // var unit = unitsData[0]
  let unit
  function getPNGMatch(id) {
    return availableSLDS.some((png) => png.split("-")[0] === id)
  }

  if (unitID !== null && currentPage !== "/unit-finder") {
    setLocalState({
      unitID: null,
      mapCategory: null,
    })
    // var unit = unitsData[0]
  } else if (unitID !== null && currentPage == "/unit-finder") {
    unit = plotData[unitID]
  }

  function getPNGS(id) {
    const pngBaseURL = "/assets/images/sld/"
    const filtered = availableSLDS.filter((png) => png.split("-")[0] === id)
    return filtered.map((id) => {
      return {
        src: `${pngBaseURL}${id}.webp`,
      }
    })
  }

  useEffect(() => {
    if (!cardRef.current) return
    if (unitID && metrics === null) {
      setMetrics(getData(unitID))
      gsap.to(cardRef.current, { opacity: 1, x: "0%", delay: 1 })
    }
    if (unitID === null && metrics !== null) {
      gsap.to(cardRef.current, {
        opacity: 0,
        x: "100%",
        onComplete: () => setMetrics(null),
      })
    }
    if (unitID && metrics !== null) {
      gsap.to(cardRef.current, {
        opacity: 0,
        x: "100%",
        onComplete: () => {
          setMetrics(getData(unitID))
          gsap.set(cardRef.current, { x: "-100%" })
          setTimeout(() => {
            gsap.to(cardRef.current, {
              opacity: 1,
              x: "0%",
            })
          }, 0)
        },
      })
    }
    gsap.to(".card-main", {
      autoAlpha: unitID ? 1 : 0,
    })
  }, [unitID])

  if (!unitID) return null

  return (
    <CardWrapper className="card-main">
      <CopyWrapper ref={cardRef}>
        <DiamondButton
          size="medium"
          color="light-green"
          className="closeButton"
          onClick={() => {
            setLocalState({
              unitID: null,
            })
          }}
          icon="/assets/icons/common/close.svg"
        />
        <Title style={{ marginTop: "25px" }}>
          <div
            style={{ color: "var(--light-green)", fontSize: "2.2rem" }}
            className="unitType"
          >
            {zones[mapCategory]}
          </div>
          <div className="unitType">
            Unit {metrics?.[0]?.value} - {metrics?.[1]?.value}
          </div>
          <div
            className="unitType"
            style={{ color: "var(--light-green)", fontSize: "1.2rem" }}
          >
            {plotData?.[unitID]?.["ROOM NAME"]?.toLowerCase()}
          </div>
        </Title>
        <div className="buttons">
          <Button
            style={{
              backgroundColor: "black",
              width: "100%",
              height: "1rem",
              padding: "1.2rem",
            }}
            variant="rectangle"
            color="brown"
            icon={
              isFavorite(unit?.id)
                ? "/assets/icons/common/fav-on.svg"
                : "/assets/icons/common/fav-off.svg"
            }
            title="Favorite"
            onClick={() => {
              addToFavorites({
                id: unit.id,
                buildingNo: metrics?.[0]?.value,
                unitNo: metrics?.[1]?.value,
                sqm: metrics?.[2]?.value,
                roomName: plotData?.[unitID]?.["ROOM NAME"]?.toLowerCase(),
                zone: zones[mapCategory],
              })
            }}
          />
          {getPNGMatch(unitID) && (
            <Button
              style={{
                backgroundColor: "black",
                width: "100%",
                height: "1rem",
                padding: "1.2rem",
              }}
              variant="rectangle"
              color="brown"
              icon={"/assets/icons/common/eye.svg"}
              title="SLD"
              onClick={() => {
                const galleryData = getPNGS(unitID)
                setLocalState({
                  swiperGalleryData: galleryData,
                  swiperGalleryOpen: true,
                })
              }}
            />
          )}
        </div>
        <div className="container">
          {metrics?.map((metric, index) => (
            <div key={index} className="table">
              <img src={metric.img} className="logo" />
              <div className="unitMetrics">
                <div className="type">{metric.type}</div>
                <div className="value">{metric.value}</div>
              </div>
            </div>
          ))}
        </div>
      </CopyWrapper>
    </CardWrapper>
  )
}

export default UnitCard

const CardWrapper = styled.div`
  overflow: hidden scroll;
  position: absolute;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 50%;
  background: var(--light-grey);
  padding: var(--margin);

  .closeButton {
    z-index: 2;
    position: absolute;
    top: -2%;
    right: -2%;
    transform: scale(0.7) rotate(45deg);
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .container > :nth-child(1) {
    border-radius: 5px 5px 0 0;
  }

  .container > :nth-child(3) {
    border-radius: 0 0 5px 5px;
  }

  .unitType {
    font-size: 1.5rem;
    color: black;
    opacity: 0.75;
    padding: 0 5px 0 0;
    text-transform: capitalize;
  }

  .table {
    border: rgba(256, 256, 256, 0.5) solid 0.5px;
    padding: 15px 15px;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  .logo {
    width: 10%;
    object-fit: contain;
    border: rgba(256, 256, 256, 0.5) solid 1px;
    border-radius: 5px;
    padding: 8px;
  }

  .unitMetrics {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .type {
    font-size: 1rem;
    font-family: "BeausiteFitTrial-Regular";
  }

  .value {
    font-size: 0.75rem;
    font-family: "Arial";
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    gap: 1rem;
    margin-bottom: 1rem;
  }
`

const Title = styled.h2`
  font-family: "BeausiteGrandTrial-Light";
  color: var(--white);
  font-size: clamp(25px, 2rem, 40px);
  font-weight: 100;
  margin: 1rem 0;
  text-align: left;
  width: 90%;

  div{
    margin: 0.5rem 0;

  }
`

const CopyWrapper = styled.div`
  opacity: 0;
  transform: translateX(100%);
`

function getData(id) {
  let newMetrics = []
  if (id && plotData[id]) {
    const unitDetails = plotData[id]
    newMetrics = [
      {
        img: "/assets/icons/favorites/building.svg",
        type: "Building No",
        value: unitDetails["BUILDING NO."] || "N/A",
      },
      {
        img: "/assets/icons/favorites/unit.svg",
        type: "Unit No",
        value: unitDetails["UNIT NO."] || "N/A",
      },
      {
        img: "/assets/icons/favorites/area.svg",
        type: "Area (sqm)",
        value: unitDetails["AREA (sqm)"] || "N/A",
      },
    ]
    return newMetrics
  }
}

const zones = {
  department: "Department Store",
  "premium-dining": "Premium Dining",
  entertainment: "Entertainment and Dining",
  anchor: "Anchor Lifestyle Retail",
  "luxury-n": "Luxury Retail North",
  "luxury-s": "Luxury Retail South",
  culture: "Culture and Dining",
  lifestyle: "Lifestyle Retail",
  "lifestyle-convenience-B1": "Lifestyle Convenience",
  "lifestyle-homewares-B1": "Lifestyle Homewares",
}
