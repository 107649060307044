import { ReactNode, useEffect, useState, useRef, useLayoutEffect } from "react"
import styled from "styled-components"

type TDropdownProps = {
  button: ReactNode
  dropContent: ReactNode
  autoClose?: boolean
  defaultState?: boolean
  [x: string]: unknown
}

function DropDown(props: TDropdownProps) {
  const { button, dropContent, autoClose = true, defaultState = false, ...rest } = props
  const [open, setOpen] = useState(defaultState)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLDivElement>(null)
  const bounds = useRef({
    minX: null,
    maxX: null,
    minY: null,
    maxY: null,
  })

  function handleClickOutside(e) {
    const { minX, maxX, minY, maxY } = bounds.current
    const insideY = e.clientY > minY && e.clientY < maxY
    const insideX = e.clientX > minX && e.clientX < maxX

    if (!insideY || !insideX) {
      setOpen(false)
    }
  }

  useLayoutEffect(() => {
    if (!contentRef.current || !buttonRef.current || !wrapperRef.current) return
    const contentHeight = contentRef.current.clientHeight
    const buttonWidth = buttonRef.current.clientWidth
    wrapperRef.current.style.width = `${buttonWidth}px`
    contentRef.current.style.transform = `translateY(-${contentHeight + 15}px)`
  }, [])

  useEffect(() => {
    if (!contentRef.current || !autoClose) return
    if (open) {
      const rect = contentRef.current.getBoundingClientRect()
      bounds.current.minX = rect.left
      bounds.current.maxX = rect.left + rect.width
      bounds.current.minY = rect.top
      bounds.current.maxY = rect.top + rect.height
      document.addEventListener("click", handleClickOutside)
    } else {
      document.removeEventListener("click", handleClickOutside)
    }
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [open])
  return (
    <DropDownWrapper {...rest} ref={wrapperRef}>
      <ButtonWrapper
        onClick={(e) => {
          e.stopPropagation()
          setOpen(!open)
        }}
        ref={buttonRef}
      >
        {button}
      </ButtonWrapper>
      <ContentWrapper
        className={open ? "show" : "hide"}
        ref={contentRef}
        id="dropdown-content"
      >
        {dropContent}
      </ContentWrapper>
    </DropDownWrapper>
  )
}

export default DropDown

const DropDownWrapper = styled.div`
  position: relative;
  .show {
    clip-path: polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%);
  }
  .hide {
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }
`

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
`

const ContentWrapper = styled.div`
  transition: clip-path 0.5s ease-out;
  position: absolute;
`
