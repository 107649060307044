import styled from "styled-components"
import { motion } from "framer-motion"
import { useStore } from "@state/store"
import { Button } from "@components/buttons/Button"
import { AnimatePresence } from "framer-motion"
import unitsData from "../../data/unit-finder/unitsSheetsInfo"
import React, { Suspense } from "react"
import { DiamondButton } from "@components/buttons"
import availableSLDS from "@data/availableSLDS"
//@ts-ignore
import plotData from "@data/plotData.json"
import { useFavoritesStore } from "@state/favoritesStore"

type galleryData = {
  caption?: string
  src: string
}

type PropType = {
  categories: {
    title?: string
    subtitle?: string
    swipeGalleryData?: galleryData[] | null
    img?: string
    imgThumb?: string
    desc?: string
    feature1Text?: string
    feature1?: string
    feature2Text?: string
    feature2?: string
    feature3Text?: string
    feature3?: string
    link?: string
    para?: string
  }
}

const SidePanelDetails: React.FC<PropType> = (props) => {
  const { categories } = props
  const mapCategory = useStore((s) => s.mapCategory)
  const activeHotel = useStore((s) => s.activeHotel)
  const unitID = useStore((s) => s.unitID)
  const currentPage = location.pathname
  const setLocalState = useStore((s) => s.setLocalState)
  const sidePanel = useStore((s) => s.sidePanel)
  var unit = unitsData[0]
  const { addToFavorites, isFavorite } = useFavoritesStore((state) => ({
    addToFavorites: state.addToFavorites,
    isFavorite: state.isFavorite,
  }))

  console.log(unit)

  if (unitID !== null && currentPage !== "/unit-finder") {
    setLocalState({
      unitID: null,
      mapCategory: null,
    })
    var unit = unitsData[0]
  } else if (unitID !== null && currentPage == "/unit-finder") {
    var unit = unitsData.find((unit) => unit.id === unitID)
    // @matt the unit details are looked up from the data using the unitID
  }

  // @matt - You can add in the data here
  const [metrics, setMetrics] = React.useState([])

  // Assume plotData is imported and contains relevant details
  React.useEffect(() => {
    if (unitID && plotData[unitID]) {
      const unitDetails = plotData[unitID]
      const newMetrics = [
        {
          img: "/assets/icons/favorites/building.svg",
          type: "Building No",
          value: unitDetails["BUILDING NO."] || "N/A",
        },
        {
          img: "/assets/icons/favorites/unit.svg",
          type: "Unit No",
          value: unitDetails["UNIT NO."] || "N/A",
        },
        {
          img: "/assets/icons/favorites/area.svg",
          type: "Area (sqm)",
          value: unitDetails["AREA (sqm)"] || "N/A",
        },
        {
          img: "/assets/icons/favorites/electrical.svg",
          type: "Electric Load",
          value:
            unitDetails["ELECTRICAL LOAD DENSITY ( W/M2) / LOAD (KW)"] || "N/A",
        },
        {
          img: "/assets/icons/favorites/electrical.svg",
          type: "Cooling Load Density",
          value: unitDetails["COOLING LOAD DENSITY ( W/M2)"] || "N/A",
        },
        {
          img: "/assets/icons/favorites/electrical.svg",
          type: "Fresh Air / General Exhaust",
          value: unitDetails["FRESH AIR / GENERAL EXHAUST"] || "N/A",
        },
        {
          img: "/assets/icons/favorites/kitchen.svg",
          type: "Kitchen Make up Air / Grease Exhaust",
          value: unitDetails["KITCHEN MAKE UP AIR / GREASE EXHAUST"] || "N/A",
        },
        {
          img: "/assets/icons/favorites/charcoal.svg",
          type: "Kitchen Charcoal Exhaust",
          value: unitDetails["KITCHEN CHARCOAL EXHAUST"] ? "Yes" : "No",
        },
        {
          img: "/assets/icons/favorites/sng.svg",
          type: "Sng",
          value: unitDetails["SNG"] ? "Yes" : "No",
        },
        {
          img: "/assets/icons/favorites/water.svg",
          type: "Water Drainager",
          value: unitDetails[
            "DRAINAGE / WATER SUPPLY / FIRE FIGHTING / FIRE ALARM / DATA"
          ]
            ? "Yes"
            : "No",
        },
      ]
      setMetrics(newMetrics)
    }
  }, [unitID])

  function getPNGMatch(id) {
    return availableSLDS.some((png) => png.split("-")[0] === id)
  }

  function getPNGS(id) {
    const pngBaseURL = "/assets/images/sld/"
    const filtered = availableSLDS.filter((png) => png.split("-")[0] === id)
    return filtered.map((id) => {
      return {
        src: `${pngBaseURL}${id}.webp`,
      }
    })
  }

  console.log("SIDE PANEL")

  return (
    <Wrapper>
      {mapCategory !== null && (
        <DiamondButton
          size="medium"
          color="light-green"
          className="closeButton"
          onClick={() => {
            if (unitID) {
              setLocalState({
                unitID: null,
                unitLevel: "0",
                mapCategory: mapCategory,
              })
              return
            }
            if (activeHotel !== null) {
              setLocalState({
                mapCategory:
                  activeHotel === "armaniresi" ? "residential" : "hospitality",
                activeHotel: null,
                unitLevel: null,
              })
            } else {
              setLocalState({
                mapCategory: null,
                activeHotel: null,
                unitID: null,
                unitLevel: null,
              })
            }
          }}
          icon="/assets/icons/common/close.svg"
        />
      )}
      {unitID == null && (
        <div>
          <Title
            style={{ width: "90%", display: "flex", flexDirection: "column" }}
            initial={{ opacity: 0, x: 5 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
            exit={{ opacity: 0, transition: { duration: 0.3 } }}
          >
            {categories?.title && categories.title}
          </Title>
          <Image
            src={categories?.imgThumb}
            onClick={() => {
              if (!categories?.swipeGalleryData) return
              setLocalState({
                swiperGalleryData: categories?.swipeGalleryData,
                swiperGalleryOpen: true,
              })
            }}
            initial={{ opacity: 0, x: -5 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            exit={{ opacity: 0, transition: { duration: 0.3 } }}
          />
          {location.pathname === "/unit-finder" && (
            <Desc
              initial={{ opacity: 0, x: 5 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.6 }}
              exit={{ opacity: 0, transition: { duration: 0.3 } }}
            >
              {categories?.desc}
            </Desc>
          )}

          {location.pathname !== "/unit-finder" && (
            <>
              <Desc
                initial={{ opacity: 0, x: 5 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: 0.6 }}
                exit={{ opacity: 0, transition: { duration: 0.3 } }}
              >
                {categories?.desc}
              </Desc>
              {activeHotel == null &&
                categories?.feature1Text &&
                categories?.feature2Text && (
                  <Figure
                    initial={{ opacity: 0, x: -5 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.6, delay: 0.6 }}
                    exit={{ opacity: 0, transition: { duration: 0.3 } }}
                  >
                    <div className="subHeading">KEY FIGURES</div>

                    <div className="rightText">
                      <Figures>
                        <div className="figureMetric">
                          {categories?.feature1Text}
                        </div>
                        <div className="figureType">{categories?.feature1}</div>
                      </Figures>
                      <Figures>
                        <div className="figureMetric">
                          {categories?.feature2Text}
                        </div>
                        <div className="figureType">{categories?.feature2}</div>
                      </Figures>
                      <Figures>
                        <div className="figureMetric">
                          {categories?.feature3Text}
                        </div>
                        <div className="figureType">{categories?.feature3}</div>
                      </Figures>
                    </div>
                  </Figure>
                )}
              <Paragraph
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6, delay: 0.6 }}
                exit={{ opacity: 0, transition: { duration: 0.3 } }}
              >
                {categories?.para}
              </Paragraph>
            </>
          )}
        </div>
      )}
      <AnimatePresence>
        {unitID !== null && sidePanel == true && (
          <UnitDetails>
            <Title
              initial={{ opacity: 0, x: 5 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              exit={{ opacity: 0, transition: { duration: 0.3 } }}
              style={{ marginTop: "25px" }}
            >
              Unit {metrics?.[0]?.value} - {metrics?.[1]?.value}
              <div className="unitType">
                {plotData?.[unitID]?.["ROOM NAME"]}
              </div>
            </Title>
            <motion.div
              className="container"
              initial={{ opacity: 0, x: -5 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              exit={{ opacity: 0, transition: { duration: 0.3 } }}
            >
              {metrics.map((metric, index) => (
                <div key={index} className="table">
                  <img src={metric.img} className="logo" />
                  <div className="unitMetrics">
                    <div className="type">{metric.type}</div>
                    <div className="value">{metric.value}</div>
                  </div>
                </div>
              ))}
            </motion.div>
            <motion.div
              className="buttons"
              initial={{ opacity: 0, x: 5 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              exit={{ opacity: 0, transition: { duration: 0.3 } }}
            >
              <Button
                style={{ backgroundColor: "black", width: "100%" }}
                variant="rectangle"
                color="brown"
                icon={
                  isFavorite(unitID)
                    ? "/assets/icons/common/fav-on.svg"
                    : "/assets/icons/common/fav-off.svg"
                }
                title="Favorite"
                onClick={() => {
                  addToFavorites({
                    id: unitID,
                    buildingNo: metrics?.[0]?.value,
                    unitNo: metrics?.[1]?.value,
                    sqm: metrics?.[2]?.value,
                    roomName: plotData?.[unitID]?.["ROOM NAME"],
                    zone: "",
                  })
                }}
              />
              {getPNGMatch(unitID) && (
                <Button
                  style={{ backgroundColor: "black", width: "40%" }}
                  variant="rectangle"
                  color="brown"
                  icon={"/assets/icons/common/eye.svg"}
                  title="SLD"
                  onClick={() => {
                    const galleryData = getPNGS(unitID)
                    setLocalState({
                      swiperGalleryData: galleryData,
                      swiperGalleryOpen: true,
                    })
                  }}
                />
              )}
            </motion.div>
          </UnitDetails>
        )}
      </AnimatePresence>
    </Wrapper>
  )
}

export default SidePanelDetails

const Wrapper = styled(motion.div)`
  .category {
    padding: 1px;
    display: flex;
    column-gap: 15px;
    color: black;
  }

  .toggle {
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    border: rgba(1, 1, 1, 0.5) solid 1px;
  }

  .categoryName {
    letter-spacing: 1px;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 0.6rem;
    font-family: "Arial";
  }

  .closeButton {
    z-index: 2;
    position: absolute;
    top: 2rem;
    right: 2rem;
    transform: scale(0.7) rotate(45deg);
  }

  position: relative;

  height: 100%;
  padding-bottom: 20vh;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y;

  color: ${({ theme }) => theme.darkGreen};

  > * {
    overflow-x: clip;
  }
`

export const Figure = styled(motion.div)`
  display: flex;
  flex-direction: row;
  font-family: "Arial";
  letter-spacing: 1px;
  margin: 1.75rem 2rem 1rem 2rem;
  padding-bottom: 1.5rem;
  border-bottom: white solid 1px;
  touch-action: pan-y;

  .rightText {
    width: 50%;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .figureMetric {
    font-size: 1.25rem;
    font-family: "BeausiteGrandTrial-Light";
  }

  .subHeading {
    font-size: 0.5rem;
  }

  .figureType {
    font-size: 0.75rem;
  }
`

export const Figures = styled(motion.div)`
  display: flex;
  touch-action: pan-y;
  flex-direction: column;
`

export const Paragraph = styled(motion.div)`
  padding: 1rem 2rem;
  font-size: 1rem;
  line-height: 1.5rem;
  touch-action: pan-y;
`

export const Title = styled(motion.div)`
  font-size: 2.5rem;
  font-family: "BeausiteGrandTrial-Light";
  text-align: start;
  padding: 2rem 2rem 1rem 2rem;
  color: white;
  touch-action: pan-y;
  word-break: break-word;

  span {
    font-size: 1.25rem;
    font-family: "BeausiteFitTrial-Regular";
    color: ${({ theme }) => theme.darkGreen};
    opacity: 0.75;
  }

  .unitType {
    font-size: 1.75rem;
    color: black;
    opacity: 0.75;
    padding: 0 5px 0 0;
  }
`

export const Image = styled(motion.img)`
  width: 100%;
  // margin: 10%;
  padding: 0 0 0 2rem;
  touch-action: pan-y;
  cursor: pointer;
`

export const Desc = styled(motion.div)`
  font-size: 1.35rem;
  font-family: "BeausiteGrandTrial-Light";
  padding-bottom: 2rem;
  margin: 1.75rem 2rem 1rem 2rem;
  border-bottom: white solid 1px;
  touch-action: pan-y;
`

const UnitDetails = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  .container {
    display: flex;
    flex-direction: column;
    margin: auto 2rem;
  }

  .container > :nth-child(1) {
    border-radius: 5px 5px 0 0;
  }

  .container > :nth-child(8) {
    border-radius: 0 0 5px 5px;
  }

  .table {
    border: rgba(256, 256, 256, 0.5) solid 0.5px;
    padding: 15px 15px;
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .logo {
    width: 15%;
    object-fit: contain;
    border: rgba(256, 256, 256, 0.5) solid 1px;
    border-radius: 5px;
    padding: 8px;
  }

  .unitMetrics {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .type {
    font-size: 1rem;
    font-family: "BeausiteFitTrial-Regular";
  }

  .value {
    font-size: 0.75rem;
    font-family: "Arial";
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    gap: 1rem;
    padding: 2rem;
  }
`
