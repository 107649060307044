import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { DiamondButton } from "@components/buttons"
import { motion, AnimatePresence } from "framer-motion"
import FeatureText from "../FeatureText"
import { useStore } from "@state/store"
import ListView from "../ListView"
import StaggeredText from "@components/StaggeredText"
interface DetailsProps {
  data?: {
    heading1?: string
    heading2?: string
    subText?: string
    subText2?: string
    feature1?: string
    type1?: string
    feature2?: string
    type2?: string
    feature3?: string
    type3?: string
    img?: string
    list?: {
      [key: string]: string
    }
  }
}

type ListItem = {
  text1?: string
  text2?: string
}

const Details2: React.FC<DetailsProps> = ({ data }: DetailsProps) => {
  const {
    heading1,
    heading2,
    subText,
    subText2,
    feature1,
    type1,
    feature2,
    type2,
    feature3,
    type3,
    img,
  } = data

  const listItems: ListItem[] = Object.entries(data.list).map(
    ([key, value]) => ({
      text1: key,
      text2: value,
    })
  )

  const features2 = [
    { text1: feature1, type: type1 },
    { text1: feature2, type: type2 },
    { text1: feature3, type: type3 },
  ]

  const delay = useStore((s) => s.featuresAnimationDelay)
  const showDetails = useStore((s) => s.showDetails)
  const setLocalState = useStore((s) => s.setLocalState)

  const unshowDetails = (): void => {
    setLocalState({ showDetails: false })
  }

  return (
    <AnimatePresence>
      {showDetails && (
        <TabWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          exit={{ opacity: 0, transition: { duration: 0.6, delay: 0.3 } }}
        >
          <img className="svgDesign" src={"/assets/images/features/svgDesign.svg"} onClick={unshowDetails} />
          <TopWrapper>
            <LeftText
              initial={{ x: -5, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1, ease: "easeInOut", delay}}
              exit={{ opacity: 0, transition: { duration: 0.3 } }}
              className="mainText2"
            >
              <div className="subText2"></div>
              <DiamondButton
                className="back"
                onClick={unshowDetails}
                icon="/assets/icons/common/close-icon.svg"
                size="medium"
                color="brown"
              />
              <div className="headers">
                <StaggeredText text={heading1 ?? ""} cue delay={delay} />
                <StaggeredText text={heading2 ?? ""} cue delay={delay} />
              </div>
              <motion.div
                className="subText2"
                initial={{ x: -15, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1, ease: "easeInOut", delay}}
                exit={{ opacity: 0, transition: { duration: 0.3 } }}
              >
                {subText}
              </motion.div>
            </LeftText>
            <ImageContainer
              src={"/assets/images/features/3.jpg"}
              initial={{ x: 5, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1, ease: "easeInOut", delay}}
              exit={{ opacity: 0, transition: { duration: 0.3 } }}
            />
          </TopWrapper>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
            <motion.div
              className="features2"
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, ease: "easeInOut", delay}}
              exit={{ opacity: 0, transition: { duration: 0.3 } }}
            >
              {features2.map((feature, index) => (
                <FeatureWrapper key={index}>
                  <FeatureText text1={feature.text1} type={feature.type} />
                </FeatureWrapper>
              ))}
            </motion.div>
            <motion.div
              className="subText2 variant"
              initial={{ y: 5, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, ease: "easeInOut", delay}}
              exit={{ opacity: 0, transition: { duration: 0.3 } }}
            >
              {subText2}
            </motion.div>
          </div>

          <BottomView
            initial={{ y: 25, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, ease: "easeInOut", delay}}
            exit={{ opacity: 0, transition: { duration: 0.3 } }}
          >
            <div className="imageContainer">
              <img className="image" src={"/assets/images/features/4.jpg"} alt="" />
              <div
                style={{
                  width: "90%",
                  marginLeft: "auto",
                  textAlign: "right",
                  fontSize: "0.8rem",
                  fontFamily: "Arial",
                  lineHeight: "1.5rem",
                }}
              >
                DSQ is part of Diriyah 1 which is a pedestrian oriented
                development that oversees the natural oasis of
                Wadi Hanifah encouraging walkability.​
              </div>
            </div>
            <div
              style={{
                width: "15%",
                fontSize: "0.7rem",
                fontFamily: "Arial",
                textTransform: "uppercase",
                backgroundColor: "white",
              }}
            >
              Key sustainability considerations and targets that form DGDA’s
              Sustainability Framework:
            </div>
            <div style={{ width: "45%" }}>
              <ListView listItems={listItems} />
            </div>
          </BottomView>
        </TabWrapper>
      )}
    </AnimatePresence>
  )
}

export default Details2

const TabWrapper = styled(motion.div)`
  z-index: 200;
  background-color: white;
  font-family: "BeausiteGrandTrial-Light";
  width: 100%;
  height: 100%;
  font-weight: 200;
  position: absolute;
  color: ${({ theme }) => theme.darkGreen};
  display: flex;
  padding-right: 30px;
  flex-direction: column;
  gap: 4rem;
  opacity: 1;
  padding-bottom: 10rem;
  pointer-events: all;

  .bottomsubText2 {
    margin: auto auto auto auto;
    font-family: "BeausiteClassicTrial-Clear";
    font-size: 1rem;
  }

  .features2 {
    display: flex;
    margin: auto 0;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: var(--margin);
    width: 74%;
    gap: 2rem;
  }
  .mainText2 {
    line-height: 5rem;
    font-size: 5rem;
  }

  .subText2 {
    width: 70%;
    font-size: 1rem;
    font-family: "BeausiteClassicTrial-Clear";
    text-align: right;
    margin: 2rem 0 auto auto;
    line-height: 25px;
  }

  .variant {
    text-align: left;
    margin: auto 0;
  }

  #Icons {
    transform: rotate(-45deg);
    transform-origin: center;
  }

  .svgDesign {
    position: absolute;
    // left: 20px;
    opacity: 0.5;
  }

  .back {
    width: 2.5rem;
    height: 2.5rem;
    margin-top: 2rem;
    position: relative;
    right: -95%;
    top: -5%;
  }

  .headers {
    text-align: right;
  }

  overflow-y: auto;
`

const ImageContainer = styled(motion.img)`
  width: 50%;
  height: 70vh;
  object-fit: cover;
`

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`

const LeftText = styled(motion.div)`
  width: 50vw;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  // background-color:red;
`

const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
  border-radius: 1px;
  gap: 1rem;
  // width: calc(100%/4);
`

const BottomView = styled(motion.div)`
  width: 100%;
  display: flex;
  gap: 5%;

  .imageContainer {
    width: 30%;
    height: 100%;
  }

  .image {
    width: 100%;
    height: 70%;
    object-fit: cover;
  }
`
