import { useStore } from "@state/store"
import React, { useEffect, useRef, useState } from "react"
import SVG from "react-inlinesvg"
import { Panel } from "@components/panels"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import riyadhMap from "@assets/images/vision/map-riyahd.svg"

type RiyadhMapProps = {
  visible: boolean
  activeMap: string
}

const RiyadhMap: React.FC<RiyadhMapProps> = ({ visible, activeMap }) => {
  const navigate = useNavigate()
  const riyadhMapRef = useRef(null)
  const [svgLoaded, setSvgLoaded] = useState(false)
  const { setRiyadhPanel, riyadhPanel } = useStore((state) => ({
    riyadhPanel: state.riyadhPanel,
    setRiyadhPanel: state.setRiyadhPanel,
  }))

  useEffect(() => {
    const riyadhMap = riyadhMapRef.current

    if (!riyadhMap || !svgLoaded) return

    const labelConfigurations = [
      {
        id: "#Label-KingKhalid",
        action: () => setRiyadhPanel("airport"),
        logMessage: "CLICK KING KHALID",
      },
      {
        id: "#Label-KingSalman",
        action: () => setRiyadhPanel("king-salman-park"),
        logMessage: "CLICK KING SALMAN PARK",
      },
      {
        id: "#Label-KAFD",
        action: () => setRiyadhPanel("kafd"),
        logMessage: "CLICK KAFD",
      },
      {
        id: "#Label-KingdomTower",
        action: () => setRiyadhPanel("kingdom-tower"),
        logMessage: "CLICK KINGDOM TOWER",
      },
      {
        id: "#Label-Diriyah",
        action: () => navigate("/masterplan"),
        logMessage: "CLICK",
      },
    ]

    const cleanupFunctions = []
    let activeRects = []

    labelConfigurations.forEach(({ id, action, logMessage }) => {
      const label = riyadhMap.querySelector(id)
      if (label) {
        const rects = label.querySelectorAll("rect")

        label.style.cursor = "pointer"
        label.setAttribute("class", visible ? "show" : "hide")
        const onClickHandler = () => {
          action()

          // Reset styles for previously active rects
          activeRects.forEach((rect) => {
            rect.style.stroke = ""
            rect.style.strokeWidth = ""
            rect.style.transition = "all 0.6s"
          })

          // Apply styles to the current rects to indicate it is active
          rects.forEach((rect: any) => {
            rect.style.stroke = "#AF9781"
            rect.style.strokeWidth = "10px"
            rect.style.transition = "all 0.6s"
          })

          // Update the reference to point to the current active rects
          activeRects = Array.from(rects)
        }
        label.addEventListener("click", onClickHandler)

        // Cleanup function logic remains unchanged
        cleanupFunctions.push(() =>
          label.removeEventListener("click", onClickHandler),
        )
      }
    })

    return () => {
      cleanupFunctions.forEach((cleanup) => cleanup())
      setRiyadhPanel(null)
    }
  }, [visible, svgLoaded])

  return (
    <>
      <StyledSvg
        visible={visible ? "true" : "false"}
        fill="none"
        innerRef={riyadhMapRef}
        src={riyadhMap}
        preserveAspectRatio="xMidYMid slice"
        onLoad={() => {
          setSvgLoaded(true)
        }}
      />
      <StyledPanel
        className="stats-panel"
        visible={visible && riyadhPanel === null}
      >
        <div className="stats-box" style={{ transitionDelay: "0.1s" }}>
          <span className="top-text">A Growing Population</span>
          <div className="mid-text">7 million</div>
          <div className="bottom-text">Current Riyadh population </div>
        </div>
        <div className="stats-box" style={{ transitionDelay: "0.2s" }}>
          <span className="top-text">A vibrant economy</span>
          <div className="mid-text">42.6 million</div>
          <div className="bottom-text">Targeted visitors to Riyadh by 2030</div>
        </div>
        <div className="stats-box" style={{ transitionDelay: "0.3s" }}>
          <span className="top-text">A thriving economy</span>
          <div className="mid-text">44+</div>
          <div className="bottom-text">Multinationals with HQ in Riyadh</div>
        </div>
        <div className="stats-box" style={{ transitionDelay: "0.4s" }}>
          <span className="top-text">Economic prosperity</span>
          <div className="mid-text">50%</div>
          <div className="bottom-text">
            Of the non-oil economy of KSA is in Riyadh
          </div>
        </div>
        <div className="stats-box" style={{ transitionDelay: "0.5s" }}>
          <span className="top-text">A promising future</span>
          <div className="mid-text">229 billion USD</div>
          <div className="bottom-text">National Investment in Riyadh</div>
        </div>
      </StyledPanel>
    </>
  )
}

export default RiyadhMap

//@ts-ignore
const StyledSvg = styled(SVG)<{ visible: string }>`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  z-index: ${({ visible }) => (visible === "true" ? 1 : -1)};
  opacity: ${({ visible }) => (visible === "true" ? 1 : 0)};
  transition: all 1s;

  @media screen and (max-width: 1366px) {
    left: 40%;
  }

  @media screen and (min-width: 1920px) {
    padding: 0 5rem;
  }

  & .visible {
    opacity: 1;
    transform: scale(1) translate(0, 0) !important;
    transform-origin: center;
    transition: all 1s;
  }

  & .hidden {
    opacity: 0;
    transform: scale(0.5) translate(0, 0) !important;
    transform-origin: center;
    transition: all 1s;
  }

  & .show {
    opacity: 1;
  }

  & .hide {
    opacity: 0;
  }
`

const StyledPanel = styled(Panel)<{ visible: boolean }>`
  position: absolute;
  top: 0;
  right: ${({ visible }) => (visible ? "0" : "-23rem")};
  height: 100%;
  width: 23rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row dense;
  justify-items: center;
  z-index: 1;
  padding: 0;
  transition: right 0.6s;

  & .stats-box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    padding: 2rem;
    position: relative;
    right: ${({ visible }) => (visible ? "0%" : "-50%")};
    transition: right .6s ease-in-out;

    &:not(:last-child) {
      border-bottom: 1px solid #ffffff;
    }

    .top-text {
      flex: 1;
    }

    .top-text,
    .bottom-text {
      font-size: 0.7rem;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.darkGreen};
    }

    .bottom-text {
      text-transform: none;
    }

    .mid-text {
      font-size: 2.5rem;
      font-family: BeausiteGrandTrial-Light;
      padding: 0.5rem 0;
    }
  }
`
