import { Unit } from "@pages/favorites/Favorites"
import { create } from "zustand"

interface FavoritesState {
  favorites: Unit[]
  isFavorite: (unitId: string) => boolean
  addToFavorites: (unit: Unit) => void
  removeFromFavorites: (unit: Unit) => void
  clearFavorites: () => void
  loadFavorites: () => void
}

export const useFavoritesStore = create<FavoritesState>()((set, get) => ({
  favorites: [],

  addToFavorites: (unit: Unit) => {
    const updatedFavorites = get().favorites.filter(
      (favorite) => favorite.id !== unit.id
    )

    if (updatedFavorites.length === get().favorites.length) {
      updatedFavorites.push(unit)
    }

    localStorage.setItem("favorites", JSON.stringify(updatedFavorites))
    set({ favorites: updatedFavorites })
  },

  removeFromFavorites: (unit: Unit) => {
    const updatedFavorites = get().favorites.filter(
      (favorite) => favorite.id !== unit.id
    )

    localStorage.setItem("favorites", JSON.stringify(updatedFavorites))
    set({ favorites: updatedFavorites })
  },

  clearFavorites: () => {
    localStorage.removeItem("favorites")
    set({ favorites: [] })
  },

  loadFavorites: () => {
    const storedFavorites = localStorage.getItem("favorites")
    if (storedFavorites) {
      set({ favorites: JSON.parse(storedFavorites) })
    }
  },

  isFavorite: (unitId: string) => {
    return get().favorites.some((favorite) => favorite.id === unitId)
  },
}))
