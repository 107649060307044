import styled from "styled-components"
import { useEffect, useState } from "react"
import { useStore } from "@state/store"
import { useLocation } from "react-router-dom"

export const amenities = [
  {
    name: "WELCOME HALLS",
    link: "welcome-halls",
    icon: "/assets/icons/common/welcomeHalls.svg",
  },
  {
    name: "DRIVER LOUNGES",
    link: "driver-lounges",
    icon: "/assets/icons/common/valet.svg",
  },
  {
    name: "VALET LOUNGES",
    link: "valet-lounges",
    icon: "/assets/images/map/icons/flagIconGreen.svg",
  },
  {
    name: "VIP LOUNGES",
    link: "vip-lounges",
    icon: "/assets/icons/common/star.svg",
  },
  {
    name: "VVIP LOUNGES",
    link: "vvip-lounges",
    icon: "/assets/icons/common/star.svg",
  },
  {
    name: "RESTROOMS",
    link: "restrooms",
    icon: "/assets/icons/common/baby.svg",
  },
]

export const connectionsMain = [
  {
    name: "EV CHARGING",
    link: "ev",
    icon: "/assets/icons/common/ev.svg",
  },
  { name: "BUS", link: "bus", icon: "/assets/icons/common/bus.svg" },
  {
    name: "TAXI / SHARE RIDE",
    link: "taxi",
    icon: "/assets/icons/common/valet.svg",
  },
  {
    name: "VALET",
    link: "valet",
    icon: "/assets/icons/common/drop.svg",
  },
]

const connectionsMasterplan = [
  {
    name: "METRO",
    link: "metro",
    icon: "/assets/icons/common/metro.svg",
  },

  {
    name: "CAR",
    link: "car",
    icon: "/assets/images/map/icons/carIconGreen.svg",
  },
]

const Filter: React.FC<any> = ({ ...props }) => {
  //Location
  const location = useLocation()
  const connections =
    location.pathname === "/masterplan"
      ? connectionsMasterplan
      : connectionsMain

  const unitLevel = useStore((s) => s.unitLevel)
  const amenityCategory = useStore((s) => s.amenityCategory)
  const mapPage = useStore((s) => s.mapPage)
  const setLocalState = useStore((s) => s.setLocalState)
  const [amenitiesOpacity, setAmenitiesOpacity] = useState("none")
  const [amenitiesName, setAmenitiesName] = useState(null)
  const [connectionName, setConnectionName] = useState(null)
  const [connectionNameMasterplan, setConnectionNameMasterplan] = useState(null)

  const [connectionOpacity, setConnectionOpacity] = useState("none")
  const [levelOpacity, setLevelOpacity] = useState("none")

  useEffect(() => {
    setAmenitiesOpacity("none")
    setAmenitiesName(null)
    setConnectionName(null)
    setConnectionNameMasterplan(null)
    setConnectionOpacity("none")
    setLevelOpacity("none")
  }, [location.pathname])

  function handleAmenityClick(category, name) {
    if (category == amenityCategory) {
      setLocalState({
        amenityCategory: null,
      })
      if (openState == "amenities") setAmenitiesName(null)
      else if (openState == "connections") setConnectionName(null)
    } else {
      setLocalState({
        amenityCategory: category,
      })
      if (openState == "amenities") {
        setConnectionName(null)
        setAmenitiesName(name)
      } else if (openState == "connections") {
        setAmenitiesName(null)
        if (location.pathname === "/masterplan")
          setConnectionNameMasterplan(name)
        else if (location.pathname !== "/masterplan") setConnectionName(name)
      }
    }
  }

  function handleLevelClick(level) {
    if (level === unitLevel) {
      setLocalState({
        unitLevel: null,
      })
    } else {
      setLocalState({
        unitLevel: level,
      })
    }
  }

  function reset() {
    setLocalState({
      amenityCategory: null,
      mapCategory: null,
      unitLevel: null,
      unitID: null,
      selectedUnit: null
    })
    setConnectionName(null)
    setConnectionNameMasterplan(null)
    setAmenitiesName(null)
    setOpenState(null)
  }

  const [openState, setOpenState] = useState(null)

  const handleOpenPanel = (type) => {
    if (openState === type) {
      setOpenState(null)
      return
    }

    if (type === "amenities") {
      setOpenState("amenities")
    } else if (type === "connections") {
      setOpenState("connections")
    }
  }

  useEffect(() => {
    if (openState) {
      setLevelOpacity("flex")

      if (openState === "amenities") {
        setAmenitiesOpacity("flex")
        setConnectionOpacity("none")
      } else if (openState === "connections") {
        setConnectionOpacity("flex")
        setAmenitiesOpacity("none")
      }
    } else {
      setLevelOpacity("none")
      setAmenitiesOpacity("none")
      setConnectionOpacity("none")
    }
  }, [openState])

  const mapLevels = ["1", "0", "B1", "B2", "B3"]

  return (
    <Wrapper>
      <div className="filter-container">
        <VideoButton
          onClick={() => {
            setLocalState({ videoOpen: true })
          }}
        >
          <img src="/assets/icons/common/bus.svg" />
        </VideoButton>
        {location.pathname === "/unit-finder" && (
          <div className="levelContainer">
            <div
              className="level-dropdown"
              style={{ flexDirection: "column", display: levelOpacity }}
            >
              {mapLevels.map((level, index) => (
                <div
                  key={index}
                  className={`optionLevel ${
                    unitLevel === level ? "activeFilter" : ""
                  }`}
                  onClick={() => handleLevelClick(level)}
                >
                  {level === "0" ? "G" : level}
                </div>
              ))}
            </div>
            <div className="level-dropdown">
              <img
                style={{ padding: "12px", width: "90%", margin: "auto" }}
                src="/assets/icons/common/stairs.svg"
                alt=""
              />
            </div>
          </div>
        )}
        {location.pathname === "/unit-finder" && (
          <div className="amenityContainer">
            <div
              className="amenity-dropdown"
              style={{ display: amenitiesOpacity }}
            >
              {amenities.map((amenity, index) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "5px",
                  }}
                  key={index}
                  className={`option ${
                    amenityCategory === amenity.link ? "activeFilter" : ""
                  }`}
                  onClick={() => handleAmenityClick(amenity.link, amenity.name)}
                >
                  <img
                    style={{ width: "1rem", color: "red" }}
                    src={amenity.icon}
                  />
                  <div key={index} className="option">
                    {amenity.name}
                  </div>
                </div>
              ))}
            </div>
            <div
              className="amenityToggle"
              onClick={() => handleOpenPanel("amenities")}
            >
              <div>AMENITIES</div>
              <div>{amenitiesName}</div>
              <img
                style={{ marginLeft: "auto" }}
                src="/assets/icons/common/dropdown.svg"
                alt=""
              />
            </div>
          </div>
        )}
        {location.pathname !== "/diriyah-square" && (
          <div className="amenityContainer">
            <div
              className="amenity-dropdown"
              style={{ display: connectionOpacity }}
            >
              {connections.map((connection, index) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "5px",
                  }}
                  key={index}
                  className={`option ${
                    amenityCategory === connection.link ? "activeFilter" : ""
                  }`}
                  onClick={() =>
                    handleAmenityClick(connection.link, connection.name)
                  }
                >
                  <img
                    style={{ width: "1rem", color: "red" }}
                    src={connection.icon}
                  />
                  <div key={index} className="option">
                    {connection.name}
                  </div>
                </div>
              ))}
            </div>
            <div
              className="amenityToggle"
              onClick={() => handleOpenPanel("connections")}
            >
              <div>CONNECTIONS </div>{" "}
              {location.pathname === "/masterplan" && (
                <div>{connectionNameMasterplan}</div>
              )}
              {location.pathname !== "/masterplan" && (
                <div>{connectionName}</div>
              )}
              <img
                style={{ marginLeft: "auto" }}
                src="/assets/icons/common/dropdown.svg"
                alt=""
              />
            </div>
          </div>
        )}
        {amenityCategory !== null && (
          <div className="reset" onClick={() => reset()}>
            <img src="/assets/icons/common/close.svg" />
          </div>
        )}

        {/* <div
          style={{
            opacity: amenityCategory ? 1 : 0,
            pointerEvents: amenityCategory ? "auto" : "none",
          }}
          onClick={() => {
            setConnectionOpacity("none")
            setAmenitiesOpacity("none")
            setLocalState({
              amenityCategory: null,
              unitLevel: "0",
            })
          }}
          className="amenity-title"
        >
          <div>
            <img
              style={{ padding: "14px", width: "80%" }}
              src="/assets/icons/common/close-amenities.svg"
              alt=""
            />
          </div>
          <div>L-{unitLevel}</div>
          <div> | </div>
          <div>{amenityCategory?.replaceAll("-", " ")}</div>
        </div> */}
      </div>
    </Wrapper>
  )
}

export default Filter

const Wrapper = styled.div`
  z-index: 11;
  .filter-container {
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: 20px;
    font-size: 0.6rem;
    left: var(--margin) !important;
    z-index: 2;
    color: white;
    letter-spacing: 1px;
    cursor: pointer;
  }

  .filter-button {
    cursor: pointer;
  }

  .amenity-title {
    display: flex;

    text-transform: uppercase;

    width: 14rem;

    justify-content: flex-start;
    gap: 12px;
    align-items: center;
    padding-left: 20px;
    transition: 0.3s;
    display: flex;
    margin-top: auto;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.darkGreen};
  }

  .level-dropdown {
    transition: 0.3s;
    display: flex;
    margin-top: auto;
    flex-direction: column;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.darkGreen};
  }

  .category {
    padding: 1px;
    display: flex;
    column-gap: 15px;
    color: black;
    cursor: pointer;
    align-items: center;
  }


  .categoryName {
    letter-spacing: 1px;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 0.6rem;
    font-family: "Arial";
    transition: 0.6s;
  }
  
  .amenityContainer {
    flex-direction: column;
    gap: 5px;
    margin-top: auto;
  }

  .levelContainer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .amenityToggle {
    border-radius: 4px;
    min-width: 250px;
    display: flex;
    column-gap: 0.8rem;
    padding: 15px 20px;
    align-items: center;
    text-align: center;
    transition: 0.3s;
    background-color: ${({ theme }) => theme.darkGreen};
  }

  .reset {
    margin-top: auto;
    border-radius: 4px;
    display: flex;
    padding: 13px 13px;
    align-items: center;
    text-align: center;
    transition: 0.3s;
    background-color: ${({ theme }) => theme.darkGreen};
  }

  .amenityToggle:hover {
    background-color: ${({ theme }) => theme.darkGreen}F4;
  }

  .amenity-dropdown {
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 10px;
    border-radius: 4px;
    transition: 0.3s;
    background-color: ${({ theme }) => theme.darkGreen};
  }

  .active {
    border-radius: 2px;
  }

  .activeFilter {
    border-radius: 3px;
    background-color: white;
    color: black;
    transition: 0.6s;
  }

  .option {
    font-family: "Arial";
    padding: 5px 8px;
    text-align: start;
    display: flex;
    justify-content: center;
  }

  .optionLevel {
    font-family: "Arial";
    padding: 15px;
    text-align: start;
    display: flex;
    justify-content: center;
  }

  .option:hover {
    transition: 0.3s;
  }
`

export const VideoButton = styled.div`
  background-color: rgba(var(--dark-green-rgb));
  width: 40px;
  height: 40px;
  left: var(--margin);
  border-radius: 100%;
  display: grid;
  place-content: center;
  pointer-events: all;
  cursor: pointer;
  margin-top: auto;
`
