function preloadVideo(src) {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video")
    video.oncanplaythrough = resolve
    video.onerror = reject
    video.src = src
    video.load()
  })
}

export function preloadAssets(assets) {
  const loadAsset = (asset) => {
    return new Promise((resolve, reject) => {
      if (asset.type === "image") {
        const img = new Image()
        img.onload = resolve
        img.onerror = reject
        img.src = asset.src
      } else if (asset.type === "video") {
        // Assuming preloadVideo returns a promise
        preloadVideo(asset.src).then(resolve).catch(reject)
      }
    })
  }

  return Promise.all(assets.map(loadAsset))
}
