import { useRef } from "react"
import styled from "styled-components"
import unitsSVGData from "@data/unit-finder/unitFinderZoneUnits"
import useClickOverDrag from "@hooks/useClickOverDrag"
import { useStore } from "@state/store"

function getStroke(unitID, id) {
  return id === unitID ? "white" : "black"
}

function UnitsLayer({ active }: { active: boolean }) {
  const { unitLevel, unitID,  setLocalState } = useStore((s) => ({
    unitLevel: s.unitLevel,
    unitID: s.unitID,
    setLocalState: s.setLocalState,
  }))
  const overlayRef = useRef<SVGGElement>(null)
  const { handleUp, handleDown } = useClickOverDrag(200, 50)

  function handlePlotClick(obj) {
    setLocalState({
      unitID: obj.unitID,
      mapCategory: obj.zone,
    })
  }

  function getClassName(cond) {
    return `animateLayer ${cond ? "animateIn" : "animateOut"}`
  }

  return active ? (
    <Group ref={overlayRef}>
      {unitLevel === "B1" && (
        <Group className={getClassName(unitLevel === "B1")}>
          {unitsSVGData["B1"].map((obj, i) => {
            return (
              <polyline
                fillOpacity={0}
                strokeWidth={1}
                key={`unit-${i}`}
                stroke={getStroke(unitID, obj.unitID)}
                fill="transparent"
                points={obj.coords}
                onClick={() => handlePlotClick(obj)}
              />
              // )
            )
          })}
        </Group>
      )}
      {(unitLevel === "0" || !unitLevel) && (
        <Group className={getClassName(unitLevel === "0")}>
          {unitsSVGData["0"].map((obj, i) => {
            return (
              <polyline
                fillOpacity={0}
                strokeWidth={1}
                key={`unit-${i}`}
                stroke={getStroke(unitID, obj.unitID)}
                fill="transparent"
                points={obj.coords}
                onClick={() => handlePlotClick(obj)}
              />
            )
          })}
        </Group>
      )}
      {unitLevel === "1" && (
        <Group className={getClassName(unitLevel === "1")}>
          {unitsSVGData["1"].map((obj, i) => {
            return (
              <polyline
                fillOpacity={0}
                strokeWidth={1}
                key={`unit-${i}`}
                stroke={getStroke(unitID, obj.unitID)}
                fill="transparent"
                points={obj.coords}
                onClick={() => handlePlotClick(obj)}
              />
            )
          })}
        </Group>
      )}
    </Group>
  ) : null
}

export default UnitsLayer

const Group = styled.g`
  & polyline {
    cursor: pointer;
    transition: all 0.4s linear;
    stroke-linecap: round;
  }
`
