import styled from "styled-components"
import { motion } from "framer-motion"
import SVG from "react-inlinesvg"
import { useNavigate } from "react-router-dom"
import splashBg from "@assets/images/splash.svg"
import PageWrapper from "@components/PageWrapper"

const Splash: React.FC<any> = () => {
  const navigate = useNavigate()

  return (
    <PageWrapper>
      <Wrapper style={{ backgroundImage: `url(${splashBg})` }}>
        <div
          className="logo-splash"
          onClick={() =>
            navigate("/vision", { state: { from: location.pathname } })
          }
        >
          <StyledSvg className="logo" src="/logo-eng.svg" />
        </div>
      </Wrapper>
    </PageWrapper>
  )
}

export default Splash

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .logo-splash {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 13rem;
    width: 13rem;
  }

  transition: all 0.3s;
`
//@ts-ignore
const StyledSvg = styled(SVG)<{ active?: string }>`
  width: 100%;
  height: 100%;

  &.logo #text {
    fill: ${({ theme }) => theme.darkGreen};
    stroke: ${({ theme }) => theme.darkGreen};
    stroke-width: 0.5;
    transition: all 0.3s;
  }

  &.logo #bg {
    fill: ${({ theme }) => theme.white};
    stroke: ${({ theme }) => theme.white};
  }
`
