//@ts-nocheck

function EmailTemplate({ name }) {
  const containerStyle = {
    margin: "auto",
    padding: "2em",
    borderRadius: "10px",
    border: "1px solid rgba(0, 0, 0, 0.25)",
    fontFamily:
      "Aptos, Aptos_EmbeddedFont, Aptos_MSFontService, Calibri, Helvetica, sans-serif",
    fontSize: "14px",
    lineHeight: "1.6",
    color: "#000",
    maxWidth: "800px",
    textAlign: "left",
    backgroundColor: "#ffffff",
  }

  const resetStyle = {
    margin: 0,
    padding: 0,
    border: 0,
    verticalAlign: "baseline",
  }

  const listStyle = {
    margin: "1em 0",
    paddingLeft: "1.5em",
    fontWeight: "normal",
  }

  const paragraphStyle = {
    margin: "1em 0",
    fontWeight: "normal",
  }

  const buttonStyle = {
    display: "inline-block",
    margin: "1em 0",
    borderRadius: "5px",
    textDecoration: "underline",
    textAlign: "center",
    fontWeight: "bold",
  }
  return (
    <html
      style={{
        width: "100%",
        maxWidth: "100vw",
        backgroundColor: "#ffffff",
        fontFamily:
          "Aptos, Aptos_EmbeddedFont, Aptos_MSFontService, Calibri, Helvetica, sans-serif",
      }}
    >
      <head></head>
      <body
        style={{
          width: "100%",
          maxWidth: "100vw",
          backgroundColor: "#ffffff",
          margin: 0,
          padding: 0,
        }}
      >
        <table cellSpacing={0} style={{ margin: "auto", padding: "2em 0" }}>
          <tbody>
            <tr>
              <th>
                <div style={containerStyle}>
                  <div style={{ textAlign: "center", marginBottom: "1em" }}>
                    <img
                      src=""
                      alt="Logo"
                      className="email-logo"
                      style={{
                        display: "block",
                        height: "auto",
                        maxHeight: "70px",
                        marginBottom: "1em",
                      }}
                    />
                  </div>
                  <div style={paragraphStyle}>Hello {name},</div>
                  <div style={paragraphStyle}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    ac nunc non metus fermentum aliquet. Proin nec tortor
                    elementum, ultricies ex nec, ultricies ex. Integer
                    scelerisque, nunc nec aliquam tincidunt, turpis metus
                    vehicula justo, a vehicula justo.
                  </div>
                  <div style={{ ...paragraphStyle, fontWeight: "bold" }}>
                    Enclosed please also find the following documents for your
                    review and consideration:
                  </div>
                  <div style={paragraphStyle}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    ac nunc non metus fermentum aliquet. Proin nec tortor
                    elementum, ultricies ex nec, ultricies ex. Integer
                    scelerisque, nunc nec aliquam tincidunt, turpis metus
                    vehicula justo, a vehicula justo.
                  </div>
                  <div
                    style={{
                      ...paragraphStyle,
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    Condominium Pricing Starts at:
                  </div>
                  <ul style={listStyle}>
                    <li>One Bedrooms from: $975,000</li>
                    <li>Two Bedrooms from: $2,650,000</li>
                    <li>Three Bedrooms from: $2,995,000</li>
                    <li>Four Bedrooms from: $5,575,000</li>
                    <li>Five Bedrooms from: $10,275,000</li>
                  </ul>
                  <div style={paragraphStyle}>A paragraph.</div>
                  <div style={paragraphStyle}>Another paragraph.</div>
                  <div style={paragraphStyle}>Thank you,</div>
                  <div style={paragraphStyle}>Diriyah Square Sales Team</div>
                </div>
              </th>
            </tr>
          </tbody>
        </table>
      </body>
    </html>
  )
}

export default EmailTemplate
