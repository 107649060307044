import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { DiamondButton } from "@components/buttons"
import SplitView from "../SplitView"
import { motion, AnimatePresence } from "framer-motion"
import FeatureText from "../FeatureText"
import svgDesign from "@assets/images/features/svgDesign.svg"
import { useStore } from "@state/store"
import features7Img from "@assets/images/features/7.jpg"
import ListView from "../ListView"
import StaggeredText from "@components/StaggeredText"

interface DetailsProps {
  data?: {
    heading1?: string
    heading2?: string
    subText?: string
    subText2?: string
    subText3?: string
    img?: string
  }
}

const Details2: React.FC<DetailsProps> = ({ data }: DetailsProps) => {
  const { heading1, heading2, subText, subText2, subText3, img } = data

  const delay = useStore((s) => s.featuresAnimationDelay)
  const showDetails = useStore((s) => s.showDetails)
  const setLocalState = useStore((s) => s.setLocalState)

  const unshowDetails = (): void => {
    setLocalState({ showDetails: false })
  }

  return (
    <AnimatePresence>
      {showDetails && (
        <TabWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          exit={{ opacity: 0, transition: { duration: 0.6, delay: 0.3 } }}
        >
          <img className="svgDesign" src={svgDesign} onClick={unshowDetails} />
          <TopWrapper>
            <LeftText
              initial={{ x: -5, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1, ease: "easeInOut", delay}}
              exit={{ opacity: 0, transition: { duration: 0.3 } }}
              className="mainText2"
            >
              <div className="subText2"></div>
              <DiamondButton
                className="back"
                onClick={unshowDetails}
                icon="/assets/icons/common/close-icon.svg"
                size="medium"
                color="brown"
              />
              <div className="headers">
                <StaggeredText text={heading1 ?? ""} cue delay={delay} />
                <StaggeredText text={heading2 ?? ""} cue delay={delay} />
              </div>
              <motion.div
                className="subText2"
                initial={{ x: -15, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1, ease: "easeInOut", delay}}
                exit={{ opacity: 0, transition: { duration: 0.3 } }}
              >
                {subText}
              </motion.div>
            </LeftText>
            <ImageContainer
              src={img}
              initial={{ x: 5, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1, ease: "easeInOut", delay}}
              exit={{ opacity: 0, transition: { duration: 0.3 } }}
            />
          </TopWrapper>
        </TabWrapper>
      )}
    </AnimatePresence>
  )
}

export default Details2

const TabWrapper = styled(motion.div)`
  z-index: 200;
  background-color: white;
  font-family: "BeausiteGrandTrial-Light";
  width: 100%;
  height: 100%;
  font-weight: 200;
  position: absolute;
  color: ${({ theme }) => theme.darkGreen};
  display: flex;
  flex-direction: column;
  opacity: 1;
  padding-bottom: 10rem;
  padding-left: var(--margin);
  pointer-events: all;

  .mainText2 {
    font-size: 5rem;
  }

  .subText2 {
    width: 70%;
    font-size: 1rem;
    font-family: "BeausiteClassicTrial-Clear";
    text-align: right;
    margin: 2rem 0 auto auto;
    line-height: 25px;
  }

  #Icons {
    transform: rotate(-45deg);
    transform-origin: center;
  }

  .svgDesign {
    position: absolute;
    // left: 20px;
    opacity: 0.5;
  }

  .back {
    width: 2.5rem;
    height: 2.5rem;
    margin-top: 2rem;
    position: relative;
    right: -95%;
    top: -5%;
  }

  .headers {
    text-align: right;
  }
`

const ImageContainer = styled(motion.img)`
  width: 55%;
  height: 90vh;
  object-fit: cover;
`

const TopWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 2rem;
`

const LeftText = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
