import d from "@assets/images/features/d.jpg"

export const paths = {
  "welcome-halls": [
    { src: "/assets/images/amenities/welcome-halls/full/atrium-2.webp" },
    { src: "/assets/images/amenities/welcome-halls/full/atrium.webp" },
    { src: "/assets/images/amenities/welcome-halls/full/coach.webp" },
    { src: "/assets/images/amenities/welcome-halls/full/coach-2.webp" },
    { src: "/assets/images/amenities/welcome-halls/full/exterior.webp" },
    { src: "/assets/images/amenities/welcome-halls/full/lobby.webp" },
    { src: "/assets/images/amenities/welcome-halls/full/lounge.webp" },
    { src: "/assets/images/amenities/welcome-halls/full/scalator.webp" },
  ],
  "valet-lounges": [
    { src: "/assets/images/amenities/valet/full/desk.webp" },
    { src: "/assets/images/amenities/valet/full/entrance.webp" },
    { src: "/assets/images/amenities/valet/full/lounge.webp" },
    { src: "/assets/images/amenities/valet/full/terrace.webp" },
  ],
  "vip-lounges": [
    { src: "/assets/images/amenities/vip/full/ground.webp" },
    { src: "/assets/images/amenities/vip/full/lobby.webp" },
    { src: "/assets/images/amenities/vip/full/private-suite.webp" },
    { src: "/assets/images/amenities/vip/full/terrace.webp" },
  ],
  "vvip-lounges": [
    { src: "/assets/images/amenities/vvip/full/drop-off.webp" },
    { src: "/assets/images/amenities/vvip/full/ground.webp" },
    { src: "/assets/images/amenities/vvip/full/lounge.webp" },
    { src: "/assets/images/amenities/vvip/full/main-lounge.webp" },
    { src: "/assets/images/amenities/vvip/full/private-suite.webp" },
    { src: "/assets/images/amenities/vvip/full/private-suite-2.webp" },
    { src: "/assets/images/amenities/vvip/full/private-suite-3.webp" },
    { src: "/assets/images/amenities/vvip/full/restroom.webp" },
    { src: "/assets/images/amenities/vvip/full/terrace.webp" },
  ],
  restrooms: [{ src: d }],
  "retail-concierge": [
    { src: "/assets/images/amenities/welcome-halls/full/coach.webp" },
  ],
}

const guestAmenitiesData = {
  features: [
    {
      text1: "Welcome",
      text2: "Halls",
      type: "Branded Residential units",
      link: "welcome-halls",
    },
    {
      text1: "VIP",
      text2: "Lounges",
      type: "6 Luxirious suites, 3 levels of comfort",
      link: "vip-lounges",
    },
    {
      text1: "VVIP",
      text2: "Lounges",
      type: "Four Ultra Luxury Suites",
      link: "vvip-lounges",
    },
    {
      text1: "Family",
      text2: "Facilities",
      type: "Facilities to cater for all ages",
      link: "restrooms",
    },
    {
      text1: "Retail",
      text2: "Concierge",
      type: "Located throughout Diriyah Square",
    },
    {
      text1: "Valet",
      text2: "Lounges",
      type: "Valet parking services",
      link: "valet-lounges",
    },
  ],
  details: [
    {
      heading1: "Welcome",
      heading2: "Halls",
      subText:
        "Step into a realm of refined elegance and personalised attention as you approach any one of the nine welcome halls. You will be warmly welcomed by our friendly staff, poised to assist with any inquiries or special requests as part of your Diriyah Square experience and before embarking on your shopping adventure.",
      feature1: "9",
      type1: "Luxurious arrival suites",
      img: paths["welcome-halls"][0].src,
      popupArray: paths["welcome-halls"],
    },
    {
      heading1: "VIP",
      heading2: "Lounges",
      subText:
        "The VIP Lounge consists of 6 private suites, reception area, shared lounge spaces and restrooms. The VIP facilities are split over 3 levels within the central Welcome Hall building, the VIP elevator takes guests directly to the main facilities.",
      feature1: "6",
      type1: "Luxurious private suites",
      img: paths["vip-lounges"][2].src,
      popupArray: paths["vip-lounges"],
    },
    {
      heading1: "VVIP",
      heading2: "Lounges",
      subText:
        "The VVIP Lounge consists of 4 ultra luxury suites, a reception area, and an outdoor terrace with private access through a dedicated drop off area and welcome lobby. A private elevator takes guests directly to the first floor where the luxury suites are located.",
      feature1: "4",
      type1: "Ultra Luxury suites",
      img: paths["vvip-lounges"][4].src,
      popupArray: paths["vvip-lounges"],
    },
    {
      heading1: "Family Friendly",
      heading2: "Facilities",
      subText:
        "Family-friendly amenities and restrooms aim to create an inclusive and enjoyable experience catering to the needs of parents and children alike. These amenities include dedicated play areas equipped with safe and engaging equipment for children, along with family restrooms featuring changing facilities and nursing areas.",
      img: paths["restrooms"][0].src,
      popupArray: paths["restrooms"],
    },
    {
      heading1: "Retail",
      heading2: "Concierge",
      subText:
        "Located throughout Diriyah square, our concierge desks are dedicated to assisting guests with a wide range of services. From providing precint information and handling customer feedback to offering wheelchair and stroller rentals, purchasing gift cards, booking chauffeur services, and facilitating convenient shopping options like Shop and Drop, Click and Collect and Shop and Deliver, and Airport check in.",
      type1:
        "Our concierge team is committed to ensuring every guest's needs are met with efficiency and care.",
      img: paths["retail-concierge"][0].src,
      popupArray: paths["retail-concierge"],
    },
    {
      heading1: "Valet",
      heading2: "Lounges",
      subText:
        "Our valet parking service ensures a seamless transition from car to entrance, setting the stage for a truly luxurious shopping experience.",
      img: paths["valet-lounges"][2].src,
      popupArray: paths["valet-lounges"],
    },
  ],
}

export default guestAmenitiesData
