import mapGlobalValues from "../mapGlobalValues"

const baseConfig = {
  icon: mapGlobalValues.iconPaths.green.eye,
  bgColor: mapGlobalValues.zoneIconBackgroundColor,
  textColor: mapGlobalValues.zoneIconTextColor,
  strokeColor: "white",
  strokeWidth: 1,
  iconPosition: "left",
  iconShape: "circle",
  height: 30,
}

const data = [
  {
    label: "DIRIYAH SQUARE",
    activeState: "dsq",
    x: 860.66,
    y: 1051.74,
  },
  {
    label: "QURAIN CULTURAL DISTRICT",
    activeState: "qurain",
    x: 1050.48,
    y: 890,
  },
  {
    label: "KING SALMAN KNOWLEDGE DISTRICT",
    activeState: "king",
    x: 1112.68,
    y: 746.55,
  },
  {
    label: "EVENTS DISTRICT",
    activeState: "events-district",
    x: 1125.83,
    y: 574.94,
  },
  {
    label: "DIRIYAH SOUTH",
    activeState: "diriyah-south",
    x: 966.13,
    y: 1248.43,
  },
  {
    label: "DIRIYAH GARDENS",
    activeState: "diriyah-gardens",
    x: 833.42,
    y: 1365.58,
  },
  {
    label: "BUJAIRI",
    activeState: "bujairi",
    x: 836.08,
    y: 608.22,
  },
  {
    label: "SAMHAN",
    activeState: "samhan",
    x: 673.83,
    y: 498,
  },
  {
    label: "CREATIVE DISTRICT",
    activeState: "creative-district",
    x: 1394.12,
    y: 898.63,
  },
  {
    label: "BOULEVARD DISTRICT",
    activeState: "boulevard-district",
    x: 1289.12,
    y: 1051.74,
  },
  {
    label: "MEDIA AND INNOVATION DISTRICT",
    activeState: "media-district",
    x: 1162.52,
    y: 1284.89,
  },
  {
    label: "CIVIC QUARTER",
    activeState: "civic-quarter",
    x: 1206.08,
    y: 841.01,
  },
  {
    label: "KSU DISTRICT",
    activeState: "ksu-district",
    x: 1528.72,
    y: 836,
  },
  {
    label: "KHUZAMA EAST",
    activeState: "khuzama-east",
    x: 1652.67,
    y: 1207.39,
  },
  {
    label: "KHUZAMA WEST",
    activeState: "khuzama-west",
    x: 1357.41,
    y: 1239.02,
  },
  {
    label: "GATEWAY",
    activeState: "gateway",
    x: 1303.38,
    y: 505.46,
  },
]

const masterplanZoneIconsData = data.map((item) => {
  return item.label === "DIRIYAH SQUARE"
    ? {
        ...item,
        bgColor: mapGlobalValues.mainIconBackgroundColor,
        textColor: mapGlobalValues.mainIconTextColor,
        icon: mapGlobalValues.iconPaths.white.arrow,
        strokeColor: "white",
        strokeWidth: 1,
        iconPosition: "right",
        iconShape: "diamond",
        height: 50,
      }
    : {
        ...item,
        ...baseConfig,
      }
})

export default masterplanZoneIconsData

const baseMetroConfig = {
  icon: mapGlobalValues.iconPaths.green.bus,
  bgColor: "#004344",
  textColor: mapGlobalValues.zoneIconTextColor,
  strokeColor: "white",
  strokeWidth: 1,
  iconPosition: "left",
  iconShape: "circle",
  height: 35,
}

const dataMetro = [
  {
    x: 1135.56,
    y: 985.59,
  },
  {
    x: 987.75,
    y: 1380.5,
  },
  {
    x: 1218.31,
    y: 625.89,
  }
]

export const masterplanMetroIconsData = dataMetro.map((item) => {
  return {
    ...item,
    ...baseMetroConfig,
  }
})
