import { useRef, memo } from "react"
import styled from "styled-components"

import MapSVGIconGroup from "@components/MapSVGIconGroup"

import { iconIndex } from "@data/mapGlobalValues"
import { amenityData } from "@data/map/amenity-data"
import useClickOverDrag from "@hooks/useClickOverDrag"
import { paths } from "@pages/features/data/guestAmenities"
import { useStore } from "@state/store"
import { useMap } from "react-leaflet"

const data = amenityData.filter((obj) => obj.mapID === "diriyah-square")

function AmenitiesLayer({ active }: { active: boolean }) {
  const { amenityCategory, unitLevel, setLocalState } = useStore((s) => ({
    amenityCategory: s.amenityCategory,
    unitLevel: s.unitLevel,
    setLocalState: s.setLocalState,
  }))
  const overlayRef = useRef<SVGGElement>(null)
  const layerTranslate = [8, -8]
  const { handleUp, handleDown } = useClickOverDrag()
  const MAP = useMap()
  const zoom = MAP.getZoom()

  function handlePlotClick(state) {
    switch (state) {
      case "vip-lounges":
        setLocalState({
          swiperGalleryAmenity: state,
          swiperGalleryData: paths["vip-lounges"],
          swiperGalleryOpen: true,
        })
        break
      case "vvip-lounges":
        setLocalState({
          swiperGalleryAmenity: state,
          swiperGalleryData: paths["vvip-lounges"],
          swiperGalleryOpen: true,
        })
        break
      case "restrooms":
        setLocalState({
          swiperGalleryAmenity: state,
          swiperGalleryData: paths.restrooms,
          swiperGalleryOpen: true,
        })
        break
      case "welcome-halls":
        setLocalState({
          swiperGalleryAmenity: state,
          swiperGalleryData: paths["welcome-halls"],
          swiperGalleryOpen: true,
        })
        break
      case "driver-lounges":
        setLocalState({
          swiperGalleryAmenity: state,
          swiperGalleryData: paths["valet-lounges"],
          swiperGalleryOpen: true,
        })
        break
      case "valet-lounges":
        setLocalState({
          swiperGalleryAmenity: state,
          swiperGalleryData: paths["valet-lounges"],
          swiperGalleryOpen: true,
        })
        break
      default:
        console.log(state)
        break
    }
  }

  function getClassName(cond) {
    return `animateLayer ${cond ? "animateIn" : "animateOut"}`
  }
  return active ? (
    <Group
      transform={`translate(${layerTranslate[0]} ${layerTranslate[1]})`}
      ref={overlayRef}
    >
      {data.map((obj, i) => {
        const active =
          (unitLevel === null ? obj.level === "0" : obj.level === unitLevel) &&
          amenityCategory === obj.activeState
        return (
          active && (
            <Group
              id="amenity-icon"
              style={{
                transform: `scale(${scales[Math.floor(zoom)]})`,
                transformOrigin: "center",
                transformBox: "fill-box",
              }}
              key={`amenityIcon${i}`}
            >
              <MapSVGIconGroup
                x={obj.x}
                y={obj.y}
                label={null}
                icon={iconIndex[obj.activeState]}
                bgColor="#104344"
                hasIcon={true}
                onPointerDown={handleDown}
                onPointerUp={(e) => {
                  handleUp(e, () => handlePlotClick(obj.activeState))
                }}
                className={getClassName(active)}
                height={40}
              />
            </Group>
          )
        )
      })}
    </Group>
  ) : null
}

export default AmenitiesLayer

const Group = styled.g<{ $zoom?: number }>`
  & g {
    cursor: pointer;
    transition: all 0.4s linear;
    stroke-linecap: round;
  }
`

const scales = {
  4: 1,
  5: 0.75,
  6: 0.5,
  7: 0.3,
  8: 0.2,
}
