import { useEffect, useRef, FC } from "react"
import ApexCharts, { ApexOptions } from "apexcharts"

type BarChartProps = {
  title: string
  data: number[]
  yaxismax?: number
  categories?: string[]
  yaxisTitle?: string
}

const BarChart: FC<BarChartProps> = ({
  title,
  data,
  yaxismax,
  categories,
  yaxisTitle,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const barChart = new ApexCharts(
      chartRef.current,
      barChartOptions(title, data, yaxismax, categories, yaxisTitle),
    )
    if (barChart) {
      barChart.render()
    }

    return barChart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef])

  return <div style={{ width: "100%", height: "100%" }} ref={chartRef}></div>
}

const barChartOptions = (
  title: string,
  data: number[],
  yaxismax?: number,
  categories?: string[],
  yaxisTitle?: string,
): ApexOptions => {
  const labelColor = "#004344"

  return {
    responsive: [
      {
        breakpoint: 1367,
        options: {
          chart: {
            height: "100%",
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    series: [
      {
        name: title,
        data: data,
      },
    ],
    title: {
      text: title,
      align: "left",
      style: {
        fontSize: "16px",
        color: "#004344",
        fontWeight: "400",
        fontFamily: "BeausiteClassicTrial-Clear",
      },
    },
    chart: {
      fontFamily: "inherit",
      type: "bar",
      height: "100%",
      width: "100%",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    colors: ["#b2c6c6"],
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: 50,
        dataLabels: {
          position: "bottom", // top, center, bottom
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      formatter(val, opts) {
        const formattedVal = Number(val).toLocaleString()
        return formattedVal + " mn"
      },
      style: {
        fontSize: "14px",
        fontWeight: "400",
        colors: [labelColor],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: "#004344",
          fontSize: "13px",
        },
      },
    },
    yaxis: {
      max: yaxismax,
      title: {
        text: yaxisTitle,
        offsetX: 5,
        style: {
          color: "#004344",
          fontSize: "13px",
          fontWeight: 400,
        },
      },
      labels: {
        show: true,
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
  }
}

export { BarChart }
