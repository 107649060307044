import React from "react"
import styled from "styled-components"

import StaggeredText from "@components/StaggeredText"

import { useStore } from "@state/store"

const guestAmenities = "/assets/images/amenities/welcome-halls/full/atrium-2.webp"
const team1 = "/assets/images/team/1.png"
const team2 = "/assets/images/team/2.png"
const team3 = "/assets/images/team/3.png"
const team4 = "/assets/images/team/4.png"
const team5 = "/assets/images/team/5.png"
const team6 = "/assets/images/team/6.png"
const team7 = "/assets/images/team/7.png"
const team8 = "/assets/images/team/8.png"
const team9 = "/assets/images/team/9.png"
const team10 = "/assets/images/team/10.png"

const consultants = [
  {
    img: team7,
    label: "Conceptual Design Consultant",
  },
  {
    img: team2,
    label: "Multidisciplinary Design Consultants",
    // label: "Design Consultants",
  },
  { img: team5, label: "Project Manager" },
  { img: team6, label: "Landscape Architect" },
  { img: team9, label: "Shade Specialist" },
  { img: team8, label: "Specialist Lighting" },
  {
    img: team1,
    label: "Fire and Life Safety",
  },
  {
    img: team4,
    label: "Market Research Retail",
  },
  {
    img: team10,
    label: "Market Research Leisure",
  },
  {
    img: team3,
    label: "Signage & Wayfinding",
  },
]

const Overview: React.FC = () => {
  const navigating = useStore((s) => s.navigating)
  const navDurationSec = useStore((s) => s.navDurationSec)

  const textAnimationDelay = (n) => navDurationSec * 0.75 + n * 0.25
  const textAnimationDuration = 0.5

  const mainText = [
    "Building a historical",
    "cultural and lifestyle",
    "destination.",
  ]

  const subText = [
    "Diriyah company is a public investment fund entity that",
    "transforming Diriyah into a global, cultural, historical, and",
    "lifesttyle hub and positioning it as a leading world gathering site.",
  ]

  const navAnimStyle = {
    animationName: navigating ? "fadeOut" : "fadeIn",
    animationDelay: navigating ? `${navDurationSec}s` : "0.8s",
  }

  return (
    <TabWrapper>
      <img className="svgDesign" src={"/assets/images/features/svgDesign.svg"} />
      <TopWrapper>
        <LeftText>
          <div>
            {subText.map((line, i) => {
              return (
                <StaggeredText
                  text={line}
                  cue={!navigating}
                  className="subText"
                  delay={textAnimationDelay(i)}
                  duration={textAnimationDuration}
                  key={`subTextLine${i}`}
                />
              )
            })}
          </div>
          <div>
            {mainText.map((line, i) => {
              return (
                <StaggeredText
                  text={line}
                  cue={!navigating}
                  className="mainText"
                  delay={textAnimationDelay(i)}
                  duration={textAnimationDuration}
                  key={`mainTextLine${i}`}
                />
              )
            })}
          </div>
        </LeftText>
        <ImageContainer
          src={guestAmenities}
          className="animateOnNav"
          style={navAnimStyle}
        />
      </TopWrapper>
      <LowerWrapper>
        <div className="consultantsContainer">
          {consultants.map((consultants, index) => (
            <div
              key={index}
              style={{
                animationName: navAnimStyle.animationName,
                animationDelay: !navigating ? `${navDurationSec + index * 0.1}s` : "0.8s",
              }}
              className="consultants animateOnNav"
            >
              <img
                src={consultants.img}
                alt="consultant"
                className="consultantLogo"
              />
              <div className="caption">{consultants.label}</div>
            </div>
          ))}
        </div>

        {/* <div className="feature">
                  Ensuring Diriyah's cultural lnadmarks are <br /> complemented by world-class retail offerings, fine- <br />  be
                  dining expericnes and leading hospitalty brands
        </div> */}
      </LowerWrapper>
    </TabWrapper>
  )
}

export default Overview

const TabWrapper = styled.div`
  font-family: "BeausiteGrandTrial-Light";
  width: 100%;
  font-weight: 200;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-left: var(--margin);

  .consultantsContainer {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1);
    border-radius: 8px;
    width: 70%;
  }
  
  .consultants {
    height: 8vh;
    border: white solid 0.5px;
    padding: 10px 10px;
    display: flex;
    row-gap: 0px !important;
    flex-direction: column;
    background-color: #f1f1ef; 
  }

  .consultantLogo {
    width: 125px;
    margin: auto auto 0 auto;
    height: 100%;
    object-fit: contain;
  }
  .caption {
    font-size: 0.6rem;
    font-family: "BeausiteGrandTrial-Light";
    text-align: center;
  }

  .button {
    position: fixed;
    bottom: 10%;
    right: 2%;
    width: 200px;
    box-shadow: none;
  }

  .button:hover {
    opacity: 0.75;
  }

  .feature {
    margin-left: auto;
    font-size: 1.25rem;
  }
  .mainText {
    font-size: 4.5rem;
    color: var(--light-brown);
  }

  .subText {
    font-size: 1rem;
    text-align: right;

    :nth-of-type(1) {
      margin: 2rem 0 0rem 0;
    }
  }

  // @media only screen and (min-width: 1921px) {
  //   gap: 6rem;

  //   .mainText {
  //     font-size: 6rem;
  //   }

  //   .subText {
  //     font-size: 1.5rem;
  //     text-align: right;
  //     line-height: 1.2;
  //   }
  // }

  .svgDesign {
    position: absolute;
    left: 0;
    opacity: 0.5;
  }
`

const TopWrapper = styled.div`
  height: 60vh;
  display: flex;
  flex-direction: row;
  gap: 2rem;
`

const LowerWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`

const ImageContainer = styled.img`
  width: 45%;
  object-fit: cover;
`

const LeftText = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 7.5rem;

  // @media only screen and (max-height: 800px) {
  //   gap: 6rem;
  // }
`
