import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import gsap from "gsap"
import { useNavigate } from "react-router-dom"

import PageWrapper from "@components/PageWrapper"
import MapTransportLines from "@components/MapTransportLines"
import Filter from "@components/panels/Filter"
import Clouds from "@components/Clouds"
import MapSVGIconGroup from "@components/MapSVGIconGroup"
import { DiamondButton } from "@components/buttons"
import {
  Title,
  Image,
  Figure,
  Figures,
  Paragraph,
  Desc,
} from "@components/panels"

import { useStore } from "@state/store"
import masterplanZoneIconsData, {
  masterplanMetroIconsData,
} from "@data/masterplan/masterplanZoneIcons"
import masterplanZoneOverlayData, {
  masterplanMaskData,
} from "@data/masterplan/masterplanZoneOverlays"
import masterplanCopyData from "@data/side-panel/masterplan"
import getMarkDown from "@utils/getMapMarkDown"

function Masterplan() {
  const {
    navigating,
    navDurationSec,
    setLocalState,
    mapCategory,
    amenityCategory,
  } = useStore((s) => ({
    navigating: s.navigating,
    navDurationSec: s.navDurationSec,
    setLocalState: s.setLocalState,
    mapCategory: s.mapCategory,
    amenityCategory: s.amenityCategory,
  }))
  const navigate = useNavigate()
  const [copy, setCopy] = useState(masterplanCopyData.default)
  const copyRef = useRef<HTMLDivElement>(null)
  const bgRef = useRef<SVGImageElement>(null)

  function revealBG(isThumb) {
    if (!bgRef.current) return
    bgRef.current.setAttribute(
      "href",
      `/assets/images/masterplan/${isThumb ? "thumb" : "full"}/masterplan.webp`
    )
  }

  function handleIconClick(category) {
    if (category === mapCategory) {
      if(category === "dsq"){
        setLocalState({
          navigating: true,
          navOpen: false,
          activeHotel: null,
          amenityCategory: null,
          mapCategory: null,
          unitLevel: null,
          selectedUnit: null,
        })
        setTimeout(() => {
          navigate("/diriyah-square")
        }, navDurationSec * 1100)
      }else{
        setLocalState({ mapCategory: null, unitLevel: null })
      }
    } else {
      setLocalState({ mapCategory: category, unitLevel: "0" })
    }
  }

  useEffect(() => {
    if (!copyRef.current) return
    gsap.to(".masterplan-mask", {
      opacity: (i) => (mapCategory === masterplanMaskData[i].id ? 1 : 0),
    })
    gsap.to(copyRef.current, {
      opacity: 0,
      x: "100%",
      duration: 0.6,
    })
    setTimeout(() => {
      setCopy(
        masterplanCopyData[mapCategory === null ? "default" : mapCategory]
      )
      gsap.set(copyRef.current, { x: "-100%" })
    }, 650)
    gsap.to(copyRef.current, { opacity: 1, x: "0%", delay: 0.7 })
  }, [mapCategory])

  return (
    <PageWrapper
      onStart={() => {
        !navigating && revealBG(navigating)
      }}
    >
      <MasterplanWrapper>
        <SVG
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid slice"
          viewBox="0 0 2048 2048"
          >
          <image
            x={0}
            y={0}
            width={2048}
            height={2048}
            href={"/assets/images/masterplan/thumb/masterplan.webp"}
            ref={bgRef}
          />

          {/* OVERLAYS */}
          <g
            className={`masterplan-overlays ${
              amenityCategory === null && mapCategory === null && !navigating
                ? "show"
                : "hide"
            }`}
            style={{
              transitionDelay:
                amenityCategory === null && mapCategory === null
                  ? `0.9s`
                  : "0s",
            }}
          >
            {masterplanZoneOverlayData.map((obj, i) => {
              return getMarkDown(obj, i, null, null)
            })}
          </g>
          {/* MASKS */}
          <g>
            {masterplanMaskData.map((obj, i) => {
              return getMarkDown(obj, i, null, null)
            })}
          </g>

          <MapTransportLines />
          {/* ZONE ICONS */}
          <g>
            {masterplanZoneIconsData.map((obj, i) => {
              return (
                <MapSVGIconGroup
                  label={obj.label}
                  x={obj.x}
                  y={obj.y}
                  height={obj.height}
                  icon={obj.icon}
                  //@ts-ignore
                  iconPosition={obj.iconPosition}
                  //@ts-ignore
                  iconShape={obj.iconShape}
                  bgColor={obj.bgColor}
                  textColor={obj.textColor}
                  strokeColor={obj.strokeColor}
                  strokeWidth={obj.strokeWidth}
                  hasIcon
                  key={`icon-${i}`}
                  className={`icon-masterplan ${
                    amenityCategory === null && !navigating
                      ? mapCategory === obj.activeState || mapCategory === null
                        ? "show"
                        : "unselected"
                      : "hide"
                  }`}
                  style={{
                    transitionDelay:
                      amenityCategory === null ? `${0.9 + i * 0.015}s` : "0s",
                  }}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleIconClick(obj.activeState)
                  }}
                />
              )
            })}
          </g>
          {/* TRANSPORT ICONS */}
          <g>
            {masterplanMetroIconsData.map((obj, i) => {
              return (
                <MapSVGIconGroup
                  x={obj.x}
                  y={obj.y}
                  height={obj.height}
                  icon={obj.icon}
                  //@ts-ignore
                  iconPosition={obj.iconPosition}
                  //@ts-ignore
                  iconShape={obj.iconShape}
                  bgColor={obj.bgColor}
                  textColor={obj.textColor}
                  strokeColor={obj.strokeColor}
                  strokeWidth={obj.strokeWidth}
                  hasIcon
                  key={`icon-${i}`}
                  className={`icon-masterplan ${
                    amenityCategory === "metro" && !navigating ? "show" : "hide"
                  }`}
                  style={{
                    transitionDelay:
                      amenityCategory === "metro"
                        ? `${0.9 + i * 0.015}s`
                        : "0s",
                  }}
                />
              )
            })}
          </g>
        </SVG>
        <Filter />
        <SidePanelWrapper>
          <DiamondButton
            size="medium"
            color="light-green"
            className={`close-btn-masterplan icon-masterplan ${
              mapCategory === null ? "hide" : "show"
            }`}
            onClick={() => {
              setLocalState({
                mapCategory: null,
              })
            }}
            icon="/assets/icons/common/close.svg"
          />
          <CopyWrapper ref={copyRef}>
            <Title>{copy?.title}</Title>
            <Image
              src={copy?.imgThumb}
              onClick={() => {
                if (!copy?.swipeGalleryData) return
                setLocalState({
                  swiperGalleryData: copy?.swipeGalleryData,
                  swiperGalleryOpen: true,
                })
              }}
            />
            <Desc>{copy?.desc}</Desc>
            {copy?.feature1 && (
              <Figure>
                <div className="subHeading">KEY FIGURES</div>
                <div className="rightText">
                  <Figures>
                    <div className="figureMetric">{copy?.feature1Text}</div>
                    <div className="figureType">{copy?.feature1}</div>
                  </Figures>
                  <Figures>
                    <div className="figureMetric">{copy?.feature2Text}</div>
                    <div className="figureType">{copy?.feature2}</div>
                  </Figures>
                  <Figures>
                    <div className="figureMetric">{copy?.feature3Text}</div>
                    <div className="figureType">{copy?.feature3}</div>
                  </Figures>
                </div>
              </Figure>
            )}
            <Paragraph>{copy?.para}</Paragraph>
          </CopyWrapper>
        </SidePanelWrapper>
      </MasterplanWrapper>
      <Clouds position="top" amount={15} />
      <Clouds position="bottom" amount={15} />
    </PageWrapper>
  )
}

export default Masterplan

const MasterplanWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .masterplan-overlays {
    transition: opacity 0.4s ease-in-out;
  }

  .masterplan-blur {
    transition: opacity 0.4s ease-in-out;
    -webkit-filter: blur(5px);
    filter: blur(5px);
  }

  .icon-masterplan {
    transition-property: opacity;
    transition-duration: 0.4s;
    transition-timing-function: ease-in-out;
    cursor: pointer;
  }

  .unselected {
    pointer-events: all;
    opacity: 0.6;
  }

  .show {
    pointer-events: all;
    opacity: 1;
  }

  .hide {
    pointer-events: none;
    opacity: 0;
  }

  .close-btn-masterplan {
    position: absolute;
    right: var(--margin);
    top: var(--margin);
  }

  .masterplan-mask {
    transition: opacity 0.4s ease-in-out;
    opacity: 0;
    -webkit-filter: blur(20px);
    filter: blur(20px);
  }
`

const SVG = styled.svg`
  width: calc(100% - 26vw);
`

const SidePanelWrapper = styled.div`
  position: absolute;
  z-index: 10;
  width: 26vw;
  height: 100%;
  top: 0;
  right: 0;
  overflow: hidden scroll;
  background-color: var(--light-grey);
  padding-top: 4vw;
`

const CopyWrapper = styled.div`
  position: relative;
  width: 100%;
`
