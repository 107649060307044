import React from "react"
import styled from "styled-components"
import { DiamondButton } from "@components/buttons"
import { motion, AnimatePresence } from "framer-motion"
import { useStore } from "@state/store"
import ListView from "../ListView"
import StaggeredText from "@components/StaggeredText"

interface DetailsProps {
  data?: {
    heading1?: string
    heading2?: string
    subText?: string
    subText2?: string
    subText3?: string
    img?: string
    list?: {
      [key: string]: string
    }
  }
}

type ListItem = {
  text1?: string
  text2?: string
}

const Details2: React.FC<DetailsProps> = ({ data }: DetailsProps) => {
  const { heading1, heading2, subText, subText2, subText3, img } = data

  const listItems: ListItem[] = Object.entries(data.list).map(
    ([key, value]) => ({
      text1: key,
      text2: value,
    })
  )

  const delay = useStore((s) => s.featuresAnimationDelay)
  const showDetails = useStore((s) => s.showDetails)
  const setLocalState = useStore((s) => s.setLocalState)

  const unshowDetails = (): void => {
    setLocalState({ showDetails: false })
  }

  return (
    <AnimatePresence>
      {showDetails && (
        <TabWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          exit={{ opacity: 0, transition: { duration: 0.6, delay: 0.3 } }}
        >
          <img className="svgDesign" src={"/assets/images/features/svgDesign.svg"} onClick={unshowDetails} />
          <TopWrapper>
            <LeftText
              initial={{ x: -5, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1, ease: "easeInOut", delay }}
              exit={{ opacity: 0, transition: { duration: 0.3 } }}
              className="mainText2"
            >
              <div className="subText2"></div>
              <DiamondButton
                className="back"
                onClick={unshowDetails}
                icon="/assets/icons/common/close-icon.svg"
                size="medium"
                color="brown"
              />
              <div className="headers">
                <StaggeredText text={heading1 ?? ""} cue delay={delay} />
                <StaggeredText text={heading2 ?? ""} cue delay={delay} />
              </div>
              <motion.div
                className="subText2"
                initial={{ x: -5, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1, ease: "easeInOut", delay }}
                exit={{ opacity: 0, transition: { duration: 0.3 } }}
              >
                {subText}
              </motion.div>
            </LeftText>
            <ImageContainer
              src={"/assets/images/renders/full/view4.webp"}
              initial={{ x: 5, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1, ease: "easeInOut", delay }}
              exit={{ opacity: 0, transition: { duration: 0.3 } }}
            />
          </TopWrapper>

          <BottomView
            initial={{ y: 25, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, ease: "easeInOut", delay }}
            exit={{ opacity: 0, transition: { duration: 0.3 } }}
          >
            <div className="imageContainer">
              <div className="variant">{subText2}</div>
              <div className=" variant">{subText3}</div>
            </div>
            <div style={{ width: "49%" }}>
              <ListView listItems={listItems} />
            </div>
          </BottomView>
        </TabWrapper>
      )}
    </AnimatePresence>
  )
}

export default Details2

const TabWrapper = styled(motion.div)`
  z-index: 200;
  background-color: white;
  font-family: "BeausiteGrandTrial-Light";
  width: 100%;
  height: 100%;
  font-weight: 200;
  position: absolute;
  color: ${({ theme }) => theme.darkGreen};
  display: flex;
  padding-right: 30px;
  flex-direction: column;
  gap: 5rem;
  opacity: 1;
  padding-bottom: 10rem;
  pointer-events: all;

  .bottomsubText2 {
    margin: auto auto auto auto;
    font-family: "BeausiteClassicTrial-Clear";
    font-size: 1rem;
  }

  .features2 {
    display: flex;
    margin: auto 0;
    flex-direction: row;
    width: 100%;
    gap: 4rem;
  }
  .mainText2 {
    line-height: 5rem;
    font-size: 5rem;
  }

  .subText2 {
    width: 70%;
    font-size: 1rem;
    font-family: "BeausiteClassicTrial-Clear";
    text-align: right;
    margin: 2rem 0 auto auto;
    line-height: 25px;
  }

  .variant {
    width: 90%;
    text-align: right;
    padding-bottom: 1rem;
    font-size: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  #Icons {
    transform: rotate(-45deg);
    transform-origin: center;
  }

  .svgDesign {
    position: absolute;
    // left: 20px;
    opacity: 0.5;
  }

  .back {
    width: 2.5rem;
    height: 2.5rem;
    margin-top: 2rem;
    position: relative;
    right: -95%;
    top: -5%;
  }

  overflow-y: auto;
`

const ImageContainer = styled(motion.img)`
  width: 50%;
  height: 70vh;
  object-fit: cover;
`

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`

const LeftText = styled(motion.div)`
  width: 50vw;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  // background-color:red;
`

const BottomView = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 2rem;

  .imageContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: .5rem;
    gap: 4rem;
  }

  .image {
    width: 100%;
    height: 70%;
    object-fit: cover;
  }
`
