import React, { useState } from "react"
import { EmblaOptionsType } from "embla-carousel"
import useEmblaCarousel from "embla-carousel-react"
import styled from "styled-components"
import { motion } from "framer-motion"
import ShareQr from "@components/ShareQr/ShareQr"
import SVG from "react-inlinesvg"

type PropType = {
  activeCategory?: any
  items: {
    name?: string
    imagePath?: string
    job?: string
    email?: string
    qr?: string
  }[]
  options?: EmblaOptionsType
}

const TeamCarousel: React.FC<PropType> = (props) => {
  const { items, options, activeCategory } = props
  const [emblaRef, emblaApi] = useEmblaCarousel({ ...options, dragFree: true })
  const [activeQR, setActiveQR] = useState(null)

  const showQR = (index: number) => {
    setActiveQR(activeQR === index ? null : index)
  }

  return (
    <Section
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      <div className="embla__viewport2" ref={emblaRef}>
        <div className="embla__container">
          {items.map((item, index) => (
            <div
              className="embla__slide"
              key={index}
              data-team={activeCategory}
            >
              <img
                style={{
                  objectFit:
                    activeCategory === "board" && index === 0
                      ? "cover"
                      : "contain",
                }}
                className="image"
                src={item.imagePath}
                alt={`Slide ${index + 1}`}
              />

              {activeCategory === "leasing" && (
                <>
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      lineHeight: 0,
                      padding: "0.5rem",
                      zIndex: 1,
                    }}
                  >
                    {/* @ts-ignore */}
                    <SVG
                      src="/assets/icons/common/qr-code.svg"
                      onClick={() => showQR(index)}
                      style={{
                        cursor: "pointer",
                        width: "1.5rem",
                        height: "1.5rem",
                      }}
                    />
                  </div>
                  {activeQR === index && <ShareQr url={item.qr} />}
                </>
              )}
              <div className="text-wrapper">
                <div>
                  <p className="job">{item.job}</p>
                  <p className="name">{item.name}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  )
}

export default TeamCarousel

const Section = styled(motion.div)`
  height: auto;
  font-family: "BeausiteGrandTrial-Light";
  font-size: 2rem;
  width: 100%;
  overflow: hidden;
  padding: 0 var(--margin);

  .qrButton {
    border-radius: 5px;
    padding: 12px;
    margin-left: auto;
    background-color: ${({ theme }) => theme.lightBrown};
  }

  .qrButton.active {
    background-color: ${({ theme }) => theme.darkGreen};
  }

  .text-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  .embla__container {
    display: flex;
    height: 100%;
  }

  .job {
    font-family: "Arial";
    text-transform: uppercase;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.lightBrown};
    text-align: start;
  }

  .name {
    max-width: 100%;
    font-family: "Arial";
    font-weight: 600;
    font-size: 1rem;
    color: ${({ theme }) => theme.darkGreen};
    margin-top: 0.5rem;
  }

  .email {
    font-family: "Arial";
    font-size: 0.75rem;
    color: ${({ theme }) => theme.darkGreen};
  }

  .image {
    border-radius: 5px;
    width: 15vw;
    height: 10rem;
    min-width: 14rem;
    object-fit: contain;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);

    // @media screen and (min-width: 1920px) {
    //   height: 23rem;
    // }
  }

  .embla__viewport2 {
    padding-top: 0px !important;
  }

  .embla__slide {
    position: relative;
    margin: 0 2rem 0 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`
