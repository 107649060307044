
const img1 = "/assets/images/features/5.jpg";
const img2 = "/assets/images/features/6.jpg";


const data = {
  "features": [
    {
      "text1": "Smart Cities",
      "text2": "",
      "type": "An integrated, efficient, and sustainable destination"
    },
    {
      "text1": "Sustainability",
      "text2": "",
      "type": "Sustainability and Urban Innovation"
    },
    {
      "text1": "Najdi",
      "text2": "Architecture",
      "type": "Inspired by history"
    },
    {
      "text1": "Comfort",
      "text2": "Convenience",
      "type": "Street cooling technology"
    },
    {
      "text1": "Walkable",
      "text2": "City",
      "type": "Pedestrian friendly streets"
    }
  ],

  "details": [
    {
      "heading1": "Smart Cities",
      "heading2": "",
      "subText2": "Enable Diriyah to provide outstanding quality of life, exceptional experience, and commercial success through human-centric technologies within an integrated, efficient, and sustainable destination.​",

      "list1": {
        "Better Life for Diriyah Community": "Facilitate An Active, Engaged, And Healthy Community​",
        "Connect To Disconnect:​": "Optimize Technology To Enable A Better Quality Of Life And Empower Human Interaction.​",
        "Sustainable Environment:": "Protect And Enhance Diriyah’s Environment And Resources By Holistically Ensuring We Continually Monitor And Improve.​​",
        "Our Heritage Is Our Source Of Pride:": "Promote And Celebrate Our Heritage Through Technology Enablement. ​​",
        "Wise:": "Design Solutions With Human Privacy In Focus​"
      },
      "list2": {
        "01": "For Residents To Have An Exceptional Quality Of Life Underlined By Great Experiences​​",
        "02": "For Employees To Work In An Integrated Destination That Enhances Employees’ Lives​​",
        "03": "To Enable An Exceptional Educational Experience To Students Through Integrated Services In Diriyah​​",
        "04": "For Tourists To Experience A Sustainable, People-centric City Enabled By Cutting Edge Technologies​​",
        "05": "For Day Visitors To Have An Optimal And Seamless Experience​​",
        "06": "For Commercial Tenants (Partners) To Operate In An Attractive Destination That Enables Commercial Success​​",
        "07": "For Authorities & Operators To Work In Real-time Data Analytics Enabling Smart And Quick Actions​​​"
      }
    },
    {
      "heading1": "Sustainability and",
      "heading2": "Design Experience",
      "subText": "With a core value to ground all of the development’s endeavours in upholding and embracing sustainability, the Diriyah Development will become a world-class destination showcasing exemplary sustainability and environmental performance credentials.​",
      "feature1": "3km",
      "type1": "Escarpment Walk",
      "subText2": "Diriyah is a pioneer on sustainability and urban innovation, holding platiinum LEED and Mostadam certifications",
      "feature2": "6.6m+",
      "type2": "Plants, Trees & Shrubs",
      "feature3": "17K+",
      "type3": "Horse-riding and Biking Trails",
      "list": {
        "Water Conservation": "Water is the most precious resource in Riyadh, and ensuring its​ sustainable consumption is a key objective of Vision 2030. Therefore, the Development of​ Diriyah seeks to incorporate water efficiency in all projects through reuse and reduction​ measures.​",
        "Energy Efficieny": "The Development of Diriyah aims to reduce carbon emissions and​ contributions to climate change as it is a paramount global issue which makes it an​ essential value that will ground all the development’s projects. Especially, since Saudi​ Arabia’s electricity grid is predominately fossil fuel based, so to aid in the overall reduction​ of the Kingdom’s carbon emission, Diriyah plans to address energy efficiency measures through both the energy supply and demand.​",
        "Happy & Healthy Residents": "to significantly contribute towards the health and wellbeing of​ residents and visitors, Diriyah is working towards creating favourable, connected, safe​ and walkable conditions all throughout the development. Additionally, the development​ aims to prioritize indoor and outdoor air quality through smart cities solutions for​ monitoring and tracking air quality. This will contribute to the overall population health,​ reducing pollutants, enhancing natural ventilation, and maintaining a better indoor​ environment comfort level.​",
        "Cultural Heritage": "All throughout the development exists cultural heritage sites and​ features. The traditional Najdi vernacular architecture has sustainability at its core.​ Therefore, it is a priority to protect and promote existing cultural features and sites, as it​ not only preserves Saudi Arabia’s rich culture and heritage but bolsters its inherent​ sustainable history and nature.​",
        "Climate Change Resillience": "This principal centres around strengthening societies’ resilience and flexibility to climate change and mitigate the potential vulnerability to future extreme climate change induced events, such as flooding, extreme temperatures, and sandstorms.​",
        "Material Impact Reduction": "The products and materials procured for the development will be selected to avoid the polluting of the local environment and community which is of the utmost priority. The products and materials’ procurement process will consider the most sustainable and environmentally responsible sourcing for materials based on the best international practices and choosing the most feasible locally sourced materials to minimize the development carbon footprint.​"
      }
    },
    {
      "heading1": "Najdi ",
      "heading2": " Architecture",
      "subText": "Diriyah stands as a testament to the rich tapestry of heritage and culture deeply woven into the fabric of Saudi Arabia. Through its meticulously planned design, distinctive building layout, and diverse districts, Diriyah reveres its past while embracing modernity.​",
      "subText2": "Nestled within the embrace of Wadi Hanifah, Diriyah emerges from the very earth, paying homage to the land from which it rises.",
      "subText3": " “Najdi architecture\" refers to the traditional architectural style native to the Najd region of Saudi Arabia. This style is characterized by its use of locally sourced materials, such as clay, stone, and wood, to create structures that are well-suited to the region's climate and cultural traditions.​",
      "img": img1,
      "img2": img2
    },
    {
      "heading1": "Comfort ",
      "heading2": "Convenience",
      "subText": "Street cooling initiatives are implemented across the precinct to support lower ambient temperatures during the warmer months.​",
      "subText2": "With a multifaceted approach, incorporating various design elements, cutting-edge technologies, and accessible facilities, all with the shared objective of optimizing the overall comfort convenience and customer experience when outside.​",
      "subText3": "By combining these street cooling initiatives, the precinct aims to create a more livable and enjoyable urban environment, where residents and visitors can comfortably navigate outdoor spaces and engage in various activities year-round.​",
      "list": {
        "High-level screens": "High-level screens are designed to blend with the surrounding Najdi environment while effectively providing shading from direct sunlight to the streets below.​​",
        "Radiant cooling systems": "Radiant cooling systems will be embedded in the street pavements, involving the circulation of cool water through pipes to support the absorption of any excess heat.​​",
        "Chilled Air": "​Chilled air will circulate into the streets from small openings, known as trickle vents.​​"
      }
    },
    {
      "heading1": "Pedestrian ",
      "heading2": "Friendly Streets",
      "subText": "Diriyah Gate creates a cultural city with flexible plazas, green refuge spaces, clustered shade canopies, trees and healthy walk-able streets, one that can truly be immersed in a natural setting of historic Riyadh.​",
      "subText2": "Echoing the Historical design where the built fabric was formed to respond to the extreme climate conditions of Saudi Arabia, Diriyah Square's public realm is formed of Narrow alleyways, intimate and shaded passages and courtyards.", 
      "subText3": "Creating an experience that allows the opportunity to comfortably relax and shop during the day, and dine and enjoy the entertainment hotspot at night.",
      "list": {
        "Pedestrian Spine": "Spanning across the length of the Diriyah Gate Development from North to South, is the 3km long vibrant primary Pedestrian Spine connects all the major landmarks and visitor hubs.​​",
        "Traffic-free Network": "The entire walled  city to the west of the Western Ring Road is envisioned as a fully pedestrianised area with limited vehicular access in line with the Najdi traditional architecture inspired from At-Turaif.​​",
        "Public Open Space & Street Strategy": "The public open space and streets strategy is built around function, aesthetics and the provision of overall site amenities. The various elements of the landscape strategy are deployed at a variety of scales with a view towards developing a distinct and pleasant aesthetic character that is simultaneously comfortable, safe, supports wayfinding, and is sensitive to local conditions and climate.​",
        "Old City Design": "The sikkah network, souq and main square form the basis of a traditional public realm. It is a system that has worked well for generations, reflecting Saudi culture, fostering pedestrianisation and providing comfortable ways of alleviating Saudi’s desert climate.​"
      }
    }
  ]
};

export default data;
