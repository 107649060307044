import { useEffect } from "react"
import styled from "styled-components"

import DropDown from "@components/DropDown"

import { useStore } from "@state/store"
import { amenities, connectionsMain } from "@data/unit-finder/filterData"

function Filter() {
  const { setLocalState, unitLevel, amenityCategory, finderMapUI } = useStore(
    (s) => ({
      setLocalState: s.setLocalState,
      unitLevel: s.unitLevel,
      amenityCategory: s.amenityCategory,
      finderMapUI: s.finderMapUI,
    })
  )
  const levels = ["1", "0", "B1", "B2", "B3"] as any
  // const hideClose = !amenityCategory && !unitLevel && finderMapUI

  return (
    <FiltersWrapper>
      <DropDown
        button={
          <Button>
            <img src="/assets/icons/common/stairs.svg" />
          </Button>
        }
        dropContent={
          <Content>
            {levels.map((lvl, i) => (
              <Button
                key={`lvl-btn${i}`}
                style={{
                  opacity: unitLevel === lvl ? 1 : 0.6,
                }}
                onClick={() => {
                  setLocalState({ unitLevel: lvl })
                }}
              >
                {lvl === "0" ? "G" : lvl}
              </Button>
            ))}
          </Content>
        }
        autoClose={false}
        defaultState={true}
      />
      <DropDown
        button={
          <DropdownHeader>
            AMENITIES{" "}
            <img
              style={{ marginLeft: "auto" }}
              src="/assets/icons/common/dropdown.svg"
              alt=""
            />
          </DropdownHeader>
        }
        dropContent={
          <Content>
            {amenities.map((obj, i) => (
              <DropdownHeader
                key={`lvl-btn${i}`}
                style={{
                  opacity:
                    amenityCategory === obj.link
                      ? 1
                      : obj.levels.includes(unitLevel)
                      ? 0.6
                      : 0.1,
                  cursor: obj.levels.includes(unitLevel) ? "pointer" : "auto",
                  pointerEvents: obj.levels.includes(unitLevel)
                    ? "all"
                    : "none",
                }}
                onClick={() => {
                  setLocalState({ amenityCategory: amenityCategory === obj.link ? null : obj.link })
                }}
              >
                {obj.name}
                <img style={{ marginLeft: "auto" }} src={obj.icon} alt="" />
              </DropdownHeader>
            ))}
          </Content>
        }
        autoClose={false}
      />
      <DropDown
        button={
          <DropdownHeader>
            CONNECTIONS{" "}
            <img
              style={{ marginLeft: "auto" }}
              src="/assets/icons/common/dropdown.svg"
              alt=""
            />
          </DropdownHeader>
        }
        dropContent={
          <Content>
            {connectionsMain.map((obj, i) => (
              <DropdownHeader
                key={`lvl-btn${i}`}
                style={{
                  opacity:
                    amenityCategory === obj.link
                      ? 1
                      : obj.levels.includes(unitLevel)
                      ? 0.6
                      : 0.1,
                  cursor: obj.levels.includes(unitLevel) ? "pointer" : "auto",
                  pointerEvents: obj.levels.includes(unitLevel)
                    ? "all"
                    : "none",
                }}
                onClick={() => {
                  setLocalState({ amenityCategory: obj.link })
                }}
              >
                {obj.name}
                <img style={{ marginLeft: "auto" }} src={obj.icon} alt="" />
              </DropdownHeader>
            ))}
          </Content>
        }
        autoClose={false}
      />
      <DropdownHeader
        onClick={() => {
          setLocalState({
            finderMapUI: !finderMapUI,
          })
        }}
        style={{
          minWidth: "unset",
          width: "fit-content",
        }}
      >
        OVERLAYS
        <svg
          style={{ marginLeft: "40px" }}
          viewBox="0 0 20 20"
          width="20px"
          height="20px"
        >
          <circle
            cx="10"
            cy="10"
            r="8"
            stroke="var(--white)"
            fill={finderMapUI ? "var(--white)" : "transparent"}
          />
        </svg>
      </DropdownHeader>
      <Button
        onClick={() => {
          setLocalState({ videoOpen: true })
        }}
      >
        <img src="/assets/icons/common/bus.svg" />
      </Button>
      {/* <DropdownHeader
        onClick={() => {
          setLocalState({
            unitLevel: null,
            amenityCategory: null,
            mapCategory: null,
            finderMapUI: true,
            finderLabelsLayer: false,
            finderOverlayLayer: true,
            finderPlotsLayer: false,
            unitID: null,
          })
        }}
        style={{
          opacity: hideClose ? 0 : 1,
          pointerEvents: hideClose ? "none" : "all",
          minWidth: "unset",
          width: "fit-content",
        }}
      >
        RESET
      </DropdownHeader> */}
    </FiltersWrapper>
  )
}

export default Filter

const FiltersWrapper = styled.div`
  position: absolute;
  z-index: 100;
  width: calc(74% - calc(var(--margin) * 2));
  bottom: var(--margin);
  left: var(--margin);
  display: flex;
  gap: var(--margin);
`

const Button = styled.div`
  background-color: rgba(var(--dark-green-rgb));
  width: 40px;
  height: 40px;
  left: var(--margin);
  border-radius: 4px;
  display: grid;
  place-content: center;
  pointer-events: all;
  cursor: pointer;
  margin-top: auto;
  color: var(--white);
  font-weight: 100;
  font-size: clamp(10px, 0.8rem, 14px);
  font-family: "Arial";
  transition: opacity 0.4s ease-out;
`

const Content = styled.div`
  background-color: var(--dark-green);
`

const DropdownHeader = styled.div`
  color: var(--white);
  border-radius: 4px;
  min-width: 250px;
  height: 40px;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  text-align: center;
  font-size: clamp(10px, 0.8rem, 14px);
  font-weight: 100;
  background-color: var(--dark-green);
  font-family: "Arial";
  transition: opacity 0.4s ease-out;
  cursor: pointer;

  & svg circle {
    transition: fill 0.4s ease-out;
  }
`
