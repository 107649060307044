export const paths = {
  placeholder: {
    thumb: "/assets/images/renders/thumb/view18.webp",
    full: "/assets/images/renders/full/view18.webp",
  },
  turaif: {
    thumb: "/assets/images/sidebar/thumb/turaif.webp",
    full: "/assets/images/sidebar/full/turaif.webp",
  },
  qurain: {
    thumb: "/assets/images/sidebar/thumb/qurain.webp",
    full: "/assets/images/sidebar/full/qurain.webp",
  },
  samhan: {
    thumb: "/assets/images/sidebar/thumb/samhan.webp",
    full: "/assets/images/sidebar/full/samhan.webp",
  },
  knowledge: {
    thumb: "/assets/images/sidebar/thumb/knowledge-district.webp",
    full: "/assets/images/sidebar/full/knowledge-district.webp",
  },
  king1: {
    thumb: "/assets/images/sidebar/thumb/king-1.webp",
    full: "/assets/images/sidebar/full/king-1.webp",
  },
  king2: {
    thumb: "/assets/images/sidebar/thumb/king-2.webp",
    full: "/assets/images/sidebar/full/king-2.webp",
  },
  retail: {
    thumb: "/assets/images/sidebar/thumb/lifestyle-retail.webp",
    full: "/assets/images/sidebar/full/lifestyle-retail.webp",
  },
  lifestyleRetail: {
    thumb: "/assets/images/renders/thumb/view4.webp",
    full: "/assets/images/renders/full/view4.webp",
  },
  bujairi: {
    thumb: "/assets/images/sidebar/thumb/bujairi.webp",
    full: "/assets/images/sidebar/full/bujairi.webp",
  },
  diriyahSouth: {
    thumb: "/assets/images/sidebar/thumb/diriyah-south.webp",
    full: "/assets/images/sidebar/full/diriyah-south.webp",
  },
  eventsDistrict: {
    thumb: "/assets/images/sidebar/thumb/event-district.webp",
    full: "/assets/images/sidebar/full/event-district.webp",
  },
  masterplan2: {
    thumb: "/assets/images/sidebar/thumb/masterplan-2.webp",
    full: "/assets/images/sidebar/full/masterplan-2.webp",
  },
  dsq: {
    thumb: "/assets/images/sidebar/thumb/dsq.webp",
    full: "/assets/images/sidebar/full/dsq.webp",
  },
  cultureCommunity: {
    thumb: "/assets/images/sidebar/thumb/culture-and-community.webp",
    full: "/assets/images/sidebar/full/culture-and-community.webp",
  },
  resortsHotels: {
    thumb: "/assets/images/sidebar/thumb/resorts-and-hotels.webp",
    full: "/assets/images/sidebar/full/resorts-and-hotels.webp",
  },
  greenSpaces: {
    thumb: "/assets/images/renders/thumb/view24.webp",
    full: "/assets/images/renders/full/view24.webp",
  },
  workspaceOffering: {
    thumb: "/assets/images/sidebar/thumb/workspace-offering.webp",
    full: "/assets/images/sidebar/full/workspace-offering.webp",
  },
  uniqueResidences: {
    thumb: "/assets/images/sidebar/thumb/unique-residences.webp",
    full: "/assets/images/sidebar/full/unique-residences.webp",
  },
  luxuryRetail: {
    thumb: "/assets/images/renders/thumb/view10A.webp",
    full: "/assets/images/renders/full/view10A.webp",
  },
  guestAmenities: {
    thumb: "/assets/images/features/guestAmenities.jpg",
    full: "/assets/images/features/guestAmenities.jpg",
  },
  departmentStore: {
    thumb: "/assets/images/sidebar/thumb/department-store.webp",
    full: "/assets/images/sidebar/full/department-store.webp",
  },
  entertainment: {
    thumb: "/assets/images/sidebar/thumb/entertainment-and-dining.webp",
    full: "/assets/images/sidebar/full/entertainment-and-dining.webp",
  },
  bujairiDining: {
    thumb: "/assets/images/renders/thumb/view14.webp",
    full: "/assets/images/renders/full/view14.webp",
  },
  baccaratHotel: {
    thumb: "/assets/images/sidebar/thumb/baccarat-hotel.webp",
    full: "/assets/images/sidebar/full/baccarat-hotel.webp",
  },
  corinthiaHotel: {
    thumb: "/assets/images/sidebar/thumb/corinthia-hotel.webp",
    full: "/assets/images/sidebar/full/corinthia-hotel.webp",
  },
  rosewoodHotel: {
    thumb: "/assets/images/sidebar/thumb/rosewood-hotel.webp",
    full: "/assets/images/sidebar/full/rosewood-hotel.webp",
  },
  fauchonHotel: {
    thumb: "/assets/images/sidebar/thumb/fauchon-hotel.webp",
    full: "/assets/images/sidebar/full/fauchon-hotel.webp",
  },
  armaniHotel: {
    thumb: "/assets/images/sidebar/thumb/armani-hotel.webp",
    full: "/assets/images/sidebar/full/armani-hotel.webp",
  },
  orientHotel: {
    thumb: "/assets/images/sidebar/thumb/orient-hotel.webp",
    full: "/assets/images/sidebar/full/orient-hotel.webp",
  },
  baccaratResidences: {
    thumb: "/assets/images/sidebar/thumb/baccarat-residences.webp",
    full: "/assets/images/sidebar/full/baccarat-residences.webp",
  },
  corinthiaResidences: {
    thumb: "/assets/images/sidebar/thumb/corinthia-residences.webp",
    full: "/assets/images/sidebar/full/corinthia-residences.webp",
  },
  armaniResidences: {
    thumb: "/assets/images/sidebar/thumb/armani-residences.webp",
    full: "/assets/images/sidebar/full/armani-residences.webp",
  },
}

export const sidePanelData = [
  /*
  -.-.-.-.-.- MASTERPLAN -.-.-.-.-.-
   */
  {
    name: "",
    title: "Diriyah, The City of Earth",
    desc: "Founded nearly 300 years ago, Diriyah is a symbol of unity, beauty, generosity, and the resilience of the Saudi nation and its people.",
    para: "Diriyah is set to become the world's foremost historical, cultural and lifestyle destination. This mixed-use development will deliver a new lifestyle experience for residents and visitors to Saudi Arabia. Combining global luxury with Najdi heritage, offering unparalleled shopping, dining, and living experiences.",
    swipeGalleryData: [
      { src: paths.turaif.full, caption: "" },
    ],
    img: paths.turaif.full,
    imgThumb: paths.turaif.thumb,
    link: "masterplan",
    mapID: "masterplan",
  },
  {
    name: "QURAIN CULTURAL",
    title: "Qurain Cultural District",
    desc: "A 100% pedestrian environment, situated overlooking the Wadi Hanifah and adjacent to the historical site of At-Turaif, where heritage endures, culture evolves.",
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    para: "The district will include residences, hotels, galleries, retail, and office spaces that produce immersive creative experiences. Celebrating and reinterpreting the artistic traditions of Saudi Arabia, the district will contain six cultural academies hosting a variety of cultural programs. Peppered with public art and brought to life with homegrown artists, Qurain district will introduce the world to the wonders of Saudi art, in a location where tradition lives on through celebration and artistic expression.",
    swipeGalleryData: [
      { src: paths.qurain.full, caption: "" },
    ],
    img: paths.qurain.full,
    imgThumb: paths.qurain.thumb,
    link: "qurain",
    mapID: "masterplan",
  },
  {
    name: "SAMHAN",
    title: "Samhan",
    desc: "Rooted in historic significance, the Samhan District was once home to some of Diriyah’s earliest residents and still houses one of Diriyah’s oldest mosques.",
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    para: "Samhan provides authentic tranquil living within a city. Traditional Najdi design has been reimagined for contemporary living with emphasis on the courtyard element of Najdi residences. Samhan offers exceptional offices, premium retail, and convenience stores. Safe, connected, and completely walkable, the district offers a village way of life within The City of Earth.",
    swipeGalleryData: [
      { src: paths.samhan.full, caption: "" },
    ],
    img: paths.samhan.full,
    imgThumb: paths.samhan.thumb,
    link: "samhan",
    mapID: "masterplan",
  },
  {
    name: "KNOWLEDGE DISTRICT",
    title: "King Salman Knowledge District",
    desc: "King Salman Knowledge District will enthrall a host of intellectually and creatively minded audiences.",
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    para: "King Salman Knowledge District is a prestigious destination that fosters national pride, inviting people to walk in the footsteps of legendary Saudi Arabian heroes and kings. The district promises to delve into a profoundly rich heritage, literature, and culture.",
    swipeGalleryData: [
      { src: paths.knowledge.full, caption: "" },
    ],
    img: paths.knowledge.full,
    imgThumb: paths.knowledge.thumb,
    link: "king",
    mapID: "masterplan",
  },
  {
    name: "DIRIYAH SQUARE",
    title: "Diriyah Square",
    desc: "Through a selection of retail and dining destinations, visitors can relax and shop during the day then enjoy open-air entertainment at night.",
    feature1Text: "450+",
    feature1: "Iconic retail and dining outlets",
    feature2Text: "186k sqm",
    feature2: "Total retail GLA",
    para: "At the heart of Diriyah, with excellent road and metro access, Diriyah Square blends over 450+ of the world’s finest retail brands with local artisanal goods. Fully pedestrian, the district exudes a Najdi village atmosphere.",
    swipeGalleryData: [
      { src: paths.lifestyleRetail.full, caption: "" },
    ],
    img: paths.lifestyleRetail.full,
    imgThumb: paths.lifestyleRetail.thumb,
    link: "dsq",
    mapID: "masterplan",
  },
  {
    name: "BUJAIRI",
    title: "Bujairi",
    desc: "Bujairi is enhanced by its natural location and characteristic buildings constructed from local materials. Bujairi benefits from its own veritable microclimate.",
    feature1Text: null,
    feature1: null,
    feature2Text: null,
    feature2: null,
    para: "Situated between the event and cultural districts, and with direct pedestrian access to At-Turaif, Bujairi is home to the kingdom’s premier dining destination - Bujairi Terrace. Following its acclaimed opening in 2022, this outstanding culinary venue now offers over 20 world-class dining experiences celebrating the finest international cuisines and local flavours, as well as cultural activities and live events.",
    swipeGalleryData: [
      { src: paths.bujairi.full, caption: "" },
    ],
    img: paths.bujairi.full,
    imgThumb: paths.bujairi.thumb,
    link: "bujairi",
    mapID: "masterplan",
  },
  {
    name: "DIRIYAH SOUTH",
    title: "Diriyah South",
    desc: "Diriyah South embodies the Diriyah way of life through walkable residential areas set amongst some of the finest cultural destinations.",
    feature1Text: "9",
    feature1: "Cultural institutions",
    para: "With the greenery of the Wadi to the west, and the entertainment experiences of Diriyah Square to the north, Diriyah South is perfectly positioned for calm and comfort. Key to the district is Diriyah Gardens; a celebration of Arabic roots and Saudi heritage and a serene experience in the middle of the city.",
    swipeGalleryData: [
      { src: paths.diriyahSouth.full, caption: "" },
    ],
    img: paths.diriyahSouth.full,
    imgThumb: paths.diriyahSouth.thumb,
    link: "diriyah-south",
    mapID: "masterplan",
  },
  {
    name: "EVENTS DISTRICT",
    title: "Events District",
    desc: "As a podium for Saudi ​and international events, it plays a significant role in placing Diriyah on the map and the promotion of its cultural effervescence.",
    feature1Text: null,
    feature1: null,
    feature2Text: null,
    feature2: null,
    para: "In a prime location, at the top of the city, with Bujairi Terrace to its left and King Salman Knowledge District below, the Events District is an extensive blank canvas for exceptional occasions. Vast in size, the space brings rhythm to the Diriyah way of life through a curated schedule of events.",
    swipeGalleryData: [
      { src: paths.eventsDistrict.full, caption: "" },
    ],
    img: paths.eventsDistrict.full,
    imgThumb: paths.eventsDistrict.thumb,
    link: "events-district",
    mapID: "masterplan",
  },
  {
    name: "MASTERPLAN 2",
    title: "Masterplan 2",
    desc: "Both ancient and new worlds come alive in a pivotal area, with diagonal streets branching out into nine districts organised along an active boulevard. D2 offers a refreshing representation of modern living.",
    feature1Text: null,
    feature1: null,
    feature2Text: null,
    feature2: null,
    para: "The area magnifies Najdi design principles with multiple courtyards and connecting walkways and brings together like-minded, time-tested fundamentals on perspective and natural boundaries. From bespoke residences overlooking Wadi Hanifah, to family-servicing retailers and high-tech media districts, monuments, offices, hotels, retail and creative spaces, D2 is testimony to contemporary urban planning that amplifies traditional aesthetics for a most generous way of living.",
    swipeGalleryData: [
      { src: paths.masterplan2.full, caption: "" },
    ],
    img: paths.masterplan2.full,
    imgThumb: paths.masterplan2.thumb,
    link: "masterplan-2",
    mapID: "masterplan",
  },
  /*
  -.-.-.-.-.- DIRIYAH SQUARE -.-.-.-.-.-
   */
  {
    name: "",
    title: "Diriyah Square",
    desc: "Diriyah will be home to a unique collection of over 400 of the finest global brands and local souks offering world-class shopping.",
    feature1Text: "460k sqm",
    feature1: "Total land area GLA",
    feature2Text: "186K sqm",
    feature2: "Total Retail GLA",
    para: "A luxury-lifestyle destination where iconic brands will be complimented with traditional artisan souks and bazaars, all set within an authentic, walkable Najdi village atmosphere providing a new lifestyle experience unique to Diriyah.",
    swipeGalleryData: [
      { src: paths.dsq.full, caption: "" },
    ],
    img: paths.dsq.full,
    imgThumb: paths.dsq.thumb,
    link: "diriyah-square",
    mapID: "diriyah-square",
    subState: "primary",
  },
  {
    name: "CULTURE & COMMUNITY",
    title: "Culture and Community",
    desc: "Unveiling Arabian Peninsula's rich history through top-tier education and cultural institutions.",
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    // feature1: "Schools + 1 University",
    // feature1Text: "12",
    // feature2: "Mosques + 9 Museums",
    // feature2Text: "20+",
    para: "A diverse collection of attractions where visitors can engage with and enjoy a multitude of experiences: from celebrating the accomplishments of the Kingdom’s leaders to reliving the history of the region and connecting with modern artistic expressions.",
    swipeGalleryData: [
      { src: paths.cultureCommunity.full, caption: "" },
    ],
    img: paths.cultureCommunity.full,
    imgThumb: paths.cultureCommunity.thumb,
    link: "community",
    mapID: "diriyah-square",
    subState: "primary",
  },
  {
    name: "HOTELS & RESORTS",
    title: "Hotels and Resorts",
    desc: "Diriyah Square will offer visitors a luxurious and world class service by the world's leading hospitality brands.",
    feature1: "Luxury Hotels & Resorts",
    feature1Text: "6",
    feature2: "Hotel Keys",
    feature2Text: "675",
    para: "For the experiential travelers, guests will be immersed in Najdi inspired architecture and design, triggering emotions of nostalgia, authenticity, wonder and discovery. They will experience an unparalleled level of service and luxury, an authentic cultural and heritage environment and generous hospitality by leading hospitality brands.",
    swipeGalleryData: [
      { src: paths.resortsHotels.full, caption: "" },
      { src: paths.baccaratHotel.full, caption: "" },
      { src: paths.rosewoodHotel.full, caption: "" },
      { src: paths.fauchonHotel.full, caption: "" },
      { src: paths.armaniHotel.full, caption: "" },
      { src: paths.orientHotel.full, caption: "" },
    ],
    img: paths.resortsHotels.full,
    imgThumb: paths.resortsHotels.thumb,
    link: "hospitality",
    mapID: "diriyah-square",
    subState: "primary",
  },
  {
    name: "GREEN & OPEN SPACES",
    title: "Green and Open Spaces",
    desc: "Diriyah is a pioneer in sustainability and urban innovation, holding a Platinum LEED pre-certification from the US green building council.",
    feature1: "Escarpment Walk",
    feature1Text: "3km",
    feature2: "Plants, Trees and Shrubs",
    feature2Text: "6.5M+",
    para: "Diriyah Square is part of Diriyah which is a pedestrian oriented development that oversees the natural oasis of Wadi Hanifah encouraging walkability.",
    swipeGalleryData: [
      { src: paths.greenSpaces.full, caption: "" },
    ],
    img: paths.greenSpaces.full,
    imgThumb: paths.greenSpaces.thumb,
    link: "open-spaces",
    mapID: "diriyah-square",
    subState: "primary",
  },
  {
    name: "COMMERCIAL OFFICES",
    title: "Offices",
    desc: "An Exclusive and Sustainable  Business Address.",
    feature1: "Premium office buildings",
    feature1Text: "5",
    feature2: "Office GLA",
    feature2Text: "45k sqm",
    feature3: "Top-tier Kindergarten",
    feature3Text: "1",
    para: "As Diriyah’s premier  commercial  hub, Diriyah Square will feature exclusive and sustainably  designed,  state-of-the-art office buildings. The prestigious  offices will be home to high-profile government  and institutions that align with Diriyah’s heritage,  cultural  and sustainability values, and are responsible  for driving the nation’s transformation. Employees will benefit from unparalleled  access  to luxurious amenities,  including dining, retail outlets, exclusive leisure  experiences and a top-tier  kindergarten  to enable a balanced work life.",
    swipeGalleryData: [
      { src: paths.workspaceOffering.full, caption: "" },
    ],
    img: paths.workspaceOffering.full,
    imgThumb: paths.workspaceOffering.thumb,
    link: "commercial",
    mapID: "diriyah-square",
    subState: "primary",
  },
  {
    name: "BRANDED RESIDENCES",
    title: "Exclusive branded Residences",
    desc: "Diriyah Square offers a life of unprecedented luxury.",
    feature1: "Branded residences",
    feature1Text: "3",
    feature2: "Branded residential units",
    feature2Text: "34",
    para: "Diriyah Square comprises a limited collection of luxurious residences that cater to those looking for an elevated lifestyle in a one-of-a-kind location. The architecturally unique homes seamlessly blend modern comforts with exclusive leisure and lifestyle experiences.",
    swipeGalleryData: [
      { src: paths.uniqueResidences.full, caption: "" },
      { src: paths.baccaratResidences.full, caption: "" },
      { src: paths.corinthiaResidences.full, caption: "" },
    ],
    img: paths.uniqueResidences.full,
    imgThumb: paths.uniqueResidences.thumb,
    link: "residential",
    mapID: "diriyah-square",
    subState: "primary",
  },
  {
    name: "RETAIL & DINING",
    title: "The Retail Experience",
    desc: "Pioneering Riyadh's future retail and leisure landscape.",
    feature1: "Retail Units",
    feature1Text: "400+",
    feature2: "Dining Units",
    feature2Text: "100+",
    para: "This unique project gathers together over 400 of the finest luxury and lifestyle brands from across the Kingdom and around the world in an exceptional, authentic and intimate environment of streets laneways and squares.",
    swipeGalleryData: [
      { src: paths.luxuryRetail.full, caption: "" },
    ],
    img: paths.luxuryRetail.full,
    imgThumb: paths.luxuryRetail.thumb,
    link: "retail",
    mapID: "diriyah-square",
    subState: "primary",
  },
  /*
  -.-.-.-.-.- UNIT FINDER 0 + 1 -.-.-.-.-.-
   */
  {
    name: "",
    title: "Diriyah Square",
    desc: "Diriyah will be home to a unique collection of over 400 of the finest global brands and local souks offering world-class shopping.",
    feature1: "Total Retail GFA",
    feature1Text: "566k+ sqm",
    feature2: "Total Commercial Office GFA",
    feature2Text: "1.6M+sqm",
    para: "A luxury-lifestyle destination where iconic brands will be complimented with traditional artisan souks and bazaars, all set within an authentic, walkable Najdi village atmosphere providing a new lifestyle experience unique to Diriyah.",
    swipeGalleryData: [
      { src: paths.guestAmenities.full, caption: "" },
    ],
    img: paths.guestAmenities.full,
    imgThumb: paths.guestAmenities.thumb,
    link: "unit-finder",
    mapID: "unit-finder",
  },
  {
    name: "Premium Dining",
    subtitle: "Elevated alfresco style dining",
    title: "Premium Dining",
    desc: "Elevated alfresco style dining with breathtakingly vast views over Wadi Hanifah.",
    feature1: "Schools + 1 University",
    feature1Text: "12",
    feature2: "Mosques + 9 Museums",
    feature2Text: "20+",
    para: "A diverse collection of attractions where visitors can engage with and enjoy a multitude of experiences: from celebrating the accomplishments of the Kingdom’s leaders to reliving the history of the region and connecting with modern artistic expressions.",
    swipeGalleryData: [
      { src: paths.placeholder.full, caption: "" },
    ],
    img: paths.placeholder.full,
    imgThumb: paths.placeholder.thumb,
    link: "premium-dining",
    mapID: "unit-finder",
  },
  {
    name: "Department Store",
    subtitle: "Multi-category department store precinct",
    title: "Department Store",
    desc: "Impressive and wide selection of instore products including clothing, home goods, furniture, electronics, and more. 8000spm GLA.",
    feature1: "Hotels & Resorts",
    feature1Text: "40+",
    feature2: "Branded Residences",
    feature2Text: "200+",
    para: "For the experiential travelers, guests will be immersed in Najdi inspired architecture and design, triggering emotions of nostalgia, authenticity, wonder and discovery. They will experience an unparalleled level of service and luxury, an authentic cultural and heritage environment and generous hospitality by leading hospitality brands.",
    swipeGalleryData: [
      { src: paths.departmentStore.full, caption: "" },
    ],
    img: paths.departmentStore.full,
    imgThumb: paths.departmentStore.thumb,
    link: "department",
    mapID: "unit-finder",
  },
  {
    name: "Entertainment & Dining",
    subtitle: "Vibrant and lively immersive environments",
    title: "Entertainment & Dining",
    desc: "A vibrant culmination of dining and entertainment, this area will cater to seekers of lively immersive environments.",
    feature1: "Hotels & Resorts",
    feature1Text: "40+",
    feature2: "Branded Residences",
    feature2Text: "200+",
    para: "For the experiential travelers, guests will be immersed in Najdi inspired architecture and design, triggering emotions of nostalgia, authenticity, wonder and discovery. They will experience an unparalleled level of service and luxury, an authentic cultural and heritage environment and generous hospitality by leading hospitality brands.",
    swipeGalleryData: [
      { src: paths.entertainment.full, caption: "" },
    ],
    img: paths.entertainment.full,
    imgThumb: paths.entertainment.thumb,
    link: "entertainment",
    mapID: "unit-finder",
  },
  {
    name: "Anchor Lifestyle Retail",
    subtitle: "A bridge between fashion and lifestyle offering",
    title: "Anchor Lifestyle Retail",
    desc: "With modern retail needs in mind, universal styling and flagship fashion  incorporating their homeware collections will create a bridge between fashion and lifestyle offering.",
    feature1: "Escarpment Walk",
    feature1Text: "3km",
    feature2: "Plants, Trees and Shrubs",
    feature2Text: "6.5M+",
    para: "A luxury-lifestyle destination where iconic brands will be complimented with traditional artisan souks and bazaars, all set within an authentic, walkable Najdi village atmosphere providing a new lifestyle experience unique to Diriyah.",
    swipeGalleryData: [
      { src: paths.lifestyleRetail.full, caption: "" },
    ],
    img: paths.lifestyleRetail.full,
    imgThumb: paths.lifestyleRetail.thumb,
    link: "anchor",
    mapID: "unit-finder",
  },
  {
    name: "Luxury Retail North",
    subtitle: "Featuring the world’s finest luxury retail",
    title: "Luxury Retail North",
    desc: "Featuring the world’s finest luxury retail precinct, high fashion, jewelry and beauty brands in an inspired and unforgettable location.",
    feature1: "Commercial Office GFA",
    feature1Text: "1.6M+ sqm",
    feature2: "Projected Number of Jobs",
    feature2Text: "178k+",
    para: "Diriyah is set to become a prestigious workplace address that combines authentic Najdi- inspired architecture with cutting-edge modern amenities and convenience. Built to the highest international standards, professionals will benefit from being surrounded by culture, retail, dining, and entertainment.",
    swipeGalleryData: [
      { src: paths.placeholder.full, caption: "" },
    ],
    img: paths.placeholder.full,
    imgThumb: paths.placeholder.thumb,
    link: "luxury-n",
    mapID: "unit-finder",
  },
  {
    name: "Luxury Retail South",
    subtitle: "High fashion, jewelry and beauty",
    title: "Luxury Retail South",
    desc: "Featuring the world’s finest luxury retail precinct, high fashion, jewelry and beauty brands in an inspired and unforgettable location.",
    feature1: "Expected Residents",
    feature1Text: "75k+",
    feature2: "Luxury Najdi Residences",
    feature2Text: "17k+",
    para: "To live in Diriyah is to experience a world where everything is carefully designed to enhance your quality of life. Choose from an array of international cuisine, iconic cultural attractions, high-end fashion brands, and breathtaking outdoor spaces just moments from home.",
    swipeGalleryData: [
      { src: paths.luxuryRetail.full, caption: "" },
    ],
    img: paths.luxuryRetail.full,
    imgThumb: paths.luxuryRetail.thumb,
    link: "luxury-s",
    mapID: "unit-finder",
  },
  {
    name: "Culture & Dining",
    subtitle: "Family dining, entertainment, culture and art",
    title: "Culture & Dining",
    desc: "Family dining, entertainment, bazaar, culture and art, stimulating the senses and creating an authentic experience.",
    feature1: "Najdi Retail Units",
    feature1Text: "400+",
    feature2: "Dining Experiences",
    feature2Text: "20+",
    para: "This unique project gathers together over 400 of the finest luxury and lifestyle brands from across the Kingdom and around the world in an exceptional, authentic and intimate environment of streets laneways and squares.",
    swipeGalleryData: [
      { src: paths.bujairiDining.full, caption: "" },
    ],
    img: paths.bujairiDining.full,
    imgThumb: paths.bujairiDining.thumb,
    link: "culture",
    mapID: "unit-finder",
  },
  {
    name: "Lifestyle Retail",
    subtitle: "Upcoming, popular and next-generation shopping",
    title: "Lifestyle Retail",
    desc: "Upcoming, popular and next-generation shopping with an emphasis on sports, fashion, urban lifestyle and leisure.",
    feature1: "Najdi Retail Units",
    feature1Text: "400+",
    feature2: "Dining Experiences",
    feature2Text: "20+",
    para: "This unique project gathers together over 400 of the finest luxury and lifestyle brands from across the Kingdom and around the world in an exceptional, authentic and intimate environment of streets laneways and squares.",
    swipeGalleryData: [
      { src: paths.retail.full, caption: "" },
    ],
    img: paths.retail.full,
    imgThumb: paths.retail.thumb,
    link: "lifestyle",
    mapID: "unit-finder",
  },
  /*
    -.-.-.-.-.- UNIT FINDER B1 -.-.-.-.-.-
    */

  {
    name: "",
    title: "Premium Dining",
    // desc: "XXX",
    // feature1: "XXX",
    // feature1Text: "XXX",
    // feature2: "XXX",
    // feature2Text: "XXX",
    // para: "XXX",
    swipeGalleryData: [
      { src: paths.retail.full, caption: "" },
    ],
    img: paths.retail.full,
    imgThumb: paths.retail.thumb,
    link: "premium-dining",
    mapID: "unit-finder-B1",
    para: "A luxury-lifestyle destination where iconic brands will be complimented with traditional artisan souks and bazaars, all set within an authentic, walkable Najdi village atmosphere providing a new lifestyle experience unique to Diriyah."
  },
  {
    name: "Premium Dining",
    subtitle: "The premium dining experience",
    title: "Premium Dining",
    desc: "The premium dining experience carries over to the lower ground floor with outdoor dining and connectivity to the outdoor amphitheater.",
    // feature1: "XXX",
    // feature1Text: "XXX",
    // feature2: "XXX",
    // feature2Text: "XXX",
    swipeGalleryData: [
      { src: paths.retail.full, caption: "" },
    ],
    img: paths.retail.full,
    imgThumb: paths.retail.thumb,
    link: "premium-dining-B1",
    mapID: "unit-finder-B1",
    para: "A luxury-lifestyle destination where iconic brands will be complimented with traditional artisan souks and bazaars, all set within an authentic, walkable Najdi village atmosphere providing a new lifestyle experience unique to Diriyah."
  },
  {
    name: "Lifestyle Convenience",
    subtitle:
      "Products and services that cater to everyday needs, convenience and community",
    title: "Lifestyle Convenience",
    desc: "Impressive selection of beautiful lifestyle retailers and contemporary homeware specialists.",
    // feature1: "XXX",
    // feature1Text: "XXX",
    // feature2: "XXX",
    // feature2Text: "XXX",
    swipeGalleryData: [
      { src: paths.placeholder.full, caption: "" },
    ],
    img: paths.placeholder.full,
    imgThumb: paths.placeholder.thumb,
    link: "lifestyle-convenience-B1",
    mapID: "unit-finder-B1",
    para: "A luxury-lifestyle destination where iconic brands will be complimented with traditional artisan souks and bazaars, all set within an authentic, walkable Najdi village atmosphere providing a new lifestyle experience unique to Diriyah."
  },
  {
    name: "Lifestyle Homewares",
    subtitle: "Lifestyle retailers and contemporary homeware specialists",
    title: "Lifestyle Homewares",
    desc: "Providing products and services that cater to everyday needs, convenience and community retail.",
    // feature1: "XXX",
    // feature1Text: "XXX",
    // feature2: "XXX",
    // feature2Text: "XXX",
    swipeGalleryData: [
      { src: paths.placeholder.full, caption: "" },
    ],
    img: paths.placeholder.full,
    imgThumb: paths.placeholder.thumb,
    link: "lifestyle-homewares-B1",
    mapID: "unit-finder-B1",
    para: "A luxury-lifestyle destination where iconic brands will be complimented with traditional artisan souks and bazaars, all set within an authentic, walkable Najdi village atmosphere providing a new lifestyle experience unique to Diriyah."
  },
  /*
    -.-.-.-.-.- HOTELS -.-.-.-.-.-
    */

  {
    name: "baccarat",
    title: "Baccarat Hotel",
    desc: "Baccarat Diriyah will offer guests an artistic atmosphere catering to opulent social moments.",
    para: "Key count - 86    ",
    swipeGalleryData: [
      { src: "/assets/images/residences-hotels/baccarat-hotel/aerial-1.webp", caption: "" },
      { src: "/assets/images/residences-hotels/baccarat-hotel/aerial-2.webp", caption: "" },
      { src: "/assets/images/residences-hotels/baccarat-hotel/courtyard.webp", caption: "" },
      { src: "/assets/images/residences-hotels/baccarat-hotel/lux.webp", caption: "" },
    ],
    img: paths.baccaratHotel.full,
    imgThumb: paths.baccaratHotel.thumb,
    link: "baccarat",
    mapID: null,
  },
  {
    name: "baccarat",
    title: "Corinthia Hotel",
    desc: "The Corinthia Hotel will build upon its hospitality legacy by delighting the senses of Diriyah’s travelers with great service and timeless design motifs.    ",
    // feature1: "XXX",
    // feature1Text: "XXX",
    // feature2: "XXX",
    // feature2Text: "XXX",
    // para: "Key count – 86    ",
    swipeGalleryData: [
      { src: "/assets/images/residences-hotels/corinthia-hotel/aerial-1.webp", caption: "" },
      { src: "/assets/images/residences-hotels/corinthia-hotel/aerial-2.webp", caption: "" },
      { src: "/assets/images/residences-hotels/corinthia-hotel/aerial.webp", caption: "" },
      { src: "/assets/images/residences-hotels/corinthia-hotel/entrance.webp", caption: "" },
      { src: "/assets/images/residences-hotels/corinthia-hotel/external.webp", caption: "" },
      { src: "/assets/images/residences-hotels/corinthia-hotel/lobby.webp", caption: "" },
      { src: "/assets/images/residences-hotels/corinthia-hotel/lux.webp", caption: "" },
      { src: "/assets/images/residences-hotels/corinthia-hotel/plaza.webp", caption: "" },
      { src: "/assets/images/residences-hotels/corinthia-hotel/spa.webp", caption: "" },
    ],
    img: paths.corinthiaHotel.full,
    imgThumb: paths.corinthiaHotel.thumb,
    link: "corinthia",
    mapID: null,
  },
  {
    name: "baccarat",
    title: "Rosewood Hotel & Resort",
    desc: "The Rosewood Hotel and Resort will add a grand and gracious new urban sanctuary to Diriyah and Wadi Hanifah. The hotel uniquely features 52 keys in the privacy and luscious Wadi Hanifah, and 210 keys in a prime location within the vibrant Diriyah Square in the midst of the retail hub.",
    // feature1: "XXX",
    // feature1Text: "XXX",
    // feature2: "XXX",
    // feature2Text: "XXX",
    // para: "Key count – 262     ",
    swipeGalleryData: [
      { src: "/assets/images/residences-hotels/rosewood-hotel/aerial-1.webp", caption: "" },
      { src: "/assets/images/residences-hotels/rosewood-hotel/aerial-2.webp", caption: "" },
      { src: "/assets/images/residences-hotels/rosewood-hotel/aerial-3.webp", caption: "" },
      { src: "/assets/images/residences-hotels/rosewood-hotel/aerial-4.webp", caption: "" },
      { src: "/assets/images/residences-hotels/rosewood-hotel/courtyard.webp", caption: "" },
      { src: "/assets/images/residences-hotels/rosewood-hotel/drop-off-night.webp", caption: "" },
      { src: "/assets/images/residences-hotels/rosewood-hotel/drop-off.webp", caption: "" },
      { src: "/assets/images/residences-hotels/rosewood-hotel/guest-house.webp", caption: "" },
      { src: "/assets/images/residences-hotels/rosewood-hotel/pavilion.webp", caption: "" },
      { src: "/assets/images/residences-hotels/rosewood-hotel/pool-2.webp", caption: "" },
      { src: "/assets/images/residences-hotels/rosewood-hotel/pool-3.webp", caption: "" },
      { src: "/assets/images/residences-hotels/rosewood-hotel/pool.webp", caption: "" },
      { src: "/assets/images/residences-hotels/rosewood-hotel/sikka.webp", caption: "" },
    ],
    img: paths.rosewoodHotel.full,
    imgThumb: paths.rosewoodHotel.thumb,
    link: "rosewood",
    mapID: null,
  },
  {
    name: "baccarat",
    title: "Fauchon Hotel",
    desc: "The purveyor of French contemporary gastronomy since 1886, will launch its first Fauchon Hotel in the Middle East, showcasing the perfect Parisian “art de vivre” experience",
    // feature1: "XXX",
    // feature1Text: "XXX",
    // feature2: "XXX",
    // feature2Text: "XXX",
    // para: "Key Count - 85",
    swipeGalleryData: [
      { src: "/assets/images/residences-hotels/fauchon-hotel/aerial-1.webp", caption: "" },
      { src: "/assets/images/residences-hotels/fauchon-hotel/aerial-2.webp", caption: "" },
      { src: "/assets/images/residences-hotels/fauchon-hotel/dining.webp", caption: "" },
      { src: "/assets/images/residences-hotels/fauchon-hotel/lobby.webp", caption: "" },
      { src: "/assets/images/residences-hotels/fauchon-hotel/pool.webp", caption: "" },
    ],
    img: paths.fauchonHotel.full,
    imgThumb: paths.fauchonHotel.thumb,
    link: "fauchon",
    mapID: null,
  },
  {
    name: "baccarat",
    title: "Armani Hotel",
    desc: "Armani Diriyah will represent a new interpretation of Armani hospitality, by Mr. Giorgio Armani, conversing with the unique architecture and landscape of the destination.    ",
    // feature1: "XXX",
    // feature1Text: "XXX",
    // feature2: "XXX",
    // feature2Text: "XXX",
    // para: "Key Count - 85",
    swipeGalleryData: [
      { src: "/assets/images/residences-hotels/armani-hotel/aerial-2.webp", caption: "" },
      { src: "/assets/images/residences-hotels/armani-hotel/aerial.webp", caption: "" },
      { src: "/assets/images/residences-hotels/armani-hotel/court.webp", caption: "" },
      { src: "/assets/images/residences-hotels/armani-hotel/drop-off.webp", caption: "" },
      { src: "/assets/images/residences-hotels/armani-hotel/entrance-2.webp", caption: "" },
      { src: "/assets/images/residences-hotels/armani-hotel/entrance.webp", caption: "" },
      { src: "/assets/images/residences-hotels/armani-hotel/lux.webp", caption: "" },
      { src: "/assets/images/residences-hotels/armani-hotel/pool.webp", caption: "" },
      { src: "/assets/images/residences-hotels/armani-hotel/restaurant.webp", caption: "" },
      { src: "/assets/images/residences-hotels/armani-hotel/spa.webp", caption: "" },
    ],
    img: paths.armaniHotel.full,
    imgThumb: paths.armaniHotel.thumb,
    link: "armani",
    mapID: null,
  },
  {
    name: "orient",
    title: "Orient Express",
    desc: "Will make its debut into the Middle East, bringing the refined nomadic spirit and state-of-art detailing that characterize the artisan of travel.",
    // feature1: "XXX",
    // feature1Text: "XXX",
    // feature2: "XXX",
    // feature2Text: "XXX",
    // para: "Key Count - 86",
    swipeGalleryData: [
      { src: paths.orientHotel.full, caption: "" },
    ],
    img: paths.orientHotel.full,
    imgThumb: paths.orientHotel.thumb,
    link: "orient",
    mapID: null,
  },

  /*
    -.-.-.-.-.- RESI -.-.-.-.-.-
    */

    {
      name: "armani-resi",
      title: "Armani Residences Diriyah",
      desc: "Armani Residences Diriyah will represent a new interpretation of Armani hospitality, by Mr. Giorgio Armani, conversing with the unique architecture and landscape of the destination.",
      // feature1: "XXX",
      // feature1Text: "XXX",
      // feature2: "XXX",
      // feature2Text: "XXX",
      // para: "Key Count - 86",
      swipeGalleryData: [
        { src: "/assets/images/residences-hotels/armani-residence/aerial-view-2.webp", caption: "" },
        { src: "/assets/images/residences-hotels/armani-residence/aerial-view.webp", caption: "" },
        { src: "/assets/images/residences-hotels/armani-residence/courtyard.webp", caption: "" },
        { src: "/assets/images/residences-hotels/armani-residence/entrance.webp", caption: "" },
        { src: "/assets/images/residences-hotels/armani-residence/pool.webp", caption: "" },
        { src: "/assets/images/residences-hotels/armani-residence/sikka.webp", caption: "" },
      ],
      img: paths.armaniResidences.full,
      imgThumb: paths.armaniResidences.thumb,
      link: "armaniresi",
      mapID: null,
    },
    {
      name: "armani-resi",
      title: "Baccarat Residences Diriyah",
      desc: "In the style of Baccarat’s uncompromising quality and impeccable hospitality, the residences are serviced by the five-star hotel group, which also features extensive amenities and events.",
      // feature1: "XXX",
      // feature1Text: "XXX",
      // feature2: "XXX",
      // feature2Text: "XXX",
      // para: "Key Count - 86",
      swipeGalleryData: [
        { src: paths.armaniResidences.full, caption: "" }
      ],
      img: paths.armaniResidences.full,
      imgThumb: paths.armaniResidences.thumb,
      link: "baccaratresi",
      mapID: null,
    },
    {
      name: "armani-resi",
      title: "Corinthia Residences Diriyah",
      desc: "True to the style and motifs of Najdi design, the collection of residences is an interplay of height and depth, light and air. Elegant foyers open to soaring ceilings that branch out into intimate family spaces and sweeping areas for entertaining.",
      // feature1: "XXX",
      // feature1Text: "XXX",
      // feature2: "XXX",
      // feature2Text: "XXX",
      // para: "Key Count - 86",
      swipeGalleryData: [
        { src: "/assets/images/residences-hotels/corinthia-residences/aerial-2.webp", caption: "" },
        { src: "/assets/images/residences-hotels/corinthia-residences/aerial-1.webp", caption: "" },
        { src: "/assets/images/residences-hotels/corinthia-residences/courtyard.webp", caption: "" },
        { src: "/assets/images/residences-hotels/corinthia-residences/entry.webp", caption: "" },
        { src: "/assets/images/residences-hotels/corinthia-residences/pool.webp", caption: "" }
      ],
      img: paths.armaniResidences.full,
      imgThumb: paths.armaniResidences.thumb,
      link: "corinthiaresi",
      mapID: null,
    },
    {
      name: "armani-resi",
      title: "Rosewood Residences Diriyah",
      desc: "Will add a grand and gracious new urban sanctuary to Diriyah and Wadi Hanifah.",
      // feature1: "XXX",
      // feature1Text: "XXX",
      // feature2: "XXX",
      // feature2Text: "XXX",
      // para: "Key Count - 86",
      swipeGalleryData: [
        { src: "/assets/images/residences-hotels/rosewood-residence/aerial-2.webp", caption: "" },
        { src: "/assets/images/residences-hotels/rosewood-residence/aerial-1.webp", caption: "" },
        { src: "/assets/images/residences-hotels/rosewood-residence/aerial-3.webp", caption: "" },
        { src: "/assets/images/residences-hotels/rosewood-residence/entrance-2.webp", caption: "" },
        { src: "/assets/images/residences-hotels/rosewood-residence/entrance-3.webp", caption: "" },
        { src: "/assets/images/residences-hotels/rosewood-residence/entrance.webp", caption: "" },
        { src: "/assets/images/residences-hotels/rosewood-residence/garden.webp", caption: "" },
        { src: "/assets/images/residences-hotels/rosewood-residence/lobby.webp", caption: "" },
        { src: "/assets/images/residences-hotels/rosewood-residence/pool.webp", caption: "" },
        { src: "/assets/images/residences-hotels/rosewood-residence/pool-2.webp", caption: "" },
        { src: "/assets/images/residences-hotels/rosewood-residence/pool-3.webp", caption: "" },
        { src: "/assets/images/residences-hotels/rosewood-residence/restaurant-3.webp", caption: "" },
        { src: "/assets/images/residences-hotels/rosewood-residence/restaurant-2.webp", caption: "" },
        { src: "/assets/images/residences-hotels/rosewood-residence/restaurant.webp", caption: "" },
        { src: "/assets/images/residences-hotels/rosewood-residence/spa.webp", caption: "" },
        { src: "/assets/images/residences-hotels/rosewood-residence/villa.webp", caption: "" },
      ],
      img: paths.armaniResidences.full,
      imgThumb: paths.armaniResidences.thumb,
      link: "rosewoodresi",
      mapID: null,
    },
]
