import { useMemo } from "react"
import styled from "styled-components"

type TCloudsProps = {
  position?: "top" | "bottom"
  minDurationMS?: number
  maxDurationMS?: number
  amount?: number
  direction?: "left" | "right"
  [x: string]: unknown
}

function Clouds(props: TCloudsProps) {
  const {
    position = "top",
    minDurationMS = 70000,
    maxDurationMS = 140000,
    amount = 10,
    direction = "right",
    ...rest
  } = props
  const srcArray = useMemo(
    () => generateArray(amount, minDurationMS, maxDurationMS),
    [amount, minDurationMS, maxDurationMS]
  )

  return (
    <CloudsWrapper position={position} {...rest}>
      {srcArray.map((obj, i) => {
        return (
          <CloudImage
            src={obj.src}
            alt="cloud"
            key={`cloud-${i}`}
            position={position}
            style={{
              animationName: `glide-${direction}`,
              animationDuration: `${obj.duration}ms`,
            }}
          />
        )
      })}
    </CloudsWrapper>
  )
}

export default Clouds

const CloudsWrapper = styled.div<Partial<TCloudsProps>>`
  width: 100%;
  height: 0;
  overflow: visible;
  white-space: nowrap;
  position: absolute;
  ${(p) => p.position}: 0;

`

const CloudImage = styled.img<Partial<TCloudsProps>>`
  position: absolute;
  ${(p) => p.position}: 0;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  max-height: 15vh;
  transform: translateX(-100%);

  @keyframes glide-right {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(calc(100vw + 100%));
    }
  }

  @keyframes glide-left {
    0% {
      transform: translateX(calc(100vw + 100%));
    }
    100% {
      transform: translateX(-100%);
    }
  }
`

function generateArray(n, min, max) {
  const base = "/assets/images/clouds/cloud-"
  const extension = ".webp"
  const normalized =
    typeof n !== "number" || Number.isNaN(n) ? 10 : Math.round(Math.abs(n))
  let array = []
  const random = (minV, maxV) => Math.random() * (maxV - minV) + minV
  for (let i = 0; i < normalized; i++) {
    const index = i % 10
    array.push({
      src: `${base}${index}${extension}`,
      duration: Math.round(random(min, max)),
      offsetY: Math.round(random(-50, 50)),
    })
  }
  return array
}
