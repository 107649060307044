import { useRef } from "react"

export default function useClickOverDrag(timeThreshold = 100, dragThreshold = 20){
  const clickDown = useRef(0)
  const clickMove = useRef({
    x: 0,
    y: 0,
  })
  
  function handleDown(e) {
    clickDown.current = e.timeStamp
    clickMove.current = {
      x: e.clientX,
      y: e.clientY,
    }
  }

  function handleUp(e, callback) {
    const diff = e.timeStamp - clickDown.current
    const xMove = Math.abs(e.clientX - clickMove.current.x)
    const yMove = Math.abs(e.clientY - clickMove.current.y)
    if (diff < timeThreshold && xMove < dragThreshold && yMove < dragThreshold) {
      callback()
    }
  }

  return {
    handleDown,
    handleUp
  }
}