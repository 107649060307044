import { create } from "zustand"

type TMapElement = (
  | "zone-overlay"
  | "zone-highlight"
  | "zone-icon"
  | "zone-primary-icon"
  | "unit-overlay"
  | "amenity-icon"
  | "transport-icon"
  | "none"
)[]

type TUnitFinderSections =
  | "entertainment"
  | "dining"
  | "anchor"
  | "department"
  | "luxury-n"
  | "luxury-s"
  | "culture"
  | "lifestyle"
  | null

type TMasterplanSections =
  | "bujairi"
  | "at-turaif"
  | "diriyah-square"
  | "events-district"
  | "diriyah-gardens"
  | "northern-cultural"
  | "diriyah-north"
  | "wadi-hanifah"
  | "diriyah-south"
  | "ajmakan"
  | "samhan"
  | "king"
  | "diriyah-square"
  | "qurain"
  | null

type TDiriyahSquareSections =
  | "community"
  | "hospitality"
  | "open-spaces"
  | "commercial"
  | "residential"
  | "retail"
  | null

type TAmenityCategory =
  | "restrooms"
  | "driver-lounges"
  | "valet-lounges"
  | "welcome-halls"
  | "vip-lounges"
  | "vvip-lounges"
  | "concierge"
  | "valet"
  | "taxi"
  | "bus"
  | "ev"
  | "coach"
  | "metro"
  | "cycle-parking"
  | "parking"
  | "car"
  | string
  | null

type TUnitLevel = "B3" | "B2" | "B1" | "0" | "1" | null

type TAmenityLevel = "B3" | "B2" | "B1" | "0" | "1" | null

type galleryData = {
  caption?: string
  src: string
}

interface InitialState {
  someState: []
  fullscreenIntroVideo: boolean
  setFullscreenIntroVideo: (value: boolean) => void
  fullscreenFeaturesVideo: boolean
  setFullscreenFeaturesVideo: (value: boolean) => void
  setSomeState: (value: string) => void
  showNavbar: boolean
  setShowNavbar: (value: boolean) => void
  visionSection: string
  setVisionSection: (value: string) => void
  elementType: TMapElement
  primaryLevel: boolean
  activeHotel: string
  mapPage: "unit-finder" | "masterplan" | "diriyah-square" | "features" | null
  mapCategory:
    | TDiriyahSquareSections
    | TMasterplanSections
    | TUnitFinderSections
    | "features"
    | string
  mapFullScreenImage: null | string
  amenityCategory: TAmenityCategory
  zoomRef: any
  selectedCategoryIndex: number | null
  unitLevel: TUnitLevel
  sidePanel: boolean | true
  selectedUnit: boolean | false
  unitFinderCategory: boolean | true
  showDetails: boolean | false
  miniMap: boolean
  // amenityLevel: TAmenityLevel
  unitID: string | null
  activeMap: string
  masterplanType: "districts" | "assets"
  setActiveMap: (value: string) => void
  riyadhPanel: "airport" | "kafd" | "king-salman-park" | "kingdom-tower" | null
  setRiyadhPanel: (
    value: "airport" | "kafd" | "king-salman-park" | "kingdom-tower"
  ) => void
  currentUser: any
  setCurrentUser: (value: any) => void
  tourOpen: boolean
  tourIndex: number
  initialSwiperGalleryIndex: number
  swiperGalleryData: galleryData[] | []
  swiperGalleryAmenity: TAmenityCategory
  swiperGalleryOpen: boolean
  pdfViewerOpen: boolean
  navOpen: boolean
  navigating: boolean
  navDurationSec: number
  featuresAnimationDelay: number
  videoOpen: boolean
  mapZooming: boolean
  finderMapUI: boolean
  finderOverlayLayer: boolean
  finderPlotsLayer: boolean
  finderLabelsLayer: boolean
  dsqOrbitPlaying: boolean
  dsqOrbitDir: boolean
  dsqRetailView: boolean
}

type SetLocalState = {
  setLocalState: (state: Partial<InitialState>) => void
}

export const useStore = create<InitialState & SetLocalState>()((set, get) => ({
  zoomRef: null,
  someState: [],
  navOpen: false,
  navigating: true,
  navDurationSec: 0.5,
  miniMap: false,
  setSomeState: (value: any) => set({ someState: value }),
  fullscreenIntroVideo: true,
  setFullscreenIntroVideo: (value: boolean) =>
    set({ fullscreenIntroVideo: value }),
  fullscreenFeaturesVideo: true,
  setFullscreenFeaturesVideo: (value: boolean) =>
    set({ fullscreenFeaturesVideo: value }),
  showNavbar: false,
  activeHotel: null,
  setShowNavbar: (value: boolean) => set({ showNavbar: value }),
  visionSection: "overview",
  setVisionSection: (value: string) => set({ visionSection: value }),
  elementType: ["zone-overlay"],
  // mapPage: null,
  primaryLevel: true,
  mapPage: "masterplan",
  mapCategory: null,
  mapFullScreenImage: null,
  selectedCategoryIndex: 0,
  amenityCategory: null,
  unitLevel: null,
  sidePanel: true,
  selectedUnit: false,
  // amenityLevel: "B1",
  unitID: null,
  unitFinderCategory: true,
  showDetails: false,
  activeMap: "global",
  riyadhPanel: null,
  setRiyadhPanel: (
    value: "airport" | "kafd" | "king-salman-park" | "kingdom-tower"
  ) => set({ riyadhPanel: value }),
  masterplanType: "districts",
  setActiveMap: (value: string) => set({ activeMap: value }),
  setLocalState: (stateToUpdate) => {
    set({ ...stateToUpdate })
  },
  currentUser: null,
  setCurrentUser: (value: any) => set({ currentUser: value }),
  tourOpen: false,
  tourIndex: 0,
  swiperGalleryData: [],
  initialSwiperGalleryIndex: 0,
  swiperGalleryAmenity: null,
  swiperGalleryOpen: false,
  pdfViewerOpen: false,
  featuresAnimationDelay: 0.4,
  videoOpen: false,
  mapZooming: false,
  finderMapUI: true,
  finderOverlayLayer: true,
  finderPlotsLayer: false,
  finderLabelsLayer: false,
  dsqOrbitDir: true,
  dsqOrbitPlaying: false,
  dsqRetailView: false,
}))
