import styled from "styled-components"
import { motion } from "framer-motion"
import { PieChart } from "../charts/PieChart"
import { ColumnChart } from "../charts/ColumnChart"
import SmallBox from "../charts/SmallBox"
import TableChart from "../charts/TableChart"
import { TreemapChart } from "../charts/TreemapChart"

const MetricsOverview: React.FC<any> = () => {
  //
  return (
    <Wrapper>
      <div className="content">
        <div className="left">
          <SmallBox
            title="Product development cost (SAR)"
            data={8.2}
            suffix="bn"
          />
          <SmallBox title="Year of opening" data={2027} />
          <SmallBox title="Estimated year of maturity" data={2029} />
          <TableChart />
          <div className="medium-box">
            <ColumnChart
              title="FOOTFALL (mm)"
              data={[23.8, 29.46]}
              categories={["Yr 2028", "Yr 2031"]}
              yaxismax={35}
            />
          </div>
        </div>
        <div className="right">
          <div className="top-boxes">
            <TreemapChart />
          </div>

          <div className="middle-boxes">
            <div className="box">
              <span className="data">~420</span>
              <span className="title">Number of stores</span>
            </div>
            <div className="box">
              <span className="data">7</span>
              <span className="title">Number of unique leisure offers</span>
            </div>
            <div className="box">
              <span className="data">2.41%</span>
              <span className="title">Market capture rate % (2028)</span>
            </div>
          </div>
          <div className="bottom-boxes">
            <div className="left-box">
              <PieChart
                title="TYPE OF VISITOR (%)"
                data={[66, 26, 8]}
                legend={true}
                labels={[
                  "Riyadh Residents",
                  "Domestic Tourists",
                  "International Tourists",
                ]}
              />
            </div>
            <div className="right-box">
              <ColumnChart
                title="SALES DENSITY (SAR/sqm)"
                data={[24100, 25600]}
                categories={["Yr 2028", "Yr 2031"]}
                yaxismax={30000}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default MetricsOverview

const Wrapper = styled(motion.div)`
  height: 100%;
  animation: fadeIn 1s ease-in forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 1rem;
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
  }

  iframe {
    width: calc(100% - 15em);
    height: 100%;
  }

  .left {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border-radius: 4px;

    .medium-box {
      width: 100%;
      height: 18rem;
      border-radius: 4px;
      background-color: #f0f0f0;
      padding: 1rem;
      flex: 1;
    }
  }

  .right {
    width: 80%;
    height: 100%;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;

    .top-boxes {
      width: 100%;
      border-radius: 4px;
      flex: 1;
      background: #f0f0f0;
      padding: 1rem;
      border-radius: 4px;
    }

    .middle-boxes {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      .box {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;
        border-radius: 4px;
        padding: 1rem 2rem;
        background: ${({ theme }) => theme.lightGreen};
        color: ${({ theme }) => theme.darkGreen};

        &:nth-child(2) {
          background: #f0f0ef;
        }

        .title {
          font-size: 0.8rem;
          font-weight: 400;
          font-family: "BeausiteClassicTrial-Clear";
          text-transform: uppercase;
        }

        .data {
          font-size: 1;
          font-weight: 400;
          color: ${({ theme }) => theme.darkGreen};
          font-family: "BeausiteGrandTrial-Light";
        }
      }
    }

    .bottom-boxes {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 30%;
      gap: 1rem;

      .left-box,
      .right-box {
        width: 50%;
        height: 100%;
        border-radius: 4px;
        padding: 1rem;
        background: #f0f0ef;
      }
    }
  }
`
