import { useEffect, useRef, FC } from "react"
import ApexCharts, { ApexOptions } from "apexcharts"

type TreemapChartProps = {}

const TreemapChart: FC<TreemapChartProps> = () => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const treemapChart = new ApexCharts(chartRef.current, chart1Options())
    if (treemapChart) {
      treemapChart.render()
    }

    return treemapChart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef])

  return <div ref={chartRef}></div>
}

const chart1Options = (): ApexOptions => {
  const labelColor = "#004344"

  return {
    series: [
      {
        data: [
          {
            x: "Premium & Value Fashion, 25%",
            y: 25,
          },
          {
            x: "F&B, 18%",
            y: 18,
          },
          {
            x: "Luxury Fashion, 12%",
            y: 12,
          },
          {
            x: "L&E, 12%",
            y: 12,
          },
          {
            x: "Department Store, 8%",
            y: 8,
          },
          {
            x: "Home Furnishing, 8%",
            y: 8,
          },
          {
            x: "Other Luxury (Watches & Jewelry), 3%",
            y: 3,
          },
          {
            x: "Perfumes & Cosmetics, 3%",
            y: 3,
          },
          {
            x: "Hypermarket, 2%",
            y: 2,
          },
          {
            x: "Footwear, 3%",
            y: 3,
          },
          {
            x: "Electronics, 2%",
            y: 2,
          },
          {
            x: "Services & Others, 2%",
            y: 2,
          },
          {
            x: "Co-working, 2%",
            y: 2,
          },
        ],
      },
    ],
    legend: {
      show: false,
    },
    chart: {
      events: {
        mounted: (chart) => {
          chart.windowResizeHandler()
        },
      },
      height: "100%",
      type: "treemap",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    stroke: {
      show: true,
      curve: "monotoneCubic",
      lineCap: "square",
      colors: ["#f0f0f0"],
      width: 3,
    },
    title: {
      text: "GLA by Category (%)",
      align: "left",
      style: {
        fontSize: "14px",
        color: "#004344",
        fontWeight: "400",
        fontFamily: "BeausiteClassicTrial-Clear",
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "14px",
        fontWeight: "400",
        colors: [labelColor],
      },
    },
    xaxis: {
      tickPlacement: "between",
    },
    colors: [
      "#e2e9e9",
      "#d4dfdf",
      "#c7d7d7",
      "#bad0d0",
      "#bad0d0",
      "#bad0d0",
      "#bad0d0",
      "#bad0d0",
      "#bad0d0",
      "#bad0d0",
      "#bad0d0",
      "#bad0d0",
      "#bad0d0",
    ],
    plotOptions: {
      treemap: {
        dataLabels: {
          format: "truncate",
        },
        distributed: true,
        enableShades: false,
      },
    },
  }
}

export { TreemapChart }
