import { useEffect, useRef, FC } from "react"
import ApexCharts, { ApexOptions } from "apexcharts"

type BarChartProps = {
  title: string
  labels?: string[]
  data: number[]
  legend: boolean
}

const PieChart: FC<BarChartProps> = ({ title, labels, data, legend }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const pieChart = new ApexCharts(
      chartRef.current,
      pieChartOptions(title, labels, data, legend),
    )
    if (pieChart) {
      pieChart.render()
    }

    return pieChart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef])

  return <div ref={chartRef}></div>
}

const pieChartOptions = (
  title: string,
  labels: string[],
  data: number[],
  legend: boolean,
): ApexOptions => {
  return {
    series: data,

    title: {
      text: title,
      align: "left",
      style: {
        fontSize: "16px",
        color: "#004344",
        fontWeight: "400",
        fontFamily: "BeausiteClassicTrial-Clear",
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: true,
        blur: 2,
      },
      formatter: function (val: number, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex]
        const withLabels = name + ", " + val?.toFixed(1) + "%"
        const noLabels = `${opts.w.config.series[opts.seriesIndex]}%`

        return legend ? noLabels : withLabels
      },
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      fontSize: "14px",
      fontFamily: "BeausiteClassicTrial-Clear",
    },
    chart: {
      height: "100%",
      type: "pie",
      redrawOnParentResize: true,

      fontFamily: "BeausiteClassicTrial-Clear",
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 900,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -15,
        },
      },
    },
    stroke: {
      show: true,
      curve: "monotoneCubic",
      lineCap: "square",
      colors: ["#f0f0f0"],
      width: 3,
    },
    labels: labels,
    colors: ["#002e2f", "#335859", "#668282", "#99abac", "#ccd5d5"],
    responsive: [
      {
        breakpoint: 1367,
        options: {
          chart: {
            // height: 250,
          },
          legend: {
            show: legend,
            position: "right",
          },
        },
      },
    ],
  }
}

export { PieChart }
