const mapGlobalValues = {
  maskColor: "#000000",
  maskOpacity: "0.6",
  maskStrokeColor: null,
  amenityIconBackgroundColor: "#FFFFFF",
  zoneIconBackgroundColor: "#B2C6C6",
  zoneIconTextColor: "#004344",
  zoneOverlayStrokeColor: "#FFFFFF",
  zoneOverlayStrokeWidth: "4",
  zoneOverlayOpacity: "0.6",
  unitOverlayBackgroundColor: "#000000",
  unitOverlaySelectedColor: "#B2C6C6",
  unitOverlayStrokeColor: "#FFFFFF",
  mainIconBackgroundColor: "#AF9781",
  mainIconTextColor: "#FFFFFF",
  iconPaths: {
    green: {
      car: "/assets/images/map/icons/carIconGreen.svg",
      babyFace: "/assets/images/map/icons/restroomIconGreen.svg",
      star: "/assets/images/map/icons/starIconGreen.svg",
      hands: "/assets/images/map/icons/welcomeIconGreen.svg",
      circleArrows: "/assets/images/map/icons/360IconGreen.svg",
      eye: "/assets/images/map/icons/eyeIconGreen.svg",
      lightning: "/assets/images/map/icons/lightingIconGreen.svg",
      bus: "/assets/images/map/icons/busIconGreen.svg",
      flag: "/assets/images/map/icons/flagIconGreen.svg",
    },
    white: {
      arrow: "/assets/images/map/icons/arrowIconWhite.svg",
    },
  },
}
const icons = {
  car: "/assets/images/map/icons/carIconGreen.svg",
  babyFace: "/assets/images/map/icons/restroomIconGreen.svg",
  star: "/assets/images/map/icons/starIconGreen.svg",
  hands: "/assets/images/map/icons/welcomeIconGreen.svg",
  circleArrows: "/assets/images/map/icons/360IconGreen.svg",
  eye: "/assets/images/map/icons/eyeIconGreen.svg",
  bike: "/assets/images/map/icons/eyeIconGreen.svg",
  lightning: "/assets/images/map/icons/lightingIconGreen.svg",
  bus: "/assets/images/map/icons/busIconGreen.svg",
  flag: "/assets/images/map/icons/flagIconGreen.svg",
}

export const iconIndex = {
  bus: icons.bus,
  coach: icons.bus,
  "cycle-parking": icons.bike,
  metro: icons.bus,
  parking: icons.car,
  restrooms: icons.babyFace,
  "driver-lounges": icons.car,
  "welcome-halls": icons.hands,
  concierge: icons.hands,
  valet: icons.flag,
  "valet-lounges": icons.flag,
  "vip-lounges": icons.star,
  "vvip-lounges": icons.star,
  ev: icons.lightning,
  taxi: icons.car,
}

export default mapGlobalValues
