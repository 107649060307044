import L, { RasterCoords } from 'leaflet';
import { useCallback } from 'react';
import { useMap } from 'react-leaflet';

type UseRasterCoords = (
  imgsize: [width: number, height: number],
  tilesize?: number,
  setmaxbounds?: boolean
) => RasterCoords;

const useRasterCoords: UseRasterCoords = (imgsize, tilesize = 256, setmaxbounds = true) => {
  const map = useMap();
  const width = imgsize[0];
  const height = imgsize[1];

  /**
   * calculate accurate zoom level for the given image size
   */
  const zoomLevel = useCallback<RasterCoords['zoomLevel']>(
    function () {
      return Math.ceil(Math.log(Math.max(width, height) / tilesize) / Math.log(2));
    },
    [width, height, tilesize]
  );

  const zoom = zoomLevel();

  /**
   * unproject `coords` to the raster coordinates used by the raster image projection
   */
  const unproject = useCallback<RasterCoords['unproject']>(
    function (coords) {
      return map.unproject(coords, zoom);
    },
    [zoom]
  );

  /**
   * project `coords` back to image coordinates
   */
  const project = useCallback<RasterCoords['project']>(function (coords) {
    return map.project(coords, zoom);
  }, []);

  /**
   * get the max bounds of the image
   */
  const getMaxBounds = useCallback<RasterCoords['getMaxBounds']>(function () {
    var southWest = unproject([0, height]);
    var northEast = unproject([width, 0]);
    return new L.LatLngBounds(southWest, northEast);
  }, []);

  /**
   * sets the max bounds on map
   */
  const setMaxBounds = useCallback<RasterCoords['setMaxBounds']>(function () {
    var bounds = getMaxBounds();
    map.setMaxBounds(bounds);
  }, []);

  if (setmaxbounds && width && height) {
    setMaxBounds();
  }

  return {
    zoomLevel,
    unproject,
    project,
    getMaxBounds,
    setMaxBounds,
  };
};

export default useRasterCoords;