import React from "react";
import styled from "styled-components";
import TabContent1 from "./Tabs/Overview";
import TabContent2 from "./Tabs/mainTeam/MainTeam";

interface TabContentProps {
  selectedTab: number;
}

const TabContent: React.FC<TabContentProps> = ({ selectedTab }) => {
  return (
    <Content>
      {selectedTab === 1 && <TabContent1 />}
      {selectedTab === 2 && <TabContent2 />}
      {selectedTab === 3 && <TabContent2 />}
    </Content>
  );
};

export default TabContent;

const Content = styled.div`
  margin: 0;
`;
