import React from "react"
import { EmblaOptionsType } from "embla-carousel"
import useEmblaCarousel from "embla-carousel-react"
import { Panel } from "@components/panels"
import styled from "styled-components"
import { Button } from "@components/buttons/Button"
import { AnimatePresence } from "framer-motion"
import { DiamondButton } from "@components/buttons"
import availableSLDS from "@data/availableSLDS"
import { Unit } from "../Favorites"
import { useStore } from "@state/store"

type FavsCarouselProps = {
  units: Unit[]
  options?: EmblaOptionsType
  setUnit: (unit: any) => void
  removeFromFavorites: (unit: any) => void
}

const info = [
  {
    img: "/assets/icons/favorites/building.svg",
    text: "Building No",
    type: "buildingNo",
  },
  {
    img: "/assets/icons/favorites/unit.svg",
    text: "Unit No",
    type: "unitNo",
  },
  {
    img: "/assets/icons/favorites/area.svg",
    text: "Area (sqm)",
    type: "sqm",
  },
]

const FavsCarousel: React.FC<FavsCarouselProps> = (props) => {
  const { units, options } = props
  const [emblaRef] = useEmblaCarousel(options)
  const { unitID, mapCategory, setLocalState } = useStore((s) => ({
    unitID: s.unitID,
    mapCategory: s.mapCategory,
    setLocalState: s.setLocalState,
  }))

  function getPNGMatch(id) {
    return availableSLDS.some((png) => png.split("-")[0] === id)
  }

  function getPNGS(id) {
    const pngBaseURL = "/assets/images/sld/"
    const filtered = availableSLDS.filter((png) => png.split("-")[0] === id)
    return filtered.map((id) => {
      return {
        src: `${pngBaseURL}${id}.webp`,
      }
    })
  }

  return (
    <AnimatePresence>
      <Wrapper>
        <SectionWrapper>
          <div className="carousel-viewport" ref={emblaRef}>
            <div className="carousel-container">
              {units &&
                units.map((unit: Unit, index: number) => (
                  <Panel
                    key={`${unit.id}-${index}`}
                    className="fav-slide"
                    layoutId={`sharedId-${unit.id}`}
                  >
                    <DiamondButton
                      icon="/assets/icons/common/close-icon.svg"
                      color="outline"
                      size="large"
                      style={{
                        position: "absolute",
                        top: "2rem",
                        right: "2rem",
                      }}
                      onClick={() => {
                        props.removeFromFavorites(unit)
                      }}
                    />

                    <div className="unit-info-box">
                      <div className="top-section">
                        <div className="zone">{unit?.zone}</div>
                        <div className="unit">
                          Unit {unit?.buildingNo} - {unit?.unitNo}
                        </div>
                        <div className="room">{unit?.roomName}</div>
                      </div>
                      <div className="unit-info">
                        {info.map((item: any, index: number) => (
                          <div className="info" key={index}>
                            <div className="info-icon">
                              <img src={info[index].img} alt={item.text} />
                            </div>
                            <div className="info-text">
                              <div className="text">{info[index].text}</div>
                              <div className="value">
                                {unit[info[index].type]}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {getPNGMatch(unit.id) && (
                        <Button
                          style={{
                            position: "absolute",
                            bottom: "1em",
                            width: "calc(100% - 2em)",
                            backgroundColor: "black",
                          }}
                          variant="rectangle"
                          color="brown"
                          icon={"/assets/icons/common/eye.svg"}
                          title="SLD"
                          onClick={() => {
                            const galleryData = getPNGS(unit.id)
                            setLocalState({
                              swiperGalleryData: galleryData,
                              swiperGalleryOpen: true,
                            })
                          }}
                        />
                      )}
                    </div>
                  </Panel>
                ))}
            </div>
          </div>
        </SectionWrapper>
      </Wrapper>
    </AnimatePresence>
  )
}

export default FavsCarousel

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  width: 100%;
`

const SectionWrapper = styled.section`
  padding-left: 2rem;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;

  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-spacing-sm: 1.6rem;
  --slide-size-sm: 50%;
  --slide-spacing-lg: 2rem;
  --slide-size-lg: calc(100% / 3);

  .carousel-viewport {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .carousel-container {
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    margin-right: 3rem;
    margin-left: 2rem;
    touch-action: pan-y;
  }

  .fav-slide {
    flex: 0 0 var(--slide-size);
    padding-left: var(--slide-spacing);
    max-width: 35rem;
    min-width: 35rem;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.lightGreen};
  }

  @media (max-height: 1080px) {
    .fav-slide {
      height: calc(100% - 13rem);
    }
  }

  @media (min-width: 750px) {
    .fav-slide {
      flex: 0 0 var(--slide-size-sm);
      padding-left: var(--slide-spacing-sm);
      max-width: 35rem;
      min-width: 35rem;
    }
  }
  @media (min-width: 1200px) {
    .fav-slide {
      flex: 0 0 var(--slide-size-lg);
      padding-left: var(--slide-spacing-lg);
      max-width: 35rem;
      min-width: 35rem;
    }
  }

  .top-section {
    display: flex;
    flex-direction: column;

    .zone {
      font-size: 3rem;
      padding: 1rem 0;
      font-family: "BeausiteGrandTrial-Light";
      color: #ffffff;
    }

    .unit {
      font-size: 2rem;
      font-family: "BeausiteGrandTrial-Light";
      color: ${({ theme }) => theme.darkGreen};
    }

    .unit-description {
      font-size: 1.5rem;
      font-family: "BeausiteGrandTrial-Light";
      color: ${({ theme }) => theme.darkGreen};
    }
  }

  .unit-info-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    font-family: "BeausiteGrandTrial-Light";
  }
`
