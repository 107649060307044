import mapGlobalValues from "../mapGlobalValues"

const baseConfig = {
  icon: mapGlobalValues.iconPaths.green.eye,
  bgColor: mapGlobalValues.zoneIconBackgroundColor,
  textColor: mapGlobalValues.zoneIconTextColor,
  height: 35,
  strokeColor: "white",
  strokeWidth: 2,
}

const data = [
  {
    label: "AMPHITHEATRE",
    activeState: "open-spaces",
    set: null,
    x: 725.15,
    y: 698.37,
  },
  {
    label: "PARK",
    activeState: "open-spaces",
    set: null,
    x: 1470.42,
    y: 1011.92,
  },
  {
    label: "ROSEWOOD HOTEL RESORT",
    activeState: "hospitality",
    set: "rosewood",
    x: 588.44,
    y: 888.64,
  },
  {
    label: "BACCARAT",
    activeState: "hospitality",
    set: "baccarat",
    x: 1200.86,
    y: 800.02,
  },
  {
    label: "CORINTHIA",
    activeState: "hospitality",
    set: "corinthia",
    x: 1214.99,
    y: 873.11,
  },
  {
    label: "FAUCHON",
    activeState: "hospitality",
    set: "fauchon",
    x: 1141.08,
    y: 951.76,
  },
  {
    label: "ARMANI",
    activeState: "hospitality",
    set: "armani",
    x: 1075.85,
    y: 995.16,
  },
  {
    label: "ORIENT EXPRESS",
    activeState: "hospitality",
    set: "orient",
    x: 985.02,
    y: 1040.45,
  },
  {
    label: "FIND A UNIT",
    activeState: "retail",
    set: null,
    x: 750,
    y: 850,
  },
  {
    label: "CULTURAL ASSET",
    activeState: "community",
    set: null,
    x: 819.43,
    y: 814.32,
  },
  {
    label: "GRAND MOSQUE",
    activeState: "community",
    set: null,
    x: 660.88,
    y: 1153.79,
  },
  {
    label: "DIRIYAH SQUARE OFFICES",
    activeState: "commercial",
    set: null,
    x: 858.02,
    y: 1254.86,
  },
  {
    label: "BACCARAT",
    activeState: "residential",
    set: "baccaratresi",
    x: 1378.25,
    y: 857.78,
  },
  {
    label: "CORINTHIA",
    activeState: "residential",
    set: "corinthiaresi",
    x: 1340.66,
    y: 910.75,
  },
  {
    label: "ARMANI",
    activeState: "residential",
    set: "armaniresi",
    x: 1235.76,
    y: 1045.45,
  },
]

const diriyahSquareSecondaryIconsData = data.map((item) => {
  return item.label === "FIND A UNIT"
    ? {
        ...item,
        bgColor: mapGlobalValues.mainIconBackgroundColor,
        textColor: mapGlobalValues.mainIconTextColor,
        icon: mapGlobalValues.iconPaths.white.arrow,
        iconPosition: "right",
        iconShape: "diamond",
        height: 40,
        strokeColor: "white",
        strokeWidth: 2,
      }
    : {
        ...item,
        ...baseConfig,
      }
})

export default diriyahSquareSecondaryIconsData
