import { useEffect, useMemo, useRef } from "react"
import styled from "styled-components"
import gsap from "gsap"

type TStaggeredTextProps = {
  text: string
  cue: boolean
  duration?: number
  delay?: number
  [x: string]: unknown
}

function StaggeredText(props: TStaggeredTextProps) {
  const { text, cue, duration = 1, delay = 0, ...rest } = props
  const textArray = useMemo(() => text.split(""), [text])
  const charRefs = useRef<HTMLSpanElement[]>([])

  useEffect(() => {
    if (!charRefs.current.length) return
    gsap.to(charRefs.current, {
      opacity: cue ? 1 : 0,
      stagger: duration * 0.05,
      ease: "power1.in",
      duration,
      delay,
    })
  }, [cue, text])
  return (
    <StaggeredTextWrapper {...rest}>
      {textArray.map((char, i) => {
        return (
          <span
            key={`char${i}`}
            style={{
              display: "inline-block",
              opacity: 0,
            }}
            ref={(node) => {
              if (node) {
                charRefs.current[i] = node
              }
            }}
          >
            {char === " " ? "\u205f" : char}
          </span>
        )
      })}
    </StaggeredTextWrapper>
  )
}

export default StaggeredText

const StaggeredTextWrapper = styled.div`
  & span {
    display: inline-block;
    opacity: 0;
  }
`
