import styled from "styled-components"
import { motion } from "framer-motion"
import { PieChart } from "../charts/PieChart"
import { BarChart } from "../charts/BarChart"
import { ColumnStackedChart } from "../charts/ColumnStacked"
import { ColumnChart } from "../charts/ColumnChart"

const MetricsOverview: React.FC<any> = () => {
  return (
    <Wrapper>
      <div className="content">
        <div className="top">
          <div className="top-left">
            <div className="box1">
              <PieChart
                title="Riyadh Retail Supply"
                data={[46, 54]}
                legend={false}
                labels={["Mall Based GLA", "Non mall based GLA"]}
              />
            </div>
            <div className="box2">
              <BarChart
                title="Mall GLA growth"
                data={[3.06, 2.0, 1.33]}
                yaxismax={3.1}
                categories={["Yr 2024", "Yr 2017", "Yr 2012"]}
              />
            </div>
          </div>
          <div className="top-right">
            <div className="box3">
              <PieChart
                title="Mall GLA distribution by size (Yr 2024)"
                data={[30, 27, 21, 14, 9]}
                legend={false}
                labels={[
                  "Super Regional",
                  "Community",
                  "Regional",
                  "Lifestyle",
                  "Others",
                ]}
              />
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="bottom-left">
            <ColumnStackedChart
              title="Evolution of retail supply by type"
              yaxismax={110}
              categories={["2012", "2017", "2024", "2028", "2031"]}
              data={[
                {
                  name: "Other",
                  data: [9, 9, 9, 13, 13],
                },
                {
                  name: "Lifestyle",
                  data: [0, 2, 14, 12, 14],
                },
                {
                  name: "Regional",
                  data: [28, 19, 14, 12, 14],
                },
                {
                  name: "Community",
                  data: [27, 39, 27, 19, 18],
                },
                {
                  name: "Super Regional",
                  data: [36, 32, 30, 41, 42],
                },
              ]}
            />
          </div>
          <div className="bottom-right">
            <div className="box1">
              <ColumnChart
                title="Retail supply growth estimates"
                yaxisTitle="GLA (sqm, mn)"
                data={[3.06, 4.19, 4.62]}
                categories={["Yr 2024", "Yr 2028", "Yr 2031"]}
                yaxismax={5}
              />
            </div>
            <div className="box2">
              <div className="circle">
                <div className="value">51%</div>
                <div className="description">
                  growth in GLA between 2024 - 2030
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default MetricsOverview

const Wrapper = styled(motion.div)`
  height: 100%;
  animation: fadeIn 1s ease-in forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .top {
    height: 50%;
    width: 100%;
    display: flex;
    gap: 1rem;

    .top-left {
      width: 70%;
      height: 100%;
      border-radius: 4px;
      padding: 0 1rem;
      display: flex;
      align-items: center;
      background: #f0f0f0;

      .box1 {
        width: 50%;
        height: 80%;
      }

      .box2 {
        width: 50%;
        height: 80%;
      }
    }

    .top-right {
      width: 30%;
      height: 100%;
      padding: 0 1rem;
      border-radius: 4px;
      display: flex;
      align-items: center;
      background: #f0f0f0;

      .box3 {
        width: 100%;
        height: 80%;
      }
    }
  }
  .bottom {
    height: 50%;
    width: 100%;
    display: flex;
    gap: 1rem;

    .bottom-left {
      width: 50%;
      height: 100%;
      padding: 1rem;
      background: #f0f0f0;
      border-radius: 4px;
    }

    .bottom-right {
      width: 50%;
      height: 100%;
      padding: 1rem;
      display: flex;
      align-items: center;
      background: #f0f0f0;
      border-radius: 4px;

      .box1 {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .box2 {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .circle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        border: 20px solid ${({ theme }) => theme.lightGreen};
        border-radius: 50%;
        color: ${({ theme }) => theme.white};
        background: ${({ theme }) => theme.darkGreen};
        padding: 1rem;
        height: 16rem;
        width: 16rem;
        animation: fadeIn 1s ease forwards;

        .value {
          font-size: 2rem;
          font-weight: bolder;
          font-family: "BeausiteFitTrial-Medium";
        }

        .description {
          font-size: 1rem;
          text-align: center;
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }
    }
  }
`
