import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { preloadAssets } from "@utils/assetsLoader"
import styled from "styled-components"
import LoadingScreen from "@components/loading-screen/LoadingScreen"
import { useStore } from "@state/store"
import Overview from "./components/Overview"
import Maps from "./components/Maps"
import Breadcrumb from "@components/breadcrumb/Breadcrumb"
import visionVideo from "@assets/videos/vision.mp4"
import mapIntro from "@assets/images/vision/map-intro.svg"
import visionImg from "@assets/images/vision/vision.png"
import PageWrapper from "@components/PageWrapper"
import Popup from "@components/Popup"

const assetsToPreload = [
  { type: "video", src: visionVideo },
  { type: "image", src: mapIntro },
  { type: "image", src: visionImg },
]

const breadcrumbKeys = {
  overview: "Vision",
  global: "World",
  saudi: "Saudi Arabia",
  riyadh: "Riyadh",
}

const generateBreadcrumbItems = (activeMap: string) => {
  const paths = ["overview"]
  if (activeMap === "global") paths.push("global")
  else if (activeMap === "saudi") paths.push("global", "saudi")
  else if (activeMap === "riyadh") paths.push("global", "saudi", "riyadh")

  return paths.map((key) => ({ key, label: breadcrumbKeys[key] }))
}

type VisionProps = {}

const Vision = (props: VisionProps) => {
  const location = useLocation()
  const [assetsLoaded, setAssetsLoaded] = useState(false)
  const {
    setFullscreenIntroVideo,
    visionSection,
    setVisionSection,
    activeMap,
    setActiveMap,
  } = useStore((state) => ({
    setFullscreenIntroVideo: state.setFullscreenIntroVideo,
    activeMap: state.activeMap,
    setActiveMap: state.setActiveMap,
    visionSection: state.visionSection,
    setVisionSection: state.setVisionSection,
  }))

  useEffect(() => {
    preloadAssets(assetsToPreload)
      .then(() => setAssetsLoaded(true))
      .catch((error) => console.error("Asset loading error:", error))

    setFullscreenIntroVideo(location.state?.from === "/")

    return () => {
      setVisionSection("overview")
      setActiveMap("global")
    }
  }, [location])

  const items = generateBreadcrumbItems(activeMap)

  return (
    <PageWrapper>
      <Wrapper>
        <LoadingScreen assetsLoaded={assetsLoaded} />
        <Popup
          durationMS={1000}
          openState={visionSection === "overview"}
          setOpenState={() => null}
        closeColor="transparent"
        >
          <Overview setVisionSection={setVisionSection}/>
        </Popup>
        <Popup
          durationMS={1000}
          openState={visionSection === "maps"}
          setOpenState={() => null}
          closeColor="transparent"
          >
          <Maps />
        </Popup>
          {assetsLoaded && visionSection === "maps" && (
            <Breadcrumb
              items={items}
              style={{
                position: "fixed",
                top: "5rem",
                left: "15rem",
                zIndex: 100000000000000,
              }}
              onClick={(key) => {
                const mapToState = {
                  overview: { map: "global", section: "overview" },
                  global: { map: "global", section: "maps" },
                  saudi: { map: "saudi", section: "maps" },
                  riyadh: { map: "riyadh", section: "maps" },
                }
  
                const selection = mapToState[key]
                if (selection) {
                  setActiveMap(selection.map)
                  setVisionSection(selection.section)
                }
              }}
            />
          )}
      </Wrapper>
    </PageWrapper>
  )
}

export default Vision

const Wrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 1;

  animation: fadeIn 1s ease forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
