import { motion } from "framer-motion"
import { useState } from "react"
import styled from "styled-components"

interface ICategoriesProps {
  onClick: (metric: number) => void
}

export default function Categories(props: ICategoriesProps) {
  const [activeCategory, setActiveCategory] = useState(0)
  const handleCategoryClick = (index: number) => {
    setActiveCategory(index)
    props.onClick(index)
  }
  return (
    <CategoryWrapper>
      <Category
        active={activeCategory === 0}
        onClick={() => handleCategoryClick(0)}
      >
        Overview
      </Category>
      <Category
        active={activeCategory === 1}
        onClick={() => handleCategoryClick(1)}
      >
        Primary Trade Area
      </Category>
      <Category
        active={activeCategory === 2}
        onClick={() => handleCategoryClick(2)}
      >
        Secondary Trade Area
      </Category>
      <Category
        active={activeCategory === 3}
        onClick={() => handleCategoryClick(3)}
      >
        Retail Supply
      </Category>
      <Category
        active={activeCategory === 4}
        onClick={() => handleCategoryClick(4)}
      >
        Income Profile
      </Category>
      <Category
        active={activeCategory === 5}
        onClick={() => handleCategoryClick(5)}
      >
        Retail Trends
      </Category>
      <Category
        active={activeCategory === 6}
        onClick={() => handleCategoryClick(6)}
      >
        Market Share
      </Category>
    </CategoryWrapper>
  )
}

const CategoryWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
`

const Category = styled.div<{ active: boolean }>`
  font-size: 0.7rem;
  font-family: "Arial";
  border: 0.5px solid ${({ theme }) => theme.lightGreen};
  border-left: none;
  padding: 0.75rem 2.5rem;
  background-color: ${({ active, theme }) =>
    active ? theme.darkGreen : "transparent"};
  color: ${({ active, theme }) => (active ? "white" : theme.darkGreen)};
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.3s;

  &:first-child {
    border-left: 0.5px solid ${({ theme }) => theme.lightBrown}8A;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  @media screen and (min-width: 1920px) {
    font-size: 0.5rem;
    padding: 0.5rem 2rem;
  }
`
