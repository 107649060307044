import { useRef, useEffect, useLayoutEffect, forwardRef, Fragment } from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import gsap from "gsap"

import MapSVGIconGroup from "@components/MapSVGIconGroup"

import { useStore } from "@state/store"
import getMarkDown from "@utils/getMapMarkDown"
import diriyahSquareZoneOverlayData from "@data/diriyah-square/diriyahSquareZoneOverlays"
import diriyahSquareZoneIconsData from "@data/diriyah-square/diriyahSquareZoneIcons"
import diriyahSquareSecondaryIconsData from "@data/diriyah-square/diriyahSquareSecondaryIcons"
import diriyahSquareZoneMaskData from "@data/diriyah-square/diriyahSquareZoneMask"
import icons360Data from "@data/diriyah-square/dsq360IconsData"

const DSQ = forwardRef(function DSQ(props: any, ref: any) {
  const { navDurationSec, mapCategory, setLocalState } = useStore((s) => ({
    navDurationSec: s.navDurationSec,
    mapCategory: s.mapCategory,
    setLocalState: s.setLocalState,
  }))
  const navigate = useNavigate()
  const timeline = gsap.timeline()
  const { videoPlayed, showUI, setShowUI, handleDataChange } = props

  function handleIconClick(category) {
    if (category === "retail") {
      setLocalState({
        dsqOrbitDir: true,
        dsqOrbitPlaying: true,
      })
      return
    }
    if (category === mapCategory) {
      setLocalState({ mapCategory: null, unitLevel: null })
    } else {
      setLocalState({ mapCategory: category, unitLevel: "0" })
    }
  }

  useEffect(() => {
    if (!videoPlayed) return
    timeline.to(
      ".dsq-mask",
      {
        opacity: (i) =>
          mapCategory === diriyahSquareZoneMaskData[i].activeState ? 0.6 : 0,
        duration: 0.8,
      },
      0
    )
    timeline.to(
      ".dsq-overlay",
      {
        opacity: mapCategory === null ? 0.6 : 0,
        duration: 0.8,
      },
      0
    )
    timeline.to(
      ".icon-square",
      {
        autoAlpha: mapCategory === null  ? 1 : 0,
        duration: 0.8,
      },
      0
    )
    timeline.to(
      ".secondary-icon",
      {
        autoAlpha: (i) =>
          mapCategory === diriyahSquareSecondaryIconsData[i].activeState
            ? 1
            : 0,
        duration: 0.8,
      },
      0
    )
    handleDataChange(mapCategory)
  }, [mapCategory])

  return (
    <SVG
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid slice"
      viewBox="0 0 2048 2048"
      onDoubleClick={(e) => {
        e.stopPropagation()
        setShowUI(!showUI)
      }}
      className="centerABS"
      ref={ref}
    >
      <image
        x={0}
        y={0}
        width={2048}
        height={2048}
        href={"/assets/images/dsq/dsq.webp"}
      />
      {/* ZONE OVERLAYS */}
      <g className={`mapUI ${showUI ? "show" : "hide"}`}>
        <g>
          {diriyahSquareZoneOverlayData.map((obj, i) => {
            return getMarkDown(obj, i, null, null)
          })}
        </g>
        {/* MASKS */}
        <g>
          {diriyahSquareZoneMaskData.map((obj, i) => {
            return getMarkDown(obj, i, null, null)
          })}
        </g>
        {/* ZONE ICONS */}
        <g>
          {icons360Data.map((obj, i) => {
            return (
              <Fragment key={`icon-${i}`}>
                <SVGPole
                  data={{
                    ...obj,
                    strokeWidth: 2,
                    strokeColor: "white",
                  }}
                  className="icon-square"
                />
                <MapSVGIconGroup
                  label={null}
                  x={obj.x}
                  y={obj.y - 200}
                  height={45}
                  icon={obj.icon}
                  //@ts-ignore
                  iconShape="circle"
                  bgColor={"var(--light-brown)"}
                  strokeColor={"white"}
                  strokeWidth={1}
                  hasIcon
                  className="icon-square"
                  onClick={(e) => {
                    e.stopPropagation()
                    setLocalState({
                      tourOpen: true,
                      tourIndex: obj.set,
                    })
                  }}
                />
              </Fragment>
            )
          })}
          {diriyahSquareZoneIconsData.map((obj, i) => {
            return (
              <Fragment key={`icon-${i}`}>
                <SVGPole data={obj} className="icon-square" />
                <MapSVGIconGroup
                  label={obj.label}
                  x={obj.x}
                  y={obj.y - 200}
                  height={obj.height}
                  icon={obj.icon}
                  //@ts-ignore
                  iconPosition={"center-top"}
                  //@ts-ignore
                  iconShape={obj.iconShape}
                  bgColor={obj.bgColor}
                  textColor={obj.textColor}
                  strokeColor={obj.strokeColor}
                  strokeWidth={obj.strokeWidth}
                  hasIcon
                  className="icon-square"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleIconClick(obj.activeState)
                  }}
                />
              </Fragment>
            )
          })}
        </g>
        {/* SECONDARY ICONS */}
        <g>
          {diriyahSquareSecondaryIconsData.map((obj, i) => {
            return (
              <MapSVGIconGroup
                label={obj.label}
                x={obj.x}
                y={obj.y}
                height={obj.height}
                icon={obj.icon}
                //@ts-ignore
                iconPosition={obj.iconPosition}
                //@ts-ignore
                iconShape={obj.iconShape}
                bgColor={obj.bgColor}
                textColor={obj.textColor}
                strokeColor={obj.strokeColor}
                strokeWidth={obj.strokeWidth}
                hasIcon
                key={`icon-${i}`}
                className="secondary-icon"
                onClick={(e) => {
                  e.stopPropagation()
                  if (obj.label === "FIND A UNIT") {
                    setLocalState({
                      navigating: true,
                      navOpen: false,
                      activeHotel: null,
                      amenityCategory: null,
                      mapCategory: null,
                      unitLevel: null,
                      selectedUnit: null,
                    })
                    setTimeout(() => {
                      navigate("/unit-finder")
                    }, navDurationSec * 1100)
                  } else {
                    if (obj.set) {
                      handleDataChange(obj.set)
                      return
                    } else {
                      handleIconClick(obj.activeState)
                    }
                  }
                }}
              />
            )
          })}
        </g>
      </g>
    </SVG>
  )
})

export default DSQ

const SVG = styled.svg``

function SVGPole({ data, ...props }) {
  return (
    <g {...props}>
      <line
        x1={data.x}
        y1={data.y - 200}
        x2={data.x}
        y2={data.y}
        stroke={data.strokeColor}
        strokeWidth={data.strokeWidth + 1}
      />
      <circle cx={data.x} cy={data.y} r={5} fill={data.strokeColor} />
    </g>
  )
}
