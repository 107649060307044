import mapGlobalValues from "../mapGlobalValues"

const baseConfig = {
  icon: mapGlobalValues.iconPaths.green.eye,
  bgColor: mapGlobalValues.zoneIconBackgroundColor,
  textColor: mapGlobalValues.zoneIconTextColor,
  height: 35,
  strokeColor: "white",
  strokeWidth: 2,
}

const data = [
  {
    label: "LIFESTYLE RETAIL",
    activeState: "lifestyle",
    x: 1482.72,
    y: 933.12,
  },
  {
    label: "DEPARTMENT STORE",
    activeState: "department",
    x: 341.21,
    y: 707.33,
  },
  {
    label: "ENTERTAINMENT DINING",
    activeState: "entertainment",
    x: 530.62,
    y: 795.89,
  },
  {
    label: "LUXURY RETAIL NORTH",
    activeState: "luxury-n",
    x: 770.69,
    y: 664.91,
  },
  {
    label: "LUXURY RETAIL SOUTH",
    activeState: "luxury-s",
    x: 1305.89,
    y: 702.2,
  },
  {
    label: "ANCHOR LIFESTYLE RETAIL",
    activeState: "anchor",
    x: 821,
    y: 881.8,
  },
  {
    label: "PREMIUM DINING",
    activeState: "premium-dining",
    x: 469.92,
    y: 1168.02,
  },
  {
    label: "CULTURE DINING",
    activeState: "culture",
    x: 1274.57,
    y: 842.85,
  },
  {
    label: "FIND A UNIT",
    activeState: "retail",
    x: 1024,
    y: 1024,
  },
  {
    label: "OVERVIEW",
    activeState: "retail",
    x: 1600,
    y: 727.33,
  },
]

const dsqRetailIcons = data.map((item) => {
  return item.label === "FIND A UNIT" || item.label === "OVERVIEW"
    ? {
        ...item,
        bgColor: mapGlobalValues.mainIconBackgroundColor,
        textColor: mapGlobalValues.mainIconTextColor,
        icon: mapGlobalValues.iconPaths.white.arrow,
        iconShape: "diamond",
        height: 50,
        strokeColor: "white",
        strokeWidth: 1,
      }
    : {
        ...item,
        ...baseConfig,
      }
})

export default dsqRetailIcons
