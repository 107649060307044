// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

import { forwardRef, useState, useEffect } from 'react';
import { extend, useThree } from '@react-three/fiber';
import { shaderMaterial } from '@react-three/drei';
import * as THREE from 'three';
import gsap from 'gsap';

//@ts-expect-error - no types
import vertex from './glsl/vertex.vert';
//@ts-expect-error - no types
import fragment from './glsl/fragment.frag';

const TransitionMaterial = shaderMaterial(
  {
    // @ts-expect-error - no types
    uOpacity: 0,
    // @ts-expect-error - no types
    uBlend: 0,
    // @ts-expect-error - no types
    uCurrentIndex: { value: 0 },
    // @ts-expect-error - no types
    uCurrentTexture: { value: new THREE.Texture() },
    // @ts-expect-error - no types
    uNextTexture: { value: new THREE.Texture() },
  },
  vertex,
  fragment,
  () => null,
);

extend({ TransitionMaterial });

type TProps = {
  textures: string[];
  triggerIndex: number;
  [x:string]: unknown;
};

const TextureTransitionMaterial = forwardRef(function TextureTransitionMaterial(
  { textures, triggerIndex, ...props }: TProps,
  ref,
) {
  const loader = new THREE.TextureLoader();
  const [firstLoad, setFirstLoad] = useState(true)
  const invalidate = useThree((state) => state.invalidate)

  useEffect(() => {
    loader.load(textures[triggerIndex], (texture) => {
      ref.current.uniforms.uCurrentTexture.value = texture;
      ref.current.uniforms.uNextTexture.value = texture;
      setFirstLoad(false)
    });

    return () => {
      setFirstLoad(true)
    }
  }, []);
  
  useEffect(() => {
    if(firstLoad) return
    loader.load(textures[triggerIndex], (texture) => {
      ref.current.uniforms.uNextTexture.value = texture;
      gsap.fromTo(ref.current.uniforms.uBlend,
        {value: 0},
        {
          value: 1,
          duration: 1.8,
          onComplete: () => {
            ref.current.uniforms.uCurrentTexture.value = texture;
          },
          onUpdate: () => invalidate()
        }
      )
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerIndex])

  return (
    // @ts-expect-error - no types
    <transitionMaterial ref={ref} transparent={true} {...props}/>
  );
});

export default TextureTransitionMaterial;
