import { useEffect, useRef, memo } from "react"
import styled from "styled-components"
import { SVGOverlay } from "react-leaflet"
import gsap from "gsap"

import useRasterCoords from "@hooks/leaflet/useRasterCoords"

function RasterMapLayer({
  img,
  active,
  children,
}: {
  img: [width: number, height: number]
  active: boolean
  children: any
}) {
  const rasterCoords = useRasterCoords(img)
  const bounds = rasterCoords.getMaxBounds()
  const overlayRef = useRef<SVGGElement>(null)

  useEffect(() => {
    if (!overlayRef.current) return
    gsap.to(overlayRef.current, {
      autoAlpha: active ? 1 : 0,
      duration: 0.4,
    })
  }, [active])

  return (
    <SVGOverlay
      attributes={{
        viewBox: "0 0 4096 4096",
      }}
      bounds={bounds}
      interactive={active}
    >
      <Group ref={overlayRef}>{children}</Group>
    </SVGOverlay>
  )
}

export default RasterMapLayer

const Group = styled.g`
  opacity: 0;
`
