const baseData = { 
    label: null,
    icon: "/assets/images/map/icons/orbitIconWhite.svg",
}

const data = [
  {
    set: 0,
    x: 720.15,
    y: 769.92,
  },
  {
    set: 3,
    x: 879.12,
    y: 814.32,
  },
  {
    set: 4,
    x: 1003.9,
    y: 802.03,
  },
  {
    set: 1,
    x: 1060.19,
    y: 878.64,
  },
  {
    set: 2,
    x: 1136.08,
    y: 910.75,
  },
]

const icons360Data = data.map(obj => {
  return {
    ...obj,
    ...baseData
  }
})

export default icons360Data