import styled from "styled-components"

const TableChart = () => {
  return (
    <TableChartWrapper>
      <div className="table-title">
        Total catchment size <br /> population growth (2024-2028)
      </div>
      <div className="grid-container">
        <div className="grid-item"></div>
        <div className="grid-item head">Variance</div>
        <div className="grid-item head">% increase</div>
        <div className="grid-item row">PCA</div>
        <div className="grid-item">285,600</div>
        <div className="grid-item">20%</div>
        <div className="grid-item row">SCA</div>
        <div className="grid-item">361,300</div>
        <div className="grid-item">16%</div>
      </div>
    </TableChartWrapper>
  )
}

export default TableChart

const TableChartWrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: #f0f0f0;
  border-radius: 4px;

  .table-title {
    font-size: 0.8vw;
    font-family: "BeausiteClassicTrial-Clear";
    font-weight: 400;
    padding: 0.5rem 1rem;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.darkGreen};
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 3px;
    padding: 1rem 1rem;
  }

  .grid-item {
    background-color: ${({ theme }) => theme.lightGreen};
    text-align: center;
    font-size: 0.8vw;
    font-weight: 400;
    padding: 0.5rem;
    color: #f0f0f0;

    &.head {
      font-size: 0.8vw;
      font-weight: 400;
      padding: 1rem;
      color: ${({ theme }) => theme.darkGreen};
    }

    &.row {
      font-size: 0.8vw;
      font-weight: 400;
      padding: 0.5rem;
      color: ${({ theme }) => theme.darkGreen};
    }
  }

  .grid-item:nth-child(3n + 1) {
    grid-column: span 1;
  }
`
