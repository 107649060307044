import * as React from "react"
import { motion } from "framer-motion"
import styled from "styled-components"
import marketShare from "@assets/images/metrics/market-share.svg"

export default function MarketShare() {
  return (
    <Wrapper>
      <div className="top">
        <div className="title">
          Diriyah Square: Resident Market Share (Yr 2028)
        </div>
      </div>
      <div className="content">
        <div className="box1">
          <motion.img
            src={marketShare}
            initial={{ scale: 1.05 }}
            animate={{ scale: 1 }}
            transition={{ duration: 1 }}
            exit={{ opacity: 0 }}
            alt="Income Profile"
          />
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled(motion.div)`
  position: relative;
  height: 100%;
  display: flex;
  background: #f0f0f0;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s ease-in forwards;

  .top {
    position: absolute;
    top: 0;
    left: 0;

    .title {
      padding: 1rem;
      font-size: 1.3rem;
      text-transform: uppercase;
      font-weight: 600;
      color: #004344;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .box1 {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 1rem;
      position: absolute;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 4px;
        filter: sepia(0) saturate(0.8) hue-rotate(-15deg) brightness(1)
          contrast(1);
      }
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
