import React, { useState } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import TabButton from "./components/TabButton"
import TabContent from "./components/TabContent"
import { useStore } from "@state/store"
import PageWrapper from "@components/PageWrapper"

const Features: React.FC<any> = () => {
  const [selectedTab, setSelectedTab] = useState(1)

  const { setShowNavbar, fullscreenIntroVideo, setFullscreeIntroVideo } =
    useStore((state) => ({
      setShowNavbar: state.setShowNavbar,
      fullscreenIntroVideo: state.fullscreenIntroVideo,
      setFullscreeIntroVideo: state.setFullscreenIntroVideo,
    }))

  const handleTabClick = (tabNumber: number) => {
    setSelectedTab(tabNumber)
  }

  const tabs = [
    { id: 1, label: "OVERVIEW" },
    { id: 2, label: "DGCL" },
  ]

  return (
    <PageWrapper>
      <Wrapper>
        <ButtonsWrapper>
          {tabs.map((tab) => (
            <TabButton
              key={tab.id}
              isSelected={selectedTab === tab.id}
              onClick={() => handleTabClick(tab.id)}
            >
              {tab.label}
            </TabButton>
          ))}
        </ButtonsWrapper>
        <TabContent selectedTab={selectedTab} />
      </Wrapper>
    </PageWrapper>
  )
}

export default Features

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 2em;
  gap: 2em;

  .button {
    box-shadow: none;
    z-index: 20;
    position: absolute;
    bottom: 40px;
    right: 2%;
  }

  .button:hover {
    opacity: 0.75;
  }
`

const ButtonsWrapper = styled.div`
  position: absolute;
  bottom: 30px;
  display: flex;
  gap: 1em;
`
