import { useEffect, useRef, useState } from "react"
import * as THREE from "three"

import TextureTransitionMaterial from "./TextureTransitionMaterial"

import gsap from "gsap"

type TProps = {
  textures: string[]
  textureTransform?: (texture: THREE.Texture) => void
  initialPosition: [number, number, number]
  activeIndex?: number
  display?: boolean
  onLoaded?: () => void
}

function Sphere({
  textures,
  activeIndex = 0,
  initialPosition,
  display,
  onLoaded,
}: TProps) {
  const [visible, setVisible] = useState(false)
  const matRef = useRef<THREE.MeshBasicMaterial>()

  useEffect(() => {
    //@ts-expect-error N/A
    gsap.to(matRef.current.uniforms.uOpacity, {
      value: display ? 1 : 0,
      delay: display ? 2 : 0,
      onStart: () => {
        display && setVisible(true)
      },
      onComplete: () => {
        !display && setVisible(false)
        if (display) {
          onLoaded && onLoaded()
        }
      },
    })
  }, [display])

  return (
    <mesh
      visible={visible}
      position={[0, 0, 0]}
      rotation={initialPosition}
      scale-x={-1}
    >
      <sphereGeometry args={[400, 60, 40]} />
      <TextureTransitionMaterial
        triggerIndex={activeIndex}
        textures={textures}
        toneMapped={false}
        side={THREE.BackSide}
        ref={matRef}
      />
    </mesh>
  )
}

export default Sphere
