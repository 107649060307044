import React, { useEffect, useRef } from "react"
import SVG from "react-inlinesvg"
import gsap from "gsap"
import styled from "styled-components"
import { useStore } from "@state/store"
import { Button } from "@components/buttons/Button"
import "./../svg-map-style.css"
import { useAuth } from "../../../auth/core/Auth"
import StaggeredText from "@components/StaggeredText"
import IntroMap from "@components/IntroMap"

type OverviewProps = {
  setVisionSection: (section: string) => void
}

const Overview: React.FC<OverviewProps> = ({ setVisionSection }) => {
  const { logout } = useAuth()
  const navigating = useStore((s) => s.navigating)
  const delay = useStore((s) => s.featuresAnimationDelay)
  const fullscreenIntroVideo = useStore((s) => s.fullscreenIntroVideo)
  const setFullscreenIntroVideo = useStore((s) => s.setFullscreenIntroVideo)

  const mainText = ["Saudi Arabia:", "A Progressive Kingdom."]
  const subText = [
    "HRH THE CROWN PRINCE MOHAMMED",
    "BIN SALMAN BIN ABDULAZIZ AL-SAUD",
  ]

  const visible = !fullscreenIntroVideo && !navigating
  useEffect(() => {
    gsap.to(".vision-anm-target", {
      x: visible ? "0%" : "-3%",
      opacity: visible ? 1 : 0,
      duration: visible ? 1 : 0.6,
      ease: "power1.inOut",
      delay: visible ? delay : 0,
    })
  }, [fullscreenIntroVideo, navigating])

  return (
    <Wrapper fullscreen={fullscreenIntroVideo} >
      <img
        style={{
          position: "absolute",
          zIndex: 2,
          top: "1em",
          right: "1em",
          height: "1.5em",
          width: "1.5em",
          cursor: "pointer",
          pointerEvents: "all"
        }}
        onClick={() => logout()}
        src="/assets/icons/common/logout.svg"
      />
      <IntroMap 
        className="centerABS"
        duration={!navigating ? 2 : 0.2}
        delay={!navigating ? .8 : 0}
        animated
        cue={!navigating}
        setSection={setVisionSection}
      />
      <div
        className={`splash-video ${fullscreenIntroVideo ? "" : "window"}`}
        onClick={() => setFullscreenIntroVideo(true)}
        style={{ overflow: "hidden" }}
      >
        <video loop muted autoPlay playsInline style={{ height: "150%" }}>
          <source src={"/assets/videos/vision.mp4"} type="video/mp4" />
        </video>
      </div>
      <Button
        style={{ flex: 1 }}
        icon="/assets/icons/common/arrow-right.svg"
        color="brown"
        title="The Vision"
        className="the-vision-button"
        onClick={() => setFullscreenIntroVideo(false)}
      />

      <div className="intro-content">
        <img
          className="vision-img vision-anm-target"
          src={"/assets/images/team/board/vision.jpg"}
          alt="Vision"
        />
        <div className="text-big vision-anm-target">
          {mainText.map((line, i) => {
            return (
              <StaggeredText
                key={`mainText${i}`}
                text={line}
                cue={!fullscreenIntroVideo}
                delay={delay + i * 0.25}
              />
            )
          })}
        </div>
        <p className="vision-anm-target">
          Strategically located at the crossroads of three continents, Saudi
          Arabia is undergoing a significant socio-economic transformation
          accordance with Saudi Vision 2030.
        </p>
        <p className="vision-anm-target">
          Riyadh, as its capital, plays crucial role in diriving the nation's
          economic, cultural, and tourism aspirations, attracting leading
          multinational organizations worldwide.
        </p>
      </div>
      <div className="text-small vision-anm-target">
        “My first objective is for our country to be a <br /> pioneering and
        successful global model of <br /> excellence, on all fronts, and I will
        work <br /> with you to achieve that” <br />
        <br />
        {subText.map((line, i) => {
          return (
            <StaggeredText
              key={`mainText${i}`}
              text={line}
              cue={!fullscreenIntroVideo}
              delay={delay + i * 0.25}
              style={{
                fontFamily: "BeausiteGrandTrial-Light",
                fontWeight: "200",
                letterSpacing: "0.08rem"
              }}
            />
          )
        })}
      </div>
      <Button
        icon="/assets/icons/common/arrow-right.svg"
        variant="square"
        className="enter-button"
        onClick={() => setVisionSection("maps")}
      />
    </Wrapper>
  )
}

export default Overview

const Wrapper = styled.div<{ fullscreen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 1;
  animation: fadeIn 1s ease forwards;

  .intro-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease-in-out;
    padding: var(--margin);
    pointer-events: none !important;

    & img {
      position: relative;
      top: 5rem;
      width: 20rem;
      height: auto;
      object-fit: cover;
    }

    .text-big {
      position: relative;
      top: 6rem;
      font-family: "BeausiteGrandTrial-Light";
      font-weight: 200;
      font-size: 2.5rem;
      z-index: 10;
      color: ${({ theme }) => theme.darkGreen};
    }

    p {
      display: block;
      width: 40%;
      position: relative;
      top: 6rem;
      font-size: 1rem;
      margin-top: 1rem;
      color: ${({ theme }) => theme.darkGreen};
    }
  }

  @media screen and (max-width: 1366px) {
    .intro-content img {
      width: 30rem;
    }
  }

  .vision-anm-target{
    pointer-events: none;
  }

  .the-vision-button {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 101;
    opacity: ${({ fullscreen }) => (fullscreen ? 1 : 0)};
    transition: all 0.5s ease-in-out;
  }

  .enter-button {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    z-index: 99;
  }

  .splash-video {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    transition: all 0.5s ease-in-out;
    pointer-events: all;

    &.window {
      bottom: 2rem;
      right: 7rem;
      width: 25rem;
      height: 20rem;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
    }
  }

  .text-small {
    font-family: "BeausiteClassicTrial-Clear";
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
    position: absolute;
    top: 6rem;
    right: 6rem;
    text-align: right;
    color: ${({ theme }) => theme.darkGreen};
  }

`

const SvgWrapper = styled.svg`
  width: 100%;
  height: 100%;
  pointer-events: "auto";
`
//@ts-ignore
const StyledSvg = styled(SVG)``
