export const amenities = [
  {
    name: "WELCOME HALLS",
    link: "welcome-halls",
    icon: "/assets/icons/common/welcomeHalls.svg",
    levels: [null, "0", "B1", "B2", "B3"]
  },
  {
    name: "DRIVER LOUNGES",
    link: "driver-lounges",
    icon: "/assets/icons/common/valet.svg",
    levels: [null, "B3"]
  },
  {
    name: "VALET LOUNGES",
    link: "valet-lounges",
    icon: "/assets/images/map/icons/flagIconGreen.svg",
    levels: [null, "0", "B1", "1"]
  },
  {
    name: "VIP LOUNGES",
    link: "vip-lounges",
    icon: "/assets/icons/common/star.svg",
    levels: [null, "B1", "0", "1"]
  },
  {
    name: "VVIP LOUNGES",
    link: "vvip-lounges",
    icon: "/assets/icons/common/star.svg",
    levels: [null, "B1", "0", "1"]
  },
  {
    name: "RESTROOMS",
    link: "restrooms",
    icon: "/assets/icons/common/baby.svg",
    levels: [null, "B3", "B2", "B1", "0"]
  },
]

export const connectionsMain = [
  {
    name: "EV CHARGING",
    link: "ev",
    icon: "/assets/icons/common/ev.svg",
    levels: [null, "B1", "B2", "B3"]
  },
  { name: "BUS", link: "bus", icon: "/assets/icons/common/bus.svg",
    levels: [null, "B2", "B3"]
   },
  {
    name: "TAXI / SHARE RIDE",
    link: "taxi",
    icon: "/assets/icons/common/valet.svg",
    levels: [null, "B2"]
  },
  {
    name: "VALET",
    link: "valet",
    icon: "/assets/icons/common/drop.svg",
    levels: [null, "B1"]
  },
]

export const layers = [
  {
    name: "TOGGLE OVERLAYS",
    link: "finderMapUI",
    icon: "/assets/icons/common/ev.svg",
  },
  {
    name: "ZONE OUTLINES",
    link: "finderOverlayLayer",
    icon: "/assets/icons/common/ev.svg",
  },
  {
    name: "UNIT OUTLINES",
    link: "finderPlotsLayer",
    icon: "/assets/icons/common/valet.svg",
  },
  {
    name: "UNIT LABELS",
    link: "finderLabelsLayer",
    icon: "/assets/icons/common/drop.svg",
  },
  {
    name: "GRAY MODE",
    link: "test",
    icon: "/assets/icons/common/drop.svg",
  },
]