import { paths } from "./sidePanelData.js"

const masterplanCopyData = {
  default: {
    name: "",
    title: "Diriyah, The City of Earth",
    desc: "Founded nearly 300 years ago, Diriyah is a symbol of unity, beauty, generosity, and the resilience of the Saudi nation and its people.",
    para: "Diriyah is set to become the world's foremost historical, cultural and lifestyle destination. This mixed-use development will deliver a new lifestyle experience for residents and visitors to Saudi Arabia. Combining global luxury with Najdi heritage, offering unparalleled shopping, dining, and living experiences.",
    swipeGalleryData: [{ src: paths.turaif.full, caption: "" }],
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    img: paths.turaif.full,
    imgThumb: paths.turaif.thumb,
    link: "masterplan",
    mapID: "masterplan",
  },
  qurain: {
    name: "QURAIN CULTURAL",
    title: "Qurain Cultural District",
    desc: "A 100% pedestrian environment, situated overlooking the Wadi Hanifah and adjacent to the historical site of At-Turaif, where heritage endures, culture evolves.",
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    para: "The district will include residences, hotels, galleries, retail, and office spaces that produce immersive creative experiences. Celebrating and reinterpreting the artistic traditions of Saudi Arabia, the district will contain six cultural academies hosting a variety of cultural programs. Peppered with public art and brought to life with homegrown artists, Qurain district will introduce the world to the wonders of Saudi art, in a location where tradition lives on through celebration and artistic expression.",
    swipeGalleryData: [{ src: paths.qurain.full, caption: "" }],
    img: paths.qurain.full,
    imgThumb: paths.qurain.thumb,
    link: "qurain",
    mapID: "masterplan",
  },
  samhan: {
    name: "SAMHAN",
    title: "Samhan",
    desc: "Rooted in historic significance, the Samhan District was once home to some of Diriyah’s earliest residents and still houses one of Diriyah’s oldest mosques.",
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    para: "Samhan provides authentic tranquil living within a city. Traditional Najdi design has been reimagined for contemporary living with emphasis on the courtyard element of Najdi residences. Samhan offers exceptional offices, premium retail, and convenience stores. Safe, connected, and completely walkable, the district offers a village way of life within The City of Earth.",
    swipeGalleryData: [{ src: paths.samhan.full, caption: "" }],
    img: paths.samhan.full,
    imgThumb: paths.samhan.thumb,
    link: "samhan",
    mapID: "masterplan",
  },
  king: {
    name: "KNOWLEDGE DISTRICT",
    title: "King Salman Knowledge District",
    desc: "King Salman Knowledge District will enthrall a host of intellectually and creatively minded audiences.",
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    para: "King Salman Knowledge District is a prestigious destination that fosters national pride, inviting people to walk in the footsteps of legendary Saudi Arabian heroes and kings. The district promises to delve into a profoundly rich heritage, literature, and culture.",
    swipeGalleryData: [{ src: paths.king1.full, caption: "" }, { src: paths.king2.full, caption: "" }],
    img: paths.knowledge.full,
    imgThumb: paths.knowledge.thumb,
    link: "king",
    mapID: "masterplan",
  },
  dsq: {
    name: "DIRIYAH SQUARE",
    title: "Diriyah Square",
    desc: "Through a selection of retail and dining destinations, visitors can relax and shop during the day then enjoy open-air entertainment at night.",
    feature1Text: "450+",
    feature1: "Iconic retail and dining outlets",
    feature2Text: "186k sqm",
    feature2: "Total retail",
    feature3: null,
    feature3Text: null,
    para: "At the heart of Diriyah, with excellent road and metro access, Diriyah Square blends over 450+ of the world’s finest retail brands with local artisanal goods. Fully pedestrian, the district exudes a Najdi village atmosphere.",
    swipeGalleryData: [{ src: paths.lifestyleRetail.full, caption: "" }],
    img: paths.lifestyleRetail.full,
    imgThumb: paths.lifestyleRetail.thumb,
    link: "dsq",
    mapID: "masterplan",
  },
  bujairi: {
    name: "BUJAIRI",
    title: "Bujairi",
    desc: "Bujairi is enhanced by its natural location and characteristic buildings constructed from local materials. Bujairi benefits from its own veritable microclimate.",
    feature1Text: null,
    feature1: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    para: "Situated between the event and cultural districts, and with direct pedestrian access to At-Turaif, Bujairi is home to the kingdom’s premier dining destination - Bujairi Terrace. Following its acclaimed opening in 2022, this outstanding culinary venue now offers over 20 world-class dining experiences celebrating the finest international cuisines and local flavours, as well as cultural activities and live events.",
    swipeGalleryData: [{ src: paths.bujairi.full, caption: "" }],
    img: paths.bujairi.full,
    imgThumb: paths.bujairi.thumb,
    link: "bujairi",
    mapID: "masterplan",
  },
  "diriyah-south": {
    name: "DIRIYAH SOUTH",
    title: "Diriyah South",
    desc: "Diriyah South embodies the Diriyah way of life through walkable residential areas set amongst some of the finest cultural destinations.",
    feature1Text: "9",
    feature1: "Cultural institutions",
    feature2Text: null,
    feature2: null,
    feature3: null,
    feature3Text: null,
    para: "With the greenery of the Wadi to the west, and the entertainment experiences of Diriyah Square to the north, Diriyah South is perfectly positioned for calm and comfort. Key to the district is Diriyah Gardens; a celebration of Arabic roots and Saudi heritage and a serene experience in the middle of the city.",
    swipeGalleryData: [{ src: paths.diriyahSouth.full, caption: "" }],
    img: paths.diriyahSouth.full,
    imgThumb: paths.diriyahSouth.thumb,
    link: "diriyah-south",
    mapID: "masterplan",
  },
  "events-district": {
    name: "EVENTS DISTRICT",
    title: "Events District",
    desc: "As a podium for Saudi ​and international events, it plays a significant role in placing Diriyah on the map and the promotion of its cultural effervescence.",
    feature1Text: null,
    feature1: null,
    feature2Text: null,
    feature2: null,
    feature3: null,
    feature3Text: null,
    para: "In a prime location, at the top of the city, with Bujairi Terrace to its left and King Salman Knowledge District below, the Events District is an extensive blank canvas for exceptional occasions. Vast in size, the space brings rhythm to the Diriyah way of life through a curated schedule of events.",
    swipeGalleryData: [{ src: paths.eventsDistrict.full, caption: "" }],
    img: paths.eventsDistrict.full,
    imgThumb: paths.eventsDistrict.thumb,
    link: "events-district",
    mapID: "masterplan",
  },
  "diriyah-gardens": {
    name: "",
    title: "Diriyah, The City of Earth",
    desc: "Founded nearly 300 years ago, Diriyah is a symbol of unity, beauty, generosity, and the resilience of the Saudi nation and its people.",
    para: "Diriyah is set to become the world's foremost historical, cultural and lifestyle destination. This mixed-use development will deliver a new lifestyle experience for residents and visitors to Saudi Arabia. Combining global luxury with Najdi heritage, offering unparalleled shopping, dining, and living experiences.",
    swipeGalleryData: [{ src: paths.turaif.full, caption: "" }],
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    img: paths.turaif.full,
    imgThumb: paths.turaif.thumb,
    link: "masterplan",
    mapID: "masterplan",
  },
  "creative-district": {
    name: "",
    title: "Creative District",
    desc: "The Creative District is where culture and commerce combine to form one of the The City of Earth’s most dynamic districts.",
    para: "Located at the pinnacle of Diriyah, it has been purpose built to evolve over time, transformed by those who live, work or stay there. Through its entrepreneurial spirit, the Creative District is an an incubator for young professionals and one of Diriyah’s most vibrant places. Whether you stay, or go, everyone will leave their mark, inspired by the Creative District’s accommodating personality and effervescence.",
    swipeGalleryData: [{ src: paths.turaif.full, caption: "" }],
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    img: paths.turaif.full,
    imgThumb: paths.turaif.thumb,
    link: "masterplan",
    mapID: "masterplan",
  },
  "boulevard-district": {
    name: "",
    title: "Boulevard District",
    desc: "Boulevard District is a broad horizon of commercial, cultural and business enterprises.",
    para: "Whilst the entire area has been designed on a grand scale, it remains true to the aesthetic heart of Diriyah, with buildings capped at 7 stories and walkways laced around them. Central to its namesake, the Boulevard is a landmark location for high-end retail, with one of a kind outlets from global brands, luxury department stores and grand cafes. Amongst the stately appearance of the tree-lined boulevard, one can soon feel at ease. Branching out along a network of pedestrian streets, the welcome respite of semiprivate courtyards are just a few steps away. Invitingly exuberant, the Boulevard District is a modern realisation of Diriyah’s timeless magnificence.",
    swipeGalleryData: [{ src: paths.turaif.full, caption: "" }],
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    img: paths.turaif.full,
    imgThumb: paths.turaif.thumb,
    link: "masterplan",
    mapID: "masterplan",
  },
  "media-district": {
    name: "",
    title: "Media and Innovation District",
    desc: "The Media District is a pulsating, 24/7 place, harmoniously connected to its environment. Eclectic in its mix of residential and commercial.",
    para: "Ideal for innovative professionals looking to synergise work and play. All along the Wadi, buildings integrate with nature for a seamless presence and look out to a backdrop naturally suited to wander or host live event reports. . Landscaping references its proximity to the Wadi, with plants and natural materials arranged in organic patterns throughout. Large trees offer sanctuaries of shade for residents and district employees to connect and gather outdoors The Media District will set a precedent, exemplifying how serene living benefits both the work and social life.",
    swipeGalleryData: [{ src: paths.turaif.full, caption: "" }],
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    img: paths.turaif.full,
    imgThumb: paths.turaif.thumb,
    link: "masterplan",
    mapID: "masterplan",
  },
  "civic-quarter": {
    name: "",
    title: "Civic Quarter",
    desc: "The Civic Quarter provides the resources to service Diriyah’s communities in one convenient place and seamlessly bonds it together via its two bridges and utility offerings.",
    para: "The iconic Heroes Park building has a flexible office activity-based environment. Quick service restaurants and retail outlets focused on health and experience are easily accessibleWe possess pability and licensing in-house to perform the following Design, Engineering, and Specialist Disciplines on ground level whilst well-being is well catered for with a sports club and a hospital. A command & control centre, a fire and police station and a post office form but a few of the services that sustain serenity in the City of Earth.",
    swipeGalleryData: [{ src: paths.turaif.full, caption: "" }],
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    img: paths.turaif.full,
    imgThumb: paths.turaif.thumb,
    link: "masterplan",
    mapID: "masterplan",
  },
  "ksu-district": {
    name: "",
    title: "KSU District",
    desc: "The King Saud University district is defined by the resolutely youthful spirit of its residents. Rising talents, on-the-go professionals and first home-owners with coffee houses, bookstores, public art and casual dining, KSU is the centre of the intellectual scene.",
    para: "Predominantly residential, the King Saud University district extends along the entire eastern side of Diriyah. The university, founded by King Saud bin Abdulaziz in 1952, was the first of its kind in the Kingdom of Saudi Arabia. Eclectic shopping, street performances and public discourses, the KSU district is a place for learning and sharing experiences. A fresh expression of Saudi Arabia’s compelling modern culture.",
    swipeGalleryData: [{ src: paths.turaif.full, caption: "" }],
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    img: paths.turaif.full,
    imgThumb: paths.turaif.thumb,
    link: "masterplan",
    mapID: "masterplan",
  },
  "khuzama-east": {
    name: "",
    title: "Khuzama East",
    desc: "Informal and inviting, Khuzama East is influenced by its close proximity to the Wadi. Buildings vary in size, shape and form, combined with an abundance of attentive landscaping, inspiring a relaxed appearance and a sense of well being.",
    para: "Some 6,000 of the finest accommodations make this one of Diriyah’s most exclusive neighbourhoods.Testament to the progressiveness of D2, public areas are calm and quiet, yet active. Communities interact  through pedestrian friendly paths and family focused parks, and benefit from being amongst natural plants and organic materials Retail requirements are met through the district’s own boutique boulevard, whilst K-12 schools, several mosques and kindergartens characterise the community that makes living in Diriyah so desirable.",
    swipeGalleryData: [{ src: paths.turaif.full, caption: "" }],
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    img: paths.turaif.full,
    imgThumb: paths.turaif.thumb,
    link: "masterplan",
    mapID: "masterplan",
  },
  "khuzama-west": {
    name: "",
    title: "Khuzama West",
    desc: "Positioned between the bustle of the Boulevard and the green of the Wadi Hanifah, Khuzama West blends exclusive living and hospitality with a natural relaxed atmosphere.",
    para: "One of Diriyah’s most exclusive neighbourhoods, community convenience is fulfilled through several retail outlets, wellness services and a scattering of restaurants overlooking the Wadi. The 400 room Antara and the 375 room Treehouse hotel, as well as the Conference & Exhibition Centre exemplify how Khuzama West represents the time-trusted spirit of Saudi hospitality.",
    swipeGalleryData: [{ src: paths.turaif.full, caption: "" }],
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    img: paths.turaif.full,
    imgThumb: paths.turaif.thumb,
    link: "masterplan",
    mapID: "masterplan",
  },
  "gateway": {
    name: "",
    title: "The Gateway",
    desc: "The Gateway district is your welcome when entering Diriyah. The entrance holds particular importance as it here where visitors experience the first charms of the city before extending their visit further within.",
    para: "The district’s progressive design ethos for a consistent aesthetic, integrates both sides of the highway for visual continuity across neighbourhoods. Primarily mixed use buildings range in height between 3-4 floors, transitioning seamlessly to the surrounding existing communities. 243 residential units, as well as a range of convenience services such as coffee shops and boutiques are all within walking distance of each other. A kindergarten accommodating 300 children, 1,346 car parking spaces and a mosque complete the district that is the passageway for people to enter and discover Diriyah.",
    swipeGalleryData: [{ src: paths.turaif.full, caption: "" }],
    feature1: null,
    feature1Text: null,
    feature2: null,
    feature2Text: null,
    feature3: null,
    feature3Text: null,
    img: paths.turaif.full,
    imgThumb: paths.turaif.thumb,
    link: "masterplan",
    mapID: "masterplan",
  },
}

export default masterplanCopyData
