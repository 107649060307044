import React from "react"
import styled from "styled-components"
import TabContent1 from "./Tabs/KeyFeatures"
import TabContent2 from "./Tabs/GuestAmenities"
import TabContent3 from "./Tabs/TenantFacilities"
import TabContent4 from "./Tabs/Sustainability"
import { AnimatePresence } from "framer-motion"
import { motion } from "framer-motion"

interface TabContentProps {
  selectedTab: number
}

const TabContent: React.FC<TabContentProps> = ({ selectedTab }) => {
  return (
    <AnimatePresence>
      <Content
        initial={{ opacity: 0, x: 0 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
        exit={{ opacity: 0, transition: { duration: 1 } }}
      >
        {selectedTab === 1 && <TabContent1 />}
        {selectedTab === 2 && <TabContent2 />}
        {selectedTab === 3 && <TabContent3 />}
        {selectedTab === 4 && <TabContent4 />}
      </Content>
    </AnimatePresence>
  )
}

export default TabContent

const Content = styled(motion.div)`
  margin-top: 0;
`
