import React from "react"
import styled from "styled-components"

interface TabButtonProps {
  isSelected: boolean
  children: React.ReactNode
  onClick: () => void
}

const TabButton: React.FC<TabButtonProps> = ({
  isSelected,
  onClick,
  children,
}) => {
  return (
    <Button onClick={onClick} isSelected={isSelected}>
      {children}
      {isSelected && <SelectedIndicator />}
    </Button>
  )
}

export default TabButton

const Button = styled.button<TabButtonProps>`
  z-index: 10;
  position: relative;
  padding: 0.5em 1em;
  background-color: transparent;
  color: ${({ theme }) => theme.darkGreen};
  border: none;
  cursor: pointer;
  font-family: 'BeausiteClassicTrial-Clear';
`

const SelectedIndicator = styled.div`
  position: absolute;
  bottom: -30%;
  left: 50%;
  transform: translateX(-50%);
  width: 5px;
  height: 5px;
  background-color: ${({ theme }) => theme.darkGreen};
  border-radius: 50%;
`
